import { sortData } from 'utils';

export const getStaff = (state) => {
  const { staff } = state;
  const { filters, data = [] } = staff;

  const { searchEmail, sortEmailDest } = filters;

  const filteredByEmail = data.filter(({ email }) => {
    if (!searchEmail) {
      return true;
    }

    return email.toLowerCase().includes(searchEmail.trim().toLowerCase());
  });

  return sortData(filteredByEmail, 'email', sortEmailDest);
};

export const getStaffLoading = (state) => state.staff.loading;
export const getStaffFilters = (state) => state.staff.filters;
export const getStaffError = (state) => state.staff.error;
