import React from 'react';

const EmptyIcon = () => (
  <svg width="270" height="178" viewBox="0 0 270 178" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12C0 5.37258 5.37258 0 12 0H258C264.627 0 270 5.37258 270 12V165.482C270 172.109 264.627 177.482 258 177.482H12C5.37258 177.482 0 172.109 0 165.482V12Z" fill="white" fillOpacity="0.04" />
    <g opacity="0.5">
      <path d="M176.572 64.6704L159.6 63.8475L158.612 52.7792C158.406 50.4545 156.328 48.8292 153.901 49.0144L93.0046 54.0136C90.577 54.2193 88.8283 56.1738 89.0134 58.478L93.3749 106.989C93.5807 109.314 95.6791 110.939 98.0862 110.754L101.172 110.507L100.678 119.93C100.555 122.522 102.571 124.62 105.287 124.764L173.219 127.994C175.935 128.118 178.177 126.225 178.321 123.633L181.181 69.464C181.304 66.8923 179.267 64.7939 176.572 64.6704ZM103.538 65.7608L102.077 93.4933L98.4976 98.5748L95.2059 61.9548C95.2059 61.9137 95.2059 61.8931 95.2059 61.8519C95.2059 61.8108 95.2059 61.7902 95.2059 61.7491C95.3088 60.7204 96.0906 59.8975 97.1604 59.8152L150.856 55.4126C151.926 55.3303 152.852 56.0298 153.016 57.0378C153.016 57.079 153.078 57.079 153.078 57.1201C153.078 57.1407 153.14 57.1613 153.14 57.2024L153.695 63.5389L108.64 61.3788C105.924 61.2965 103.661 63.1892 103.538 65.7608ZM172.273 114.498L162.665 103.121L157.007 96.3941C156.514 95.7975 155.711 95.3037 154.827 95.2626C153.942 95.2214 153.284 95.5712 152.543 96.1061L149.169 98.5543C148.449 98.9863 147.894 99.2743 147.132 99.2332C146.392 99.192 145.733 98.904 145.26 98.4514C145.096 98.2868 144.787 97.9988 144.54 97.7519L135.735 87.6917C135.097 86.8893 134.048 86.375 132.896 86.3133C131.723 86.2516 130.592 86.7453 129.851 87.4654L109.052 109.849L107.653 111.371L107.714 109.972L109.113 83.4536L109.792 70.5132C109.792 70.472 109.792 70.4309 109.792 70.4103C109.792 70.3692 109.792 70.328 109.792 70.3075C110.08 69.1965 111.068 68.3942 112.24 68.4559L154.251 70.472L160.155 70.7601L172.149 71.3361C173.342 71.3978 174.268 72.303 174.289 73.4346C174.289 73.4757 174.35 73.4963 174.35 73.5374C174.35 73.5786 174.412 73.5992 174.412 73.6403L172.273 114.498Z" fill="#91A2AD" />
      <path d="M159.209 89.7901C163.2 89.7901 166.45 86.5396 166.45 82.5484C166.45 78.5573 163.22 75.3067 159.209 75.3067C155.217 75.3067 151.967 78.5367 151.967 82.5484C151.967 86.5602 155.197 89.7901 159.209 89.7901Z" fill="#91A2AD" />
    </g>
  </svg>
);

export default EmptyIcon;
