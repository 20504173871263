import * as firebaseApp from 'firebase/app';
import * as firebaseMessaging from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class PushService {
  _listeners;

  constructor() {
    this._listeners = [];

    this._initMessaging();
  }

  addListener(listener) {
    this._listeners.push(listener);

    return () => this.removeListener(listener);
  }

  removeListener(listener) {
    this._listeners = this._listeners.filter((item) => item !== listener);
  }

  async register() {
    try {
      const serviceWorkerRegistration = await this._getServiceWorker();

      const currentToken = await firebaseMessaging.getToken(this.messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration,
      });

      return currentToken;
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
      return null;
    }
  }

  _initMessaging() {
    const app = firebaseApp.initializeApp(firebaseConfig);
    const messaging = firebaseMessaging.getMessaging(app);

    firebaseMessaging.onMessage(
      messaging,
      (payload) => this._callListeners(payload),
    );

    navigator.serviceWorker.addEventListener(
      'message',
      (event) => {
        if (event.data.isBackgroundPush) {
          this._callListeners(event.data);
        }
      },
    );

    this.messaging = messaging;
  }

  async _getServiceWorker() {
    const filename = 'firebase-messaging-sw.js';
    const swUrl = `/${filename}?${new URLSearchParams(firebaseConfig).toString()}`;
    const workers = await navigator.serviceWorker.getRegistrations();
    const currentWorker = workers.find(({ active }) => active.scriptURL.includes(`/${filename}`));

    // config same
    if (currentWorker?.active.scriptURL.endsWith(swUrl)) {
      return currentWorker;
    }

    // config changed
    await currentWorker?.unregister();

    return navigator.serviceWorker.register(swUrl);
  }

  _callListeners(payload) {
    const notification = JSON.parse(payload.data.json);
    this._listeners.forEach((listener) => {
      try { listener(notification); } catch (error) {
        console.error(error);
      }
    });
  }
}

export default new PushService();
