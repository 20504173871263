import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styles from 'pages/create-report/components/indexes-row/styles.module.scss';
import IndexItem from './index-item';

const metricsWeights = {
  viral: 30.3,
  relevant: 24.24,
  recent: 6.06,
  spread: 15.15,
  volume: 24.24,
};

const IndexesRow = (props) => {
  const { indexes = {}, onChange = () => {} } = props;
  const intl = useIntl();

  const [indexesValues, setIndexesValues] = useState(indexes);
  const maxValue = 10;
  const minValue = 1;

  const computeKymIndex = (newVals) => {
    const indexesNames = Object.keys(newVals);
    let sum = 0;

    try {
      indexesNames.forEach((indexName) => {
        if (newVals[indexName] && indexName !== 'kym') {
          sum += parseFloat(newVals[indexName]) * metricsWeights[indexName];
        }
      });

      if (sum && indexesNames.length) {
        return Math.round(sum / 100);
      }
    } catch (e) {
      return minValue;
    }

    return minValue;
  };

  const onChangeIndex = (name, value) => {
    if (value > maxValue || value < minValue) {
      return;
    }

    const newVals = { ...indexesValues };

    newVals[name] = value ? Math.abs(value) : value;

    const newKymIndex = computeKymIndex(newVals);
    newVals.kym = parseFloat(newKymIndex);
    setIndexesValues(newVals);
    onChange(newVals);
  };

  return (
    <div className={styles.container}>
      <div className={styles.rowsCont}>
        <div className={styles.row}>
          <IndexItem
            title={intl.formatMessage({ id: 'viral' })}
            name="viral"
            onChange={onChangeIndex}
            value={indexesValues.viral}
            tooltipText={intl.formatMessage({ id: 'viralTooltip' })}
          />
          <IndexItem
            title={intl.formatMessage({ id: 'relevant' })}
            name="relevant"
            onChange={onChangeIndex}
            value={indexesValues.relevant}
            tooltipText={intl.formatMessage({ id: 'relevantTooltip' })}
          />
          <IndexItem
            title={intl.formatMessage({ id: 'volume' })}
            name="volume"
            onChange={onChangeIndex}
            value={indexesValues.volume}
            tooltipText={intl.formatMessage({ id: 'volumeTooltip' })}
          />
        </div>

        <div className={styles.row}>
          <IndexItem
            title={intl.formatMessage({ id: 'recent' })}
            name="recent"
            onChange={onChangeIndex}
            value={indexesValues.recent}
            tooltipText={intl.formatMessage({ id: 'recentTooltip' })}
          />
          <IndexItem
            title={intl.formatMessage({ id: 'spread' })}
            name="spread"
            onChange={onChangeIndex}
            value={indexesValues.spread}
            tooltipText={intl.formatMessage({ id: 'spreadTooltip' })}
          />
          <div className={styles.itemContainer}>
            <div className={styles.kymIndexCont}>
              <div className={styles.kymIndexTitle}>
                KYM Index
              </div>
              <div className={styles.kymIndex}>
                {indexesValues.kym}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexesRow;
