import React from 'react';

const LandingSliderRightArrow = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.93934 12.9393C0.353554 13.5251 0.353554 14.4749 0.93934 15.0607C1.52513 15.6464 2.47487 15.6464 3.06066 15.0607L0.93934 12.9393ZM8 8L9.06066 9.06066C9.34197 8.77936 9.5 8.39782 9.5 8C9.5 7.60217 9.34197 7.22064 9.06066 6.93934L8 8ZM3.06066 0.93934C2.47487 0.353553 1.52513 0.353553 0.93934 0.93934C0.353553 1.52513 0.353553 2.47487 0.93934 3.06066L3.06066 0.93934ZM3.06066 15.0607L9.06066 9.06066L6.93934 6.93934L0.93934 12.9393L3.06066 15.0607ZM9.06066 6.93934L3.06066 0.93934L0.93934 3.06066L6.93934 9.06066L9.06066 6.93934Z" fill="url(#paint0_linear_29_84)" />
    <defs>
      <linearGradient id="paint0_linear_29_84" x1="8" y1="2" x2="6.13563" y2="14.6114" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7ED8FF" />
        <stop offset="1" stopColor="#C2C5FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default LandingSliderRightArrow;
