import React from 'react';

const Views = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_79:5595)">
      <path d="M15 6.01221C12.4244 6.02363 9.75465 6.65066 7.2274 7.85113C5.35095 8.77918 3.52225 10.0885 1.93485 11.7047C1.1552 12.5296 0.160775 13.7242 0 14.9987C0.019 16.1027 1.20325 17.4654 1.93485 18.2928C3.42342 19.8454 5.2045 21.1182 7.2274 22.1473C9.58418 23.291 12.1928 23.9496 15 23.9862C17.5781 23.9746 20.2472 23.3403 22.7717 22.1473C24.6481 21.2192 26.4777 19.9089 28.0651 18.2928C28.8448 17.4678 29.8392 16.2732 30 14.9987C29.981 13.8947 28.7967 12.5319 28.0651 11.7046C26.5766 10.152 24.7945 8.88013 22.7717 7.85108C20.4161 6.70818 17.8011 6.05418 15 6.01221ZM14.9981 8.24341C18.9011 8.24341 22.065 11.2683 22.065 14.9997C22.065 18.7311 18.9011 21.7559 14.9981 21.7559C11.0951 21.7559 7.93122 18.7311 7.93122 14.9997C7.93122 11.2683 11.0951 8.24341 14.9981 8.24341Z" fill="url(#paint0_radial_79:5595)" />
    </g>
    <g filter="url(#filter1_i_79:5595)">
      <path d="M14.9915 19.3127C17.4791 19.3127 19.4957 17.3807 19.4957 14.9974C19.4957 12.6142 17.4791 10.6821 14.9915 10.6821C12.5039 10.6821 10.4873 12.6141 10.4873 14.9974C10.4873 17.3807 12.5039 19.3127 14.9915 19.3127Z" fill="url(#paint1_radial_79:5595)" />
    </g>
    <defs>
      <filter id="filter0_i_79:5595" x="-1" y="6.01221" width="31" height="19.9741" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-1" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_79:5595" />
      </filter>
      <filter id="filter1_i_79:5595" x="8.4873" y="10.6821" width="11.0083" height="10.6304" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_79:5595" />
      </filter>
      <radialGradient id="paint0_radial_79:5595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.3913 30.6194) rotate(-73.7467) scale(25.6316 18.7555)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
      <radialGradient id="paint1_radial_79:5595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.39106 30.6194) rotate(-73.7467) scale(25.6316 18.7555)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Views;
