import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CURRENT_YEAR, MAIN_ROUTES } from 'consts';
import LogoIcon from 'components/svg-icons/logo-icon';
import GlossaryLink from 'admin/pages/glossary/link';
import styles from './styles.module.scss';

const { CONTACT_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS } = MAIN_ROUTES;

const Footer = ({ isAdmin }) => (
  <div className={styles.footer}>
    <div className="container">
      <div className={styles.footerContent}>
        <a href="/">
          <LogoIcon />
        </a>
        <div className={styles.footerLinksBlock}>
          {!isAdmin && (
            <a href={CONTACT_US}>
              <FormattedMessage id="contactUs" />
            </a>
          )}
          <a href={PRIVACY_POLICY}>
            <FormattedMessage id="privacyPolicy" />
          </a>
          <a href={TERMS_AND_CONDITIONS}>
            <FormattedMessage id="termsAndConditions" />
          </a>
          <GlossaryLink />
        </div>
        <span className={styles.copyright}>
          Copyright &copy;
          2010-{CURRENT_YEAR} {process.env.REACT_APP_TITLE}. All rights reserved
        </span>
      </div>
    </div>
  </div>
);

export default Footer;
