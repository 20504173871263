import apiService from 'services/apiService';
import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_LOADING,
} from './action.types';

const setNotifications = (data) => ({
  type: SET_NOTIFICATIONS,
  data,
});

export const setNotificationsLoading = (loading) => ({
  type: SET_NOTIFICATIONS_LOADING,
  loading,
});

export const requestNotifications = () => (dispatch) => {
  dispatch(setNotificationsLoading(true));
  apiService.getNotifications()
    .then((data) => {
      dispatch(setNotifications(data));
      dispatch(setNotificationsLoading(false));
    });
};

export const deleteNotifications = () => (dispatch) => apiService
  .deleteNotifications()
  .then(() => dispatch(requestNotifications()));
