const cookiesStorageSource = {
  name: 'cookiesStorageSource',

  get(vname) {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${vname.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  },

  add(vname, value, options = {}) {
    const nOptions = {
      path: '/',
      ...options,
    };
    if (nOptions.expires instanceof Date) {
      nOptions.expires = nOptions.expires.toUTCString();
    }
    let updatedCookie = `${encodeURIComponent(vname)}=${encodeURIComponent(value)}`;
    const optionsKeys = Object.keys(nOptions);
    optionsKeys.forEach((optionKey) => {
      updatedCookie += `; ${optionKey}`;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    });
    document.cookie = updatedCookie;
  },

  delete(vname) {
    this.add(vname, '', {
      'max-age': -1,
    });
  },

  clear() {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const ck = cookie.trim();
      const eqPos = ck.indexOf('=');
      const vname = eqPos > -1 ? ck.substr(0, eqPos) : ck;
      this.add(vname, '', {
        'max-age': -1,
      });
    });
  },
};

export default cookiesStorageSource;
