import React from 'react';
import TrendArrowIcon from 'components/svg-icons/trend-arrow-icon';
import { scrollTo } from 'utils';
import styles from './styles.module.scss';

const TrendItem = (props) => {
  const { data } = props;
  const { title, _id, typeKeysIcons } = data;

  const onTrendClick = () => {
    const trendSection = document.querySelector(`[data-section=trend-section-${_id}]`);
    scrollTo(trendSection);
  };

  return (
    <div className={styles.trend} onClick={onTrendClick}>
      <div className={styles.trendTitleWrapper}>
        <span className={styles.trendTitle}>
          { typeKeysIcons.length > 0 && (
            <span className={styles.typeKeysIcons}>{typeKeysIcons.join(' ')}</span>
          ) }
          {title}
        </span>
      </div>
      <div className={styles.goToTrendButton}>
        <TrendArrowIcon />
      </div>
    </div>
  );
};

export default TrendItem;
