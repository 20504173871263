import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Input from 'components/fields/input';
import Select from 'components/fields/select';
import CloseIcon from 'components/svg-icons/close-icon';
import IndexesRow from 'pages/create-report/components/indexes-row';
import TagsRow from 'pages/create-report/components/tags-row';
import { createEmptyLinkForm } from 'pages/create-report/form-factory';
import FormBox from '../form-box';
import FieldRow from '../field-row';
import LinkForm from './link-form';
import styles from './styles.module.scss';

const TrendForm = (props) => {
  const intl = useIntl();
  const {
    trends = [],
    indexTrend = 0,
    onDelete,
    data = {},
    onChange = () => {},
    clearError = () => {},
    error,
    categories,
    typeKeys,
  } = props;

  const {
    _id: trendId,
    posts = [],
    categoryId,
    title,
    description,
    typesKeys = [],
    tags = [],
    indexMetrics = {
      viral: 1,
      recent: 1,
      relevant: 1,
      spread: 1,
      volume: 1,
      kym: 1,
    },
  } = data;

  const onDeleteClick = () => {
    onDelete(trendId);
  };

  const updateTrendData = (trendId, newData) => {
    const changedTrends = trends.map((trend) => {
      if (trend._id !== trendId) {
        return trend;
      }

      return newData;
    });

    onChange('trends', changedTrends);
  };

  const onAddLink = () => {
    const newData = { ...data };
    newData.posts = [...newData.posts, createEmptyLinkForm()];

    updateTrendData(trendId, newData);
  };

  const onDeleteLink = (linkId) => {
    clearError();
    const newData = { ...data };
    newData.posts = newData.posts.filter(({ _id }) => _id !== linkId);

    updateTrendData(trendId, newData);
  };

  const onPostChange = (linkId, params, value) => {
    const newData = data;
    let newPosts;

    if (typeof params === 'string') {
      const name = params;

      newPosts = newData.posts.map((post) => {
        if (post._id !== linkId) {
          return post;
        }

        return {
          ...post,
          [name]: value,
        };
      });
    }

    if (typeof params === 'object') {
      newPosts = newData.posts.map((post) => {
        if (post._id !== linkId) {
          return post;
        }

        return { ...post, ...params };
      });
    }

    newData.posts = newPosts;

    updateTrendData(trendId, newData);
  };

  const onSelectCategory = (name, value) => {
    const newData = { ...data };
    newData.categoryId = value;
    updateTrendData(trendId, newData);
  };

  const onTagsChange = (value) => {
    const newData = { ...data };
    newData.tags = value.map((tag) => (tag.value.replace(/#+/g, '')));
    updateTrendData(trendId, newData);
  };

  const onIndexesChange = (value) => {
    const newData = { ...data };
    newData.indexMetrics = value;
    updateTrendData(trendId, newData);
  };

  const onTrendChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;

    updateTrendData(trendId, newData);
  };

  const getSelectValue = () => {
    if (categories?.length && categoryId) {
      const category = categories.find((category) => category.value === categoryId);
      return category?.value;
    }

    return null;
  };

  return (
    <div className={styles.trendForm}>
      <FormBox>
        <div className={styles.removeTrend} onClick={onDeleteClick}>
          <CloseIcon />
        </div>
        <div className={styles.generalTrendData}>
          <div className={styles.formRow}>
            <div className={styles.formRowFields}>
              <FieldRow label={`${intl.formatMessage({ id: 'trend' })} #${indexTrend + 1}`}>
                <Input
                  name="title"
                  value={title}
                  placeholder="e.g. toys"
                  fullWidth
                  onChange={onTrendChange}
                  errorMessage={error && error.title}
                />
              </FieldRow>
              <FieldRow label={intl.formatMessage({ id: 'trendCategory' })}>
                <Select
                  placeholder={intl.formatMessage({ id: 'trendCategory' })}
                  fullWidth
                  options={categories}
                  errorMessage={error && error.categoryId}
                  value={getSelectValue()}
                  onChange={onSelectCategory}
                />
              </FieldRow>
            </div>
            <div className={styles.formRowFields}>
              <FieldRow label={intl.formatMessage({ id: 'trendDescription' })}>
                <Input
                  name="description"
                  value={description}
                  placeholder={intl.formatMessage({ id: 'shortTrendDescription' })}
                  fullWidth
                  type="textarea"
                  onChange={onTrendChange}
                  errorMessage={error && error.description}
                />
              </FieldRow>
            </div>
          </div>

          <div className={classNames(styles.formRow, styles.indexesFormRow)}>
            <div className={styles.selectRow}>
              <div className={styles.selectTitle}>
                <FormattedMessage id="trendTypesKeys" />
              </div>
              <Select
                multiple
                value={typesKeys}
                name="typesKeys"
                options={typeKeys}
                onChange={onTrendChange}
                placeholder={intl.formatMessage({ id: 'trendTypesKeys' })}
                fullWidth
                errorMessage={error && error.typesKeys}
                isTabs
              />
            </div>
            <div className={styles.formRowFields}>
              <IndexesRow indexes={indexMetrics} onChange={onIndexesChange} />
            </div>
          </div>

          <div className={classNames(styles.formRow, styles.tagsFormRow)}>
            <div className={styles.formRowFields}>
              <div>
                <div className={styles.blockTitle}><FormattedMessage id="trendTags" /></div>
                <TagsRow onChange={onTagsChange} tags={tags} />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.formRow, styles.linksFormRow)}>
          {posts.map((postData, index) => (
            <div className={styles.formField} key={postData._id}>
              <LinkForm
                data={postData}
                indexLink={index}
                onChange={onPostChange}
                onDelete={onDeleteLink}
                error={error && error.posts && error.posts[index]}
              />
            </div>
          ))}
          <div className={styles.formField}>
            <div className={styles.addLinkWrapper} onClick={onAddLink}>
              <span className={styles.addLinkText}>
                + {intl.formatMessage({ id: 'addLink' })}
              </span>
            </div>
          </div>
        </div>
      </FormBox>
    </div>
  );
};

export default TrendForm;
