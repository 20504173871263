import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import Tag from './tag';
import styles from './styles.module.scss';

const Select = (props) => {
  const {
    name,
    value,
    onChange = () => {},
    placeholder = '',
    searchPlaceholder = '',
    options = [],
    selectAll = false,
    fullWidth = false,
    multiple = false,
    withSearch = false,
    colorTheme,
    renderOption,
    errorMessage,
    isTabs = false,
    canScroll = false,
    openLock = false,
  } = props;

  const selectAllKey = 'selectAll';

  const [open, setOpen] = useState(openLock);
  const [searchValue, setSearchValue] = useState('');
  const [allSelected, setAllSelected] = useState(options.length === value.length);
  const refSelect = useRef();
  const intl = useIntl();

  useEffect(() => {
    setAllSelected(options.length === value.length);
  }, [value.length, options.length]);

  const clearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && refSelect.current && !refSelect.current.contains(e.target)) {
        setOpen(false);
        if (withSearch) {
          clearSearch();
        }
      }
    };

    if (!openLock) {
      document.addEventListener('mousedown', checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open, clearSearch]);

  const toggleOpen = (e, isCloseBut = false) => {
    if (false) {
      return null;
    }

    e.preventDefault();
    e.stopPropagation();

    if (open && withSearch) {
      clearSearch();
    }

    if (multiple && e?.currentTarget?.className?.includes('selectText')) {
      return !(isTabs && open) && setOpen(!open);
    }

    if (multiple
      && open
      && refSelect.current.contains(e.target)
      && !isCloseBut
    ) {
      return null;
    }

    return setOpen(!open);
  };

  const tabClickHandler = (e) => {
    if (openLock) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const handleChange = (optionValue) => () => {
    if (!multiple && !openLock) {
      setOpen(false);
      if (withSearch) {
        clearSearch();
      }
    }

    if (multiple) {
      let newValue = [...value];

      if (selectAll && optionValue === selectAllKey) {
        const isSelected = !allSelected;

        setAllSelected(isSelected);
        let newValues = [];

        if (isSelected) {
          newValues = options.map((item) => {
            return item.value;
          });
        }

        onChange(name, newValues);

        return;
      }

      if (newValue.includes(optionValue)) {
        newValue = newValue.filter((v) => v !== optionValue);
      } else {
        newValue = [...newValue, optionValue];
      }

      if (selectAll) {
        setAllSelected(options.length === newValue.length);
      }

      onChange(name, newValue);
      return;
    }

    onChange(name, optionValue);
  };

  const onSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const filteredOptions = searchValue
    ? options.filter((option) => option.name.toLowerCase().match(searchValue.toLowerCase().trim()))
    : options;

  const getCurrentNameValue = () => {
    if (!value) {
      return placeholder;
    }

    if (multiple) {
      if (
        !value?.length
        || !Array.isArray(value)
        || !options.find(({ value: optionValue }) => value.includes(optionValue))
      ) {
        return placeholder;
      }

      return value.map((val) => {
        const option = options.find(({ value: optionValue }) => optionValue === val);

        if (!option) {
          return null;
        }

        const { name, value } = option;
        return <Tag name={name} value={value} key={value} onRemove={handleChange(value)} />;
      });
    }

    const option = options.find(({ value: optionValue }) => optionValue === value);

    if (!option) {
      return '';
    }

    return option.name;
  };

  return (
    <div
      className={classNames(
        styles.selectWrapper,
        styles[colorTheme],
        { [styles.fullWidth]: fullWidth },
        { [styles.selectWrapperActive]: open || openLock },
      )}
      ref={refSelect}
    >
      <div
        className={classNames(styles.selectItem,
          { [styles.active]: open },
          { [styles.error]: errorMessage })}
        onClick={toggleOpen}
      >
        <span
          className={classNames(styles.selectText, { [styles.whiteSpaceNormal]: multiple })}
          onClick={toggleOpen}
        >
          {getCurrentNameValue()}
        </span>
        {!openLock && (
          <div
            className={styles.selectIcon}
            onClick={isTabs ? tabClickHandler : (e) => toggleOpen(e, true)}
          >
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="currentColor" strokeWidth="1.5" />
            </svg>
          </div>
        )}

        <div
          aria-label="select options"
          className={classNames(
            styles.selectOptions,
            { [styles.open]: open || openLock },
            { [styles.scrollStyle]: canScroll },
          )}
        >
          {withSearch && (
            <div className={styles.searchWrapper}>
              <Input
                value={searchValue}
                icon={<SearchIcon />}
                placeholder={searchPlaceholder}
                onChange={onSearchChange}
              />
            </div>
          )}

          {multiple && selectAll && (
            <div
              key={selectAllKey}
              className={classNames(
                styles.option,
                { [styles.selected]: allSelected, [styles.multiple]: multiple },
              )}
              onClick={handleChange(selectAllKey)}
            >
              <span className={styles.optionName}><FormattedMessage id="selectAll" /></span>
              <div className={classNames(classNames(
                styles.checkBox,
                { [styles.checked]: allSelected },
              ))}
              />
            </div>
          )}

          {filteredOptions.map((option) => {
            const { name, value: optionValue, flag = '' } = option;
            const isSelected = multiple && Array.isArray(value)
              ? value.includes(optionValue)
              : value === optionValue;

            return (
              <div
                key={optionValue}
                className={classNames(
                  styles.option,
                  { [styles.selected]: isSelected, [styles.multiple]: multiple },
                )}
                onClick={handleChange(optionValue)}
              >
                {flag && (
                  <div className={styles.flagWrap}>
                    {flag}
                  </div>
                )}
                { typeof renderOption === 'function' ? renderOption(option, intl, value) : (
                  <span className={styles.optionName}>{name}</span>
                )}
                { multiple && (
                  <div className={classNames(classNames(
                    styles.checkBox,
                    { [styles.checked]: isSelected },
                  ))}
                  />
                ) }
              </div>
            );
          })}
        </div>
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default Select;
