import React from 'react';

const Shares = () => (
  <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_79:5583)">
      <path d="M10.4297 1.8594C10.4297 1.21212 10.9543 0.687525 11.6016 0.687525H21.4453C24.0301 0.687525 26.1328 2.79049 26.1328 5.37502V14.9258C26.1328 15.5731 25.6082 16.0977 24.961 16.0977C24.3137 16.0977 23.7891 15.5731 23.7891 14.9258V5.37502C23.7891 4.08276 22.7376 3.03127 21.4453 3.03127H11.6016C10.9543 3.03127 10.4297 2.50668 10.4297 1.8594ZM29.6512 16.0837C29.1905 15.6294 28.4484 15.6344 27.9939 16.0952L25.3867 18.7383C25.2764 18.8491 25.1306 18.9102 24.9758 18.9102C24.9761 18.9102 24.9756 18.9102 24.9758 18.9102C24.8213 18.9102 24.6751 18.8493 24.5648 18.7387L21.9235 16.0906C21.4664 15.6321 20.7244 15.6312 20.2661 16.0883C19.8079 16.5454 19.807 17.2874 20.2641 17.7456L22.9054 20.394C23.4586 20.9486 24.1938 21.2539 24.9754 21.2539H24.9774C25.7598 21.2535 26.4954 20.947 27.0518 20.3876L29.6624 17.7411C30.117 17.2803 30.1119 16.5383 29.6512 16.0837ZM18.2813 18.9688H8.55471C7.26245 18.9688 6.21096 17.9175 6.21096 16.625V6.78127C6.21096 6.134 5.68637 5.6094 5.03909 5.6094C4.39181 5.6094 3.86721 6.134 3.86721 6.78127V16.625C3.86721 19.2098 5.96995 21.3125 8.55471 21.3125H18.2813C18.9286 21.3125 19.4532 20.7879 19.4532 20.1406C19.4532 19.4934 18.9286 18.9688 18.2813 18.9688ZM5.02421 3.03127C5.17871 3.03127 5.32496 3.09216 5.43528 3.20271L8.07658 5.8511C8.30546 6.08067 8.60598 6.19534 8.90628 6.19534C9.20565 6.19534 9.50503 6.08135 9.73391 5.85316C10.1921 5.39631 10.1931 4.65428 9.73597 4.19605L7.09468 1.54766C6.54101 0.992624 5.80584 0.68432 5.02261 0.687525C4.24029 0.688211 3.50467 0.994455 2.94825 1.55384L0.337629 4.20063C-0.116931 4.66137 -0.111895 5.40318 0.349073 5.85774C0.809583 6.3123 1.55162 6.30726 2.00618 5.84652L4.6136 3.20339C4.72369 3.09261 4.86949 3.0315 5.02421 3.03127Z" fill="url(#paint0_radial_79:5583)" />
    </g>
    <defs>
      <filter id="filter0_i_79:5583" x="-2" y="0.6875" width="32" height="23.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_79:5583" />
      </filter>
      <radialGradient id="paint0_radial_79:5583" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.39132 28.9241) rotate(-75.7448) scale(29.1337 18.9348)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Shares;
