import EmptyCheckBoxIcon from 'components/svg-icons/empty-checkbox-icon';
import classNames from 'classnames';
import CheckedCheckboxIcon from 'components/svg-icons/checked-checkbox-icon';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const Checkbox = (props) => {
  const isClient = useSelector(getIsClient);

  const {
    componentStyle = {},
    onChange = () => {},
    title = '',
    checked,
    icon,
    flag = '',
  } = props;

  return (
    <div
      className={classNames(
        styles.checkItemLine,
        componentStyle.container,
        { [styles.checkedItemLine]: checked },
        { [styles.checkLineClient]: isClient },
      )}
      onClick={() => onChange({ checked: !checked })}
    >
      <div className={styles.container}>
        { !checked ? (
          <EmptyCheckBoxIcon className={classNames(
            styles.unselectedContainer,
            { [styles.unselectedContainerClient]: isClient },
          )}
          />
        ) : <CheckedCheckboxIcon /> }
      </div>
      <div className={styles.checkLineName}>
        {icon && (
          <div className={classNames(
            [styles.logoLinkContainer, { [styles.logoLinkClient]: isClient }],
          )}
          >
            <img src={icon} alt="" />
          </div>
        )}
        {flag && (
          <div className={classNames(styles.flagWrap, { [styles.flagWrapClient]: isClient })}>
            {flag}
          </div>
        )}
        <div className={classNames(styles.titleStyle, componentStyle.title)}>
          {title}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
