import React, { useEffect, useState, useRef } from 'react';
import SmileIcon from 'components/svg-icons/smile-icon';
import EmojiPicker from './emoji-picker';
import styles from './styles.module.scss';

const EmojiComment = ({ onEmojiClick, inputElem }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [pos, setPos] = useState(-1);

  const clickIcon = () => {
    setShowPicker(!showPicker);
    setPos(-1);
  };

  const emojiClickHandler = (emoji) => {
    let position = pos;
    if (position < 0) {
      position = inputElem?.selectionStart || 0;
    }

    onEmojiClick(emoji, position);
    setPos(position + emoji.length);
  };

  const refEmoji = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (refEmoji.current && !refEmoji.current.contains(e.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div className={styles.emojiCommentContainer}>
      <div onClick={clickIcon} className={styles.iconWrap}>
        <SmileIcon />
      </div>

      {showPicker && (
        <div className={styles.emojiPickerContainer} ref={refEmoji}>
          <EmojiPicker output={emojiClickHandler} />
        </div>
      )}

    </div>
  );
};

export default EmojiComment;
