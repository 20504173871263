import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { COLOR_THEMES } from 'consts';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import Button from 'components/button';
import SearchIcon from 'components/svg-icons/search-icon';
import AddCompany from 'admin/pages/companies/add-company';
import { setCompanyFilter } from 'store/reducers/companies/actions';
import { getCompanyFilters } from 'store/reducers/companies/selectors';

const CompaniesFilters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filters = useSelector(getCompanyFilters);

  const [addCompany, setAddCompany] = useState(false);

  const openAddCompanyPopup = () => {
    setAddCompany(true);
  };

  const closeAddCompanyPopup = () => {
    setAddCompany(false);
  };

  const onFilterChange = (name, value) => {
    dispatch(setCompanyFilter(name, value));
  };

  const { searchName, sorting } = filters;

  return (
    <>
      { addCompany && <AddCompany onCancel={closeAddCompanyPopup} /> }
      <Button onClick={openAddCompanyPopup} colorTheme={COLOR_THEMES.LIGHT}>{intl.formatMessage({ id: 'addCompany' })}</Button>
      <Select
        onChange={onFilterChange}
        name="sorting"
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        value={sorting}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'alphabetAsc' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'alphabetDesc' },
          { name: intl.formatMessage({ id: 'moreUsers' }), value: 'moreUsers' },
          { name: intl.formatMessage({ id: 'lessUsers' }), value: 'lessUsers' },
        ]}
      />
      <Input
        onChange={onFilterChange}
        name="searchName"
        value={searchName}
        icon={<SearchIcon />}
        placeholder={intl.formatMessage({ id: 'typeCompanyName' })}
      />
    </>
  );
};

export default CompaniesFilters;
