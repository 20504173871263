import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import ApiService from 'services/apiService';
import MentionItem from './item';
import styles from './styles.module.scss';

const MentionWrapper = (props) => {
  const { onChange, value, onSubmit = () => {} } = props;
  const isEmptyTextArea = !value.trim();

  const fetchUsers = (query, callback) => {
    if (!query) {
      return;
    }

    ApiService.getMentionUsers(query).then((res) => {
      if (res && res.data) {
        return res.data.filter(({ name }) => !!name.trim()).map((user) => ({
          display: user.name,
          id: user.email,
          picture: user.picture,
        }));
      }

      return [];
    })
      .then(callback);
  };

  const onKeyDown = (e) => {
    const enterKeyCode = 13;

    if (e.keyCode === enterKeyCode && !e.shiftKey) {
      e.preventDefault();
      if (isEmptyTextArea) {
        return;
      }

      onSubmit();
    }
  };

  return (
    <MentionsInput
      value={value}
      onChange={onChange}
      className="mentions"
      classNames={styles}
      onKeyDown={onKeyDown}
    >
      <Mention
        displayTransform={(id, display) => `@${display}`}
        trigger="@"
        data={fetchUsers}
        renderSuggestion={MentionItem}
        className={styles.mention}
      />
    </MentionsInput>
  );
};

export default MentionWrapper;
