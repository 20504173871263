import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { MODAL_PORTAL_ID } from 'consts';
import styles from './styles.module.scss';

const ImagePopup = ({ onClose = () => {}, imgUrl }) => {
  const modalRoot = document.getElementById(MODAL_PORTAL_ID);

  const PopupLayout = (
    <div className={styles.popupLayout}>
      <div onClick={onClose} className={classNames(styles.overlay, styles.dark)} />
      <div className={classNames(styles.popup)}>
        <img alt="" src={imgUrl} />
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    PopupLayout,
    modalRoot,
  );
};

export default ImagePopup;
