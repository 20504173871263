import React, { useRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { isValidDate, convertDateToUTC } from 'utils';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const getDefaultDate = (zeroTime = true) => {
  const clearDate = new Date();

  if (zeroTime) {
    clearDate.setHours(0, 0, 0, 0);
  }

  return clearDate;
};

const Datepicker = (props) => {
  const {
    name,
    value,
    onChange = () => {},
    placeholder = '',
    icon,
    fullWidth = false,
    colorTheme,
    prefix,
    errorMessage,
    disabled,
    delay = null,
    notBordered = false,
    autoFocus = false,
    zeroTime = true,
  } = props;

  const [date, setDate] = useState(getDefaultDate(zeroTime));

  let timer = useRef();

  useEffect(() => {
    let newDate = typeof value === 'string' ? convertDateToUTC(new Date(value)) : value;

    if (!isValidDate(newDate)) {
      newDate = getDefaultDate(zeroTime);
    }

    if (zeroTime) {
      newDate.setHours(0, 0, 0, 0);
    }

    setDate(newDate);
  }, [value]);

  useEffect(() => {
    return () => {
      clearTimeout(timer?.current);
      timer = null;
    };
  }, []);

  const handleInputChange = (date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    const { current } = timer;

    if (current) {
      clearTimeout(timer.current);
    }

    if (zeroTime) {
      date.setHours(0, 0, 0, 0);
    }

    setDate(date);

    if (!delay) {
      onChange(name, date);
      return;
    }

    timer.current = setTimeout(() => {
      onChange(name, date);
    }, delay);
  };

  const inputProps = {
    autoComplete: 'off',
    className: styles.input,
    name,
    placeholder,
    selected: date,
    disabled,
    autoFocus,
    onChange: handleInputChange,
    shouldCloseOnSelect: true,
    dateFormat: 'MMM dd, yyyy',
    popperPlacement: 'bottom-start',
    popperModifiers: [
      {
        name: 'arrow',
        options: {
          padding: 110,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom'],
        },
      },
    ],
  };

  return (
    <>
      <label
        htmlFor={name}
        className={classNames(
          styles.inputWrapper,
          {
            [styles.withIcon]: icon,
            [styles.fullWidth]: fullWidth,
            [styles.withPrefix]: prefix,
            [styles.error]: errorMessage,
            [styles.disabled]: disabled,
            [styles.notBordered]: notBordered,
          },
          styles[colorTheme],
        )}
      >
        <DatePicker {...inputProps} />
      </label>
      {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
    </>
  );
};

export default Datepicker;
