export const getCompanies = (state) => {
  const { trendComments } = state;
  const { commentsData } = trendComments;

  return commentsData;
};

export const getCommentsListLoading = (state) => state.trendComments.listLoading;
export const getCommentsData = (state) => state.trendComments.commentsData;
export const getCommentsForm = (state) => state.trendComments.commentForm;
