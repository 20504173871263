import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { COLOR_THEMES, LOGIN_ROLES, ROLE_THEMES } from 'consts';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import ConfirmPopup from 'components/confirm-popup';
import { deleteUser } from 'store/reducers/companies/actions';
import AddUserPopup from 'components/add-user-popup';

import styles from './styles.module.scss';

const UserItem = (props) => {
  const {
    data,
    colorTheme,
    disableActions,
  } = props;
  const { DARK, LIGHT } = ROLE_THEMES;
  const { PRIMARY, SECONDARY } = COLOR_THEMES;

  const {
    email,
    companyId,
    _id,
    role,
  } = data;

  const dispatch = useDispatch();
  const [deleteAction, setDeleteAction] = useState(false);
  const [edit, setEdit] = useState(false);

  const deleteIconStyles = {
    mainContainer: styles.deleteButton,
  };

  const editIconStyles = {
    mainContainer: styles.editButton,
  };

  const onDelete = async () => {
    dispatch(deleteUser(companyId, _id));
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(!edit);
  };

  const showBadge = colorTheme === LIGHT || (
    colorTheme === DARK && role === LOGIN_ROLES.POWER_CLIENT
  );

  const buttonsColorTheme = colorTheme === LIGHT ? SECONDARY : PRIMARY;

  return (
    <>
      { deleteAction
      && (
      <ConfirmPopup
        colorTheme={colorTheme}
        onCancel={toggleDelete}
        submitFunction={onDelete}
      />
      ) }
      { edit && (
        <AddUserPopup
          prefilledData={data}
          colorTheme={colorTheme}
          companyId={companyId}
          onCancel={toggleEdit}
        />
      ) }
      <div className={classNames(styles.user, styles[colorTheme])}>
        <div className={styles.userNameWrapper}>
          <span className={styles.userName}>{email}</span>
          {showBadge && <div className={styles.tag}><FormattedMessage id={role} /></div>}
        </div>
        <div className={styles.actions}>
          <Button
            colorTheme={buttonsColorTheme}
            withBorder
            onClick={toggleEdit}
            onlyIcon
            componentStyle={editIconStyles}
          >
            <EditIcon />
          </Button>
          <Button
            colorTheme={buttonsColorTheme}
            withBorder
            disabled={disableActions}
            onClick={toggleDelete}
            onlyIcon
            componentStyle={deleteIconStyles}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserItem;
