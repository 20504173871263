import { sortData } from 'utils';

export const getHashtagsLoading = (state) => state.hashtags.loading;
export const getHashtagsFilter = (state) => state.hashtags.filters;

export const getHashtags = (state) => {
  const { hashtags } = state;
  const { data = [], filters = {} } = hashtags;

  const { searchTag, sortingNameDirection } = filters;

  const filteredByName = data.filter(({ title }) => {
    if (!searchTag) {
      return true;
    }

    return title.toLowerCase().includes(searchTag.trim().toLowerCase());
  });

  if (!sortingNameDirection) {
    return filteredByName;
  }

  return sortData(filteredByName, 'title', sortingNameDirection);
};
