import React from 'react';
import LikeIcon from 'components/svg-icons/like-icon';
import CommentIcon from 'components/svg-icons/comment-icon';
import ViewIcon from 'components/svg-icons/view-icon';
import ShareIcon from 'components/svg-icons/share-icon';
import { MAX_METRIC_VALUE } from 'consts';
import styles from 'pages/create-report/components/trend-form/link-form/styles.module.scss';

const icons = {
  likes: LikeIcon,
  comments: CommentIcon,
  views: ViewIcon,
  shares: ShareIcon,
};

const MetricsItem = (props) => {
  const { type, onChange = () => {}, value } = props;
  const IconComponent = icons[type];

  const onChangeInput = (event) => {
    if (!event?.target?.value) {
      return onChange(type, 0);
    }

    try {
      const val = Math.abs(parseInt(event.target.value, 10));
      if (val < MAX_METRIC_VALUE) {
        return onChange(type, val);
      }

      return null;
    } catch (e) {
      return onChange(type, 0);
    }
  };

  const onlyNumbersInput = (e) => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.metricsItemCont}>
      <div className={styles.metricsIcon}>
        {IconComponent && <IconComponent />}
      </div>
      <div className={styles.metricsInputWrapper}>
        <input
          type="number"
          value={value}
          onChange={onChangeInput}
          onKeyPress={onlyNumbersInput}
        />
      </div>
      <div className={styles.metricsPostfix}>
        K
      </div>
    </div>
  );
};

export default MetricsItem;
