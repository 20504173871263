import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import styles from './styles.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const defaultOptions = ['inline', 'fontSize', 'blockType', 'textAlign', 'link', 'emoji', 'remove'];

const TextEditor = ({
  big = false,
  dark = false,
  initialText,
  onChange = () => {},
  placeholder,
  errorMessage,
  allOptions = false,
}) => {
  const [editorState, setEditorState] = useState(initialText
    ? EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(initialText)),
    )
    : EditorState.moveFocusToEnd(EditorState.createEmpty()));

  useEffect(() => {
    if (initialText) {
      return;
    }

    setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
  }, [initialText]);

  const handleEditorChange = (state) => {
    let currentContentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
    currentContentAsHTML = currentContentAsHTML.trim().match(/^(<p><\/p>)+$/) ? '' : currentContentAsHTML;
    currentContentAsHTML = currentContentAsHTML.replace(/\n/g, '');

    onChange(currentContentAsHTML);
    setEditorState(state);
  };

  return (
    <>
      <Editor
        wrapperClassName={classNames(
          styles.mainContainer,
          { [styles.big]: big, [styles.error]: errorMessage },
        )}
        editorState={editorState}
        toolbarClassName={styles.toolbarWrap}
        editorClassName={classNames(styles.editorWrap, { [styles.dark]: dark })}
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        handlePastedText={() => false}
        toolbar={{
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          fontSize: {
            inDropdown: true,
          },
          image: {
            alignmentEnabled: false,
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
          options: !allOptions ? defaultOptions : undefined,
        }}
      />
      {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
    </>
  );
};

export default TextEditor;
