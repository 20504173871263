import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ROLE_THEMES } from 'consts';
import Popup from 'components/popup';
import styles from './styles.module.scss';

const ConfirmPopup = (props) => {
  const { LIGHT } = ROLE_THEMES;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const {
    onCancel = () => {},
    title = intl.formatMessage({ id: 'deletion' }),
    text = intl.formatMessage({ id: 'deleteConfirm' }),
    cancelText = intl.formatMessage({ id: 'cancel' }),
    submitText = intl.formatMessage({ id: 'yes' }),
    colorTheme = LIGHT,
    submitFunction = async () => {},
  } = props;

  const onSubmit = async () => {
    setLoading(true);
    await submitFunction();
  };

  return (
    <Popup
      title={title}
      processing={loading}
      onSubmit={onSubmit}
      onClose={onCancel}
      cancelText={cancelText}
      submitText={submitText}
      colorTheme={colorTheme}
    >
      <p className={classNames(styles.text, styles[colorTheme])}>{text}</p>
    </Popup>
  );
};

export default ConfirmPopup;
