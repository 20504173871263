import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const Tags = (props) => {
  const { tagsData = [] } = props;

  const [tags, setTags] = useState([]);

  useEffect(() => {
    const uniqueTags = [...new Set(tagsData)];

    setTags(uniqueTags.sort());
  }, [tagsData]);

  return (
    <div className={styles.tagsWrapper}>
      <div className={styles.tags}>
        {tags.map((tag) => (
          <span key={tag} className={styles.tagWrapper}>
            <span className={styles.hashTag}>#</span>
            <span>{tag}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tags;
