import {
  SET_TREND_FILTER,
  RESET_TREND_FILTERS,
  REMOVE_TREND,
  TRENDS_LOAD_START,
  TRENDS_LOAD_SUCCESS,
  TRENDS_LOAD_ERROR,
  SET_COLLECTION_POPUP,
  WORD_CLOUD_LOAD_START,
  WORD_CLOUD_LOAD_SUCCESS,
  SET_MAX_WORDS,
  SET_MAX_COUNT,
  SET_EXCLUDE_WORDS,
  SET_WORD_CLOUD_SEARCH,
} from './action.types';

const initialState = {
  data: [],
  wordCloud: {
    loading: true,
    data: [],
    search: '',
    filteredData: [],
    excludeWords: [],
    maxWords: [100],
    maxCount: [100],
  },
  loading: true,
  hasMore: true,
  lastId: undefined,
  lastValue: undefined,
  filtersAdded: false,
  collectionPopup: {
    name: '',
    data: {},
  },
  filters: {
    search: '',
    sorting: 'trendDate_DESC',
    status: '',
    companyId: '',
  },
};

const trendsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TREND_FILTER: {
      const filters = { ...state.filters };
      if (typeof action.value === 'undefined') {
        delete filters[action.name];
      } else {
        filters[action.name] = action.value;
      }

      const filtersKeys = Object.keys(filters);
      const filtersCountChanged = filtersKeys.length !== Object.keys(initialState.filters).length;
      const filtersAdded = filtersCountChanged || filtersKeys.some(
        (key) => initialState.filters[key] !== filters[key],
      );

      return {
        ...state,
        filtersAdded,
        filters,
      };
    }
    case RESET_TREND_FILTERS:
      return {
        ...state,
        filtersAdded: false,
        filters: initialState.filters,
        wordCloud: {
          ...initialState.wordCloud,
        },
      };
    case REMOVE_TREND:
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== payload.id),
      };
    case TRENDS_LOAD_START:
      return {
        ...state,
        loading: true,
        data: payload.reset ? [] : state.data,
      };
    case TRENDS_LOAD_SUCCESS: {
      const lastItem = payload.data[payload.data.length - 1] || {};
      const [sortBy] = state.filters.sorting.split('_');

      let sortLastValue = lastItem[sortBy];

      if (sortBy === 'title') {
        sortLastValue = encodeURIComponent(lastItem.sortTitle);
      }

      return {
        ...state,
        hasMore: payload.hasMore,
        data: [...state.data, ...payload.data],
        loading: false,
        lastId: lastItem._id,
        lastValue: sortLastValue,
      };
    }
    case TRENDS_LOAD_ERROR:
      return {
        loading: false,
        error: payload.error,
      };
    case SET_COLLECTION_POPUP:
      return {
        ...state,
        collectionPopup: {
          name: payload.name,
          data: payload.data,
        },
      };
    case WORD_CLOUD_LOAD_START:
      return {
        ...state,
        wordCloud: {
          loading: true,
          data: payload.reset ? [] : state.wordCloud.data,
        },
      };
    case WORD_CLOUD_LOAD_SUCCESS:
      return {
        ...state,
        wordCloud: {
          loading: false,
          data: payload,
          filteredData: payload,
        },
      };
    case SET_MAX_COUNT:
      return {
        ...state,
        wordCloud: {
          ...state.wordCloud,
          maxCount: payload,
        },
      };
    case SET_MAX_WORDS:
    case SET_EXCLUDE_WORDS: {
      const payloadKey = type === SET_MAX_WORDS ? 'maxWords' : 'excludeWords';

      const newState = {
        ...state,
        wordCloud: {
          ...state.wordCloud,
          [payloadKey]: payload,
        },
      };

      const { maxWords = [100], excludeWords = [], data: words = [] } = newState.wordCloud;
      const wordsVal = excludeWords.map(({ value }) => value);
      const filteredWords = words
        .slice(0, maxWords[0])
        .filter(({ word }) => !wordsVal.includes(word));

      return {
        ...state,
        wordCloud: {
          ...state.wordCloud,
          [payloadKey]: payload,
          filteredData: filteredWords,
        },
      };
    }
    case SET_WORD_CLOUD_SEARCH:
      return {
        ...state,
        wordCloud: {
          ...state.wordCloud,
          search: payload,
        },
      };
    default:
      return state;
  }
};

export default trendsReducer;
