import React from 'react';

const UploadImageIcon = () => (
  <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.27145 0.0898438C2.4569 0.0898438 1.67571 0.438312 1.09974 1.05859C0.523772 1.67886 0.200195 2.52014 0.200195 3.39734L0.200195 23.2423C0.200195 24.1195 0.523772 24.9608 1.09974 25.5811C1.67571 26.2014 2.4569 26.5498 3.27145 26.5498H29.8889C30.7035 26.5498 31.4847 26.2014 32.0606 25.5811C32.6366 24.9608 32.9602 24.1195 32.9602 23.2423V3.39734C32.9602 2.52014 32.6366 1.67886 32.0606 1.05859C31.4847 0.438312 30.7035 0.0898438 29.8889 0.0898438H3.27145ZM29.8889 2.29484C30.1605 2.29484 30.4209 2.411 30.6128 2.61776C30.8048 2.82452 30.9127 3.10494 30.9127 3.39734V16.6273L23.1834 12.3342C22.9914 12.2306 22.774 12.1947 22.562 12.2315C22.3499 12.2683 22.154 12.376 22.002 12.5393L14.4058 20.7198L8.9594 16.8126C8.76276 16.6716 8.52689 16.6082 8.29179 16.6331C8.05669 16.658 7.83682 16.7697 7.66948 16.9493L2.25179 22.1398V23.3305C2.24934 23.3012 2.24798 23.2718 2.2477 23.2423V3.39734C2.2477 3.10494 2.35555 2.82452 2.54754 2.61776C2.73953 2.411 2.99993 2.29484 3.27145 2.29484H29.8889Z" fill="#93A8B4" />
  </svg>

);

export default UploadImageIcon;
