import apiService from 'services/apiService';
import { getReportsState } from 'store/reducers/reports/selectors';
import { serializeQueryParams } from 'utils';
import {
  SET_REPORT_FILTER,
  RESET_REPORT_FILTERS,
  REMOVE_REPORT,
  REPORTS_LOAD_START,
  REPORTS_LOAD_SUCCESS,
  REPORTS_LOAD_ERROR,
} from './action.types';

const buildFilters = (filtersObj) => {
  const filters = { ...filtersObj };

  const minMaxKeys = [
    'likes',
    'shares',
    'comments',
    'views',
    'viral',
    'relevant',
    'recent',
    'spread',
    'volume',
    'kym',
  ];
  minMaxKeys.forEach((key) => {
    if (filtersObj[key]) {
      filters[key] = `${filtersObj[key].min}:${filtersObj[key].max}`;
    }
  });

  if (filtersObj.categories && filtersObj.categories.length) {
    filters.categories = filtersObj.categories.join();
  }
  if (filtersObj.sorting) {
    const [sortBy, direction] = filtersObj.sorting.split('_');
    filters.sortType = direction;
    filters.sortBy = sortBy;
    delete filters.sorting;
  }

  return filters;
};

export const requestReports = (reset = false) => async (dispatch, getState) => {
  const {
    loading,
    hasMore,
    lastId,
    lastValue,
    filters,
  } = getReportsState(getState());

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  dispatch({
    type: REPORTS_LOAD_START,
    payload: {
      reset,
    },
  });

  const isRequestActual = () => {
    if (reset) {
      return true;
    }

    const newState = getReportsState(getState());
    return newState.loading
      && newState.lastId === lastId
      && newState.lastValue === lastValue
      && newState.filters === filters;
  };

  try {
    const params = buildFilters(filters);

    if (!reset) {
      params.lastId = lastId;
      params.lastValue = lastValue;
    }

    const { list, hasMore } = await apiService.getReports(serializeQueryParams(params));

    if (isRequestActual()) {
      dispatch({
        type: REPORTS_LOAD_SUCCESS,
        payload: { data: list, hasMore },
      });
    }
  } catch (error) {
    if (isRequestActual()) {
      dispatch({
        type: REPORTS_LOAD_ERROR,
        payload: { error },
      });
    }
  }
};

export const resetReportFilters = () => (dispatch) => {
  dispatch({ type: RESET_REPORT_FILTERS });
  dispatch(requestReports(true));
};

export const removeReport = (id) => ({ type: REMOVE_REPORT, payload: { id } });

export const setReportFilter = (name, value) => (dispatch) => {
  dispatch({
    type: SET_REPORT_FILTER,
    name,
    value,
  });
  dispatch(requestReports(true));
};
