import React from 'react';

const RedditSocialsIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.12392 7.2637C5.05873 7.36129 5.02393 7.476 5.02393 7.59336C5.02393 7.67129 5.03927 7.74842 5.0691 7.82042C5.0989 7.89242 5.14263 7.95782 5.19772 8.01292C5.25282 8.06802 5.31823 8.11174 5.39022 8.14154C5.46222 8.17138 5.53936 8.18672 5.61729 8.18672C5.73464 8.18672 5.84935 8.15191 5.94694 8.08672C6.0445 8.02151 6.12057 7.92884 6.16547 7.82042C6.2104 7.712 6.22213 7.5927 6.19924 7.47761C6.17636 7.36249 6.11984 7.25678 6.03685 7.17379C5.95386 7.09081 5.84815 7.03429 5.73303 7.0114C5.61794 6.98851 5.49864 7.00024 5.39022 7.04517C5.2818 7.09007 5.18914 7.16614 5.12392 7.2637Z" fill="currentColor" />
    <path d="M7.00587 9.68211C7.53254 9.70393 8.05048 9.54246 8.47147 9.22522V9.24896C8.48705 9.23375 8.4995 9.21565 8.50808 9.19564C8.51667 9.17565 8.52121 9.15416 8.52148 9.13239C8.52178 9.11063 8.51776 9.08902 8.50967 9.06879C8.5016 9.04858 8.48962 9.03015 8.47445 9.01459C8.45925 8.99901 8.44112 8.98657 8.42113 8.97798C8.40111 8.96939 8.37962 8.96483 8.35786 8.96456C8.31391 8.96401 8.27153 8.98093 8.24006 9.01161C7.87994 9.26993 7.44247 9.39762 6.99994 9.37356C6.55795 9.3933 6.1225 9.26143 5.76575 8.99975C5.73502 8.97451 5.69597 8.96163 5.65627 8.96357C5.61654 8.96551 5.57896 8.98219 5.55083 9.0103C5.52272 9.03844 5.50604 9.07601 5.5041 9.11574C5.50216 9.15544 5.51504 9.19449 5.54027 9.22522C5.96126 9.54246 6.4792 9.70393 7.00587 9.68211Z" fill="currentColor" />
    <path d="M8.03519 8.11065C8.13278 8.17583 8.24748 8.21064 8.36484 8.21064L8.35891 8.23438C8.43935 8.23525 8.51917 8.21975 8.59343 8.18882C8.66773 8.15787 8.73491 8.11215 8.79099 8.05443C8.84705 7.9967 8.89077 7.92818 8.91951 7.85304C8.94828 7.7779 8.96143 7.69767 8.9582 7.61728C8.9582 7.49992 8.92339 7.38522 8.8582 7.28763C8.79299 7.19006 8.70032 7.11399 8.5919 7.0691C8.48349 7.02417 8.36419 7.01244 8.2491 7.03533C8.13398 7.05821 8.02827 7.11473 7.94528 7.19772C7.86229 7.28071 7.80577 7.38642 7.78288 7.50154C7.76 7.61663 7.77173 7.73593 7.81665 7.84435C7.86155 7.95276 7.93762 8.04543 8.03519 8.11065Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9336 7C12.9336 10.277 10.277 12.9336 7 12.9336C3.72296 12.9336 1.06641 10.277 1.06641 7C1.06641 3.72296 3.72296 1.06641 7 1.06641C10.277 1.06641 12.9336 3.72296 12.9336 7ZM10.8014 6.53445C10.8973 6.6713 10.9516 6.83298 10.9577 7C10.9602 7.16349 10.9164 7.32435 10.8314 7.46402C10.7463 7.60367 10.6235 7.71638 10.4771 7.78917C10.4838 7.87607 10.4838 7.96335 10.4771 8.05025C10.4771 9.37937 8.92842 10.4593 7.0178 10.4593C5.10718 10.4593 3.55852 9.37937 3.55852 8.05025C3.55184 7.96335 3.55184 7.87607 3.55852 7.78917C3.44438 7.73672 3.34302 7.66011 3.26145 7.56462C3.17989 7.46911 3.12006 7.357 3.08612 7.23609C3.05216 7.11517 3.04492 6.98832 3.06488 6.86432C3.08484 6.74032 3.13152 6.62214 3.20171 6.51796C3.27187 6.4138 3.36386 6.32614 3.47126 6.26106C3.57867 6.19598 3.69895 6.15502 3.82378 6.14105C3.9486 6.12708 4.07496 6.14039 4.19412 6.18013C4.31326 6.21983 4.42236 6.28496 4.51382 6.37104C5.19753 5.90734 6.0019 5.65365 6.82793 5.64121L7.26701 3.58225C7.27193 3.55841 7.28156 3.53579 7.29529 3.5157C7.30904 3.4956 7.32662 3.47843 7.34705 3.46519C7.36747 3.45196 7.3903 3.44288 7.41426 3.43853C7.43821 3.43418 7.46279 3.43462 7.48656 3.43984L8.94029 3.73059C9.01127 3.60861 9.12343 3.51594 9.2566 3.46921C9.38976 3.42245 9.5352 3.42472 9.66686 3.47555C9.79852 3.52639 9.90773 3.6225 9.97492 3.74661C10.0421 3.87073 10.0629 4.01472 10.0334 4.15275C10.004 4.29078 9.92636 4.4138 9.81441 4.49974C9.70247 4.58568 9.56356 4.62889 9.42263 4.62164C9.28167 4.61439 9.14793 4.55714 9.04539 4.46018C8.94286 4.36321 8.87824 4.23287 8.86315 4.09254L7.59336 3.82553L7.20768 5.67681C8.02367 5.69431 8.81713 5.94781 9.49211 6.40664C9.6127 6.29092 9.76418 6.21253 9.9283 6.18095C10.0924 6.14936 10.2622 6.16593 10.4171 6.22866C10.572 6.29139 10.7055 6.39756 10.8014 6.53445Z" fill="currentColor" />
  </svg>
);

export default RedditSocialsIcon;
