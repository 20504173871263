import React from 'react';
import { onImageError } from 'utils';
import styles from './styles.module.scss';

const MentionItem = (entry) => {
  const { display, picture = '' } = entry;

  return (
    <div className={styles.item}>
      <img
        className={styles.itemImg}
        src={picture}
        onError={onImageError}
        alt="avatar"
      />
      {display}
    </div>
  );
};

export default MentionItem;
