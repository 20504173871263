import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import LoadingComponent from 'components/loadingComponent';
import { requestCategories, setCategoriesLoading } from 'store/reducers/categories/actions';
import { getCategories, getCategoriesLoading } from 'store/reducers/categories/selectors';
import CategoriesList from './list';
import CategoriesFilters from './filters';

const CategoriesPage = () => {
  const categories = useSelector(getCategories);
  const isLoading = useSelector(getCategoriesLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCategories());

    return () => {
      dispatch(setCategoriesLoading(true));
    };
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="categories" />}
        FiltersComponent={<CategoriesFilters />}
      />
      <div className="container">
        <CategoriesList categories={categories} />
      </div>
    </>
  );
};

export default CategoriesPage;
