import nextId from 'react-id-generator';

export const createEmptyLinkForm = (additionalData = {}) => {
  return {
    _id: nextId(),
    link: '',
    title: '',
    image: '',
    metrics: {
      likes: 0,
      shares: 0,
      comments: 0,
      views: 0,
    },
    ...additionalData,
  };
};

export const createEmptyTrend = () => {
  return {
    _id: nextId(),
    status: '',
    title: '',
    description: '',
    trendDate: new Date(),
    tags: [],
    userExamplePosts: [createEmptyLinkForm({ type: 'user-example' })],
    brandExamplePosts: [createEmptyLinkForm({ type: 'brand-example' })],
    typesKeys: [],
    sharedCompanies: [],
    countries: [],
  };
};
