import Tags from 'client/pages/report/components/tags-list';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { COLOR_THEMES, CABINET_ROUTES } from 'consts';
import RouteRoleLink from 'components/route-role-link';
import Button from 'components/button';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import DateFormatter from 'components/dateFormatter';
import SocialStatistic from '../social-statistic';
import TrendsList from '../trends-list';
import styles from './styles.module.scss';

const ReportHeader = (props) => {
  const {
    title,
    description,
    trends = [],
    totalMetrics = {},
    totalIndexMetrics = {},
    createdDate,
    updatedDate,
  } = props;

  const reportHeaderRef = useRef();

  return (
    <div className={styles.reportHeader} ref={reportHeaderRef}>
      <div className="container">
        <div className={styles.backWrapper}>
          <RouteRoleLink path={CABINET_ROUTES.REPORTS}>
            <Button
              componentStyle={{ mainContainer: styles.backButton }}
              icon={<div className={styles.backIcon}><RightArrowIcon /></div>}
              colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
            >
              <FormattedMessage id="backToAllReports" />
            </Button>
          </RouteRoleLink>
          <div className={styles.dates}>
            <div className={styles.dateData}>
              <FormattedMessage id="created" />:&nbsp;
              <span className={styles.date}><DateFormatter date={createdDate} /></span>
            </div>
            <div className={styles.dateData}>
              <FormattedMessage id="updated" />:&nbsp;
              <span className={styles.date}><DateFormatter date={updatedDate} /></span>
            </div>
          </div>
        </div>
        <div className={styles.reportHeadingContent}>
          <h1 className={classNames(
            styles.titleReport,
            { [styles.titleReportFull]: !description },
          )}
          >
            {title}
          </h1>
          { description && (
            <div
              className={styles.descriptionReport}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <SocialStatistic totalMetrics={totalMetrics} totalIndexMetrics={totalIndexMetrics} />
        <TrendsList trends={trends} />
        <Tags trends={trends} />
      </div>
    </div>
  );
};

export default ReportHeader;
