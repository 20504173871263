import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTrendFilter } from 'store/reducers/trends/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import DatepickerIcon from 'components/svg-icons/datepicker-icon';
import { getTrendFilterByName } from 'store/reducers/trends/selectors';
import CollapseComponent from 'pages/reports/collapseComponent';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'pages/trends/aside-filters/trendsDateFilter/styles.module.scss';

const TrendsDateFilter = (props) => {
  const {
    filterType,
    title,
    isWordCloud,
    collectionId,
  } = props;

  const dispatch = useDispatch();
  const storeFilter = useSelector(getTrendFilterByName(filterType)) || {
    startDate: null,
    endDate: null,
  };
  const isClient = useSelector(getIsClient);

  const onChange = (dates) => {
    const [start, end] = dates;

    dispatch(setTrendFilter(filterType, {
      startDate: start,
      endDate: end,
    }, { isWordCloud }, collectionId));
  };

  return (
    <CollapseComponent
      name={title}
    >
      <div className={styles.dateContainer}>
        <div
          className={classNames(
            styles.inputWrapper,
            {
              [styles['transparent-light-white']]: isClient,
            },
          )}
        >
          <span className={styles.inputIcon}>
            <DatepickerIcon />
          </span>
          <DatePicker
            autoComplete="off"
            calendarClassName={classNames(
              styles.calendar,
              {
                [styles.dark]: isClient,
              },
            )}
            dayClassName={() => classNames(
              {
                [styles['days-dark']]: isClient,
              },
            )}
            className={classNames(
              styles.input,
              {
                [styles.light]: isClient,
              },
            )}
            name="trendDate"
            selected={storeFilter.startDate}
            onChange={onChange}
            startDate={storeFilter.startDate}
            endDate={storeFilter.endDate}
            selectsRange
            dateFormat="MM/dd/yy"
            shouldCloseOnSelect
            popperPlacement="bottom-start"
            popperModifiers={[
              {
                name: 'arrow',
                options: {
                  padding: 130,
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom'],
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [-50, 0],
                },
              },
            ]}
          />
        </div>
      </div>
    </CollapseComponent>
  );
};

export default TrendsDateFilter;
