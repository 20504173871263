import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCategories } from 'store/reducers/categories/selectors';
import { requestCategories } from 'store/reducers/categories/actions';
import { setReportFilter } from 'store/reducers/reports/actions';
import { getReportFilterByName } from 'store/reducers/reports/selectors';
import Checkbox from 'components/checkbox';
import CollapseComponent from '../collapseComponent';
import styles from './styles.module.scss';

const CategoriesCollapsedFilters = () => {
  const intl = useIntl();

  const trendCategories = useSelector(getCategories);
  const dispatch = useDispatch();
  const storeFilter = useSelector(getReportFilterByName('categories')) || [];

  useEffect(() => {
    dispatch(requestCategories());
  }, [dispatch]);

  const onChange = ({ value, checked }) => {
    let nextFilter = storeFilter;
    if (checked) {
      nextFilter = [...new Set([...nextFilter, value])];
    } else {
      nextFilter = nextFilter.filter((item) => item !== value);
    }
    dispatch(setReportFilter('categories', nextFilter.length === 0 ? undefined : nextFilter));
  };

  const checkItems = (
    <div className={styles.checkItemsContainer}>
      {trendCategories.map((category) => (
        <Checkbox
          componentStyle={{ container: styles.checkboxContainer }}
          checked={storeFilter.includes(category._id)}
          onChange={(event) => onChange({ ...event, value: category._id })}
          title={category.title}
          key={category._id}
        />
      ))}
    </div>
  );

  return (
    <CollapseComponent
      name={intl.formatMessage({ id: 'filtersCategories' })}
    >
      {trendCategories
      && !!trendCategories.length
      && checkItems}
    </CollapseComponent>
  );
};

export default CategoriesCollapsedFilters;
