import storageService from 'services/storageService';
import { WS_STATUSES } from 'consts';
import { getWsUrl } from 'utils';

class WebSocketConnector {
  constructor() {
    this.storageService = storageService;
    this.number = 0; // Message number
    this.autoReconnectInterval = 2000;// 2s
    this.open();
  }

  open() {
    this.token = this.storageService.get('accessToken');
    this.wsUrl = getWsUrl(this.token);

    this.ws = new WebSocket(this.wsUrl);

    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onopen = this.onOpen.bind(this);
    this.ws.onclose = this.onClose.bind(this);
  }

  async onMessage(wsEventData) {
    this.number += 1;

    if (typeof this.onMessageHandler === 'function') {
      this.onMessageHandler(wsEventData);
    }
  }

  async onOpen() {
    // no actions
  }

  async onClose() {
    this.reconnect();
  }

  onError(errorEvent) {
    switch (errorEvent.code) {
      case 'ECONNREFUSED':
        this.reconnect();
        break;
      default:
        // this.onerror(e);
        break;
    }
  }

  waitConnection() {
    return new Promise((resolve, reject) => {
      if (this?.ws?.readyState === WS_STATUSES.OPEN) {
        resolve(true);
      }

      const maxAttempts = 60;
      let attempts = 0;

      const interval = setInterval(() => {
        attempts += 1;

        if (this?.ws?.readyState === WS_STATUSES.OPEN) {
          clearInterval(interval);
          resolve(true);
        }

        if (attempts >= maxAttempts) {
          reject(new Error('ws connection timeout'));
        }
      }, 500);
    });
  }

  async send(data) {
    await this.waitConnection();

    try {
      this.ws.send(data);
    } catch (e) {
      this.onError(e);
    }
  }

  reconnect() {
    setTimeout(() => {
      this.open();
    }, this.autoReconnectInterval);
  }

  registerMessageHandler(onMessageHandler) {
    this.onMessageHandler = onMessageHandler;
  }
}

export default WebSocketConnector;
