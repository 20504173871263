import React from 'react';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'components/dateFormatter';
import Button from 'components/button';
import RouteRoleLink from 'components/route-role-link';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import { CABINET_ROUTES, COLOR_THEMES } from 'consts';
import COUNTRIES from 'consts/countries';
import { useDispatch, useSelector } from 'react-redux';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import { setTrendFilter, setCollectionPopup } from 'store/reducers/trends/actions';
import { changeTimeZone, isWin, stripHtmlTags } from 'utils';
import classNames from 'classnames';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import DeleteIcon from 'components/svg-icons/delete-icon';
import CommentCountIcon from 'components/svg-icons/comment-count';
import styles from './styles.module.scss';

const { SECONDARY } = COLOR_THEMES;

const ClientTrendWidget = ({
  trend,
  imgLink,
  isCollection,
  isOwnCollection,
  collectionId,
  hideActionBar = false,
}) => {
  const {
    _id: id,
    trendDate,
    title,
    description,
    typesKeys,
    countries,
    tags,
    commentsCount,
  } = trend;

  const typeKeysList = useSelector(getTypeKeys);
  const dispatch = useDispatch();
  const isWindows = isWin();

  const getEmojiList = () => {
    const allEmojiItems = [];

    if (typesKeys
      && typesKeys.length
      && typeKeysList
      && typeKeysList.length
    ) {
      typeKeysList.forEach((typeKey) => {
        if (typesKeys.includes(typeKey._id)) {
          allEmojiItems.push({ emoji: typeKey.emoji, name: typeKey.title });
        }
      });
    }

    if (countries && countries.length) {
      COUNTRIES.forEach((country) => {
        if (countries.includes(country.code)) {
          const { emoji, name, code } = country;

          allEmojiItems.push({ emoji: isWindows ? code : emoji, name, isWrapNeeded: isWindows });
        }
      });
    }

    return allEmojiItems.map((eItem) => (
      <div
        title={eItem.name}
        key={`emoji-key-${id}-${eItem.name}`}
        className={classNames(styles.emojiTag, { [styles.emojiTagWrap]: eItem?.isWrapNeeded })}
      >
        {eItem.emoji}
      </div>
    ));
  };

  const clickTag = (tag) => {
    dispatch(setTrendFilter('search', tag, null, collectionId));
  };

  const addCollectionClick = () => {
    dispatch(setCollectionPopup('addCollection', { itemId: id }));
  };

  const deleteTrendHandler = () => {
    dispatch(setCollectionPopup('deleteTrendCollection', { itemId: id }));
  };

  const getClicableTags = () => {
    if (!tags || !tags.length) {
      return null;
    }

    return tags.map((tag) => (
      <div
        key={`tags-${id}-${tag}`}
        className={styles.tagItem}
        onClick={() => clickTag(tag)}
      >
        {`#${tag}`}
      </div>
    ));
  };

  const ButtonIcon = isCollection ? DeleteIcon : CollectionsIcon;
  const buttonHandler = isCollection ? deleteTrendHandler : addCollectionClick;

  return (
    <div className={styles.trendContainer}>
      <div className={styles.date}>
        <DateFormatter date={changeTimeZone(trendDate)} showTime={false} />
      </div>

      <div className={styles.imageWraper}>
        <div className={styles.image}>
          { imgLink ? <img src={imgLink} alt="report-img" /> : <ClientLogoIcon />}
        </div>

        <div className={styles.collectionActions}>
          <div className={styles.addCollectionBlock}>
            { ((isCollection && isOwnCollection) || !isCollection)
            && !hideActionBar
            && (
              <div className={styles.addCollectionButton}>
                <Button
                  colorTheme={SECONDARY}
                  withBorder
                  onClick={buttonHandler}
                  onlyIcon
                  componentStyle={{
                    mainContainer: styles.deleteButton,
                  }}
                >
                  <ButtonIcon />
                </Button>
              </div>
            ) }
          </div>
          {!!commentsCount && (
            <div className={styles.commentsCountWrapper}>
              <CommentCountIcon />
              <span className={styles.commentCountText}>{commentsCount}</span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.title} title={title}>
          {title}
        </div>

        <div className={styles.emojiWrapper}>
          {getEmojiList()}
        </div>
        <div className={styles.badgesLine}>
          {getClicableTags()}
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: stripHtmlTags(description) }}
        />
      </div>
      <div className={styles.viewReportButtonContainer}>
        <RouteRoleLink path={`${CABINET_ROUTES.TRENDS}/${id}`}>
          <Button
            colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
            componentStyle={{
              mainContainer: styles.viewButton,
              textContainer: styles.viewButtonText,
            }}
          >
            <FormattedMessage id="viewFullTrend" />
          </Button>
        </RouteRoleLink>
      </div>
    </div>
  );
};

export default ClientTrendWidget;
