import React from 'react';
import styles from './styles.module.scss';

const PeopleTag = ({ title, clickHandler = () => {} }) => {
  return (
    <div onClick={() => clickHandler(title)} className={styles.tagWrap}>{`# ${title}`}</div>
  );
};

export default PeopleTag;
