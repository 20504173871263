import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styles from './styles.module.scss';

const RangeSlider = (props) => {
  const {
    step = 1,
    min = 0,
    max = 100,
    value = [0],
    onChange = () => {},
    disable,
  } = props;

  return (
    <Range
      disable={disable}
      values={value}
      step={step}
      min={min}
      max={max}
      onChange={onChange}
      renderTrack={({ props, children }) => {
        const { onMouseDown, onTouchStart, ref } = props;
        return (
          (
            <div
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
            >
              <div
                ref={ref}
                className={styles.line}
                style={{
                  background: getTrackBackground({
                    values: value,
                    colors: ['#1ca5e0', '#FFFFFF'],
                    min,
                    max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )
        );
      }}
      renderThumb={({ props }) => (
        <div className={styles.thumb} {...props}>
          {value}
        </div>
      )}
    />
  );
};

export default RangeSlider;
