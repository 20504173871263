import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { COLOR_THEMES } from 'consts';
import AddCompany from 'admin/pages/companies/add-company';
import Button from 'components/button';
import EditIcon from 'components/svg-icons/edit-icon';
import DeleteIcon from 'components/svg-icons/delete-icon';
import ConfirmPopup from 'components/confirm-popup';
import { deleteCompany, setCompanyData } from 'store/reducers/companies/actions';
import styles from './styles.module.scss';

const CompanyItem = (props) => {
  const {
    data,
    selected = false,
    onViewClick = () => {},
    onCompanySelect = () => {},
  } = props;

  const {
    title,
    usersLimit,
    users = 0,
    _id,
  } = data;

  const [edit, setEdit] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const dispatch = useDispatch();

  const onViewUsersClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setCompanyData(null));
    onViewClick(_id);
  };

  const onSelect = () => {
    onCompanySelect(_id);
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(!edit);
  };

  const onDelete = async () => {
    await dispatch(deleteCompany(_id));
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const editIconStyles = {
    mainContainer: styles.editButton,
  };

  const deleteIconStyles = {
    mainContainer: styles.deleteButton,
  };

  return (
    <>
      { edit && <AddCompany prefilledData={data} id={_id} onCancel={toggleEdit} /> }
      { deleteAction && <ConfirmPopup onCancel={toggleDelete} submitFunction={onDelete} /> }
      <div
        className={classNames(styles.company, { [styles.selected]: selected })}
        onClick={onSelect}
      >
        <div className={styles.companyNameWrapper}>
          <span className={styles.companyName}>{title}</span>
          <div className={classNames(styles.tag, { [styles.limit]: users >= usersLimit })}>
            {users}/{usersLimit}
            &nbsp;
            <FormattedMessage id="users" />
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onlyIcon
            onClick={toggleDelete}
            componentStyle={deleteIconStyles}
          >
            <DeleteIcon />
          </Button>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onlyIcon
            onClick={toggleEdit}
            componentStyle={editIconStyles}
          >
            <EditIcon />
          </Button>
          <Button colorTheme={COLOR_THEMES.SECONDARY} withBorder onClick={onViewUsersClick}>
            <FormattedMessage id="viewUsers" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyItem;
