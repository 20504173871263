import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionsStateList } from 'store/reducers/collections/selectors';
import {
  requestCollections,
  resetCollectionFilters,
} from 'store/reducers/collections/actions';
import HeadingFilters from 'components/heading-filters';
import InfiniteScroll from 'components/infinity-scroll';
import apiService from 'services/apiService';
import { getUser } from 'store/reducers/user/selectors';
import { requestCompanies } from 'store/reducers/companies/actions';
import CreateCollectionPopup from 'components/create-collection-popup';
import { getCollectionPopup } from 'store/reducers/trends/selectors';
import { getIsClient } from 'store/reducers/general/selectors';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import PeopleNoResults from 'pages/people/people-no-results';
import EmptyListMessage from 'components/empty-list-message';
import CollectionCard from './components/collection-card';
import CollectionFilters from './components/collection-filters';
import styles from './styles.module.scss';

const CollectionComponent = (props) => {
  const {
    userId, domains, data, isClient = false, isAdmin = false,
  } = props;
  return (
    <div className={styles.itemContainer}>
      <CollectionCard
        isClient={isClient}
        isAdmin={isAdmin}
        collectionItem={data}
        userId={userId}
        domains={domains}
      />
    </div>
  );
};

const CollectionsPage = () => {
  const [allDomains, setAllDomains] = useState([]);
  const user = useSelector(getUser);
  const isClient = useSelector(getIsClient);
  const collectionPopup = useSelector(getCollectionPopup);
  const { search } = useSelector(getCollectionsStateList);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(requestCompanies());

    return () => {
      dispatch(resetCollectionFilters());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!allDomains.length) {
        const result = await apiService.getParsersInfo();

        const parsersDomains = result?.data?.parsersDomains;

        if (Array.isArray(parsersDomains)) {
          setAllDomains(parsersDomains);
        }
      }
    })();
  }, [allDomains.length]);

  const closeCollectionPopups = () => {
    dispatch(setCollectionPopup(null));
  };

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="collections" />}
        FiltersComponent={<CollectionFilters />}
      />
      { collectionPopup && collectionPopup.name === 'createCollection' && (
        <CreateCollectionPopup
          onClose={closeCollectionPopups}
          isClient={isClient}
          needToRefresh
          {...collectionPopup.data}
        />
      ) }
      <div className="container">
        <div className={styles.reportsContent}>
          <InfiniteScroll
            key="collections-list"
            loadAction={requestCollections}
            emptyMessage={search
              ? <PeopleNoResults message={intl.formatMessage({ id: 'noCollections' })} />
              : <EmptyListMessage messageId="emptyCollectionList" />}
            RenderComponent={CollectionComponent}
            RootComponent={RootList}
            dataSelector={getCollectionsStateList}
            listItemProps={{ userId: user?._id, domains: allDomains }}
          />
        </div>
      </div>
    </>
  );
};

export default CollectionsPage;
