import React from 'react';
import TypeKeyItem from './item';
import styles from './styles.module.scss';

const TypeKeysList = (props) => {
  const { typeKeys = [] } = props;

  if (!typeKeys.length) {
    return null;
  }

  return (
    <>
      <div className={styles.typeKeysList}>
        {typeKeys.map((typeKey) => (
          <div className={styles.typeKeyItem} key={typeKey._id}>
            <TypeKeyItem data={typeKey} />
          </div>
        ))}
      </div>
    </>
  );
};

export default TypeKeysList;
