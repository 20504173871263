import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getImages } from 'utils';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import ShareCollectionIcon from 'components/svg-icons/share-collection-icon';
import {
  CABINET_ROUTES,
  COLOR_THEMES,
  LOGIN_ROLES,
  ROLE_THEMES,
} from 'consts';
import ConfirmPopup from 'components/confirm-popup';
import { deleteCollection } from 'store/reducers/collections/actions';
import { useDispatch, useSelector } from 'react-redux';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import CreateCollectionPopup from 'components/create-collection-popup';
import classNames from 'classnames';
import ShareCompaniesPopup from 'components/share-companies-popup';
import ReadMore from 'components/read-more';
import { getUser } from 'store/reducers/user/selectors';
import CollectionCreatorRow from 'pages/collections/components/collection-card/creator-row';
import CollectionSharedRow from 'pages/collections/components/collection-card/shared-row';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import EmptyIcon from 'components/svg-icons/empty-icon';
import styles from './styles.module.scss';

const { COLLECTIONS } = CABINET_ROUTES;

const { SECONDARY, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;
const { LIGHT, DARK } = ROLE_THEMES;

const CollectionInfoBlock = (props) => {
  const {
    collectionData, domains, isClient, isAdmin,
  } = props;
  const {
    imgLink,
    title,
    trends = [],
    description,
    postWithImgLink,
    _id: id, creatorId,
    companyList,
    creator,
    approvedTrends = [],
  } = collectionData;

  const [deleteAction, setDeleteAction] = useState(false);
  const [showCreateCollectionPopup, setShowCreateCollectionPopup] = useState(false);
  const [showShareCompanies, setShowShareCompanies] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = UseRoleNavigation();
  const user = useSelector(getUser);
  const isOwnCollection = user && user._id === creatorId;

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const onDelete = async () => {
    dispatch(deleteCollection(id));
    setDeleteAction(!deleteAction);
    navigate(COLLECTIONS);
  };

  const editCollectionHandler = () => {
    setShowCreateCollectionPopup(true);
  };

  const shareCollectionHandler = () => {
    setShowShareCompanies(true);
  };

  const { postImageUrl } = getImages(imgLink, postWithImgLink, domains);
  const totalTrends = isClient ? approvedTrends.length : trends.length;

  return (
    <div className={classNames(
      styles.collectionContainer,
      { [styles.adminStyleWrap]: !isClient },
    )}
    >
      <div className={styles.collectionWrap}>
        <div className={styles.imgWrapBlock}>
          { postImageUrl ? <img src={postImageUrl} alt="" /> : <EmptyIcon />}
        </div>
        <div className={styles.collectionRightBlock}>
          <div className={styles.collectionHeader}>
            <div className={styles.collectionTitle}>
              {title}
            </div>
            <div className={styles.collectionTrendsNum}>
              {totalTrends} <FormattedMessage id={totalTrends.length === 1 ? 'trendsTotalOne' : 'trendsTotal'} />
            </div>
          </div>
          <div className={styles.collectionDescription}>
            <ReadMore>{description}</ReadMore>
          </div>
          <div className={styles.collectionButtonsBlock}>
            {((!isClient && isOwnCollection) || isAdmin) && (
              <Button
                disabled={0}
                colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                onClick={shareCollectionHandler}
                icon={<ShareCollectionIcon />}
                componentStyle={{ mainContainer: styles.shareButton }}
              >
                <FormattedMessage id="share" />
              </Button>
            )}
            {(isOwnCollection || isAdmin) && (
              <>
                <Button
                  disabled={0}
                  colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                  onClick={toggleDelete}
                  onlyIcon
                  icon={<DeleteIcon />}
                  componentStyle={{ mainContainer: styles.deleteButton }}
                />

                <Button
                  disabled={0}
                  colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                  onClick={editCollectionHandler}
                  icon={<EditIcon />}
                  onlyIcon
                  componentStyle={{ mainContainer: styles.editButton }}
                />
              </>
            )}
          </div>
          <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN]}>
            <div className={styles.additionalInfoRow}>
              <CollectionCreatorRow creator={creator} />
            </div>
          </RoleVisibleWrapper>
          <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
            <div className={styles.additionalInfoRow}>
              <CollectionSharedRow companyList={companyList} withNewRow />
            </div>
          </RoleVisibleWrapper>
        </div>
      </div>
      { deleteAction
      && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deleteCollection' })}
          text={intl.formatMessage({ id: 'deleteCollectionText' })}
          colorTheme={isClient ? DARK : LIGHT}
          onCancel={toggleDelete}
          submitFunction={onDelete}
          submitText={intl.formatMessage({ id: 'delete' })}
        />
      ) }
      { showCreateCollectionPopup && (
        <CreateCollectionPopup
          onClose={() => setShowCreateCollectionPopup(false)}
          title={title}
          description={description}
          collectionId={id}
          isClient={isClient}
        />
      ) }
      {
        showShareCompanies && (
          <ShareCompaniesPopup
            onCancel={() => setShowShareCompanies(false)}
            collectionData={collectionData}
          />
        )
      }
    </div>
  );
};

export default CollectionInfoBlock;
