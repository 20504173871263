import React, { useState } from 'react';
import { COLLECTION_TYPE, ROLE_THEMES } from 'consts';
import classNames from 'classnames';
import apiService from 'services/apiService';
import Popup from 'components/popup';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestCollection,
  requestCollections,
} from 'store/reducers/collections/actions';
import { getCompanies } from 'store/reducers/companies/selectors';
import { getCompaniesOptions, renderCompanyOption } from 'components/share-companies-popup';
import Select from 'components/fields/select';
import { requestPeopleCollection, requestPeopleCollections } from 'store/reducers/people-collection/actions';
import styles from './styles.module.scss';

const { DARK, LIGHT } = ROLE_THEMES;
const TITLE_LIMIT = 256;
const DESCR_LIMIT = 1250;

const { TRENDS, PEOPLE } = COLLECTION_TYPE;

const dataActions = {
  [TRENDS]: {
    requestOne: requestCollection,
    requestMany: requestCollections,
    editItem: 'editCollection',
    createNew: 'addCollection',
  },
  [PEOPLE]: {
    requestOne: requestPeopleCollection,
    requestMany: requestPeopleCollections,
    editItem: 'editPeopleCollection',
    createNew: 'addPeopleCollection',
  },
};

const CreateCollectionPopup = (props) => {
  const {
    onClose = () => {},
    itemId = '',
    title = '',
    description = '',
    collectionId = '',
    isClient = false,
    needToRefresh = false,
    type = TRENDS,
  } = props;

  const {
    requestOne, requestMany, editItem, createNew,
  } = dataActions[type];

  const [collectionNameValue, setCollectionNameValue] = useState(title);
  const [collectionDescrValue, setCollectionDescrValue] = useState(description);
  const [chosenCompanies, setChosenCompanies] = useState([]);
  const companies = useSelector(getCompanies);
  const intl = useIntl();
  const dispatch = useDispatch();

  const isUpdate = !!collectionId;

  const createCollectionHandler = () => {
    const data = {
      description: collectionDescrValue,
      title: collectionNameValue,
      sharedCompanies: chosenCompanies,
    };

    const apiMethod = isUpdate ? editItem : createNew;

    if (!isUpdate) {
      data[type] = itemId ? [itemId] : [];
    }

    apiService[apiMethod](data, collectionId)
      .then(() => {
        if (collectionId && !needToRefresh) {
          dispatch(requestOne(collectionId));
        }
        onClose();
        if (needToRefresh) {
          dispatch(requestMany(true));
        }
      })
      .catch(() => {
        // todo handle error using notification
        onClose();
      });
  };

  const setInputValue = (value, limit, cb) => {
    if (value.length <= limit) {
      cb(value);
    }
  };

  const onCompaniesChange = (name, values) => {
    setChosenCompanies(values);
  };

  return (
    <Popup
      title={intl.formatMessage({ id: collectionId ? 'editCollection' : 'createNewCollection' })}
      onSubmit={createCollectionHandler}
      onClose={onClose}
      cancelText={intl.formatMessage({ id: 'cancel' })}
      submitText={intl.formatMessage({ id: collectionId ? 'update' : 'createCollection' })}
      colorTheme={isClient ? DARK : LIGHT}
      submitDisabled={!(collectionNameValue.trim())}
    >
      <div className={classNames(
        styles.createCollectionContainer,
        { [styles.adminStyleType]: !isClient },
      )}
      >
        <label className={styles.inputBlock}>
          <div>{intl.formatMessage({ id: 'name' })}</div>
          <input
            type="text"
            spellCheck={false}
            value={collectionNameValue}
            onChange={(e) => setInputValue(e.target.value, TITLE_LIMIT, setCollectionNameValue)}
          />
        </label>
        <label className={styles.inputBlock}>
          <div>{intl.formatMessage({ id: 'descriptionInput' })}</div>
          <textarea
            spellCheck={false}
            value={collectionDescrValue}
            onChange={(e) => setInputValue(e.target.value, DESCR_LIMIT, setCollectionDescrValue)}
          />
        </label>
        {!isClient && !isUpdate && (
          <div className={styles.shareCollectionContainer}>
            <Select
              multiple
              canScroll
              withSearch
              renderOption={renderCompanyOption}
              placeholder={intl.formatMessage({ id: 'trendCompanies' })}
              searchPlaceholder={intl.formatMessage({ id: 'searchCompany' })}
              fullWidth
              options={getCompaniesOptions(companies)}
              value={chosenCompanies}
              onChange={onCompaniesChange}
              openLock
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default CreateCollectionPopup;
