import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from 'components/button';
import styles from './styles.module.scss';

const Error404 = () => {
  const history = useHistory();

  return (
    <div className={styles.errorWrapContainer}>
      <div className={styles.errorContainer}>
        <div className={styles.errorName}>404</div>
        <div className={styles.errorDescr}>
          <FormattedMessage id="error404NotFound" />
        </div>
        <Button onClick={() => history.push('/')} componentStyle={{ mainContainer: styles.backToMainLink }}>
          <FormattedMessage id="error404BackHome" />
        </Button>
      </div>
    </div>
  );
};

export default Error404;
