import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLOR_THEMES } from 'consts';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import ConfirmPopup from 'components/confirm-popup';
import { deleteHashtag } from 'store/reducers/hashtags/actions';
import AddHashtag from '../../add-hashtag';
import styles from './styles.module.scss';

const HashtagItem = (props) => {
  const { data } = props;
  const {
    title,
    _id,
  } = data;

  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);

  const editIconStyles = {
    mainContainer: styles.editButton,
  };

  const deleteIconStyles = {
    mainContainer: styles.deleteButton,
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(!edit);
  };

  const onDelete = async () => {
    dispatch(deleteHashtag(_id));
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  return (
    <>
      { edit && (
      <AddHashtag
        prefilledData={data}
        onCancel={toggleEdit}
      />
      )}
      { deleteAction && <ConfirmPopup onCancel={toggleDelete} submitFunction={onDelete} /> }
      <div className={styles.hashtag}>
        <div className={styles.hashtagNameWrapper}>
          <span className={styles.hashtagName}># {title}</span>
        </div>
        <div className={styles.actions}>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onClick={toggleDelete}
            onlyIcon
            componentStyle={deleteIconStyles}
          >
            <DeleteIcon />
          </Button>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onClick={toggleEdit}
            onlyIcon
            componentStyle={editIconStyles}
          >
            <EditIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default HashtagItem;
