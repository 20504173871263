import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import { COLOR_THEMES } from 'consts';
import {
  getWordCloudLoading,
  getWordCloudMaxCount,
  getWordCloudMaxWords,
} from 'store/reducers/trends/selectors';
import RangeSlider from 'components/fields/range-slider';
import { setMaxWords } from 'store/reducers/trends/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import Button from 'components/button';
import SaveIcon from 'components/svg-icons/save-icon';
import useDebounce from 'hooks/useDebounce';
import styles from './styles.module.scss';

const { SECONDARY, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;

const CustomizeSection = (props) => {
  const { printRef, disabledDownload } = props;
  const dispatch = useDispatch();
  const maxWords = useSelector(getWordCloudMaxWords);
  const maxCount = useSelector(getWordCloudMaxCount);
  const wordCloudLoading = useSelector(getWordCloudLoading);
  const [localRange, setLocalRange] = useState(maxWords);
  const debouncedMaxWords = useDebounce(localRange, 500);
  const isClient = useSelector(getIsClient);

  const onSliderChange = (value) => {
    setLocalRange(value);
  };

  useEffect(() => {
    setLocalRange(maxWords);
  }, [maxWords]);

  const handleDownloadImage = async () => {
    if (disabledDownload) {
      return;
    }

    const element = printRef.current;
    const options = {};

    if (isClient) {
      options.backgroundColor = '#000';
    }

    const canvas = await html2canvas(element, options);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'word-cloud.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  useEffect(() => {
    dispatch(setMaxWords(debouncedMaxWords));
  }, [debouncedMaxWords, dispatch]);

  const buttonStyle = {
    textContainer: styles.actionButtonText,
    mainContainer: styles.actionButton,
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.title, { [styles.clientTitle]: isClient })}>
        <FormattedMessage id="customizeWordCloud" />
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.sliderWrapper}>
          <RangeSlider
            disable={wordCloudLoading}
            value={localRange || [maxCount]}
            max={maxCount}
            onChange={onSliderChange}
            min={1}
          />
        </div>
        <div>
          <Button
            disabled={disabledDownload}
            componentStyle={buttonStyle}
            withBorder
            onClick={handleDownloadImage}
            icon={<SaveIcon />}
            colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
          >
            <FormattedMessage id="savePng" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomizeSection;
