import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button from 'components/button';
import {
  LOGIN_ROLES,
  CABINET_ROUTES,
  COLOR_THEMES,
} from 'consts';
import ReportIcon from 'components/svg-icons/report-icon';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import RouteRoleLink from 'components/route-role-link';
import apiService from 'services/apiService';
import LogoIcon from 'components/svg-icons/logo-icon';
import EditReportIcon from 'components/svg-icons/edit-report-icon';
import WordCloudIcon from 'components/svg-icons/word-cloud-icon';
import PeopleIcon from 'components/svg-icons/people-icon';
import { getIsAdmin, getIsUnderMaintenance } from 'store/reducers/general/selectors';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import BurgerIcon from 'components/svg-icons/burger-icon';
import OutsideClicker from 'components/outside-clicker';
import styles from './styles.module.scss';

const {
  COMPANIES,
  TRENDS,
  CREATE_TREND,
  STAFF,
  HASHTAGS,
  TYPE_KEYS,
  GLOSSARY,
  COLLECTIONS,
  WORD_CLOUD,
  PEOPLE,
  PEOPLE_COLLECTIONS,
} = CABINET_ROUTES;

const COLLECTION_LINKS = [
  {
    path: COLLECTIONS,
    text: 'trends',
    role: [
      LOGIN_ROLES.ADMIN,
      LOGIN_ROLES.WRITER,
    ],
  },
  {
    path: PEOPLE_COLLECTIONS,
    text: 'people',
    role: [
      LOGIN_ROLES.ADMIN,
      LOGIN_ROLES.WRITER,
    ],
  },
];

const MENU_LINKS = [
  {
    path: HASHTAGS,
    role: LOGIN_ROLES.ADMIN,
    text: 'hashtags',
  },
  {
    path: TYPE_KEYS,
    role: LOGIN_ROLES.ADMIN,
    text: 'typeKeys',
  },
  {
    path: STAFF,
    role: LOGIN_ROLES.ADMIN,
    text: 'staff',
  },
  {
    path: COMPANIES,
    role: LOGIN_ROLES.ADMIN,
    text: 'companies',
  },
  {
    path: GLOSSARY,
    role: LOGIN_ROLES.ADMIN,
    text: 'glossary',
  },
];

const Header = () => {
  const [glossaryTitle, setGlossaryTitle] = useState('');
  const [openBurger, setOpenBurger] = useState(false);
  const [openCollections, setOpenCollections] = useState(false);
  const toggleBurger = (value) => {
    return () => {
      const toggleVal = typeof value !== 'undefined' ? value : !openBurger;
      setOpenBurger(toggleVal);
    };
  };
  const toggleCollections = (value) => {
    return () => {
      const toggleVal = typeof value !== 'undefined' ? value : !openCollections;
      setOpenCollections(toggleVal);
    };
  };

  const componentStyle = {
    textContainer: styles.actionButtonText,
    mainContainer: styles.actionButton,
  };

  const wordCloudStyle = {
    mainContainer: styles.wordCloudbutton,
    textContainer: styles.actionButtonText,
  };

  const location = useLocation();
  const isAdmin = useSelector(getIsAdmin);
  const isUnderMaintenance = useSelector(getIsUnderMaintenance);

  useEffect(() => {
    apiService.getGlossary().then((res) => {
      if (res.error) {
        return;
      }

      setGlossaryTitle(res?.data?.pageTitle);
    });
  }, []);

  const generateMenu = useCallback((config = []) => {
    const { pathname } = location;
    return config.map((menuItem) => {
      const { path, text, role } = menuItem;
      const parts = pathname.split('/');
      const lastPart = parts[parts.length - 1] || '';
      const realPath = lastPart.split('?')[0];

      const toggleMenu = config === COLLECTION_LINKS ? toggleCollections : toggleBurger;

      const item = (
        <div className={styles.linkAction} key={path} onClick={toggleMenu(false)}>
          <RouteRoleLink path={path}>
            <div
              className={classNames(styles.menuLink, { [styles.active]: realPath === path })}
            >
              {(path === GLOSSARY && glossaryTitle)
                ? glossaryTitle
                : <FormattedMessage id={text} />}
            </div>
          </RouteRoleLink>
        </div>
      );

      if (!role) {
        return item;
      }

      return (
        <RoleVisibleWrapper role={role} key={path}>
          {item}
        </RoleVisibleWrapper>
      );
    });
  }, [location, glossaryTitle]);

  return (
    <div className={styles.header}>
      <div className="container">
        <div className={styles.headerContent}>
          <a href="/">
            <LogoIcon />
          </a>
          { !isUnderMaintenance && (
            <div className={styles.actions}>
              <div onClick={apiService.logout} className={styles.logOut}>
                <FormattedMessage id="logOutAccount" />
              </div>
              {isAdmin && (
                <div className={styles.action}>
                  <OutsideClicker onOutsideClick={toggleBurger(false)}>
                    <div>
                      <Button
                        onClick={toggleBurger()}
                        onlyIcon
                        withBorder
                        componentStyle={wordCloudStyle}
                        icon={<BurgerIcon />}
                        colorTheme={COLOR_THEMES.SECONDARY}
                      />
                      <div
                        className={classNames(styles.burgerMenu, { [styles.active]: openBurger })}
                      >
                        {generateMenu(MENU_LINKS)}
                      </div>
                    </div>
                  </OutsideClicker>
                </div>
              )}
              <div className={styles.action}>
                <RouteRoleLink path={PEOPLE}>
                  <Button
                    withBorder
                    componentStyle={componentStyle}
                    icon={<PeopleIcon />}
                    colorTheme={COLOR_THEMES.SECONDARY}
                  >
                    <FormattedMessage id="people" />
                  </Button>
                </RouteRoleLink>
              </div>
              <div className={styles.action}>
                <RouteRoleLink path={WORD_CLOUD}>
                  <Button
                    onlyIcon={isAdmin}
                    withBorder
                    componentStyle={isAdmin ? wordCloudStyle : componentStyle}
                    icon={<WordCloudIcon />}
                    colorTheme={COLOR_THEMES.SECONDARY}
                  >
                    {!isAdmin && <FormattedMessage id="wordCloud" />}
                  </Button>
                </RouteRoleLink>
              </div>
              <div className={styles.action}>
                <OutsideClicker onOutsideClick={toggleCollections(false)}>
                  <div>
                    <Button
                      onClick={toggleCollections()}
                      withBorder
                      componentStyle={componentStyle}
                      icon={<CollectionsIcon />}
                      colorTheme={COLOR_THEMES.SECONDARY}
                    >
                      <FormattedMessage id="collections" />
                    </Button>
                    <div
                      className={classNames(
                        styles.burgerMenu,
                        { [styles.active]: openCollections },
                      )}
                    >
                      {generateMenu(COLLECTION_LINKS)}
                    </div>
                  </div>
                </OutsideClicker>
              </div>
              <div className={styles.action}>
                <RouteRoleLink path={TRENDS}>
                  <Button
                    withBorder
                    componentStyle={componentStyle}
                    icon={<EditReportIcon />}
                    colorTheme={COLOR_THEMES.SECONDARY}
                  >
                    <FormattedMessage id="trends" />
                  </Button>
                </RouteRoleLink>
              </div>
              <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
                <div className={styles.action}>
                  <RouteRoleLink path={CREATE_TREND}>
                    <Button componentStyle={componentStyle} icon={<ReportIcon />}><FormattedMessage
                      id="generateTrend"
                    />
                    </Button>
                  </RouteRoleLink>
                </div>
              </RoleVisibleWrapper>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default Header;
