import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import TimeAgo from 'javascript-time-ago';
import DateFormatter from 'components/dateFormatter';
import wsApiService from 'services/wsApiService';
import { likeComment, removeComment, unlikeComment } from 'store/reducers/trend-comments/actions';
import CommentLikedIcon from 'components/svg-icons/comment-liked-icon';
import CommentUnlikedIcon from 'components/svg-icons/comment-unliked-icon';
import DotsIcon from 'components/svg-icons/dots-icon';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import Button from 'components/button';
import { getUser } from 'store/reducers/user/selectors';
import ConfirmPopup from 'components/confirm-popup';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import {
  COLOR_THEMES, LOGIN_ROLES, ROLE_THEMES, MS_IN_DAY,
} from 'consts';
import classNames from 'classnames/index';
import { onImageError } from 'utils';
import AttachedImgItem from 'client/pages/trend/components/trend-comments/attached-img-item';
import CommentForm from '../../comment-form';
import CommentText from '../../comment-text';
import styles from './styles.module.scss';

const timeAgo = new TimeAgo('en-US');

const RepliedComment = (props) => {
  const { repliedComment, imgOrigin } = props;

  if (!repliedComment) {
    return null;
  }

  if (repliedComment.deleted) {
    return (
      <div className={styles.repliedComment}>
        <div className={styles.repliedContent}>
          <div className={styles.repliedText}>
            <FormattedMessage id="commentWasDeleted" />
          </div>
        </div>
      </div>
    );
  }

  const { user, text, imgs } = repliedComment;
  const { picture, firstName, lastName } = user;

  return (
    <div className={styles.repliedComment}>
      <div className={classNames(styles.userAvatarWrapper, styles.replied)}>
        <img
          src={picture}
          width={36}
          height={36}
          alt=""
          onError={(e) => onImageError(e, 16)}
        />
      </div>
      <div className={styles.repliedContent}>
        <div className={styles.repliedUserName}>{firstName} {lastName}</div>
        <div className={styles.repliedText}>
          <CommentText text={text} />
        </div>
        { imgs && !!imgs.length && (
          <div className={styles.attachedImgContainer}>
            {imgs.map((imgUrl) => (
              <div key={imgUrl} className={styles.attachedImgWrap}>
                <AttachedImgItem
                  origin={imgOrigin}
                  imgUrl={imgUrl}
                />
              </div>
            ))}
          </div>
        )}

      </div>
    </div>
  );
};

const CommentItem = (props) => {
  const { comment, imgOrigin, onReply = () => {} } = props;
  const {
    _id,
    user,
    text,
    likesCount,
    isLiked,
    createdAt,
    repliedComment,
    trendId,
    edited,
    imgs = [],
  } = comment;

  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();
  const refItem = useRef();
  const intl = useIntl();
  const allowEdit = useMemo(() => {
    if (!user || !currentUser) {
      return false;
    }
    return currentUser._id === user._id;
  }, [user, currentUser]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [editState, setEditState] = useState(false);
  const commentImgs = imgs.map((imgUrl) => ({ url: imgUrl, temp: false }));
  const createdAtDate = new Date(createdAt);
  const olderThanDay = Date.now() - createdAtDate.getTime() > MS_IN_DAY;

  const onLikeClick = () => {
    dispatch(isLiked ? unlikeComment(_id, true) : likeComment(_id, true));
  };

  const onReplyClick = () => {
    onReply(comment);
  };

  const dotsClick = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropdown(false);
    setDeleteAction(!deleteAction);
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    setShowDropdown(false);
    setEditState(!editState);
  };

  const cancelEdit = () => {
    setEditState(false);
  };

  const onDelete = async () => {
    wsApiService.deleteCommentTrend({ id: _id }).then((comment) => {
      if (!comment || !comment.deleted) {
        return;
      }

      dispatch(removeComment(_id));
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showDropdown && refItem.current && !refItem.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showDropdown]);

  const {
    firstName,
    lastName,
    picture,
    companyName,
  } = user;

  return (
    <>
      { deleteAction && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deleteCommentTitle' })}
          text={intl.formatMessage({ id: 'deleteCommentText' })}
          onCancel={toggleDelete}
          submitFunction={onDelete}
          colorTheme={ROLE_THEMES.DARK}
        />
      ) }
      <div className={styles.commentItem} ref={refItem} data-comment-id={_id}>
        <div className={styles.userAvatarWrapper}>
          <img
            src={picture}
            width={54}
            height={54}
            alt=""
            onError={onImageError}
          />
        </div>
        <div className={styles.commentContent}>
          <div className={styles.userName}>
            {firstName} {lastName}
            { companyName && (
              <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
                <span>&nbsp;({companyName})</span>
              </RoleVisibleWrapper>
            ) }
          </div>
          <div className={styles.commentDate}>
            {olderThanDay
              ? <DateFormatter date={createdAt} />
              : timeAgo.format(createdAtDate)}

            {edited && (
              <div className={styles.edited}>
                (<FormattedMessage id="edited" />)
              </div>
            )}
          </div>
          {editState ? (
            <CommentForm
              imgOrigin={imgOrigin}
              trendId={trendId}
              editComment={comment}
              isEdit
              cancelEvent={cancelEdit}
              imgList={commentImgs}
            />
          ) : (
            <div>
              <RepliedComment repliedComment={repliedComment} imgOrigin={imgOrigin} />
              {text && (
                <div className={styles.commentText}>
                  <CommentText text={text} />
                </div>
              )}
              {imgs && !!imgs.length && (
                <div className={styles.attachmentsContainer}>
                  {imgs.map((imgUrl) => (
                    <div key={imgUrl} className={styles.attachedImgWrap}>
                      <AttachedImgItem
                        origin={imgOrigin}
                        imgUrl={imgUrl}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className={styles.commentActions}>
            <div>{likesCount} <FormattedMessage id={likesCount === 1 ? 'likesOne' : 'likes'} /></div>
            <RoleVisibleWrapper role={[LOGIN_ROLES.CLIENT, LOGIN_ROLES.POWER_CLIENT]}>
              <div className={styles.reply} onClick={onReplyClick}><FormattedMessage id="reply" /></div>
            </RoleVisibleWrapper>
          </div>
        </div>
        { allowEdit && (
          <div className={styles.eventSection}>
            <Button
              onlyIcon
              componentStyle={{ mainContainer: classNames(styles.eventButton) }}
              colorTheme={COLOR_THEMES.TRANSPARENT_FULL}
              onClick={dotsClick}
            >
              <DotsIcon className={styles.eventIcon} />
            </Button>
            { showDropdown && (
              <div className={styles.eventWrapper}>
                <Button
                  componentStyle={{
                    mainContainer: classNames(styles.eventButton),
                    textContainer: classNames(styles.spaceBetween),
                  }}
                  colorTheme={COLOR_THEMES.TRANSPARENT_FULL}
                  fullWidth
                  onClick={toggleEdit}
                >
                  <FormattedMessage id="edit" />
                  <EditIcon className={styles.eventIcon} />
                </Button>
                <Button
                  componentStyle={{
                    mainContainer: classNames(styles.eventButton, styles.deleteButton),
                    textContainer: classNames(styles.spaceBetween),
                  }}
                  colorTheme={COLOR_THEMES.TRANSPARENT_FULL}
                  fullWidth
                  onClick={toggleDelete}
                >
                  <FormattedMessage id="delete" />
                  <DeleteIcon className={styles.eventIcon} />
                </Button>
              </div>
            )}
          </div>
        )}
        <RoleVisibleWrapper role={[LOGIN_ROLES.CLIENT, LOGIN_ROLES.POWER_CLIENT]}>
          <div className={styles.likeAction} onClick={onLikeClick}>
            { isLiked ? (
              <CommentLikedIcon />
            ) : (
              <CommentUnlikedIcon />
            ) }
          </div>
        </RoleVisibleWrapper>
      </div>
    </>
  );
};

export default React.memo(CommentItem);
