import apiService from 'services/apiService';
import notificationService from 'services/notificationService';
import {
  RESET_COMPANY_USERS_FILTERS,
  SET_COMPANIES,
  SET_COMPANIES_LOADING,
  SET_COMPANY_DATA,
  SET_COMPANY_FILTER,
  SET_COMPANY_LOADING,
  SET_USER_FILTER,
  SET_COMPANY_DATA_ERROR,
} from './action.types';

const setCompanies = (companies) => ({
  type: SET_COMPANIES,
  companies,
});

export const resetCompanyUsersFilters = () => ({
  type: RESET_COMPANY_USERS_FILTERS,
});

export const setCompanyData = (companyData) => ({
  type: SET_COMPANY_DATA,
  companyData,
});

export const setCompanyDataError = (error) => ({
  type: SET_COMPANY_DATA_ERROR,
  error,
});

const setCompaniesLoading = (loading) => ({
  type: SET_COMPANIES_LOADING,
  loading,
});

const setCompanyLoading = (loading) => ({
  type: SET_COMPANY_LOADING,
  loading,
});

export const setCompanyFilter = (name, value) => ({
  type: SET_COMPANY_FILTER,
  name,
  value,
});

export const setUsersFilter = (name, value) => ({
  type: SET_USER_FILTER,
  name,
  value,
});

export const requestCompanies = () => (dispatch) => {
  dispatch(setCompaniesLoading(true));
  apiService.getCompanies()
    .then((data) => dispatch(setCompanies(data)));
};

export const getCompany = (id) => (dispatch) => {
  dispatch(setCompanyLoading(true));
  apiService.getCompany(id)
    .then((data) => {
      dispatch(setCompanyData(data));
    })
    .catch((err) => {
      notificationService.addNotification(err?.response?.statusText);
    })
    .finally(() => {
      dispatch(setCompanyLoading(false));
    });
};

export const addCompany = (data) => (dispatch) => {
  apiService.addCompany(data)
    .then((res) => {
      dispatch(setCompanyDataError(null));

      if (!res.error) {
        dispatch(requestCompanies());
        return;
      }

      dispatch(setCompanyDataError(res.error));
    });
};

export const editCompany = (id, data) => (dispatch) => apiService.editCompany(id, data)
  .then(() => dispatch(requestCompanies()));

export const deleteCompany = (id) => (dispatch) => apiService.deleteCompany(id)
  .then(() => dispatch(requestCompanies()));

export const addUser = (companyId, data) => (dispatch) => {
  apiService.addUser(data)
    .then((res) => {
      dispatch(setCompanyDataError(null));

      if (!res.error) {
        dispatch(requestCompanies());
        dispatch(getCompany(companyId));
        return;
      }

      dispatch(setCompanyDataError(res.error));
    });
};

export const editUser = (companyId, userId, data) => (dispatch) => apiService
  .editUser(userId, data)
  .then(() => dispatch(getCompany(companyId)));

export const deleteUser = (companyId, userId) => (dispatch) => apiService
  .deleteUser(userId)
  .then(() => {
    dispatch(requestCompanies());
    dispatch(getCompany(companyId));
  });
