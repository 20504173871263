import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import CollapseComponent from 'pages/reports/collapseComponent';
import {
  getExcludeWords,
  getWordCloudData,
} from 'store/reducers/trends/selectors';
import { setExcludeWords } from 'store/reducers/trends/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import 'pages/create-trend/components/tags-row/styles.module.scss';
import styles from './styles.module.scss';

const ExcludedWordsFilter = () => {
  const dispatch = useDispatch();
  const excludedWords = useSelector(getExcludeWords) || [];
  const words = useSelector(getWordCloudData);
  const isClient = useSelector(getIsClient);
  const intl = useIntl();

  const wordsOptions = useMemo(() => {
    const sortedWords = words?.length ? words.sort((firstVal, secondVal) => {
      let firstStr = firstVal?.word?.toLowerCase();
      let secondStr = secondVal?.word?.toLowerCase();

      firstStr = firstStr.replaceAll(/([^a-z])/g, (a) => {
        return String.fromCharCode(a.charCodeAt() - 100);
      });

      secondStr = secondStr.replaceAll(/([^a-z])/g, (a) => {
        return String.fromCharCode(a.charCodeAt() - 100);
      });

      return firstStr.localeCompare(secondStr);
    }) : [];

    return sortedWords.map(({ word }) => ({ label: word, value: word }));
  }, [words]);

  const onChange = (val) => {
    dispatch(setExcludeWords(val));
  };

  return (
    <CollapseComponent
      name={intl.formatMessage({ id: 'excludedWords' })}
    >
      <div className={styles.wrapper}>
        <Select
          onChange={onChange}
          value={excludedWords}
          className={classNames('react-select-container', { isClient })}
          classNamePrefix="react-select"
          options={wordsOptions}
          maxMenuHeight={300}
          isMulti
        />
      </div>
    </CollapseComponent>
  );
};

export default ExcludedWordsFilter;
