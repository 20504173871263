import React from 'react';
import SuccessIcon from 'components/svg-icons/success-icon';
import styles from './styles.module.scss';

const SuccessMessage = (props) => {
  const { message = '' } = props;

  if (!message) {
    return null;
  }

  return (
    <div className={styles.successMessage}>
      <SuccessIcon />
      <span className={styles.message}>{message}</span>
    </div>
  );
};

export default SuccessMessage;
