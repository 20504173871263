import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { getImages } from 'utils';
import { iconComponents, socialStatsFormatter } from 'utils/social-helpers';
import KymIndexIcon from 'components/svg-icons/kym-index-icon';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import Recent from 'client/pages/report/components/social-statistic/icons/recent';
import Relevant from 'client/pages/report/components/social-statistic/icons/relevant';
import Spread from 'client/pages/report/components/social-statistic/icons/spread';
import Viral from 'client/pages/report/components/social-statistic/icons/viral';
import Volume from 'client/pages/report/components/social-statistic/icons/volume';
import ApiService from 'services/apiService';
import styles from './styles.module.scss';

const iconsConfig = {
  kym: {
    icon: <KymIndexIcon />,
    title: 'KYM Index',
  },
  relevant: {
    icon: <Relevant />,
    title: 'Relevant',
  },
  recent: {
    icon: <Recent />,
    title: 'Recent',
  },
  spread: {
    icon: <Spread />,
    title: 'Spread',
  },
  viral: {
    icon: <Viral />,
    title: 'Viral',
  },
  volume: {
    icon: <Volume />,
    title: 'Volume',
  },
};

const TrendData = (props) => {
  const { data } = props;
  const {
    title,
    description,
    typesKeys = [],
    posts = [],
    indexMetrics = {},
    tags,
    _id,
  } = data;

  const [inUserViewPort, setInUserViewPort] = useState(false);
  const [parsersDomains, setParsersDomains] = useState(null);
  const fullTrendWidth = !posts.length || posts.length % 2 === 0;
  const trendDataRef = useRef();

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [] } = parsersData;
        setParsersDomains(parsersDomains);
      }
    });
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setInUserViewPort(true);
    }
  };

  const initObserver = useCallback(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (!trendDataRef.current) {
      return;
    }

    observer.observe(trendDataRef.current);
  }, []);

  useEffect(() => {
    initObserver();
  }, [initObserver]);

  const getStatistic = (statistic) => {
    if (!statistic) {
      return null;
    }

    const statisticComponents = [];

    const badgesTypes = Object.keys(statistic);

    badgesTypes.forEach((badgeType) => {
      const Icon = iconComponents[badgeType];
      const statValue = socialStatsFormatter(statistic[badgeType]);

      if (statistic[badgeType] && statValue) {
        statisticComponents.push(
          <div className={styles.statCounter} key={badgeType}>
            <Icon className={styles.statIcon} />
            <span className={styles.count}>{statValue}</span>
          </div>,
        );
      }
    });

    return statisticComponents;
  };

  return (
    <div
      data-section={`trend-section-${_id}`}
      className={classNames(styles.trendWrapper, { [styles.initScale]: inUserViewPort })}
      ref={trendDataRef}
    >
      <div className={classNames(
        styles.trendItem,
        styles.cartItem,
        { [styles.fullTrendWidth]: fullTrendWidth },
      )}
      >
        <div className={styles.trendContent}>
          <div className={styles.kymIndexWrapper}>
            {Object.keys(indexMetrics)
              .filter((metricName) => (indexMetrics[metricName]))
              .map((metricName) => {
                const { title, icon: IconComponent } = iconsConfig[metricName];
                return (
                  <span className={styles.kymIndex} title={title} key={`metric_${title}_${_id}`}>
                    <div className={styles.kymIcon}>
                      {IconComponent}
                    </div>
                    {Math.round(indexMetrics[metricName])}
                  </span>
                );
              })}
          </div>
          <div className={styles.kymIndexWrapper}>
            {tags.map((tagItem) => (
              <div className={styles.tagItem}>
                {`#${tagItem}`}
              </div>
            ))}
          </div>
          <h2 className={styles.trendTitle}>{title}</h2>
          <div className={styles.trendDescription}>{description}</div>
          <div className={styles.tags}>
            { typesKeys.map((tag) => <div className={styles.tag} key={tag}>{tag}</div>) }
          </div>
        </div>
      </div>
      { posts.map((post) => {
        const {
          _id,
          title,
          image,
          metrics = {},
          link,
        } = post;

        const { postImageUrl, postLogoUrl } = getImages(image, link, parsersDomains);

        return (
          <a
            className={classNames(styles.postItem, styles.cartItem)}
            key={_id}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            { postImageUrl ? (
              <img className={styles.postImg} src={postImageUrl} alt="" />
            ) : (
              <div className={styles.postImg}>
                <ClientLogoIcon />
              </div>
            ) }
            { postLogoUrl && (
              <div className={styles.postLogo}>
                <img alt="" src={postLogoUrl} />
              </div>
            ) }

            <div className={styles.postContent}>
              <h4 className={styles.postTitle}>{title}</h4>
              <div className={styles.statistics}>
                {getStatistic(metrics)}
              </div>
            </div>
          </a>
        );
      }) }
    </div>
  );
};

export default TrendData;
