import React from 'react';

const InfoIconTransparent = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391Z" fill="white" />
    <path d="M8.15625 5.90625C8.15625 6.13003 8.24514 6.34464 8.40338 6.50287C8.56161 6.66111 8.77622 6.75 9 6.75C9.22378 6.75 9.43839 6.66111 9.59662 6.50287C9.75485 6.34464 9.84375 6.13003 9.84375 5.90625C9.84375 5.68247 9.75485 5.46786 9.59662 5.30963C9.43839 5.15139 9.22378 5.0625 9 5.0625C8.77622 5.0625 8.56161 5.15139 8.40338 5.30963C8.24514 5.46786 8.15625 5.68247 8.15625 5.90625ZM9.42188 7.875H8.57812C8.50078 7.875 8.4375 7.93828 8.4375 8.01562V12.7969C8.4375 12.8742 8.50078 12.9375 8.57812 12.9375H9.42188C9.49922 12.9375 9.5625 12.8742 9.5625 12.7969V8.01562C9.5625 7.93828 9.49922 7.875 9.42188 7.875Z" fill="white" />
  </svg>
);

export default InfoIconTransparent;
