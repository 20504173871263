import Button from 'components/button';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import { COLOR_THEMES, LOGIN_ROLES } from 'consts';
import React from 'react';
import classNames from 'classnames';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import styles from './styles.module.scss';

const ActionsBar = (props) => {
  const {
    onClick = () => {},
    editDisabled,
    containerStyle,
    isCollection,
    isOwnCollection,
    isAdmin,
    isDeclinedTrend,
  } = props;

  const callOnClick = (event, eventType) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(eventType);
  };

  return (
    <div className={classNames(styles.container, containerStyle)}>
      {isCollection ? (
        <>
          { (isOwnCollection || isAdmin) && (
            <Button
              onlyIcon
              componentStyle={{
                mainContainer: classNames(styles.eventButton,
                  styles.deleteButton),
              }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={(event) => callOnClick(event, 'deleteCollectionTrend')}
            >
              <DeleteIcon className={styles.eventIcon} />
            </Button>
          ) }
        </>
      ) : (
        <>
          {!isDeclinedTrend && (
            <Button
              onlyIcon
              componentStyle={{ mainContainer: classNames(styles.eventButton, styles.editButton) }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={(event) => callOnClick(event, 'collection')}
            >
              <CollectionsIcon className={styles.eventIcon} />
            </Button>
          )}
          <Button
            onlyIcon
            componentStyle={{ mainContainer: classNames(styles.eventButton, styles.editButton) }}
            colorTheme={COLOR_THEMES.LIGHT}
            onClick={(event) => callOnClick(event, 'edit')}
            disabled={editDisabled}
          >
            <EditIcon className={styles.eventIcon} />
          </Button>
          <RoleVisibleWrapper role={LOGIN_ROLES.ADMIN}>
            <Button
              onlyIcon
              componentStyle={{
                mainContainer: classNames(styles.eventButton,
                  styles.deleteButton),
              }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={(event) => callOnClick(event, 'delete')}
            >
              <DeleteIcon className={styles.eventIcon} />
            </Button>
          </RoleVisibleWrapper>
        </>
      )}
    </div>
  );
};

export default ActionsBar;
