import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { COLOR_THEMES } from 'consts';
import { getIsClient } from 'store/reducers/general/selectors';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import { getCollectionsStateList } from 'store/reducers/collections/selectors';
import { requestCompanies } from 'store/reducers/companies/actions';
import { setCollectionFilter } from 'store/reducers/collections/actions';
import Select from 'components/fields/select';
import Button from 'components/button';
import PlusIcon from 'components/svg-icons/plus-icon';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import styles from './styles.module.scss';

const { PRIMARY, PRIMARY_GRADIENT } = COLOR_THEMES;

const CollectionFilters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { search, sorting } = useSelector(getCollectionsStateList);

  useEffect(() => {
    dispatch(requestCompanies());
  }, []);

  const isClient = useSelector(getIsClient);

  const theme = isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.LIGHT;
  const onFilterChange = (name, value) => {
    dispatch(setCollectionFilter({ name, value }));
  };

  const createCollection = () => {
    dispatch(setCollectionPopup('createCollection'));
  };

  return (
    <>
      <div className={styles.searchInput}>
        <Input
          delay={1000}
          colorTheme={theme}
          name="search"
          value={search}
          onChange={onFilterChange}
          icon={<SearchIcon />}
          placeholder={intl.formatMessage({ id: 'typeCollectionName' })}
        />
      </div>

      <Select
        colorTheme={theme}
        name="sorting"
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        onChange={onFilterChange}
        value={sorting}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'title_ASC' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'title_DESC' },
          { name: intl.formatMessage({ id: 'newest' }), value: 'date_DESC' },
          { name: intl.formatMessage({ id: 'oldest' }), value: 'date_ASC' },
        ]}
      />
      <Button
        colorTheme={isClient ? PRIMARY_GRADIENT : PRIMARY}
        onClick={createCollection}
        icon={<PlusIcon />}
      >
        {intl.formatMessage({ id: 'addNew' })}
      </Button>
    </>
  );
};

export default CollectionFilters;
