import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CreateCollectionPopup from 'components/create-collection-popup';
import AddCollectionPopup from 'components/add-collection-popup';
import HeadingFilters from 'components/heading-filters';
import InfiniteScroll from 'components/infinity-scroll';
import { getPeopleState } from 'store/reducers/people/selectors';
import { requestPeople, resetPeopleFilters } from 'store/reducers/people/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import { getCollectionPopup } from 'store/reducers/trends/selectors';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import { requestCompanies } from 'store/reducers/companies/actions';
import { resetPeopleCollectionFilters } from 'store/reducers/people-collection/actions';
import apiService from 'services/apiService';
import PeopleNoResults from 'pages/people/people-no-results';
import { COLLECTION_TYPE } from 'consts';
import PeopleItem from './people-item';
import TopPeopleFilters from './top-filters';
import styles from './styles.module.scss';

const ListComponent = ({ data, isClient }) => {
  const { id = 0 } = data;

  return (
    <div className={styles.itemContainer} key={`people_id_${id}`}>
      <PeopleItem data={data} isClient={isClient} />
    </div>
  );
};

const PeoplePage = () => {
  const intl = useIntl();
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const collectionPopup = useSelector(getCollectionPopup);

  const closeCollectionPopups = () => {
    dispatch(setCollectionPopup(null));
  };

  const handleCreateCollection = () => {
    dispatch(setCollectionPopup('createPeopleCollection', collectionPopup.data));
  };

  useEffect(() => {
    dispatch(requestCompanies());

    apiService.getParsersInfo().then((result) => {
      dispatch(setParsersInfo(result.data));
    });

    return () => {
      dispatch(resetPeopleCollectionFilters());
      dispatch(resetPeopleFilters());
    };
  }, []);

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="people" />}
        FiltersComponent={<TopPeopleFilters />}
      />
      { collectionPopup && collectionPopup.name === 'addPeopleCollection' && (
        <AddCollectionPopup
          onClose={closeCollectionPopups}
          handleCreateCollection={handleCreateCollection}
          type={COLLECTION_TYPE.PEOPLE}
          title={intl.formatMessage({ id: 'addCharacterToCollection' })}
          {...collectionPopup.data}
        />
      ) }
      { collectionPopup && collectionPopup.name === 'createPeopleCollection' && (
        <CreateCollectionPopup
          onClose={closeCollectionPopups}
          isClient={isClient}
          needToRefresh
          type={COLLECTION_TYPE.PEOPLE}
          {...collectionPopup.data}
        />
      ) }
      <div className="container">
        <div>
          <InfiniteScroll
            RootComponent={RootList}
            RenderComponent={ListComponent}
            dataSelector={getPeopleState}
            loadAction={requestPeople}
            emptyMessage={<PeopleNoResults />}
          />
        </div>
      </div>
    </>
  );
};

export default PeoplePage;
