import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'store/reducers/user/selectors';
import Layout from 'components/layout';
import LoadingComponent from 'components/loadingComponent';
import { routeConfig } from 'utils';
import { MAIN_ROUTES } from 'consts';
import notificationService from 'services/notificationService';

const ProtectedRoute = ({ children, roleName }) => {
  const [loading, setLoading] = useState(true);
  const user = useSelector(getUser);
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      history.replace(MAIN_ROUTES.LOGIN);
    }

    if (user && user.role && roleName) {
      const { role } = user;

      if (role !== roleName) {
        const path = routeConfig[role];

        if (path) {
          history.push(path);
        } else {
          notificationService.addNotification('Config error');
        }

        return;
      }
    }

    setLoading(false);
  }, [user, history, roleName]);

  return loading ? <LoadingComponent /> : (
    <Layout>
      {children}
    </Layout>
  );
};

export default ProtectedRoute;
