import React, { useState, useEffect } from 'react';
import Layout from 'components/layout';
import { useDispatch } from 'react-redux';
import ApiService from 'services/apiService';
import styles from './styles.module.scss';

const initialData = {
  title: '',
  description: '',
};

const GlossaryPage = () => {
  const dispatch = useDispatch();
  const [pageData, setpageData] = useState(initialData);

  useEffect(() => {
    ApiService.getGlossary().then((res) => {
      if (res.error) {
        return;
      }

      setpageData(res.data);
    });
  }, [dispatch]);

  return (
    <Layout>
      <div className="container">
        <div className={styles.glossaryMainContainer}>
          <h1 className={styles.glossaryTitle}>
            {pageData.title}
          </h1>
          <div
            className={styles.contentWrap}
            dangerouslySetInnerHTML={{ __html: pageData.description }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default GlossaryPage;
