import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'components/infinity-scroll';
import { getImages } from 'utils';
import {
  requestCollections,
  setFilterExcludedTrend,
} from 'store/reducers/collections/actions';
import { getCollectionsStateList } from 'store/reducers/collections/selectors';
import { getUser } from 'store/reducers/user/selectors';
import { COLLECTION_TYPE, LOGIN_ROLES } from 'consts';
import { requestPeopleCollections, setFilterExcludedCharacter } from 'store/reducers/people-collection/actions';
import { getPeopleCollectionState } from 'store/reducers/people-collection/selectors';
import CollectionItem from '../collection-item';
import styles from '../../styles.module.scss';

const { TRENDS, PEOPLE } = COLLECTION_TYPE;

const dataActions = {
  [TRENDS]: {
    getList: getCollectionsStateList,
    requestData: requestCollections,
    setExcludedFilter: setFilterExcludedTrend,
  },
  [PEOPLE]: {
    getList: getPeopleCollectionState,
    requestData: requestPeopleCollections,
    setExcludedFilter: setFilterExcludedCharacter,
  },
};

const CollectionList = ({
  onChoose = () => {},
  chosenItem,
  itemId,
  domains,
  isClient = false,
  type = TRENDS,
}) => {
  const user = useSelector(getUser);
  const { getList, requestData, setExcludedFilter } = dataActions[type];

  const filterCollections = useCallback((item) => {
    const { creatorId, trends } = item;
    const { role, _id: userId } = user;
    const isOwnCollection = creatorId === userId;
    const isAdmin = role === LOGIN_ROLES.ADMIN;

    if (isOwnCollection || isAdmin) {
      if (trends?.length) {
        return !trends.includes(itemId);
      }
      return true;
    }

    return false;
  }, []);

  const { data: collectionList } = useSelector(getList);

  const RootList = ({ children }) => {
    return (
      <div
        className={classNames(
          styles.collectionsListWrap,
          { [styles.emptyListStyle]: !collectionList.length },
        )}
      >
        {children}
      </div>
    );
  };

  const CollectionComponent = (props) => {
    const { data } = props;
    const {
      imgLink, imgs, title, _id: id, trends = [], postWithImgLink, people = [],
    } = data;

    const { postImageUrl } = getImages(imgLink, postWithImgLink, domains);

    return (
      <CollectionItem
        img={imgs || postImageUrl}
        name={title}
        key={`collection_item_${data._id}_key`}
        id={id}
        checked={id === chosenItem}
        onClick={onChoose}
        total={trends.length || people.length}
        isClient={isClient}
        type={type}
      />
    );
  };

  return (
    <div className={classNames(styles.listWrapper, { [styles.clientListWrap]: isClient })}>
      <InfiniteScroll
        key="add-collection-popup"
        loadAction={requestData}
        preloadAction={(dispatch) => dispatch(setExcludedFilter(itemId))}
        emptyMessage={<FormattedMessage id="emptyCollectionList" />}
        RenderComponent={CollectionComponent}
        RootComponent={RootList}
        filterDataFunction={filterCollections}
        dataSelector={getList}
      />
    </div>
  );
};

export default CollectionList;
