import {
  SET_COMMENTS_LIST_LOADING,
  SET_COMMENTS_DATA,
  SET_COMMENT_FORM,
  RESET_COMMENT_FORM,
  PUT_NEW_COMMENT,
  UPDATE_COMMENT_IN_LIST,
  REMOVE_COMMENT,
} from './action.types';

const initialState = {
  listLoading: true,
  commentForm: {
    repliedComment: null,
  },
  commentsData: {
    list: [],
  },
};

const trendCommentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMMENTS_LIST_LOADING:
      return { ...state, listLoading: payload };
    case SET_COMMENTS_DATA: {
      return {
        ...state,
        listLoading: false,
        commentsData: {
          list: action.payload,
        },
      };
    }
    case PUT_NEW_COMMENT: {
      return {
        ...state,
        commentsData: {
          ...state.commentsData,
          list: [action.payload, ...state.commentsData.list],
        },
      };
    }
    case SET_COMMENT_FORM: {
      return {
        ...state,
        commentForm: {
          ...state.commentForm,
          [payload.fieldName]: payload.fieldValue,
        },
      };
    }
    case RESET_COMMENT_FORM: {
      return {
        ...state,
        commentForm: initialState.commentForm,
      };
    }
    case UPDATE_COMMENT_IN_LIST: {
      const updatedComment = action.payload;

      const list = state.commentsData.list.map((el) => {
        if (el._id === updatedComment._id) {
          if (!updatedComment.repliedComment && !updatedComment.repliedId) {
            updatedComment.repliedComment = null;
          }

          return { ...el, ...updatedComment };
        }

        return el;
      });

      return {
        ...state,
        commentsData: {
          ...state.commentsData,
          list,
        },
      };
    }
    case REMOVE_COMMENT:
      return {
        ...state,
        commentsData: {
          ...state.commentsData,
          list: state.commentsData.list.filter(({ _id }) => _id !== payload.id),
        },
      };
    default:
      return state;
  }
};

export default trendCommentsReducer;
