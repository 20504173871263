import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'store/reducers/user/selectors';

const UseRoleNavigation = () => {
  const history = useHistory();
  const user = useSelector(getUser);

  const navigate = (path, forse = false) => {
    if (!user) {
      return;
    }

    const { role } = user;

    const pathWithRole = `/${role}/${path}`;

    if (forse) {
      window.location.href = pathWithRole;
    }
    history.push(pathWithRole);
  };

  return {
    navigate,
    rolePath: user ? `/${user.role}` : '/',
  };
};

export default UseRoleNavigation;
