import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from 'pages/collections/styles.module.scss';

const CollectionSharedRow = (props) => {
  const { companyList = [], withNewRow = false } = props;
  const [tagsWidth, setTagsWidth] = useState({ scrollWidth: 0, clientWidth: 0 });
  const sharedRef = useRef();

  useEffect(() => {
    if (!sharedRef.current) {
      return;
    }

    const { scrollWidth, clientWidth } = sharedRef.current;
    setTagsWidth({ scrollWidth, clientWidth });
  }, []);

  return (
    <>
      <div className={styles.createdLabel}>
        <FormattedMessage id="shareTo" />:
      </div>
      <div
        className={classNames(
          styles.sharedCompanyWrapper,
          {
            [styles.limitCompanies]: (tagsWidth.scrollWidth > tagsWidth.clientWidth) && !withNewRow,
            [styles.withNewRow]: withNewRow,
          },
        )}
        ref={sharedRef}
      >
        {companyList.map((company) => {
          return <div key={company._id} className={styles.sharedCompany}>{company.title}</div>;
        })}
      </div>
    </>
  );
};

export default CollectionSharedRow;
