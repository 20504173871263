import {
  COLOR_THEMES, LOGIN_ROLES, MAIN_ROUTES, OS_TYPES,
} from 'consts';

const {
  ADMIN,
  WRITER,
  CLIENT,
  POWER_CLIENT,
} = LOGIN_ROLES;

const {
  WINDOWS,
} = OS_TYPES;

export const routeConfig = {
  [ADMIN]: MAIN_ROUTES.ADMIN,
  [WRITER]: MAIN_ROUTES.WRITER,
  [CLIENT]: MAIN_ROUTES.CLIENT,
  [POWER_CLIENT]: MAIN_ROUTES.POWER_CLIENT,
};

export const colorThemes = [
  COLOR_THEMES.PRIMARY,
  COLOR_THEMES.SECONDARY,
  COLOR_THEMES.LIGHT,
  COLOR_THEMES.PRIMARY_GRADIENT,
  COLOR_THEMES.TRANSPARENT_FULL,
  COLOR_THEMES.TRANSPARENT_LIGHT_WHITE,
  COLOR_THEMES.TRANSPARENT_LIGHT_WHITE_2,
  COLOR_THEMES.LANDING_THEME,
];

export const serializeQueryParams = (object) => {
  const parameters = [];
  Object.keys(object).forEach((property) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(property)) {
      if (!object[property]) {
        return;
      }

      let objValue = object[property];

      if (objValue === '#') {
        return;
      }

      if (typeof objValue === 'string' && objValue.startsWith('#')) {
        objValue = `::tag::${objValue.slice(1)}`;
      }

      parameters.push(encodeURI(`${property}=${objValue}`));
    }
  });

  return `?${parameters.join('&')}`;
};

export const isInteger = (n) => n % 1 === 0;

export const scrollTo = (el) => {
  if (!el) {
    return;
  }

  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  global.window.scrollTo({
    top: yCoordinate - 79,
    behavior: 'smooth',
  });
};

export const sortData = (data, field, direction, type = 'string') => {
  if (!direction) {
    return data;
  }

  return [...data].sort((a, b) => {
    const firstFieldCompare = direction === 'asc' ? a[field] : b[field];
    const secondFieldCompare = direction === 'asc' ? b[field] : a[field];

    if (type === 'string') {
      return firstFieldCompare.localeCompare(secondFieldCompare);
    }

    if (type === 'date') {
      return new Date(firstFieldCompare) - new Date(secondFieldCompare);
    }

    return firstFieldCompare - secondFieldCompare;
  });
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
};

export const formatNumber = (value, separator = ' ') => {
  if (!value) {
    return value;
  }

  return value.toString().replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, `$&${separator}`);
};

export const isDev = process.env.NODE_ENV === 'development';

export const getImages = (imageLink = '', postLink, parsersDomains) => {
  let imagesObj = {
    postImageUrl: null,
    postLogoUrl: null,
  };

  if (!postLink) {
    return imagesObj;
  }

  let postDomain = new URL(postLink).hostname;

  if (['youtube.com', 'youtu.be'].includes(postDomain)) {
    postDomain = 'www.youtube.com';
  }

  if (!parsersDomains || !parsersDomains.length || !parsersDomains.includes(postDomain)) {
    return imagesObj;
  }

  const handledDomainName = postDomain.replaceAll('.', '-');

  if (!imageLink) {
    imagesObj.postImageUrl = `/img/post-images/${handledDomainName}-empty-image.png`;
  } else {
    imagesObj = {
      postImageUrl: isDev ? imageLink : `//${imageLink}`,
      postLogoUrl: `/img/post-logos/${handledDomainName}-logo.svg`,
    };
  }

  return imagesObj;
};

export const getWsUrl = (token) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const wsProtocol = isDev ? 'ws' : 'wss';
  const url = new URL(apiUrl);

  return `${wsProtocol}://${url.host}?auth=${token}`;
};

export const isWin = () => {
  return navigator.appVersion.indexOf(WINDOWS) !== -1;
};

export const stripHtmlTags = (strWithHtml) => {
  return strWithHtml.replace(/(<([^>]+?)>)/gi, ' ');
};

export const isValidDate = (date) => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};

export const getISODate = (date) => {
  if (!isValidDate(date)) {
    return '';
  }

  return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;
};

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const changeTimeZone = (date, timeZone = 'America/New_York') => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
};

export const getCurrentTrendId = () => {
  const splitedPath = window.location.pathname.split('/');

  if (splitedPath[2] === 'trends' && splitedPath[3]) {
    return splitedPath[3];
  }

  return null;
};

export const onImageError = (e, size = 24) => {
  e.target.width = size;
  e.target.height = size;
  e.target.onerror = null;
  e.target.src = '/img/default-avatar.svg';
};
