import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { COLOR_THEMES } from 'consts';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import AddUserPopup from 'components/add-user-popup';
import ConfirmPopup from 'components/confirm-popup';
import { deleteStaff } from 'store/reducers/staff/actions';
import EditIcon from 'components/svg-icons/edit-icon';
import styles from './styles.module.scss';

const StaffItem = (props) => {
  const { data, disableActions } = props;
  const {
    email,
    role,
    _id,
  } = data;

  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);

  const deleteIconStyles = {
    mainContainer: styles.deleteButton,
  };

  const editIconStyles = {
    mainContainer: styles.editButton,
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(!edit);
  };

  const onDelete = async () => {
    dispatch(deleteStaff(_id));
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  return (
    <>
      { edit && (
      <AddUserPopup
        isStaff
        prefilledData={data}
        onCancel={toggleEdit}
      />
      )}
      { deleteAction && <ConfirmPopup onCancel={toggleDelete} submitFunction={onDelete} cancelText={<FormattedMessage id="no" />} /> }
      <div className={classNames(styles.user, { [styles.disabled]: disableActions })}>
        <div className={styles.userNameWrapper}>
          <span className={styles.userName}>{email}</span>
          <div className={styles.tag}><FormattedMessage id={role} /></div>
        </div>
        <div className={styles.actions}>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onClick={toggleEdit}
            disabled={disableActions}
            onlyIcon
            componentStyle={editIconStyles}
          >
            <EditIcon />
          </Button>
          <Button
            colorTheme={COLOR_THEMES.SECONDARY}
            withBorder
            onClick={toggleDelete}
            disabled={disableActions}
            onlyIcon
            componentStyle={deleteIconStyles}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default StaffItem;
