import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CABINET_ROUTES, ROLE_THEMES } from 'consts';
import HeadingFilters from 'components/heading-filters';
import UsersList from 'components/user-list';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import LoadingComponent from 'components/loadingComponent';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import { getIsClient } from 'store/reducers/general/selectors';
import { getCompany, resetCompanyUsersFilters } from 'store/reducers/companies/actions';
import { getCompanyLoading, getCompanyData } from 'store/reducers/companies/selectors';
import styles from 'components/user-list/styles.module.scss';
import UsersFilters from './filters';

const CompanyUsersPage = (props) => {
  const { COMPANIES } = CABINET_ROUTES;
  const { DARK, LIGHT } = ROLE_THEMES;
  const { companyId, isAdmin = false } = props;
  const dispatch = useDispatch();
  const loading = useSelector(getCompanyLoading);
  const companyData = useSelector(getCompanyData);
  const isClient = useSelector(getIsClient);

  const { navigate } = UseRoleNavigation();

  const { id } = useParams();

  const currentCompanyId = id || companyId;

  const backToCompanies = () => {
    navigate(COMPANIES);
  };

  useEffect(() => {
    dispatch(getCompany(currentCompanyId));

    return () => {
      dispatch(resetCompanyUsersFilters());
    };
  }, [currentCompanyId, dispatch]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!companyData) {
    return null;
  }

  const { title, users = [], usersLimit } = companyData;
  const colorTheme = isClient ? DARK : LIGHT;

  const TitleComponent = (
    <div className={styles.companyWrapper}>
      <div className={styles.company}>
        {!isClient && (
          <div className={styles.backButton} onClick={backToCompanies}>
            <div className={styles.backIcon}>
              <RightArrowIcon />
            </div>
          </div>
        )}
        <h2 className={styles.companyName}>{title}</h2>
      </div>
    </div>
  );

  return (
    <>
      <HeadingFilters
        title={TitleComponent}
        FiltersComponent={(
          <UsersFilters
            usersLimit={usersLimit}
            colorTheme={colorTheme}
            usersCount={users.length}
            companyId={currentCompanyId}
            changeRoles={isAdmin}
          />
        )}
      />
      <div className="container">
        <UsersList
          colorTheme={colorTheme}
          companyId={currentCompanyId}
          users={users}
          changeRoles={isAdmin}
        />
      </div>
    </>
  );
};

export default CompanyUsersPage;
