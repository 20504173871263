import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import { getIsClient } from 'store/reducers/general/selectors';
import {
  getWordCloudData,
  getWordCloudFiltered,
  getWordCloudLoading,
  getWordCloudSearch,
} from 'store/reducers/trends/selectors';
import CloseIcon from 'components/svg-icons/close-icon';
import {
  clearTrendFilters, requestWordCloud,
  resetWordCloud,
} from 'store/reducers/trends/actions';
import TrendsSideFilter from 'pages/trends/aside-filters/trendsSideFilter';
import TrendsDateFilter from 'pages/trends/aside-filters/trendsDateFilter';
import Button from 'components/button';
import { LoaderElement } from 'components/loadingComponent';
import WordCloud from 'components/word-cloud';
import { COLOR_THEMES } from 'consts';
import useTrendAsideFilters from 'hooks/useTrendAsideFilters';
import CustomizeSection from './customize-section';
import TopWordCloudFilters from './top-filters';
import ExcludedWordsFilter from './excluded-words-filter';
import styles from './styles.module.scss';

const { PRIMARY, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;

const WordCloudPage = () => {
  const dispatch = useDispatch();
  const isClient = useSelector(getIsClient);
  const intl = useIntl();
  const loading = useSelector(getWordCloudLoading);
  const wordsData = useSelector(getWordCloudFiltered);
  const initialWords = useSelector(getWordCloudData);
  const searchString = useSelector(getWordCloudSearch);

  const printRef = useRef();

  const {
    clearSearchState,
    resetFiltersActive,
    filterSearchHandler,
    countriesForFilterData,
    typeKeysForFilterData,
    allPlatforms,
    filterSearchState,
    companiesForSelect,
  } = useTrendAsideFilters();

  useEffect(() => {
    dispatch(requestWordCloud(true));

    return () => {
      dispatch(clearTrendFilters());
    };
  }, [dispatch]);

  const resetHandler = () => {
    dispatch(resetWordCloud());
    clearSearchState();
  };

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="wordCloud" />}
        FiltersComponent={<TopWordCloudFilters companies={companiesForSelect} />}
      />
      <div className="container">
        <div className={styles.wordCloudWrapper}>
          <div className={styles.filters}>
            <div className={classNames(styles.filters, { [styles.filtersClient]: isClient })}>
              <div className={styles.filtersTopPanel}>
                <div className={styles.filtersTopTitle}>Filters</div>
                <Button
                  disabled={!resetFiltersActive}
                  colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : PRIMARY}
                  onClick={resetHandler}
                  icon={<CloseIcon />}
                >
                  Reset
                </Button>
              </div>

              <ExcludedWordsFilter />

              <TrendsDateFilter
                isWordCloud
                filterType="trendDate"
                title={intl.formatMessage({ id: 'filtersDateRange' })}
              />

              <TrendsSideFilter
                isWordCloud
                filterType="platforms"
                title={intl.formatMessage({ id: 'filtersPlatforms' })}
                data={allPlatforms}
              />

              <TrendsSideFilter
                isWordCloud
                filterType="typesKeys"
                title={intl.formatMessage({ id: 'filtersTypes' })}
                data={typeKeysForFilterData}
                withSearch
                search={filterSearchState.typesKeys}
                searchHandler={filterSearchHandler}
              />

              <TrendsSideFilter
                isWordCloud
                filterType="countries"
                title={intl.formatMessage({ id: 'filtersCountry' })}
                data={countriesForFilterData}
                withSearch
                search={filterSearchState.countries}
                searchHandler={filterSearchHandler}
              />
            </div>
          </div>
          <div className={styles.wordCloudContent}>
            <CustomizeSection printRef={printRef} disabledDownload={!wordsData?.length} />
            { loading ? (
              <div className={styles.loaderWrapper}>
                <LoaderElement />
              </div>
            ) : (
              <WordCloud
                initialWords={initialWords}
                words={wordsData}
                printRef={printRef}
                search={searchString}
              />
            ) }
          </div>
        </div>
      </div>
    </>
  );
};

export default WordCloudPage;
