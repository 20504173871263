import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import LoadingComponent from 'components/loadingComponent';
import { requestHashtags, setHashtagsLoading } from 'store/reducers/hashtags/actions';
import { getHashtags, getHashtagsLoading } from 'store/reducers/hashtags/selectors';
import HashtagsList from './list';
import HashtagsFilters from './filters';

const HashtagsPage = () => {
  const hashtags = useSelector(getHashtags);
  const isLoading = useSelector(getHashtagsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestHashtags());

    return () => {
      dispatch(setHashtagsLoading(true));
    };
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="hashtags" />}
        FiltersComponent={<HashtagsFilters />}
      />
      <div className="container">
        <HashtagsList hashtags={hashtags} />
      </div>
    </>
  );
};

export default HashtagsPage;
