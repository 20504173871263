import nextId from 'react-id-generator';

export const createEmptyLinkForm = () => {
  return {
    _id: nextId(),
    link: '',
    title: '',
    image: '',
    metrics: {
      likes: 0,
      shares: 0,
      comments: 0,
      views: 0,
    },
  };
};

export const createEmptyTrend = () => {
  return {
    _id: nextId(),
    title: '',
    description: '',
    tags: [],
    posts: [createEmptyLinkForm()],
    categoryId: '',
    typesKeys: [],
    indexMetrics: {
      viral: 1,
      recent: 1,
      relevant: 1,
      spread: 1,
      volume: 1,
      kym: 1,
    },
  };
};
