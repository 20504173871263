import React from 'react';

const Comments = () => (
  <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_79:5592)">
      <path d="M27.991 5.475C26.6518 3.82896 24.8326 2.52863 22.5336 1.57438C20.2345 0.620133 17.7233 0.143066 15 0.143066C12.2768 0.143066 9.76557 0.620133 7.46649 1.57438C5.16741 2.52863 3.34827 3.82872 2.00895 5.475C0.669571 7.12116 0 8.91514 0 10.8571C0 12.5311 0.504861 14.0997 1.51499 15.5616C2.52513 17.0237 3.90608 18.2515 5.65849 19.2448C5.53559 19.6911 5.39052 20.1151 5.22305 20.5172C5.05565 20.9189 4.90501 21.2482 4.77108 21.505C4.63704 21.7615 4.45585 22.0463 4.22688 22.3585C3.99825 22.6711 3.82527 22.8917 3.70806 23.0201C3.59096 23.1485 3.39846 23.3602 3.13043 23.6562C2.86276 23.9518 2.68966 24.1446 2.61161 24.2339C2.6003 24.2396 2.55573 24.2895 2.47763 24.3849C2.39959 24.4795 2.36042 24.5267 2.36042 24.5267L2.25997 24.6774C2.20409 24.7607 2.17899 24.8138 2.18451 24.8359C2.19014 24.8584 2.17899 24.9142 2.15108 25.0032C2.12311 25.0927 2.12599 25.1597 2.15941 25.2043V25.2211C2.20409 25.4108 2.29328 25.5643 2.4272 25.6815C2.56113 25.7983 2.71206 25.8569 2.87929 25.8569H2.96308C3.68871 25.7674 4.3248 25.645 4.87159 25.4887C7.79579 24.741 10.3628 23.3906 12.5726 21.4373C13.4095 21.5266 14.2187 21.5711 14.9999 21.5711C17.7231 21.5711 20.2344 21.0941 22.5335 20.1396C24.8325 19.1852 26.6517 17.8853 27.9909 16.2392C29.3303 14.5932 29.9999 12.799 29.9999 10.8571C30 8.91538 29.3304 7.12116 27.991 5.475Z" fill="url(#paint0_radial_79:5592)" />
    </g>
    <defs>
      <filter id="filter0_i_79:5592" x="-2" y="0.143066" width="32" height="28.7139" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_79:5592" />
      </filter>
      <radialGradient id="paint0_radial_79:5592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.39129 35.3465) rotate(-78.4817) scale(35.9269 19.1429)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Comments;
