import apiService from 'services/apiService';
import { serializeQueryParams } from 'utils';
import { getCollectionsStateList } from './selectors';
import {
  COLLECTIONS_LOAD_START,
  SET_COLLECTION,
  SET_COLLECTIONS,
  DELETE_COLLECTION_SUCCESS,
  RESET_COLLECTION,
  COLLECTION_LOAD_ERROR,
  SET_COLLECTION_FILTER,
  RESET_COLLECTION_FILTERS,
  FILTER_EXCLUDED_TREND,
} from './action.types';

export const resetCollectionFilters = () => ({
  type: RESET_COLLECTION_FILTERS,
});

export const setFilterExcludedTrend = (trendId) => ({
  type: FILTER_EXCLUDED_TREND,
  trendId,
});

export const setCollectionLoadError = (payload) => ({
  type: COLLECTION_LOAD_ERROR,
  payload,
});

export const setCollections = (payload) => ({
  type: SET_COLLECTIONS,
  payload: {
    data: payload.list,
    hasMore: payload.hasMore,
  },
});

export const deleteCollectionSuccess = (id) => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload: { id },
});

export const setCollection = (collection) => ({
  type: SET_COLLECTION,
  collection,
});

export const collectionsLoadStart = (reset) => ({
  type: COLLECTIONS_LOAD_START,
  reset,
});

export const requestCollection = (id) => (dispatch) => {
  apiService.getCollection(id)
    .then((res) => {
      const collection = res?.data;
      const collectionError = res?.error;

      if (collection) {
        dispatch(setCollection(collection));
      }

      if (collectionError) {
        dispatch(setCollectionLoadError({
          collectionId: id,
          error: collectionError,
        }));
      }
    });
};

export const requestCollections = (reset) => async (dispatch, getState) => {
  const {
    loading,
    hasMore,
    page,
    search,
    sorting,
    excludedTrend,
  } = getCollectionsStateList(getState());

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  const options = { page: reset ? 1 : page + 1, search, excludedTrend };

  if (sorting) {
    const [sortBy, direction] = sorting.split('_');
    options.sortType = direction;
    options.sortBy = sortBy;
  }

  dispatch(collectionsLoadStart(reset));

  const response = await apiService.getCollections(
    serializeQueryParams(options),
  );
  if (!response) {
    return;
  }

  const { list, metadata } = response;

  dispatch(setCollections({ list, hasMore: metadata.hasMore }));
};

export const deleteCollection = (collectionId) => async (dispatch) => {
  const deleteCollection = await apiService.deleteCollection(collectionId);
  if (deleteCollection.status !== 200) {
    return;
  }

  dispatch(deleteCollectionSuccess(collectionId));
};

export const resetCollection = () => ({
  type: RESET_COLLECTION,
});

export const setCollectionFilter = (payload) => async (dispatch) => {
  dispatch({ type: SET_COLLECTION_FILTER, payload });
  dispatch(requestCollections(true));
};
