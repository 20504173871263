import { createValidator } from 'hooks/useFormData/validation';

const postValidator = createValidator([{
  type: 'checkArray',
  descr: {
    link: createValidator([{ type: 'required' }, { type: 'link' }]),
    title: createValidator([{ type: 'required' }, { type: 'maxLength', value: 40, message: 'The "Post title" is too long (maximum is 40 characters)' }]),
  },
}]);

const trendValidator = {
  title: createValidator([{ type: 'required' }, { type: 'maxLength', value: 63, message: 'The "Trend #" is too long (maximum is 63 characters)' }]),
  description: createValidator([{ type: 'required' }]),
  sharedCompanies: createValidator([{ type: 'required' }]),
  trendDate: createValidator([{ type: 'required' }]),
  userExamplePosts: postValidator,
  brandExamplePosts: postValidator,
};

export default trendValidator;
