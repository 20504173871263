import React from 'react';
import PeopleTagIcon from 'components/svg-icons/people-tag-icon';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import { CABINET_ROUTES } from 'consts';
import styles from './styles.module.scss';

const initialData = {
  title: '',
  _id: '',
  imgLink: '',
};

const TrendPeopleTag = ({ data, s3Domain }) => {
  const itemData = typeof data === 'object' ? data : initialData;
  const { _id = '', title = '', imgLink = '' } = itemData;
  const { navigate } = UseRoleNavigation();
  const s3 = s3Domain?.startsWith('http') ? s3Domain : `//${s3Domain}`;

  const onClick = () => {
    navigate(`${CABINET_ROUTES.PEOPLE}/${_id}`);
  };

  return (
    <div className={styles.peopleTag} onClick={onClick}>
      <div className={styles.peopleAvatar}>
        {imgLink ? (
          <img src={`${s3}/${imgLink}`} alt="" />
        ) : (
          <PeopleTagIcon />
        )}
      </div>
      <div className={styles.peopleTitle}>
        {title}
      </div>
    </div>
  );
};

export default TrendPeopleTag;
