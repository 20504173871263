import React from 'react';
import { store } from 'react-notifications-component';
import { NOTIF_DURATION } from 'consts';
import Notification from 'components/notification';

class NotificationService {
  constructor() {
    this.store = store;
  }

  removeNotification(id) {
    this.store.removeNotification(id);
  }

  addNotification(title, component, additionalProps = {}) {
    if (!title) {
      return null;
    }

    const NotifComponent = component || Notification;

    const notifId = this.store.addNotification({
      dismiss: {
        duration: NOTIF_DURATION,
        pauseOnHover: true,
        click: false,
        touch: false,
      },
      insert: 'top',
      container: 'bottom-left',
      title,
      content: (<NotifComponent
        title={title}
        closeClickHandler={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.removeNotification(notifId);
        }}
        {...additionalProps}
      />),
    });

    return notifId;
  }
}

export default new NotificationService();
