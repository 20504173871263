const defaultStorageSource = {
  name: 'defaultStorageSource',

  variablesBundle: {},

  get(vname) {
    return this.variablesBundle[vname];
  },

  add(vname, value) {
    this.variablesBundle[vname] = value;
  },

  delete(vname) {
    this.variablesBundle[vname] = null;
  },

  clear() {
    this.variablesBundle = {};
  },
};

export default defaultStorageSource;
