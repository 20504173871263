import React from 'react';
import HashtagItem from './item';
import styles from './styles.module.scss';

const HashtagsList = (props) => {
  const { hashtags = [] } = props;

  if (!hashtags.length) {
    return null;
  }

  return (
    <>
      <div className={styles.hashtagsList}>
        {hashtags.map((hashtag) => (
          <div className={styles.hashtagItem} key={hashtag._id}>
            <HashtagItem data={hashtag} />
          </div>
        ))}
      </div>
    </>
  );
};

export default HashtagsList;
