export const TRENDS_LOAD_START = 'TRENDS_LOAD_START';
export const TRENDS_LOAD_SUCCESS = 'TRENDS_LOAD_SUCCESS';
export const TRENDS_LOAD_ERROR = 'TRENDS_LOAD_ERROR';
export const SET_TREND_FILTER = 'SET_TREND_FILTER';
export const RESET_TREND_FILTERS = 'RESET_TREND_FILTERS';
export const REMOVE_TREND = 'REMOVE_TREND';
export const SET_COLLECTION_POPUP = 'SET_COLLECTION_POPUP';
export const WORD_CLOUD_LOAD_START = 'WORD_CLOUD_LOAD_START';
export const WORD_CLOUD_LOAD_SUCCESS = 'WORD_CLOUD_LOAD_SUCCESS';
export const SET_MAX_WORDS = 'SET_MAX_WORDS';
export const SET_MAX_COUNT = 'SET_MAX_COUNT';
export const SET_EXCLUDE_WORDS = 'SET_EXCLUDE_WORDS';
export const SET_WORD_CLOUD_SEARCH = 'SET_WORD_CLOUD_SEARCH';
