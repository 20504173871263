import React from 'react';

const LogoIcon = () => (
  <svg width="162" height="40" viewBox="0 0 162 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="86.832" y="18.0591" width="75.1681" height="21.9409" rx="3" fill="#1CA5E0" />
    <path d="M4.94292 0V1.89367H3.79806V6.03376L7.05094 2.8962C7.35381 2.61154 7.59611 2.39494 7.77784 2.24641C7.97168 2.09789 8.1534 1.99269 8.32301 1.9308V1.89367H6.97825V0H12.1392V1.89367H11.1398L6.45124 6.18228L11.3578 11.1021H12.3755V12.9958H6.77835V11.1021H8.2685V11.065C8.07466 10.9288 7.89899 10.8051 7.74149 10.6937C7.59611 10.5823 7.48102 10.4833 7.39621 10.3966L3.79806 6.81351V11.1021H4.94292V12.9958H0V11.1021H1.14487V1.89367H0V0H4.94292Z" fill="#104665" />
    <path d="M23.0914 11.1021H24.1818V12.9958H19.5115V11.1021H20.5473V7.05486C20.5473 6.44839 20.3959 6.00282 20.093 5.71815C19.8022 5.43348 19.4024 5.29114 18.8936 5.29114C18.1667 5.29114 17.6155 5.52631 17.2399 5.99663C16.8643 6.45458 16.6765 7.05486 16.6765 7.79747V11.1021H17.7669V12.9958H13.2056V11.1021H14.1324V5.17975H12.8967V3.28608H16.622C16.622 3.64501 16.616 3.97919 16.6039 4.28861C16.5917 4.59803 16.5493 4.93221 16.4767 5.29114H16.513C16.7553 4.64754 17.1551 4.11533 17.7124 3.69452C18.2697 3.2737 18.9663 3.06329 19.8022 3.06329C20.3716 3.06329 20.8623 3.15612 21.2742 3.34177C21.6861 3.52743 22.0253 3.78735 22.2919 4.12152C22.5705 4.44332 22.7704 4.8332 22.8916 5.29114C23.0248 5.73671 23.0914 6.23179 23.0914 6.77638V11.1021Z" fill="#104665" />
    <path d="M30.389 11.1021C31.1886 11.1021 31.758 10.8608 32.0972 10.3781C32.4485 9.88299 32.6242 9.18988 32.6242 8.29874C32.6242 7.2467 32.4485 6.46695 32.0972 5.9595C31.7459 5.43967 31.1704 5.17975 30.3708 5.17975C29.7166 5.17975 29.1835 5.43967 28.7716 5.9595C28.3597 6.46695 28.1538 7.2467 28.1538 8.29874C28.1538 9.18988 28.3234 9.88299 28.6626 10.3781C29.0139 10.8608 29.5894 11.1021 30.389 11.1021ZM35.3864 8.24304C35.3864 8.97328 35.2653 9.64164 35.023 10.2481C34.7807 10.8546 34.4415 11.3806 34.0053 11.8262C33.5692 12.2594 33.0422 12.5997 32.4243 12.8473C31.8186 13.0948 31.1401 13.2186 30.389 13.2186C28.7898 13.2186 27.5541 12.7854 26.6818 11.919C25.8216 11.0526 25.3916 9.82729 25.3916 8.24304C25.3916 7.52518 25.5127 6.85064 25.755 6.21941C25.9973 5.58819 26.3365 5.0436 26.7727 4.58566C27.2088 4.11533 27.7297 3.74403 28.3355 3.47173C28.9534 3.19944 29.6379 3.06329 30.389 3.06329C32.0245 3.06329 33.2663 3.50886 34.1144 4.4C34.9624 5.27877 35.3864 6.55978 35.3864 8.24304Z" fill="#104665" />
    <path d="M40.2154 3.28608V5.17975H39.3249C39.3612 5.24164 39.3915 5.30352 39.4158 5.36541C39.4521 5.41491 39.4824 5.4768 39.5066 5.55106C39.543 5.62532 39.5793 5.72434 39.6157 5.84811C39.6641 5.9595 39.7247 6.10802 39.7974 6.29368L40.9423 9.52406C40.9665 9.58594 41.0089 9.72828 41.0695 9.95106C41.13 10.1738 41.1846 10.409 41.233 10.6565H41.2694C41.3178 10.4461 41.3905 10.2295 41.4874 10.0068C41.5844 9.7716 41.651 9.6107 41.6873 9.52406L43.1048 6.29368L42.6686 5.17975H41.651V3.28608H46.2668V5.17975H45.2855C45.3945 5.35303 45.4854 5.54487 45.5581 5.75528C45.6308 5.96569 45.7095 6.18228 45.7943 6.40507L46.8847 9.4498C46.9331 9.59832 46.9695 9.7159 46.9937 9.80254C47.03 9.8768 47.0543 9.94487 47.0664 10.0068C47.0906 10.0686 47.1088 10.1491 47.1209 10.2481C47.1451 10.3347 47.1754 10.4709 47.2118 10.6565H47.2481C47.2845 10.4585 47.3148 10.2976 47.339 10.1738C47.3753 10.0501 47.4056 9.95106 47.4298 9.8768C47.4541 9.80254 47.4722 9.74065 47.4844 9.69115C47.4965 9.64164 47.5146 9.59213 47.5389 9.54262L48.6474 6.36794C48.7807 5.97188 48.8776 5.69958 48.9382 5.55106C49.0109 5.40254 49.0775 5.27877 49.1381 5.17975H47.8842V3.28608H52.4273V5.17975H51.555L48.2658 12.9958H45.6126L44.5768 10.2295C44.5162 10.0563 44.4314 9.83348 44.3223 9.56119C44.2254 9.2889 44.1588 9.03517 44.1224 8.80001H44.0861C44.0619 8.96091 44.0316 9.10324 43.9952 9.22701C43.9589 9.3384 43.9104 9.47455 43.8499 9.63545L42.4142 12.9958H39.761L36.5627 5.17975H35.6722V3.28608H40.2154Z" fill="#104665" />
    <path d="M61.853 0V0.816879H60.4901C60.5628 0.89114 60.6173 0.953025 60.6536 1.00253C60.69 1.03966 60.7202 1.07679 60.7445 1.11392C60.7808 1.15106 60.8111 1.18819 60.8353 1.22532C60.8596 1.26245 60.8959 1.31814 60.9444 1.39241L64.379 6.27511L67.8863 1.31815C67.9105 1.26864 67.9529 1.20675 68.0135 1.13249C68.0741 1.04585 68.1649 0.940648 68.2861 0.816879H66.6142V0H70.5213V0.816879H69.3219L64.906 7.20338L64.8333 12.1789H66.4143V12.9958H62.3437V12.1789H63.8338L63.8883 7.20338L59.3088 0.816879H57.9459V0H61.853Z" fill="#104665" />
    <path d="M70.8539 8.22448C70.8539 9.4993 71.1447 10.5018 71.7262 11.2321C72.3077 11.9623 73.1255 12.3274 74.1795 12.3274C75.2335 12.3274 76.0513 11.9623 76.6328 11.2321C77.2143 10.5018 77.5051 9.4993 77.5051 8.22448C77.5051 6.94965 77.2143 5.94712 76.6328 5.21688C76.0513 4.48664 75.2275 4.12152 74.1613 4.12152C73.1316 4.12152 72.3198 4.49283 71.7262 5.23545C71.1447 5.96569 70.8539 6.96203 70.8539 8.22448ZM74.1795 3.23038C74.8337 3.23038 75.4274 3.35415 75.9604 3.60169C76.5056 3.83685 76.966 4.17722 77.3415 4.62279C77.7171 5.05598 78.0079 5.582 78.2138 6.20085C78.4198 6.80732 78.5227 7.48186 78.5227 8.22448C78.5227 8.9671 78.4198 9.64783 78.2138 10.2667C78.02 10.8855 77.7353 11.4115 77.3597 11.8447C76.9841 12.2779 76.5238 12.6183 75.9786 12.8658C75.4455 13.101 74.8458 13.2186 74.1795 13.2186C73.5132 13.2186 72.9074 13.0948 72.3623 12.8473C71.8292 12.5997 71.3749 12.2594 70.9993 11.8262C70.6237 11.3806 70.333 10.8546 70.127 10.2481C69.9332 9.62926 69.8363 8.95472 69.8363 8.22448C69.8363 7.48186 69.9332 6.80732 70.127 6.20085C70.333 5.582 70.6237 5.05598 70.9993 4.62279C71.3749 4.17722 71.8292 3.83685 72.3623 3.60169C72.9074 3.35415 73.5132 3.23038 74.1795 3.23038Z" fill="#104665" />
    <path d="M82.6337 3.45317L82.561 9.30127C82.5489 10.3904 82.7367 11.1702 83.1244 11.6405C83.5242 12.0985 84.142 12.3274 84.978 12.3274C85.9472 12.3274 86.6862 11.9871 87.195 11.3063C87.7038 10.6256 87.9704 9.66639 87.9946 8.4287L88.0673 4.27005H86.3954V3.45317H89.0123L88.9396 12.1789H90.3934V12.9958H88.0128C88.0128 12.7111 88.0249 12.3769 88.0491 11.9933C88.0855 11.6096 88.146 11.1826 88.2308 10.7122H88.1945C87.9764 11.4672 87.5706 12.0737 86.9769 12.5317C86.3954 12.9896 85.717 13.2186 84.9416 13.2186C83.8149 13.2186 82.9729 12.9153 82.4156 12.3089C81.8583 11.69 81.5918 10.7061 81.616 9.35697L81.6887 4.27005H80.0896V3.45317H82.6337Z" fill="#104665" />
    <path d="M97.634 3.23038C97.8763 3.23038 98.0762 3.24895 98.2337 3.28608C98.4033 3.31083 98.6395 3.36034 98.9424 3.4346L98.797 4.36287C98.6153 4.27623 98.4336 4.21435 98.2518 4.17722C98.0822 4.14009 97.8823 4.12152 97.6522 4.12152C97.216 4.12152 96.8102 4.25767 96.4346 4.52996C96.059 4.80225 95.7319 5.155 95.4533 5.58819C95.1746 6.02138 94.9505 6.51646 94.7809 7.07342C94.6234 7.61801 94.5447 8.17497 94.5447 8.74431L94.5083 12.1789H96.2529V12.9958H92.0732V12.1789H93.5815L93.636 4.27005H91.8733V3.45317H94.6173C94.6173 3.88636 94.6052 4.35668 94.581 4.86414C94.5568 5.37159 94.5204 5.92237 94.472 6.51646H94.5083C94.7991 5.46442 95.211 4.65373 95.744 4.08439C96.2771 3.51505 96.9071 3.23038 97.634 3.23038Z" fill="#104665" />
    <path d="M109.368 0L112.639 8.61435C112.723 8.83714 112.82 9.09705 112.929 9.3941C113.038 9.67877 113.111 10.0068 113.147 10.3781H113.184C113.196 10.2543 113.208 10.1367 113.22 10.0253C113.244 9.90156 113.275 9.75922 113.311 9.59832C113.359 9.42504 113.42 9.22082 113.493 8.98566C113.578 8.73812 113.687 8.43489 113.82 8.07596L116.782 0H121.398V1.89367H120.071V11.1021H121.343V12.9958H116.455V11.1021H117.527V3.62026H117.491C117.442 3.97919 117.357 4.33193 117.236 4.67848C117.115 5.02504 116.988 5.37159 116.855 5.71815L114.129 12.9958H111.585L108.695 5.34684C108.586 5.04979 108.471 4.74656 108.35 4.43713C108.229 4.12771 108.156 3.8121 108.132 3.4903H108.096V11.1021H109.422V12.9958H104.915V11.1021H106.024V1.89367H104.697V0H109.368Z" fill="#104665" />
    <path d="M128.974 6.94347C128.974 6.27511 128.804 5.76147 128.465 5.40254C128.126 5.03123 127.647 4.84557 127.029 4.84557C126.472 4.84557 126.024 5.02504 125.685 5.38397C125.357 5.7429 125.164 6.26273 125.103 6.94347H128.974ZM125.121 8.72575C125.158 9.50549 125.442 10.1182 125.975 10.5637C126.52 10.9969 127.247 11.2135 128.156 11.2135C128.738 11.2135 129.252 11.164 129.701 11.065C130.149 10.966 130.652 10.7927 131.209 10.5452L131.082 12.6059C130.488 12.8287 129.919 12.9834 129.374 13.0701C128.84 13.1691 128.247 13.2186 127.593 13.2186C126.757 13.2186 126.012 13.101 125.357 12.8658C124.715 12.6307 124.17 12.2965 123.722 11.8633C123.286 11.4177 122.947 10.8855 122.704 10.2667C122.474 9.64783 122.359 8.95472 122.359 8.18735C122.359 7.4076 122.468 6.70211 122.686 6.07089C122.916 5.43967 123.237 4.90127 123.649 4.4557C124.073 4.01013 124.576 3.66976 125.158 3.4346C125.751 3.18706 126.411 3.06329 127.138 3.06329C127.841 3.06329 128.477 3.18706 129.046 3.4346C129.616 3.66976 130.1 4.02251 130.5 4.49283C130.9 4.96315 131.203 5.55725 131.409 6.27511C131.627 6.9806 131.736 7.79747 131.736 8.72575H125.121Z" fill="#104665" />
    <path d="M142.498 5.25401C142.728 4.57328 143.104 4.04107 143.625 3.65739C144.158 3.26132 144.842 3.06329 145.678 3.06329C146.248 3.06329 146.738 3.15612 147.15 3.34177C147.562 3.52743 147.901 3.78735 148.168 4.12152C148.447 4.44332 148.646 4.8332 148.768 5.29114C148.901 5.73671 148.968 6.23179 148.968 6.77638V11.1021H150.058V12.9958H145.497V11.1021H146.423V7.05486C146.423 6.44839 146.272 6.00282 145.969 5.71815C145.678 5.43348 145.278 5.29114 144.77 5.29114C144.14 5.29114 143.643 5.53249 143.28 6.01519C142.928 6.48552 142.753 7.07961 142.753 7.79747V11.1021H143.843V12.9958H139.282V11.1021H140.208V7.05486C140.208 6.44839 140.057 6.00282 139.754 5.71815C139.463 5.43348 139.063 5.29114 138.555 5.29114C137.9 5.29114 137.398 5.53249 137.046 6.01519C136.707 6.4979 136.538 7.09199 136.538 7.79747V11.1021H137.628V12.9958H132.958V11.1021H133.993V5.17975H132.758V3.28608H136.483C136.483 3.63263 136.477 3.96681 136.465 4.28861C136.453 4.59803 136.41 4.91983 136.338 5.25401H136.374C136.64 4.54853 137.052 4.01013 137.61 3.63882C138.179 3.25514 138.864 3.06329 139.663 3.06329C141.262 3.06329 142.195 3.79353 142.462 5.25401H142.498Z" fill="#104665" />
    <path d="M157.901 6.94347C157.901 6.27511 157.731 5.76147 157.392 5.40254C157.053 5.03123 156.574 4.84557 155.956 4.84557C155.399 4.84557 154.951 5.02504 154.611 5.38397C154.284 5.7429 154.091 6.26273 154.03 6.94347H157.901ZM154.048 8.72575C154.084 9.50549 154.369 10.1182 154.902 10.5637C155.447 10.9969 156.174 11.2135 157.083 11.2135C157.664 11.2135 158.179 11.164 158.628 11.065C159.076 10.966 159.579 10.7927 160.136 10.5452L160.009 12.6059C159.415 12.8287 158.846 12.9834 158.301 13.0701C157.767 13.1691 157.174 13.2186 156.52 13.2186C155.684 13.2186 154.939 13.101 154.284 12.8658C153.642 12.6307 153.097 12.2965 152.649 11.8633C152.213 11.4177 151.874 10.8855 151.631 10.2667C151.401 9.64783 151.286 8.95472 151.286 8.18735C151.286 7.4076 151.395 6.70211 151.613 6.07089C151.843 5.43967 152.164 4.90127 152.576 4.4557C153 4.01013 153.503 3.66976 154.084 3.4346C154.678 3.18706 155.338 3.06329 156.065 3.06329C156.768 3.06329 157.404 3.18706 157.973 3.4346C158.543 3.66976 159.027 4.02251 159.427 4.49283C159.827 4.96315 160.13 5.55725 160.336 6.27511C160.554 6.9806 160.663 7.79747 160.663 8.72575H154.048Z" fill="#104665" />
    <path d="M96.7165 24.2525V33.8221H94.4565V24.2525H96.7165Z" fill="white" />
    <path d="M98.6818 24.2525H100.763L102.815 27.4575C103.182 28.0348 103.534 28.6272 103.871 29.2348C104.218 29.8322 104.485 30.374 104.674 30.8601H104.704C104.654 30.2525 104.624 29.6449 104.614 29.0373C104.604 28.4196 104.599 27.8069 104.599 27.1993V24.2525H106.681V33.8221H104.599L102.429 30.5563C102.072 30.0196 101.745 29.4727 101.447 28.9158C101.15 28.3588 100.897 27.7715 100.689 27.1537H100.659C100.709 27.7917 100.739 28.4044 100.749 28.9917C100.758 29.5791 100.763 30.1765 100.763 30.7841V33.8221H98.6818V24.2525Z" fill="white" />
    <path d="M111.81 24.0702C112.464 24.0702 113.029 24.1411 113.505 24.2828C113.98 24.4145 114.392 24.5765 114.739 24.7689L114.233 26.455C113.846 26.2525 113.455 26.0955 113.058 25.9841C112.672 25.8626 112.3 25.8018 111.943 25.8018C111.487 25.8018 111.14 25.8879 110.903 26.0601C110.665 26.2322 110.546 26.4601 110.546 26.7436C110.546 27.0677 110.66 27.3056 110.888 27.4575C111.116 27.5993 111.468 27.7512 111.943 27.9132L112.821 28.2018C113.167 28.3234 113.49 28.4753 113.787 28.6575C114.084 28.8297 114.337 29.0322 114.545 29.2651C114.763 29.498 114.932 29.7613 115.051 30.055C115.17 30.3487 115.229 30.6677 115.229 31.012C115.229 31.417 115.155 31.8018 115.006 32.1664C114.858 32.5208 114.63 32.8348 114.322 33.1082C114.025 33.3816 113.643 33.5993 113.177 33.7613C112.721 33.9132 112.181 33.9892 111.557 33.9892C110.972 33.9892 110.412 33.9284 109.877 33.8069C109.351 33.6753 108.866 33.493 108.42 33.2601L108.925 31.574C109.351 31.7867 109.768 31.9537 110.174 32.0753C110.58 32.1968 111.007 32.2575 111.453 32.2575C111.978 32.2575 112.355 32.1715 112.583 31.9993C112.821 31.817 112.94 31.574 112.94 31.2702C112.94 31.1689 112.92 31.0677 112.88 30.9664C112.84 30.8651 112.761 30.7639 112.642 30.6626C112.533 30.5613 112.375 30.4651 112.166 30.374C111.968 30.2727 111.71 30.1715 111.393 30.0702L110.665 29.812C110.318 29.6803 109.996 29.5335 109.698 29.3715C109.401 29.2094 109.143 29.017 108.925 28.7942C108.717 28.5715 108.553 28.3132 108.434 28.0196C108.315 27.7259 108.256 27.3765 108.256 26.9715C108.256 26.5563 108.33 26.1715 108.479 25.817C108.638 25.4626 108.866 25.1588 109.163 24.9056C109.47 24.6423 109.842 24.4398 110.278 24.298C110.724 24.1461 111.235 24.0702 111.81 24.0702Z" fill="white" />
    <path d="M119.034 24.2525V33.8221H116.774V24.2525H119.034Z" fill="white" />
    <path d="M125.623 24.0702C126.218 24.0702 126.743 24.1208 127.199 24.2221C127.665 24.3234 128.076 24.4601 128.433 24.6322L127.972 26.1512C127.635 25.9791 127.278 25.8575 126.902 25.7866C126.535 25.7056 126.178 25.6651 125.831 25.6651C125.435 25.6651 125.063 25.731 124.716 25.8626C124.369 25.9942 124.062 26.2018 123.794 26.4854C123.537 26.7588 123.333 27.1082 123.185 27.5335C123.036 27.9588 122.962 28.4651 122.962 29.0525C122.962 30.1259 123.19 30.9512 123.646 31.5284C124.111 32.0955 124.776 32.3791 125.638 32.3791C125.846 32.3791 126.034 32.3689 126.203 32.3487C126.371 32.3284 126.5 32.298 126.59 32.2575V30.1765H125.118V28.4753H128.76V33.3968C128.374 33.5689 127.918 33.7107 127.392 33.8221C126.867 33.9335 126.277 33.9892 125.623 33.9892C124.909 33.9892 124.245 33.8829 123.631 33.6702C123.026 33.4575 122.496 33.1436 122.04 32.7284C121.584 32.3031 121.227 31.7867 120.969 31.1791C120.712 30.5613 120.583 29.8525 120.583 29.0525C120.583 28.2525 120.712 27.5436 120.969 26.9259C121.227 26.298 121.584 25.7765 122.04 25.3613C122.496 24.936 123.026 24.617 123.631 24.4044C124.245 24.1816 124.909 24.0702 125.623 24.0702Z" fill="white" />
    <path d="M130.451 33.8221V24.2525H132.711V27.974H135.968V24.2525H138.228V33.8221H135.968V29.7664H132.711V33.8221H130.451Z" fill="white" />
    <path d="M146.677 24.2525V26.0449H144.12V33.8221H141.86V26.0449H139.362V24.2525H146.677Z" fill="white" />
    <path d="M150.955 24.0702C151.609 24.0702 152.174 24.1411 152.65 24.2828C153.126 24.4145 153.537 24.5765 153.884 24.7689L153.379 26.455C152.992 26.2525 152.601 26.0955 152.204 25.9841C151.818 25.8626 151.446 25.8018 151.089 25.8018C150.633 25.8018 150.286 25.8879 150.048 26.0601C149.81 26.2322 149.691 26.4601 149.691 26.7436C149.691 27.0677 149.805 27.3056 150.033 27.4575C150.261 27.5993 150.613 27.7512 151.089 27.9132L151.966 28.2018C152.313 28.3234 152.635 28.4753 152.933 28.6575C153.23 28.8297 153.483 29.0322 153.691 29.2651C153.909 29.498 154.078 29.7613 154.197 30.055C154.316 30.3487 154.375 30.6677 154.375 31.012C154.375 31.417 154.301 31.8018 154.152 32.1664C154.003 32.5208 153.775 32.8348 153.468 33.1082C153.171 33.3816 152.789 33.5993 152.323 33.7613C151.867 33.9132 151.327 33.9892 150.703 33.9892C150.118 33.9892 149.558 33.9284 149.022 33.8069C148.497 33.6753 148.011 33.493 147.565 33.2601L148.071 31.574C148.497 31.7867 148.913 31.9537 149.32 32.0753C149.726 32.1968 150.152 32.2575 150.598 32.2575C151.124 32.2575 151.5 32.1715 151.728 31.9993C151.966 31.817 152.085 31.574 152.085 31.2702C152.085 31.1689 152.065 31.0677 152.026 30.9664C151.986 30.8651 151.907 30.7639 151.788 30.6626C151.679 30.5613 151.52 30.4651 151.312 30.374C151.114 30.2727 150.856 30.1715 150.539 30.0702L149.81 29.812C149.463 29.6803 149.141 29.5335 148.844 29.3715C148.547 29.2094 148.289 29.017 148.071 28.7942C147.863 28.5715 147.699 28.3132 147.58 28.0196C147.461 27.7259 147.402 27.3765 147.402 26.9715C147.402 26.5563 147.476 26.1715 147.625 25.817C147.783 25.4626 148.011 25.1588 148.309 24.9056C148.616 24.6423 148.988 24.4398 149.424 24.298C149.87 24.1461 150.38 24.0702 150.955 24.0702Z" fill="white" />
  </svg>
);

export default LogoIcon;
