import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { CABINET_ROUTES, REPORT_STATUSES } from 'consts';
import ApiService from 'services/apiService';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import LoadingComponent from 'components/loadingComponent';
import AddCollectionPopup from 'components/add-collection-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionPopup } from 'store/reducers/trends/selectors';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import CreateCollectionPopup from 'components/create-collection-popup';
import { getIsClient } from 'store/reducers/general/selectors';
import PreviewHeadActions from './components/preview-head-actions';
import TrendHeader from './components/trend-header';
import TrendData from './components/trend-data';
import Comments from './components/trend-comments';
import styles from './styles.module.scss';

const TrendItemPage = (props) => {
  const { isPreview } = props;

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [trendData, setTrendData] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const collectionPopup = useSelector(getCollectionPopup);
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const { navigate } = UseRoleNavigation();
  const intl = useIntl();

  const toggleForceUpdate = () => {
    setForceUpdate(!forceUpdate);
  };

  const closeCollectionPopups = () => {
    dispatch(setCollectionPopup(null));
  };
  const handleCreateCollection = () => {
    dispatch(setCollectionPopup('createCollection', collectionPopup.data));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    ApiService.getTrendView(id).then((res) => {
      if (res && res.data) {
        setTrendData(res.data);
        setLoading(false);
        return;
      }

      navigate(CABINET_ROUTES.TRENDS);
    });
  }, [id, forceUpdate]);

  if (loading || !trendData) {
    return <LoadingComponent />;
  }

  const {
    title,
    description,
    trendDate,
    updatedAt,
    createdAt,
    indexMetrics,
    totalPostsMetrics,
    tags,
    typesKeys,
    countries,
    status,
    people,
    userExamplePosts,
    brandExamplePosts,
  } = trendData;

  return trendData && trendData.typesKeys && (
    <div
      className={classNames(styles.trendPage, styles.isPreview)}
    >
      { isPreview && (
        <PreviewHeadActions reportData={trendData} id={id} toggleForceUpdate={toggleForceUpdate} />
      ) }
      <TrendHeader
        title={title}
        description={description}
        trendDate={trendDate}
        updatedDate={updatedAt}
        createdDate={createdAt}
        totalMetrics={totalPostsMetrics}
        totalIndexMetrics={indexMetrics}
        tags={tags}
        typesKeys={typesKeys}
        countries={countries}
        trendId={id}
        isDeclined={status === REPORT_STATUSES.DECLINED}
        people={people}
      />
      <div className={styles.trendData}>
        <div className="container">
          <div className={styles.categoryTrends}>
            <TrendData posts={userExamplePosts} title={intl.formatMessage({ id: 'userExamplePosts' })} />
          </div>
          <div className={styles.categoryTrends}>
            <TrendData posts={brandExamplePosts} title={intl.formatMessage({ id: 'brandExamplePosts' })} />
          </div>
          <Comments trendId={id} />
        </div>
      </div>
      { collectionPopup && collectionPopup.name === 'addCollection' && (
        <AddCollectionPopup
          onClose={closeCollectionPopups}
          handleCreateCollection={handleCreateCollection}
          {...collectionPopup.data}
        />
      ) }
      { collectionPopup && collectionPopup.name === 'createCollection' && (
        <CreateCollectionPopup
          onClose={closeCollectionPopups}
          isClient={isClient}
          {...collectionPopup.data}
        />
      ) }
    </div>
  );
};

export default TrendItemPage;
