import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { SIDES, COLOR_THEMES } from 'consts';
import { colorThemes } from 'utils';
import styles from './styles.module.scss';

const Button = (props) => {
  const {
    icon,
    componentStyle = {},
    loading,
    disabled,
    onClick = () => null,
    children,
    withBorder,
    isClient,
    badge,
    fullWidth = false,
    onlyIcon = false,
    size,
    type = 'button',
    title,
    fileProps = null,
    resetFile = false,
  } = props;

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!fileProps) {
      return;
    }

    fileInputRef.current.value = '';
  }, [resetFile]);

  const getFileContent = (fileProps) => {
    if (!fileProps) {
      return null;
    }

    const { acceptFiles, onChange = () => {} } = fileProps;
    return (
      <input
        className={styles.fileInput}
        type="file"
        accept={acceptFiles}
        onChange={onChange}
        ref={fileInputRef}
      />
    );
  };

  const clickHandler = (e) => {
    if (disabled || loading) {
      return;
    }

    if (fileProps) {
      fileInputRef?.current?.click();
      return;
    }

    onClick(e);
  };

  let {
    colorTheme,
    iconSide,
  } = props;

  iconSide = (icon || loading)
    && ([SIDES.LEFT, SIDES.RIGHT].includes(iconSide) ? iconSide : SIDES.LEFT);

  colorTheme = colorThemes.includes(colorTheme) ? colorTheme : COLOR_THEMES.PRIMARY;

  return (
    <>
      {getFileContent(fileProps)}
      <button
        /* eslint-disable-next-line react/button-has-type */
        type={type}
        title={title}
        onClick={clickHandler}
        className={classNames(
          styles[colorTheme],
          styles[iconSide],
          styles[size],
          styles.buttonContainer,
          {
            [styles.disabled]: disabled || loading,
            [styles.withBorder]: withBorder,
            [styles.fullWidth]: fullWidth,
            [styles.onlyIcon]: onlyIcon,
          },
          componentStyle.mainContainer,
        )}
      >
        {loading && (
          <div className={classNames(styles.iconContainer, componentStyle.iconContainer)}>
            <div className={styles.loaderContainer}>
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}

        {!loading && icon && (
          <div className={classNames(styles.iconContainer, componentStyle.iconContainer)}>
            {badge && <span className={classNames(styles.badge, { [styles.client]: isClient })} />}
            {icon}
          </div>
        )}

        {children && (
          <div className={classNames(styles.buttonTextContainer, componentStyle.textContainer)}>
            {children}
          </div>
        )}
      </button>
    </>
  );
};

export default Button;
