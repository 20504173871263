import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestTypeKeys } from 'store/reducers/type-keys/actions';
import { setTrendFilter } from 'store/reducers/trends/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import { getTrendFilterByName } from 'store/reducers/trends/selectors';
import Checkbox from 'components/checkbox';
import classNames from 'classnames';
import SearchIcon from 'components/svg-icons/search-icon';
import CollapseComponent from 'pages/reports/collapseComponent';
import styles from 'pages/trends/aside-filters/trendsSideFilter/styles.module.scss';

const minElemSearch = 10;

const TrendsSideFilter = (props) => {
  const {
    filterType,
    title,
    data = [],
    searchPlaceholder = 'search ...',
    withSearch = false,
    search = '',
    searchHandler = () => {},
    collectionId,
    isWordCloud,
  } = props;

  const [filteredData, setFilteredData] = useState(data);

  const dispatch = useDispatch();
  const storeFilter = useSelector(getTrendFilterByName(filterType)) || [];
  const isClient = useSelector(getIsClient);

  const filteringData = (searchStr) => {
    return data.filter((dataItem) => {
      const dataItemName = dataItem?.name;
      if (dataItemName && typeof dataItemName === 'string') {
        return dataItem.name.toLowerCase().match(searchStr.toLowerCase().trim());
      }
      return false;
    });
  };

  const searchOnChange = (e) => {
    const newSearchValue = e.target?.value;
    let newFilteredData;

    if (!newSearchValue) {
      newFilteredData = [...data];
    }

    if (data
      && Array.isArray(data)
      && typeof newSearchValue === 'string'
      && newSearchValue.length) {
      newFilteredData = filteringData(newSearchValue);
    }

    setFilteredData(newFilteredData);
    searchHandler(filterType, newSearchValue);
  };

  useEffect(() => {
    if (!search) {
      setFilteredData(data);
    } else {
      const newFilteredData = filteringData(search);
      setFilteredData(newFilteredData);
    }
  }, [data]);

  useEffect(() => {
    dispatch(requestTypeKeys());
  }, [dispatch]);

  const checkboxChangeHandler = (itemValue) => {
    const newStoreFilter = storeFilter.includes(itemValue)
      ? storeFilter.filter((item) => item !== itemValue)
      : [...storeFilter, itemValue];

    dispatch(setTrendFilter(filterType, newStoreFilter, { isWordCloud }, collectionId));
  };

  const checkItems = filteredData.map((dataItem) => (
    <Checkbox
      componentStyle={{ container: styles.checkboxContainer, title: styles.checkboxTitle }}
      checked={storeFilter.includes(dataItem.value)}
      onChange={() => checkboxChangeHandler(dataItem.value)}
      title={dataItem.name}
      key={`filter-item-${dataItem.name}-${dataItem.value}`}
      icon={dataItem.logoLink}
      flag={dataItem.flag}
    />
  ));

  return (
    <CollapseComponent
      name={title}
    >
      {withSearch && data && !!data.length && data.length > minElemSearch && (
        <div
          className={classNames(styles.filterSearchWrap, { [styles.clientSearchView]: isClient })}
        >
          <div className={styles.filterSearchIcon}>
            <SearchIcon />
          </div>
          <input placeholder={searchPlaceholder} type="text" onChange={searchOnChange} value={search} />
        </div>
      )}

      <div className={classNames(
        styles.checkItemsContainer,
        { [styles.clientCheckItemsContainer]: isClient },
      )}
      >
        {filteredData
        && !!filteredData.length
        && checkItems}
      </div>
    </CollapseComponent>
  );
};

export default TrendsSideFilter;
