import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const EmptyListMessage = ({ messageId }) => (
  <div className={styles.emptyMessageStyle}>
    <FormattedMessage id={messageId} />
  </div>
);

export default EmptyListMessage;
