import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import NotificationIcon from 'components/svg-icons/notification-icon';
import { getNotifications } from 'store/reducers/notifications/selectors';
import { requestNotifications, deleteNotifications } from 'store/reducers/notifications/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import NotificationItem from './item';
import styles from './styles.module.scss';

const NotificationList = (props) => {
  const intl = useIntl();
  const { closeList = () => {} } = props;
  // const loading = useSelector(getNotificationsLoading);
  const list = useSelector(getNotifications);
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(requestNotifications());
    },
    [dispatch],
  );

  const isNotEmpty = list.length >= 1;

  return (
    <div className={classNames(styles.notificationListContainer, { [styles.client]: isClient })}>
      <div className={styles.notificationListHeader}>
        <div className={styles.notificationsTitle}>
          <span className={styles.icon}>
            <NotificationIcon />
          </span>
          {intl.formatMessage({ id: 'notificationListHeader' })}
        </div>
        { isNotEmpty && (
          <button className={styles.clearNotifications} type="button" onClick={() => dispatch(deleteNotifications())}>
            {intl.formatMessage({ id: 'notificationListClear' })}
          </button>
        ) }
      </div>
      { isNotEmpty && (
        <div className={styles.list}>
          {list.map((item) => (
            <NotificationItem
              isClient={isClient}
              key={item._id}
              data={item}
              closeList={closeList}
            />
          ))}
        </div>
      ) }
    </div>
  );
};

export default NotificationList;
