import React, { useEffect } from 'react';
import classNames from 'classnames';
import { MODAL_PORTAL_ID, LOGIN_ROLES, MAIN_ROUTES } from 'consts';
import Header from 'components/header';
import ClientHeader from 'components/header/client';
import { getIsClient } from 'store/reducers/general/selectors';
import Footer from 'components/footer';
import ClientFooter from 'components/footer/client';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selectors';
import styles from './styles.module.scss';

const { ADMIN, WRITER } = LOGIN_ROLES;
const {
  PRIVACY_POLICY, CONTACT_US, GLOSSARY, TERMS_AND_CONDITIONS,
} = MAIN_ROUTES;

const Layout = (props) => {
  const { children, withFooter = true, centerContent = false } = props;
  const userData = useSelector(getUser);
  const isClient = useSelector(getIsClient);
  const isDarkBackground = userData
    && userData.role
    && [ADMIN, WRITER].includes(userData.role)
    && [
      CONTACT_US,
      PRIVACY_POLICY,
      GLOSSARY,
      TERMS_AND_CONDITIONS,
    ].includes(window.location.pathname);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    document.body.classList.add('client');
  }, [isClient]);

  return (
    <>
      <div id={MODAL_PORTAL_ID} />
      <div className={classNames(
        styles.layout,
        styles[userData?.role || 'client'],
      )}
      >
        { (isClient || !userData) ? <ClientHeader isGuest={!isClient} /> : <Header /> }
        <div
          className={classNames(
            styles.content,
            {
              [styles.darkBackground]: isDarkBackground,
              [styles.centerContent]: centerContent,
            },
          )}
        >
          {children}
        </div>
        { withFooter && (
          <>
            { (isClient || !userData)
              ? <ClientFooter />
              : <Footer isAdmin={userData?.role === ADMIN} />}
          </>
        ) }
      </div>
    </>
  );
};

export default Layout;
