import {
  PEOPLE_COLLECTION_LOAD_START,
  PEOPLE_COLLECTION_LOAD_SUCCESS,
  RESET_PEOPLE_COLLECTION_FILTERS,
  SET_PEOPLE_COLLECTION,
  SET_PEOPLE_COLLECTION_CREATE_SHOW,
  SET_PEOPLE_COLLECTION_FILTER,
  SET_PEOPLE_COLLECTION_LOADING,
  SET_CHECKED_PEOPLE_COLLECTION,
  CLEAR_CHECKED_PEOPLE_COLLECTION,
  REFRESH_STATE,
  REMOVE_PEOPLE_COLLECTION,
  FILTER_EXCLUDED_CHARACTER,
  PEOPLE_COLLECTION_LOAD_ERROR,
} from './action.types';

const initialState = {
  data: [],
  loading: true,
  hasMore: false,
  page: 1,
  createShow: false,
  checkedItems: [],
  excludedCharacter: null,
  collectionLoadError: null,
  collectionItem: null,
  filters: {
    search: '',
    sorting: 'date_DESC',
  },
};

const peopleCollectionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PEOPLE_COLLECTION_LOAD_ERROR:
      return { ...state, collectionLoadError: action.payload };
    case SET_PEOPLE_COLLECTION:
      return { ...state, collectionItem: payload, loading: false };
    case SET_PEOPLE_COLLECTION_LOADING:
      return { ...state, loading: payload };
    case SET_PEOPLE_COLLECTION_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.name]: payload.value,
        },
      };
    case SET_PEOPLE_COLLECTION_CREATE_SHOW:
      return {
        ...state,
        createShow: payload,
      };
    case PEOPLE_COLLECTION_LOAD_START:
      return {
        ...state,
        loading: true,
        page: payload.reset ? 1 : state.page + 1,
        data: payload.reset ? [] : state.data,
      };
    case PEOPLE_COLLECTION_LOAD_SUCCESS: {
      return {
        ...state,
        hasMore: payload.hasMore,
        data: [...state.data, ...payload.data],
        loading: false,
      };
    }
    case RESET_PEOPLE_COLLECTION_FILTERS:
      return {
        ...state,
        excludedCharacter: null,
        filters: initialState.filters,
      };
    case CLEAR_CHECKED_PEOPLE_COLLECTION:
      return {
        ...state,
        checkedItems: [],
      };
    case SET_CHECKED_PEOPLE_COLLECTION:
      return {
        ...state,
        checkedItems: payload,
      };
    case REFRESH_STATE:
      return {
        ...initialState,
      };
    case REMOVE_PEOPLE_COLLECTION:
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== payload.id),
      };
    case FILTER_EXCLUDED_CHARACTER:
      return {
        ...state,
        excludedCharacter: action.characterId,
      };
    default:
      return state;
  }
};

export default peopleCollectionReducer;
