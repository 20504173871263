import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { COLOR_THEMES, LOGIN_ROLES } from 'consts';
import { getReportFilters } from 'store/reducers/reports/selectors';
import { getIsClient } from 'store/reducers/general/selectors';
import { setReportFilter } from 'store/reducers/reports/actions';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import styles from './styles.module.scss';

const ReportFilters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filters = useSelector(getReportFilters);
  const isClient = useSelector(getIsClient);

  const { search, sorting, status } = filters;

  const onFilterChange = (name, value) => {
    dispatch(setReportFilter(name, value));
  };

  const theme = isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.LIGHT;

  return (
    <>
      <div className={styles.searchInput}>
        <Input
          delay={1000}
          colorTheme={theme}
          name="search"
          value={search}
          onChange={onFilterChange}
          icon={<SearchIcon />}
          placeholder={intl.formatMessage({ id: 'typeReportName' })}
        />
      </div>
      <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
        <Select
          colorTheme={theme}
          name="status"
          placeholder={intl.formatMessage({ id: 'filterByStatus' })}
          onChange={onFilterChange}
          value={status}
          options={[
            { name: intl.formatMessage({ id: 'allStatuses' }), value: '' },
            { name: intl.formatMessage({ id: 'approved' }), value: 'approved' },
            { name: intl.formatMessage({ id: 'pending' }), value: 'pending' },
            { name: intl.formatMessage({ id: 'declined' }), value: 'declined' },
            { name: intl.formatMessage({ id: 'draft' }), value: 'draft' },
          ]}
        />
      </RoleVisibleWrapper>
      <Select
        colorTheme={theme}
        name="sorting"
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        onChange={onFilterChange}
        value={sorting}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'title_ASC' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'title_DESC' },
          { name: intl.formatMessage({ id: 'newest' }), value: 'updatedAt_DESC' },
          { name: intl.formatMessage({ id: 'oldest' }), value: 'updatedAt_ASC' },
        ]}
      />
    </>
  );
};

export default ReportFilters;
