import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { COLOR_THEMES, LOGIN_ROLES } from 'consts';
import { getIsClient } from 'store/reducers/general/selectors';
import { getCreateShow, getPeopleState } from 'store/reducers/people/selectors';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import Button from 'components/button';
import { setPeopleCreateShow, setPeopleFilter } from 'store/reducers/people/actions';
import PlusIcon from 'components/svg-icons/plus-icon';
import AddPeople from '../add-people';
import styles from './styles.module.scss';

const TopPeopleFilters = () => {
  const intl = useIntl();
  const isClient = useSelector(getIsClient);
  const isCreateShow = useSelector(getCreateShow);
  const { filters: { search, sorting } } = useSelector(getPeopleState);
  const dispatch = useDispatch();

  const theme = isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.LIGHT;

  const toggleAddPeople = (value) => {
    return () => {
      const toggleVal = typeof value !== 'undefined' ? value : !isCreateShow;
      dispatch(setPeopleCreateShow(toggleVal));
    };
  };

  const onFilterChange = (name, value) => {
    dispatch(setPeopleFilter({ name, value }));
  };

  return (
    <>
      {isCreateShow && <AddPeople onCancel={toggleAddPeople(false)} />}
      <div className={styles.searchInput}>
        <Input
          delay={500}
          placeholder={intl.formatMessage({ id: 'typePeopleName' })}
          colorTheme={theme}
          name="search"
          icon={<SearchIcon />}
          value={search}
          onChange={onFilterChange}
        />
      </div>
      <Select
        colorTheme={theme}
        value={sorting}
        name="sorting"
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        onChange={onFilterChange}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'title_ASC' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'title_DESC' },
          { name: intl.formatMessage({ id: 'popularMore' }), value: 'popular_DESC' },
          { name: intl.formatMessage({ id: 'popularLess' }), value: 'popular_ASC' },
          { name: intl.formatMessage({ id: 'newest' }), value: 'date_DESC' },
          { name: intl.formatMessage({ id: 'oldest' }), value: 'date_ASC' },
        ]}
      />
      <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
        <Button
          colorTheme={COLOR_THEMES.PRIMARY}
          onClick={toggleAddPeople(true)}
          icon={<PlusIcon />}
        >
          {intl.formatMessage({ id: 'addNew' })}
        </Button>
      </RoleVisibleWrapper>
    </>
  );
};

export default TopPeopleFilters;
