import ImageUploader from 'components/image-uploader';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CloseIcon from 'components/svg-icons/close-icon';
import UploadImageIcon from 'components/svg-icons/upload-image-icon';
import { useSelector } from 'react-redux';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import styles from './styles.module.scss';

const PrevComponent = ({ link, onDelete = () => {} }) => {
  return (
    <div className={styles.prevContainer}>
      <div className={styles.prevWrap}>
        {link ? <img alt="" src={link} /> : <UploadImageIcon />}
      </div>
      {link && (
        <div className={styles.deleteButton} onClick={onDelete}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

const AvatarLoader = (props) => {
  const { imgLink, onChange } = props;
  const s3Domain = useSelector(getS3Domain);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <FormattedMessage id="image" />
      </div>
      <ImageUploader
        onChange={onChange}
        imageData={imgLink && `${s3Domain}/${imgLink}`}
        previewComponent={PrevComponent}
        uploadButtonStyle={{
          mainContainer: styles.uploadButton,
          textContainer: styles.uploadButtonText,
        }}
      />
    </div>
  );
};

export default AvatarLoader;
