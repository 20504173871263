import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selectors';
import StaffItem from './item';
import styles from './styles.module.scss';

const StaffList = (props) => {
  const { users = [] } = props;
  const currentUser = useSelector(getUser);

  return (
    <>
      <div className={styles.usersList}>
        {users.map((user) => (
          <div className={styles.userItem} key={user._id}>
            <StaffItem data={user} disableActions={currentUser?._id === user._id} />
          </div>
        ))}
      </div>
    </>
  );
};

export default StaffList;
