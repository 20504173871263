import React, { useState } from 'react';
import styles from 'client/pages/trend/styles.module.scss';
import { FormattedMessage } from 'react-intl';
import RouteRoleLink from 'components/route-role-link';
import { CABINET_ROUTES, COLOR_THEMES, REPORT_STATUSES } from 'consts';
import Button from 'components/button';
import { useSelector } from 'react-redux';
import { getIsAdmin } from 'store/reducers/general/selectors';
import { getUser } from 'store/reducers/user/selectors';
import ApiService from 'services/apiService';
import WriterIcon from 'components/svg-icons/writer-icon';
import EditIcon from 'components/svg-icons/edit-icon';

const ContributorNotEditableStatuses = [REPORT_STATUSES.PENDING, REPORT_STATUSES.APPROVED];

const PreviewHeadActions = (props) => {
  const isAdmin = useSelector(getIsAdmin);
  const user = useSelector(getUser);
  const { reportData, id, toggleForceUpdate = () => {} } = props;

  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  const onDecline = () => {
    setDeclineLoading(true);
    ApiService.declineTrend(id).then((res) => {
      if (res.status === 200) {
        setDeclineLoading(false);
        toggleForceUpdate();
      }
    });
  };

  const approveReport = () => {
    setApproveLoading(true);
    ApiService.approveTrend(id).then((res) => {
      if (res.status === 200) {
        setApproveLoading(false);
        toggleForceUpdate();
      }
    });
  };

  const { creator, editor, status } = reportData;

  const disableEdit = !editor
    ? (!isAdmin && ContributorNotEditableStatuses.includes(status))
    : editor?._id !== user._id;

  return (
    <div className={styles.previewHeader}>
      <div className="container">
        <div className={styles.previewContentHeader}>
          <span><FormattedMessage id="previewMode" /></span>
          <div className={styles.actionButtons}>
            { editor && (
              <div className={styles.currentEditor}>
                <span className={styles.editorIcon}>
                  <EditIcon />
                </span>
                <span className={styles.editorEmail}>{editor.email}</span>
              </div>
            ) }
            <div className={styles.creator}>
              <span className={styles.creatorText}>
                <WriterIcon /> &nbsp;
                <FormattedMessage id="author" />
              </span>
              <span className={styles.creatorEmail}>{creator ? creator.email : '-'}</span>
            </div>
            <RouteRoleLink path={`${CABINET_ROUTES.TRENDS}/${id}/edit`} disabled={disableEdit}>
              <Button
                disabled={disableEdit}
                colorTheme={COLOR_THEMES.SECONDARY}
                componentStyle={{ mainContainer: styles.actionButton }}
              >
                <FormattedMessage id="edit" />
              </Button>
            </RouteRoleLink>
            { status === 'pending' && isAdmin && (
              <>
                <Button
                  disabled={editor}
                  colorTheme={COLOR_THEMES.SECONDARY}
                  componentStyle={{ mainContainer: styles.actionButton }}
                  onClick={onDecline}
                  loading={declineLoading}
                >
                  <FormattedMessage id="declineReport" />
                </Button>
                <Button
                  disabled={editor}
                  componentStyle={{ mainContainer: styles.actionButton }}
                  onClick={approveReport}
                  loading={approveLoading}
                >
                  <FormattedMessage id="approveReport" />
                </Button>
              </>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewHeadActions;
