import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import {
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import messages from 'i18n/en/messages';
import { MAIN_ROUTES, LOGIN_ROLES, CABINET_ROUTES } from 'consts';
import { routeConfig } from 'utils';
import AdminCabinet from 'admin';
import ClientCabinet from 'client';
import PowerClientCabinet from 'power-client';
import WriterCabinet from 'writer';
import LoginPage from 'pages/login';
import LandingPage from 'pages/landing';
import PrivacyPolicyPage from 'pages/privacy-policy';
import GlossaryPage from 'pages/glossary';
import ContactUsPage from 'pages/contact-us';
import Error404 from 'pages/error404';
import Layout from 'components/layout';
import {
  getNotificationDescription,
  getNotificationTitle,
  getNotificationPath,
} from 'components/notification-list/item';
import storageService from 'services/storageService';
import apiService from 'services/apiService';
import { setIsAdmin, setIsClient } from 'store/reducers/general';
import LoadingComponent from 'components/loadingComponent';
import notificationService from 'services/notificationService';
import { patchUserProfile, setPushToken } from 'store/reducers/user';
import pushService from 'services/pushService';
import TermsAndConditionsPage from 'pages/terms-and-conditions';
import { getIsUnderMaintenance } from 'store/reducers/general/selectors';
import UnderMaintenancePage from 'pages/under-maintenance';

const {
  MAIN,
  LOGIN,
  ADMIN,
  WRITER,
  CLIENT,
  POWER_CLIENT,
  CONTACT_US,
  PRIVACY_POLICY,
  GLOSSARY,
  TERMS_AND_CONDITIONS,
} = MAIN_ROUTES;

function App() {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const isUnderMaintenance = useSelector(getIsUnderMaintenance);

  const initPushService = () => {
    pushService.register().then((token) => {
      if (token) {
        dispatch(setPushToken(token));
        apiService.setPushToken(token);
      }
    });

    pushService.addListener((notification) => {
      notificationService.addNotification(
        getNotificationTitle(notification),
        null,
        {
          isMention: true,
          description: getNotificationDescription(notification),
          path: getNotificationPath(notification),
        },
      );
      dispatch(patchUserProfile({ hasUnreadNotifications: true }));
    });
  };

  useEffect(() => {
    (async () => {
      const accessToken = storageService.get('accessToken');
      const currentPathName = window.location.pathname;
      const user = accessToken && await apiService.getUser(accessToken);

      const staticRoutes = [MAIN, PRIVACY_POLICY, CONTACT_US, GLOSSARY, TERMS_AND_CONDITIONS];

      if (!user && staticRoutes.includes(currentPathName) && !window.location.query) {
        history.replace(currentPathName);
        setLoading(false);
        return;
      }

      if (!accessToken) {
        if (currentPathName === LOGIN) {
          setLoading(false);
          return;
        }

        history.replace(LOGIN);
        setLoading(false);
        return;
      }

      if (user && user.role) {
        const { role, email } = user;
        window.dataLayer.push({ email });
        const roleRoute = routeConfig[role];
        dispatch(setIsClient(role === LOGIN_ROLES.CLIENT || role === LOGIN_ROLES.POWER_CLIENT));
        dispatch(setIsAdmin(role === LOGIN_ROLES.ADMIN));

        if (!roleRoute) {
          notificationService.addNotification('Config error');
          setLoading(false);
          return;
        }

        const redirectRoute = `${roleRoute}/${CABINET_ROUTES.TRENDS}`;

        if ([LOGIN, MAIN].includes(currentPathName)) {
          history.replace(redirectRoute);
          setLoading(false);
          initPushService();
          return;
        }

        initPushService();
        setLoading(false);
      }
    })();
  }, []);

  if (isUnderMaintenance) {
    return <UnderMaintenancePage />;
  }

  return (
    <IntlProvider locale="en" messages={messages}>
      {loading ? <LoadingComponent /> : (
        <>
          <Switch>
            <Route exact path={MAIN} component={LandingPage} />
            <Route exact path={PRIVACY_POLICY} component={PrivacyPolicyPage} />
            <Route exact path={TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
            <Route exact path={CONTACT_US} component={ContactUsPage} />
            <Route exact path={GLOSSARY} component={GlossaryPage} />
            <Route exact path={LOGIN} component={LoginPage} />
            <Route path={ADMIN} component={AdminCabinet} />
            <Route path={CLIENT} component={ClientCabinet} />
            <Route path={POWER_CLIENT} component={PowerClientCabinet} />
            <Route path={WRITER} component={WriterCabinet} />

            <Route render={() => (
              <Layout>
                <Error404 />
              </Layout>
            )}
            />
          </Switch>
        </>
      )}
      <ReactNotification />
    </IntlProvider>
  );
}

export default App;
