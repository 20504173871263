import React from 'react';

const PeopleIcon = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3723_113921)">
      <path d="M10.411 11.9251C9.4243 11.6157 8.4021 11.5778 8.1 10.7669V9.63353C8.385 9.50716 9.19947 8.63903 9.2856 7.96073C9.33627 7.80036 9.37553 7.22689 9.37553 7.09769C9.37553 6.38993 9.3635 4.9268 9.85877 3.78156C9.68587 3.01203 9.2343 2.36376 8.26593 2.2782C8.0069 1.89456 7.34317 1.7002 6.47993 1.7002C3.02763 1.75346 2.67487 3.8949 3.36773 6.35479C3.23347 6.42393 3.0118 6.6438 3.06753 7.03366C3.1714 7.76069 3.52353 7.94486 3.74773 7.96016C3.83323 8.63903 4.64833 9.50716 4.93333 9.63353V10.7669C4.3 12.4669 0.5 10.7669 0.5 15.3002H8.1C8.1 13.2897 9.36033 12.4232 10.411 11.9251Z" fill="currentColor" />
      <path d="M9.36667 15.2999C9.36667 12.4666 12.5333 13.0333 13.1667 11.4993V10.7768C10.8734 10.7768 10.2369 9.9319 10.2369 9.9319C11.4351 9.03997 9.16907 2.83327 13.249 2.83327C13.249 2.83327 13.8 2.2666 14.5119 2.2666C19.5 2.2666 17.2491 9.00597 18.5392 9.95343C18.5392 9.95343 18.0047 10.7694 15.7 10.7694V11.4993C16.3333 13.0333 19.5 12.4666 19.5 15.2999H9.36667Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_3723_113921">
        <rect width="19" height="17" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default PeopleIcon;
