import { combineReducers } from 'redux';
import collectionsReducer from 'store/reducers/collections';
import peopleCollectionReducer from 'store/reducers/people-collection';
import userReducer from './reducers/user';
import generalReducer from './reducers/general';
import companiesReducer from './reducers/companies';
import reportsReducer from './reducers/reports';
import trendsReducer from './reducers/trends';
import staffReducer from './reducers/staff';
import categoriesReducer from './reducers/categories';
import hashtagsReducer from './reducers/hashtags';
import typeKeysReducer from './reducers/type-keys';
import notificationsReducer from './reducers/notifications';
import parsersReducer from './reducers/parsers';
import trendCommentsReducer from './reducers/trend-comments';
import peopleReducer from './reducers/people';

const mainReducer = combineReducers({
  userData: userReducer,
  general: generalReducer,
  companies: companiesReducer,
  reports: reportsReducer,
  trends: trendsReducer,
  staff: staffReducer,
  categories: categoriesReducer,
  hashtags: hashtagsReducer,
  typeKeys: typeKeysReducer,
  notifications: notificationsReducer,
  parsers: parsersReducer,
  trendComments: trendCommentsReducer,
  collections: collectionsReducer,
  people: peopleReducer,
  peopleCollection: peopleCollectionReducer,
});

export default mainReducer;
