import Tags from 'client/pages/trend/components/tags-list';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { CABINET_ROUTES, COLOR_THEMES, LOGIN_ROLES } from 'consts';
import COUNTRIES from 'consts/countries';
import RouteRoleLink from 'components/route-role-link';
import Button from 'components/button';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import DateFormatter from 'components/dateFormatter';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import { getUser } from 'store/reducers/user/selectors';
import { changeTimeZone, isWin } from 'utils';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import TrendPeopleTag from 'client/pages/trend/components/trend-people-tag';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import styles from './styles.module.scss';

const admins = [LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER];

const TrendHeader = (props) => {
  const {
    title,
    description,
    trendDate,
    createdDate,
    updatedDate,
    tags,
    typesKeys,
    countries,
    trendId,
    isDeclined,
    people = [],
  } = props;

  const userInfo = useSelector(getUser);
  const s3Domain = useSelector(getS3Domain);
  const reportHeaderRef = useRef();
  const isWindows = isWin();
  const dispatch = useDispatch();

  const addCollectionClick = () => {
    dispatch(setCollectionPopup('addCollection', { trendId }));
  };

  const getAllTypeKeys = () => {
    const tagsData = typesKeys.map((tKey) => ({ name: tKey }));

    if (countries && countries.length) {
      const countriesArr = COUNTRIES.filter((countryObj) => countries.includes(countryObj.code));

      countriesArr.forEach((country) => {
        const { emoji, name, code } = country;

        if (emoji && name && code) {
          const flagPrefix = isWindows ? '' : `${emoji} `;

          tagsData.push({ name: `${flagPrefix}${name}`, flag: isWindows && code });
        }
      });
    }

    return tagsData.map((tag) => {
      const { name, flag } = tag;

      return (
        <div className={styles.tag} key={`tagsData-${tag.name}`}>
          {flag && (
            <div className={styles.flagWrap}>{flag}</div>
          )}
          {name}
        </div>
      );
    });
  };

  const role = userInfo ? userInfo.role : LOGIN_ROLES.CLIENT;
  const isClient = !admins.includes(role);

  const peopleTags = people
    // eslint-disable-next-line react/no-array-index-key
    .map((item, i) => (<TrendPeopleTag key={`trend-tag-${i}`} s3Domain={s3Domain} data={item} />));

  return (
    <div className={styles.reportHeader} ref={reportHeaderRef}>
      <div className="container">
        <div className={styles.backWrapper}>
          <RouteRoleLink path={CABINET_ROUTES.TRENDS}>
            <Button
              componentStyle={{ mainContainer: styles.backButton }}
              icon={<div className={styles.backIcon}><RightArrowIcon /></div>}
              colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
            >
              <FormattedMessage id="back" />
            </Button>
          </RouteRoleLink>
          <div className={styles.dates}>
            <RoleVisibleWrapper role={admins}>
              <div className={styles.dateData}>
                <FormattedMessage id="trendDate" />:&nbsp;
                <span className={styles.date}>
                  <DateFormatter date={changeTimeZone(trendDate)} showTime={false} />
                </span>
              </div>
            </RoleVisibleWrapper>
            <div className={styles.dateData}>
              <FormattedMessage id="created" />:&nbsp;
              <span className={styles.date}>
                <DateFormatter date={isClient ? trendDate : createdDate} showTime={!isClient} />
              </span>
            </div>
            <RoleVisibleWrapper role={admins}>
              <div className={styles.dateData}>
                <FormattedMessage id="edited" />:&nbsp;
                <span className={styles.date}><DateFormatter date={updatedDate} /></span>
              </div>
            </RoleVisibleWrapper>
          </div>
        </div>
        {!isDeclined && (
          <div className={styles.addCollectionButton}>
            <Button
              componentStyle={{ mainContainer: styles.backButton }}
              icon={<div><CollectionsIcon /></div>}
              colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
              onClick={addCollectionClick}
            >
              <FormattedMessage id="addToCollection" />
            </Button>
          </div>
        )}
        <div className={styles.reportHeadingContent}>
          <h1 className={classNames(
            styles.titleReport,
            { [styles.titleReportFull]: !description },
          )}
          >
            {title}
          </h1>
          <div className={styles.tags}>
            {getAllTypeKeys()}
          </div>
          {!!people?.length && (
            <div className={styles.peopleTags}>
              <div className={styles.peopleHeader}>
                <FormattedMessage id="mainCharacters" />:
              </div>
              <div className={styles.tagsContainer}>
                {peopleTags}
              </div>
            </div>
          )}
          { description && (
            <div
              className={styles.descriptionReport}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <Tags tagsData={tags} />
      </div>
    </div>
  );
};

export default TrendHeader;
