import React from 'react';

const LandingMainPartProductsItemIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.6667 18.4668V20.0002C36.6646 23.5942 35.5008 27.0913 33.3489 29.9699C31.1969 32.8485 28.1721 34.9543 24.7256 35.9734C21.279 36.9924 17.5954 36.87 14.2241 35.6245C10.8528 34.379 7.9744 32.077 6.01825 29.062C4.06209 26.0469 3.13296 22.4803 3.36944 18.894C3.60592 15.3078 4.99532 11.8941 7.33045 9.16197C9.66557 6.42988 12.8213 4.52582 16.327 3.73376C19.8326 2.9417 23.5004 3.30408 26.7833 4.76685" stroke="url(#paint0_linear_1_680)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.6667 6.66663L20 23.35L15 18.35" stroke="url(#paint1_linear_1_680)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_1_680" x1="3.13725" y1="-263.343" x2="37.5788" y2="-263.269" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007DC8" />
        <stop offset="1" stopColor="#11ABB5" />
      </linearGradient>
      <linearGradient id="paint1_linear_1_680" x1="14.8726" y1="-126.8" x2="37.2595" y2="-126.738" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007DC8" />
        <stop offset="1" stopColor="#11ABB5" />
      </linearGradient>
    </defs>
  </svg>
);

export default LandingMainPartProductsItemIcon;
