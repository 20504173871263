import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NoPeopleIcon from 'components/svg-icons/no-people-icon';
import CollectionCreatorRow from 'pages/collections/components/collection-card/creator-row';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import {
  CABINET_ROUTES, COLLECTION_TYPE, COLOR_THEMES, LOGIN_ROLES, ROLE_THEMES,
} from 'consts';
import CollectionSharedRow from 'pages/collections/components/collection-card/shared-row';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import CharacterChainWidget from 'pages/people-collections/character-chain-widget';
import ConfirmPopup from 'components/confirm-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import apiService from 'services/apiService';
import { removePeopleCollection } from 'store/reducers/people-collection/actions';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import CreateCollectionPopup from 'components/create-collection-popup';
import SharePeopleCollectionIcon from 'components/svg-icons/share-people-collection-icon';
import ShareCompaniesPopup from 'components/share-companies-popup';
import classNames from 'classnames';
import styles from './styles.module.scss';

const { PEOPLE_COLLECTIONS } = CABINET_ROUTES;
const { DARK, LIGHT } = ROLE_THEMES;

const PeopleCollectionsItem = ({
  userId, data, windowWidth, isAdmin = false,
}) => {
  const {
    imgs = [],
    title = '',
    _id = '',
    creator,
    companyList,
    people = [],
    description = '',
  } = data;
  const { navigate } = UseRoleNavigation();
  const intl = useIntl();
  const [deleteAction, setDeleteAction] = useState(false);
  const [showCreateCollectionPopup, setShowCreateCollectionPopup] = useState(false);
  const [showShareCompanies, setShowShareCompanies] = useState(false);
  const isClient = useSelector(getIsClient);
  const { _id: creatorId } = creator;
  const isOwnCollection = userId === creatorId;
  const dispatch = useDispatch();

  const clickHandler = () => {
    navigate(`${PEOPLE_COLLECTIONS}/${_id}`);
  };

  const confirmDelete = () => {
    apiService.deletePeopleCollection(_id).then(() => {
      dispatch(removePeopleCollection(_id));
      setDeleteAction(false);
    }).catch(() => {
      setDeleteAction(false);
    });
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const total = people.length;

  return (
    <div
      className={classNames(
        styles.collectionItemContainer,
        {
          [styles.clientStyle]: isClient,
          [styles.contributorContainer]: !isClient && !isAdmin,
          [styles.notAdminContainer]: !isAdmin,
        },
      )}
    >
      { deleteAction
      && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deletePeopleCollection' })}
          text={intl.formatMessage({ id: 'deletePeopleCollectionText' })}
          colorTheme={isClient ? DARK : LIGHT}
          onCancel={toggleDelete}
          submitFunction={confirmDelete}
          submitText={intl.formatMessage({ id: 'delete' })}
        />
      ) }
      { showCreateCollectionPopup && (
        <CreateCollectionPopup
          onClose={() => setShowCreateCollectionPopup(false)}
          title={title}
          description={description}
          collectionId={_id}
          isClient={isClient}
          type={COLLECTION_TYPE.PEOPLE}
          needToRefresh
        />
      ) }
      {
        data && showShareCompanies && (
          <ShareCompaniesPopup
            onCancel={() => setShowShareCompanies(false)}
            collectionData={data}
            type={COLLECTION_TYPE.PEOPLE}
            needToRefresh
          />
        )
      }
      { (isAdmin || isOwnCollection) && (
        <div className={styles.editButtons}>
          <Button
            onlyIcon
            componentStyle={{
              mainContainer: styles.deleteButton,
            }}
            colorTheme={COLOR_THEMES.LIGHT}
            onClick={toggleDelete}
          >
            <DeleteIcon />
          </Button>
          <Button
            onlyIcon
            componentStyle={
              {
                mainContainer: (isAdmin || (!isClient && isOwnCollection))
                  ? styles.editButton
                  : styles.shareButton,
              }
            }
            colorTheme={COLOR_THEMES.LIGHT}
            onClick={() => setShowCreateCollectionPopup(true)}
          >
            <EditIcon />
          </Button>
          {(isAdmin || (!isClient && isOwnCollection)) && (
            <Button
              onlyIcon
              componentStyle={{ mainContainer: styles.shareButton }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={() => setShowShareCompanies(true)}
            >
              <SharePeopleCollectionIcon />
            </Button>
          )}
        </div>
      )}
      <div className={styles.title}>
        {title}
      </div>
      { total ? (
        <div className={styles.peopleCount}>
          <span>
            {total}
          </span>
          <FormattedMessage id={total === 1 ? 'peopleTotalOne' : 'peopleTotal'} />
        </div>
      ) : (
        <div className={styles.peopleCountStub} />
      )}
      {imgs.length ? (
        <div className={styles.avatarsContainer} onClick={clickHandler}>
          <CharacterChainWidget
            id={_id}
            windowWidth={windowWidth}
            peopleAvatars={imgs}
          />
        </div>
      ) : (
        <div className={styles.noPeopleWrap} onClick={clickHandler}>
          <NoPeopleIcon />
        </div>
      )}
      {creator && (
        <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN]}>
          <div>
            <div className={styles.adminItem}>
              <CollectionCreatorRow creator={creator} />
            </div>
            { !!companyList?.length && (
              <div className={styles.adminItem}>
                <CollectionSharedRow companyList={companyList} />
              </div>
            )}
          </div>
        </RoleVisibleWrapper>
      )}
    </div>
  );
};

export default PeopleCollectionsItem;
