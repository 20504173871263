import React from 'react';
import InfoIcon from 'components/svg-icons/info-icon';
import styles from '../styles.module.scss';

const IndexItem = (props) => {
  const {
    name, title = 'Viral', tooltipText = '', value, onChange = () => {},
  } = props;

  const onlyNumbersInput = (e) => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.borderedContainer}>
        <div className={styles.inputCont}>
          <input
            type="number"
            value={value}
            onChange={(event) => { onChange(name, event.target.value); }}
            onKeyPress={onlyNumbersInput}
          />
        </div>
        {tooltipText && (
        <div className={styles.tooltipIconCont}>
          <InfoIcon />
          <div className={styles.tooltipCont}>
            <div className={styles.tooltipInnerIcon}>
              <InfoIcon />
            </div>
            <div className={styles.tooltipTextCont}>
              {tooltipText}
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default IndexItem;
