import React from 'react';

const Like = () => (
  <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_79:5541)">
      <path d="M24.8028 3.26805C23.2848 1.75001 21.2537 0.914062 19.0832 0.914062C17.2145 0.914062 15.4494 1.53381 14.0258 2.67476C12.5817 1.53381 10.8144 0.914062 8.964 0.914062C6.7834 0.914062 4.74698 1.75037 3.22899 3.26805C1.71095 4.78578 0.875 6.81719 0.875 8.98737C0.875 11.1579 1.71095 13.189 3.22899 14.7066L13.3324 24.8101C13.5165 24.9945 13.7583 25.0867 14 25.0867C14.2411 25.0867 14.4822 24.9948 14.6667 24.8113L24.7782 14.7305C26.2919 13.183 27.125 11.149 27.125 9.00311C27.125 6.83733 26.3029 4.80342 24.8028 3.26805Z" fill="url(#paint0_radial_79:5541)" />
    </g>
    <defs>
      <filter id="filter0_i_79:5541" x="-1.125" y="0.914062" width="28.25" height="27.1729" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_79:5541" />
      </filter>
      <radialGradient id="paint0_radial_79:5541" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.34239 34.0076) rotate(-79.2597) scale(33.6836 16.7948)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Like;
