import React, { useState } from 'react';
import CloseIcon from 'components/svg-icons/close-icon';
import Spinner from 'components/spinner';
import ImagePopup from 'components/image-popup';
import styles from './styles.module.scss';

const AttachedImgItem = ({
  imgUrl, origin, removeHandler, loading,
}) => {
  const [expandedImgUrl, setExpandedImgUrl] = useState('');
  const realImgUrl = loading ? imgUrl : `${origin}/${imgUrl}`;

  const onClick = () => {
    if (loading) {
      return;
    }
    setExpandedImgUrl(realImgUrl);
  };

  const onCancel = () => {
    setExpandedImgUrl('');
  };

  return (
    <div className={styles.attachedImgContainer}>
      <div className={styles.imgWrapper} onClick={onClick}>
        <img src={realImgUrl} alt="" />
      </div>
      { loading && (
        <div className={styles.spinnerWrapper}>
          <Spinner size="extra-sm" />
        </div>
      )}
      { typeof removeHandler === 'function' && (
        <div className={styles.statusWrapper} onClick={() => { removeHandler(imgUrl, loading); }}>
          <CloseIcon />
        </div>
      )}
      {
        expandedImgUrl && (
          <ImagePopup
            onClose={onCancel}
            imgUrl={expandedImgUrl}
          />
        )
      }
    </div>
  );
};

export default AttachedImgItem;
