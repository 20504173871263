import { sortData } from 'utils';

export const getCompanies = (state) => {
  const { companies } = state;
  const { filters, data = [] } = companies;

  const filtersConfig = {
    alphabetAsc: {
      field: 'title',
      direction: 'asc',
      type: 'string',
    },
    alphabetDesc: {
      field: 'title',
      direction: 'desc',
      type: 'string',
    },
    moreUsers: {
      field: 'users',
      direction: 'desc',
      type: 'date',
    },
    lessUsers: {
      field: 'users',
      direction: 'asc',
      type: 'date',
    },
  };

  const { searchName, sorting } = filters.companies;

  const filteredByName = data.filter(({ title }) => {
    if (!searchName) {
      return true;
    }

    return title.toLowerCase().includes(searchName.trim().toLowerCase());
  });

  const sortingConfig = filtersConfig[sorting];

  if (!sorting || !sortingConfig) {
    return filteredByName;
  }

  const { field, direction, type } = sortingConfig;

  return sortData(filteredByName, field, direction, type);
};

export const getCompaniesLoading = (state) => state.companies.companiesLoading;
export const getCompanyLoading = (state) => state.companies.companyLoading;
export const getCompanyData = (state) => {
  const { companies } = state;
  const { filters, companyData } = companies;

  if (!companyData) {
    return companyData;
  }

  const { searchEmail, sortNameDest } = filters.users;

  const { users } = companyData;

  const filteredUsers = users.filter(({ email }) => {
    if (!searchEmail) {
      return true;
    }

    return email.toLowerCase().includes(searchEmail.trim().toLowerCase());
  });

  const newCompanyData = { ...companyData };
  newCompanyData.users = sortData(filteredUsers, 'email', sortNameDest);

  return newCompanyData;
};

export const getCompanyFilters = (state) => state.companies.filters.companies;
export const getUsersFilters = (state) => state.companies.filters.users;
export const getCompanyDataError = (state) => state.companies.error;
