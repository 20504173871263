import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getIsClient } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const PERCENTAGE_RANGE_INDEX = [5, 10, 20, 50, 80];

const RangeWords = (props) => {
  const { ranges = [], getRangeStyles = () => {} } = props;
  const isClient = useSelector(getIsClient);

  return (
    <div className={classNames({ [styles.wrapperClient]: isClient })}>
      <h4 className={classNames(styles.title, { [styles.client]: isClient })}>
        <FormattedMessage id="wordsAppearing" />:
      </h4>
      <div className={styles.ranges}>
        {ranges.map((rangeData, index) => {
          const [min] = rangeData;
          const { color } = getRangeStyles(index + 1, isClient);

          return (
            <div key={min} className={classNames(styles.rangeItem, { [styles.client]: isClient })}>
              <div className={styles.colorPlumb} style={{ backgroundColor: color }} />
              <div>{PERCENTAGE_RANGE_INDEX[index]}%</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(RangeWords);
