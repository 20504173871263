import {
  SET_TREND_TYPE_KEYS_FILTER,
  SET_TREND_TYPE_KEYS,
  SET_TREND_TYPE_KEYS_LOADING,
  SET_TREND_TYPE_KEYS_ERROR,
} from './action.types';

const initialFilters = {
  searchTypeKey: '',
  sortingNameDirection: '',
};

const initialState = {
  data: [],
  loading: true,
  error: false,
  filters: initialFilters,
};

const typeKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TREND_TYPE_KEYS:
      return { ...state, data: action.payload };
    case SET_TREND_TYPE_KEYS_ERROR:
      return { ...state, error: action.error };
    case SET_TREND_TYPE_KEYS_LOADING:
      return { ...state, loading: action.loading };
    case SET_TREND_TYPE_KEYS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

export default typeKeysReducer;
