import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from 'components/button';
import Link from 'components/link';
import { COLOR_THEMES, CABINET_ROUTES, LOGIN_ROLES } from 'consts';
import EditReportIcon from 'components/svg-icons/edit-report-icon';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import apiService from 'services/apiService';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import RouteRoleLink from 'components/route-role-link';
import UserIcon from 'components/svg-icons/user-icon';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import NotificationsButton from 'components/header/notifications-button';
import WordCloudIcon from 'components/svg-icons/word-cloud-icon';
import PeopleIcon from 'components/svg-icons/people-icon';
import OutsideClicker from 'components/outside-clicker';
import { useLocation } from 'react-router-dom';
import { getIsUnderMaintenance } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const {
  TRENDS,
  USERS,
  COLLECTIONS,
  WORD_CLOUD,
  PEOPLE,
  PEOPLE_COLLECTIONS,
} = CABINET_ROUTES;

const collectionConfig = [
  {
    path: COLLECTIONS,
    text: 'trends',
    role: [
      LOGIN_ROLES.ADMIN,
      LOGIN_ROLES.WRITER,
      LOGIN_ROLES.POWER_CLIENT,
      LOGIN_ROLES.CLIENT,
    ],
  },
  {
    path: PEOPLE_COLLECTIONS,
    text: 'people',
    role: [
      LOGIN_ROLES.ADMIN,
      LOGIN_ROLES.WRITER,
      LOGIN_ROLES.POWER_CLIENT,
      LOGIN_ROLES.CLIENT,
    ],
  },
];

const ClientHeader = ({ isGuest }) => {
  const componentStyle = {
    textContainer: styles.actionButtonText,
    mainContainer: styles.actionButton,
  };
  const [openCollections, setOpenCollections] = useState(false);

  const { navigate } = UseRoleNavigation();
  const location = useLocation();
  const isUnderMaintenance = useSelector(getIsUnderMaintenance);

  const goTo = (path) => () => {
    navigate(path);
  };

  const toggleCollections = (value) => {
    return () => {
      const toggleVal = typeof value !== 'undefined' ? value : !openCollections;
      setOpenCollections(toggleVal);
    };
  };

  const generateMenu = useCallback(() => {
    const { pathname } = location;
    return collectionConfig.map((menuItem) => {
      const { path, text, role } = menuItem;
      const parts = pathname.split('/');
      const lastPart = parts[parts.length - 1] || '';
      const realPath = lastPart.split('?')[0];

      const item = (
        <div className={styles.linkAction} key={path} onClick={toggleCollections(false)}>
          <RouteRoleLink path={path}>
            <div
              className={classNames(styles.menuLink, { [styles.active]: realPath === path })}
            >
              <FormattedMessage id={text} />
            </div>
          </RouteRoleLink>
        </div>
      );

      if (!role) {
        return item;
      }

      return (
        <RoleVisibleWrapper role={role} key={path}>
          {item}
        </RoleVisibleWrapper>
      );
    });
  }, [location]);

  return (
    <div className={classNames(styles.header, styles.client)}>
      <div className="container">
        <div className={styles.headerContent}>
          <a href="/">
            <ClientLogoIcon />
          </a>
          { !isUnderMaintenance && (
            <>
              {isGuest ? (
                <div className={styles.actions}>
                  <div onClick={apiService.logout} className={styles.logOut}>
                    <Link color={COLOR_THEMES.LIGHT}><FormattedMessage id="loginButton" /></Link>
                  </div>
                </div>
              ) : (
                <div className={styles.actions}>
                  <div onClick={apiService.logout} className={styles.logOut}>
                    <Link color={COLOR_THEMES.LIGHT}><FormattedMessage id="logOutAccount" /></Link>
                  </div>

                  <div className={styles.action}>
                    <NotificationsButton />
                  </div>

                  <div className={styles.action}>
                    <RouteRoleLink path={PEOPLE}>
                      <Button
                        withBorder
                        componentStyle={componentStyle}
                        icon={<PeopleIcon />}
                        colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                      >
                        <FormattedMessage id="people" />
                      </Button>
                    </RouteRoleLink>
                  </div>

                  <div className={styles.action}>
                    <RouteRoleLink path={WORD_CLOUD}>
                      <Button
                        withBorder
                        componentStyle={componentStyle}
                        icon={<WordCloudIcon />}
                        colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                      >
                        <FormattedMessage id="wordCloud" />
                      </Button>
                    </RouteRoleLink>
                  </div>

                  <RoleVisibleWrapper role={LOGIN_ROLES.POWER_CLIENT}>
                    <div className={styles.action}>
                      <RouteRoleLink path={USERS}>
                        <Button
                          path={CABINET_ROUTES.USERS}
                          withBorder
                          componentStyle={componentStyle}
                          icon={<UserIcon />}
                          colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                        >
                          <FormattedMessage id="manageUsers" />
                        </Button>
                      </RouteRoleLink>
                    </div>
                  </RoleVisibleWrapper>
                  <div className={styles.action}>
                    <OutsideClicker onOutsideClick={toggleCollections(false)}>
                      <div>
                        <Button
                          onClick={toggleCollections()}
                          withBorder
                          componentStyle={componentStyle}
                          icon={<CollectionsIcon />}
                          colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
                        >
                          <FormattedMessage id="collections" />
                        </Button>
                        <div
                          className={classNames(
                            styles.burgerMenu,
                            styles.clientMenu,
                            { [styles.active]: openCollections },
                          )}
                        >
                          {generateMenu()}
                        </div>
                      </div>
                    </OutsideClicker>
                  </div>
                  <div className={styles.action}>
                    <Button
                      onClick={goTo(TRENDS)}
                      componentStyle={componentStyle}
                      icon={<EditReportIcon />}
                      colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
                    >
                      <FormattedMessage id="myTrends" />
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) }
        </div>
      </div>
    </div>
  );
};

export default ClientHeader;
