import { useSelector } from 'react-redux';
import { getUser } from 'store/reducers/user/selectors';

const RoleVisibleWrapper = ({ children, role }) => {
  const userInfo = useSelector(getUser);

  if (!userInfo) {
    return null;
  }

  const { role: userRole } = userInfo;

  if (Array.isArray(role) ? !role.includes(userRole) : userRole !== role) {
    return null;
  }

  return children;
};

export default RoleVisibleWrapper;
