import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ErrorIcon from 'components/svg-icons/error-icon';
import CommentIcon from 'components/svg-icons/comment-icon';
import CloseIcon from 'components/svg-icons/close-icon';
import RouteRoleLink from 'components/route-role-link';
import styles from './styles.module.scss';

const Notification = (props) => {
  const {
    closeClickHandler = () => null,
    title,
    description,
    showCloseIcon = true,
    linkName,
    linkId,
    success = false,
    isMention,
    path,
  } = props;

  const intl = useIntl();

  const viewClickHandler = () => {
    if (linkId) {
      const elem = document.getElementById(linkId);

      if (elem) {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

        closeClickHandler();
      }
    }
  };

  return (
    <RouteRoleLink path={path} disabled={!path}>
      <div className={classNames(styles.contentContainer, { [styles.mention]: isMention })}>
        {showCloseIcon && (
          <div className={styles.closeButtonContainer} onClick={closeClickHandler}>
            <CloseIcon />
          </div>
        )}

        <div className={styles.titleContainer}>
          <div className={classNames(styles.titleIconContainer, { [styles.success]: success })}>
            { isMention ? <CommentIcon /> : <ErrorIcon /> }
          </div>
          <div className={styles.titleText}>
            {title}
            { description && (
              <div className={classNames(styles.description, { [styles.mention]: isMention })}>
                {description}
              </div>
            ) }
          </div>
        </div>

        {linkName && (<div title={linkName} className={styles.linkString}>{linkName}</div>)}

        {linkName && (
          <div className={styles.footer}>
            <div className={styles.footerLeftPart}>
              {!success && intl.formatMessage({ id: 'enterDataManually' })}
            </div>
            <div className={styles.footerRightPart} onClick={viewClickHandler}>
              View link
            </div>
          </div>
        )}
      </div>
    </RouteRoleLink>
  );
};

export default Notification;
