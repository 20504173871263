import { useState, useEffect } from 'react';

const UseFormData = ({ data = {}, validators = {} }) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const onChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      const validator = validators[key];

      const error = typeof validator === 'function' ? validator(value) : false;

      if (!error) {
        return;
      }

      errors[key] = error;
    });

    return Object.keys(errors).length ? errors : null;
  };

  return {
    data: formData,
    onChange,
    validateForm,
  };
};

export default UseFormData;
