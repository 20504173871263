import * as React from 'react';
import d3Cloud from 'd3-cloud';
import Measure from 'react-measure';

class TagCloud extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    random: Math.random,
    rotate: 0,
    spiral: 'archimedean',
    style: {
      fontFamily: 'serif',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: 1,
    },
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    children: undefined,
    height: 0,
    width: 0,
    wrappedChildren: [],
    savedTransform: [],
    words: [],
  };

  mounted = false;

  resizeTimer = undefined;

  fontFamily = this.getStyleValue.bind(this, 'fontFamily');

  fontSize = this.getStyleValue.bind(this, 'fontSize');

  fontWeight = this.getStyleValue.bind(this, 'fontWeight');

  fontStyle = this.getStyleValue.bind(this, 'fontStyle');

  padding = this.getStyleValue.bind(this, 'padding');

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  calculateLayout(
    props,
    state,
  ) {
    const {
      children, spiral, random, style, words: propWords,
    } = props;
    const {
      width,
      height,
      savedTransform,
      words: stateWords,
    } = state;
    const spiralAny = spiral;

    const transformValue = JSON.stringify(stateWords) !== JSON.stringify(propWords)
      ? [] : savedTransform;

    return new Promise((resolve) => {
      const words = React.Children.map(children, (child) => ({ child }));
      let res = d3Cloud()
        .size([width, height])
        .words(words)
        .text(this.text)
        .font(this.fontFamily)
        .fontStyle(this.fontStyle)
        .fontWeight(this.fontWeight)
        .fontSize(this.fontSize)
        .rotate(this.rotate)
        .padding(this.padding);
      if (spiralAny) {
        res = res.spiral(spiralAny);
      }
      if (random) {
        res = res.random(random);
      }
      res
        .on('end', (items) => {
          const newChildren = items.map((item, index) => {
            let { x } = item;
            x += item.x0;
            x += width / 2;
            let { y } = item;
            y += item.y0;
            y += height / 2;

            const transform = transformValue[index] ? transformValue[index] : `translate(${x}px,${y}px) rotate(${
              item.rotate
            }deg)`;

            if (!transformValue[index]) {
              transformValue[index] = transform;
            }

            const newStyle = {
              position: 'absolute',
              ...item.child.props.style,
              MozTransform: transform,
              MsTransform: transform,
              OTransform: transform,
              WebkitTransform: transform,
              fontFamily: item.font,
              fontSize: item.size,
              fontStyle: item.style,
              fontWeight: item.weight,
              textAlign: 'center',
              transform,
              transformOrigin: 'center bottom',
              whiteSpace: 'nowrap',
              width: item.width,
            };
            if (
              !newStyle.color
              && style.color
              && typeof style.color === 'function'
            ) {
              newStyle.color = style.color(item.child, index);
            }
            return React.cloneElement(
              item.child,
              {
                ...item.child.props,
                key: item.text,
                style: newStyle,
              },
              item.child.props.children,
            );
          });
          resolve({ newChildren, savedTransform: transformValue });
        })
        .start();
    });
  }

  getStyleValue(
    propName,
    word,
  ) {
    const { style } = this.props;
    const childValue = word.child.props.style
      ? word.child.props.style[propName]
      : undefined;
    let value = childValue
      || style[propName]
      || TagCloud.defaultProps.style[propName];
    if (typeof value === 'function') {
      value = value(word.child.props);
    }
    if (propName === 'fontSize') {
      value += 2;
    }
    return value;
  }

  // eslint-disable-next-line react/sort-comp
  rotate = (word) => {
    const { rotate } = this.props;
    const value = word.child.props.rotate
      || rotate
      || TagCloud.defaultProps.rotate;
    if (typeof value === 'function') {
      return value(word.child.props);
    }
    return value;
  }

  text = (word) => {
    let { text } = word.child.props;

    if (!text) {
      const { children } = word.child.props;
      text = Array.isArray(children) ? children[0] : children;
    }

    return text;
  }

  render() {
    const {
      style,
      children, // eslint-disable-line
      rotate, // eslint-disable-line
      spiral, // eslint-disable-line
      random, // eslint-disable-line
      printRef,
      words,
      ...props
    } = this.props;
    const {
      fontFamily, // eslint-disable-line
      fontSize, // eslint-disable-line
      fontWeight, // eslint-disable-line
      fontStyle, // eslint-disable-line
      color, // eslint-disable-line
      padding, // eslint-disable-line
      ...otherStyle
    } = style;
    const { wrappedChildren } = this.state;

    return (
      <Measure bounds onResize={this.onResize}>
        {({ measureRef }) => (
          <div ref={measureRef} {...props} style={otherStyle}>
            <div style={{ flex: 1 }} ref={printRef}>
              {wrappedChildren}
            </div>
          </div>
        )}
      </Measure>
    );
  }

  onResize = (contentRect) => {
    const { width, height } = contentRect.bounds;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.width !== width || this.state.height !== height) {
      // Handle the initial size observer immediately
      // eslint-disable-next-line react/destructuring-assignment
      if (!this.state.width && !this.state.height) {
        this.setState({
          height,
          width,
        });
        return;
      }

      // Handle resizes with a debounce timeout of 100ms
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      this.resizeTimer = setTimeout(() => {
        this.resizeTimer = undefined;
        if (this.mounted) {
          this.setState({
            children: undefined,
            height,
            width,
            savedTransform: [],
          });
        }
      }, 100);
    }
  }

  // eslint-disable-next-line react/sort-comp
  componentDidUpdate() {
    const { width, height } = this.state;
    const { children, words } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    if (width && height && this.props.children !== this.state.children) {
      this.calculateLayout(this.props, this.state)
        .then(({ newChildren: wrappedChildren, savedTransform }) => {
          if (!this.mounted) {
            return;
          }

          this.setState({
            children,
            wrappedChildren,
            savedTransform,
            words,
          });
        });
    }
  }
}

export default TagCloud;
