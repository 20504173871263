import React from 'react';

const LandingSliderLeftArrow = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.06066 3.06066C9.64645 2.47487 9.64645 1.52513 9.06066 0.93934C8.47487 0.353553 7.52513 0.353553 6.93934 0.93934L9.06066 3.06066ZM2 8L0.93934 6.93934C0.353553 7.52513 0.353553 8.47487 0.93934 9.06066L2 8ZM6.93934 15.0607C7.52513 15.6464 8.47487 15.6464 9.06066 15.0607C9.64645 14.4749 9.64645 13.5251 9.06066 12.9393L6.93934 15.0607ZM6.93934 0.93934L0.93934 6.93934L3.06066 9.06066L9.06066 3.06066L6.93934 0.93934ZM0.93934 9.06066L6.93934 15.0607L9.06066 12.9393L3.06066 6.93934L0.93934 9.06066Z" fill="url(#paint0_linear_29_87)" />
    <defs>
      <linearGradient id="paint0_linear_29_87" x1="2" y1="14" x2="3.86437" y2="1.38862" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7ED8FF" />
        <stop offset="1" stopColor="#C2C5FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default LandingSliderLeftArrow;
