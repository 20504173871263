import React from 'react';
import Layout from 'components/layout';
import styles from './styles.module.scss';

const TermsAndConditionsPage = () => {
  return (
    <Layout>
      <div className="container">
        <div className={styles.privacyMainContainer}>
          <h1 className={styles.privacyTitle}>
            KYM INSIGHTS TERMS & CONDITIONS
          </h1>
          <p>
            {/* eslint-disable-next-line max-len */}
            These Terms and Conditions for the KYM insights website (the: &quot;Terms&quot;) are a legal agreement between Literally Media (“Literally Media” or “ Company’ or “us”, “we” “our” etc.) and the organization, firm, company or individual on behalf of which these Terms and Conditions have been agreed to by signing an Order issued by Literally Media (the: “IO”) or by accessing the Site (the “Subscriber” or “you” “your” etc.). The individual signing the IO or accessing the Site confirms that he or she is authorized to agree to this Agreement on behalf of the Subscriber.
          </p>
          <p>
            The purpose of this T&C is to regulate your use and set the legal terms for KYM insights’ website located at -<a className={styles.paragraphLink} href="https://literally.media/kym-insights/">https://literally.media/kym-insights/</a>, KYM insights’ social media pages, KYM insights’ mobile site or downloadable mobile applications (collectively, the &quot;Site&quot;), . By using the Site, you expressly consent to the use of the Site and Terms as described in this T&C.
          </p>
          <h3 className={styles.paragraphHeader}>
            USE OF OUR SITE AND SERVICES
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            We may change the format and content of the Site from time to time without notifying you. You agree that your use of the Site is on an &apos;as is&apos; and &apos;as available&apos; basis and is at your sole risk.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Whilst we try to make sure that all information contained in the Site is correct, it is not, and it is not intended to be, any authority or advice on which any reliance should be placed.
          </p>
          <h3 className={styles.paragraphHeader}>
            CHANGE OF TERMS
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            We reserve the right to vary the Terms at any time by posting any updated terms and conditions on the Site. Any amendments to the terms and conditions shall take effect from the date of publication on the Site.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            You are responsible for regularly reviewing the terms and conditions that are published on the Site from time to time and shall be bound by these if you continue to use the Site. If you have any questions at any time in relation to the Terms then please contact us at<span className={styles.mailLine}>insights@knowyourmeme.com</span>.
          </p>
          <h3 className={styles.paragraphHeader}>
            SUBSCRIBERS
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            To register as a Subscriber, you must provide us with accurate information about you (including your current valid email address). You should inform us of any material changes to that information by contacting us at <span className={styles.mailLine}>insights@knowyourmeme.com</span> or via the Site.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Each registration is for a single user only. If you wish to sign up for multiple users then please contact us by telephone or at<span className={styles.mailLine}>insights@knowyourmeme.com</span>.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            On registration you shall choose, or will be allocated, your user name and password details (“KYM Identity”). You are not entitled to share or give another user access in any way to your KYM Identity.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            You shall safeguard your KYM Identity at all times and take any and all reasonable steps to prevent unauthorized use of it. If you reasonably believe that your KYM Identity has been stolen, disclosed or being used by another person then you should notify us immediately by emailing us at<span className={styles.mailLine}>insights@knowyourmeme.com</span>.
          </p>
          <h3 className={styles.paragraphHeader}>
            RELIENCE ON INFORMATION POSTED
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            The Site is not in any manner or in any form or part intended to constitute or form the basis of any advice (professional or otherwise) or to be used in, or in relation to, any other decision. We do not accept any liability (regardless of how it might arise) for any claim or loss arising.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            We cannot and do not guarantee that any content of the Site will be free from viruses and/or other code that may have contaminating or destructive elements. It is your responsibility to implement appropriate IT security safeguards (including antivirus and other security checks) to satisfy your requirements as to the safety and reliability of content.
          </p>
          <h3 className={styles.paragraphHeader}>
            INTELLECTUAL PROPERTY RIGHTS
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            The Site and the entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by us, our licensors or other providers of such material and are protected by copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            These Terms permit you to use the Site for your personal use only. You must not reproduce, distribute, modify, create derivative works of, publicly display,publicly perform, republish, download, store or transmit any of the material on our Site, except as follows:
          </p>
          <ul>
            <li>
              {/* eslint-disable-next-line max-len */}
              Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              You may store files that are automatically cached by your Web browser for display enhancement purposes.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication or distribution.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, noncommercial use, provided you agree to be bound by our end user license agreement for such applications.
            </li>
          </ul>
          <p>
            You must not:
          </p>
          <ul>
            <li>
              Modify copies of any materials from this site.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.
            </li>
          </ul>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you wish to make any use of materials on the Site other than that set out in this section, please address your request to<span className={styles.mailLine}>insights@knowyourmeme.com</span>.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site in breach of the Terms, your right to use the Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by us. Any use of the Site not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark and other laws.
          </p>
          <h3 className={styles.paragraphHeader}>
            LIMITATION OF LIABILITY AND INDEMNITY
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            We shall have no liability to you for any and all damages, claims, proceedings, actions, awards, expenses and costs in relation to:
          </p>
          <ul>
            <li>
              {/* eslint-disable-next-line max-len */}
              any unauthorized or unlawful use of content, information and/or other material received by or submitted to you on the Site or in any Communication that is made available to you.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              any links to other third party websites (“Linked Sites”) that the Site may contain. The Linked Sites are not under our control and we are not responsible for the contents of any Linked Site and the products and/or services offered on the Linked Sites nor any changes or updates to a Linked Site. If we provide these links to you then we do so only as a convenience. The inclusion of any link does not imply endorsement or approval by us of the Linked Site or any association with its operators.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              the accuracy of any advertising or sponsorship provided by third parties on the Site or in any Communication provided to you nor in respect of its compliance with any applicable laws.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              any consequential losses (including but not limited to loss of profits, damage to goodwill, damage to reputation, loss of data, loss of programs and/or services interruptions); economic and/or other similar losses; and/or special damages and indirect losses;
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              any error, omission, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction of communications in relation to the Site.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              any delay in performance of the Site and/or any other matters to the extent that such events and/or matters are due to any events outside our reasonable control including but not limited to acts of God, war, flood, fire, labor disputes, strikes, lock-outs, riots, civil commotion, malicious damage, explosion, governmental actions and any other similar events.
            </li>
          </ul>
          <p>
            {/* eslint-disable-next-line max-len */}
            In any event, our total liability to you under and/or arising in relation to these Terms shall not exceed the amount paid by you to us within the preceding year. You shall provide to us written evidence of any claims for which it is alleged that we are liable together with written details of how any loss was caused by us and the steps you have taken to mitigate the loss (if any) before we consider your claim.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Nothing in these Terms shall exclude or limit our liability for death or personal injury due to our negligence or any other liability which we are not permitted to exclude or limit as a matter of law.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            You shall indemnify us against any and all losses, damages, awards, costs (including legal costs), claims and any other losses and/or liabilities suffered by us arising from your misuse of the Site or arising from or due to any breach of these Terms by you.
          </p>
          <h3 className={styles.paragraphHeader}>
            DISCLAIMER
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            We do not warrant that the Site and Services, or that any element of the Site and Services, will meet your requirements, purpose and/or expectations, nor that any of the content on the Site and/or the communications is accurate or complete and we are under no obligation to verify any such content.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            The content, Site, communications and services are provided “as is” and on an “is available” basis without any representations or any kind of warranty made (whether express or implied by law) to the extent permitted by law, including the implied warranties of satisfactory quality, accuracy, fitness for a particular purpose, non-infringement, compatibility and security.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of viruses or other destructive code.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT. YOUR USE OF ANY OF THE SITE, THEIR CONTENT AND ANY SERVICES IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE RELATED TO OR ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT ANY ONE OF OUR SITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT SUCH SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH SUCH SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRANTIES OR LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRETY, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
          <h3 className={styles.paragraphHeader}>
            COMPLAINTS AND DISPUTE
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you have any feedback, questions, or complaints, contact support service at<span className={styles.mailLine}>insights@knowyourmeme.com</span>.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            In the event of a complaint, please set out the cause of your complaint, how you would like us to resolve the complaint and any other information you believe to be relevant. The support team will consider your complaint without prejudice based on the information you have provided and any information provided by us. Your complaint will be considered within a reasonable time. Support team will offer to resolve your complaint in the way you requested; make a determination rejecting your complaint and set out the reasons for the rejection; or offer to resolve your complaint with an alternative solution. You agree to use the complaints procedure of this Section before filing any claim in a competent court. Failure to comply with this provision may be used as evidence of your unwillingness to settle the issue and/or the vexatious nature of the complaint.
          </p>
          <h3 className={styles.paragraphHeader}>
            GOVERNING LAWS
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            All matters relating to the Site and these Terms and any dispute or claim arising therefrom or related thereto (in each case, including non contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of Israel without giving effect to any choice or conflict of law provision or rule.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Any legal suit, action or proceeding arising out of, or related to, these Terms or the Site shall be instituted exclusively in the courts of Israel although we retain the right to bring any suit, action or proceeding against you for breach of these Terms in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venues in such courts, unless the prevailing legislation allows otherwise (and then only to the extent allowed under such prevailing legislation).
          </p>
          <h3 className={styles.paragraphHeader}>
            ENTIRE AGREEMENT
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            The Terms, our Privacy Policy and the IO constitute the sole and entire agreement between you and us with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site.
          </p>
          <h3 className={styles.paragraphHeader}>
            QUESTIONS AND CONTACT INFORMATION
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you need to contact us or have any questions on our Terms, you may contact us as follows:
          </p>
          <p>
            E-mail: <span className={styles.mailLine}>insights@knowyourmeme.com</span>
          </p>
          <p>
            Attention to: Legal Team
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditionsPage;
