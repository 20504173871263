import ActionsBar from 'components/actions-bar';
import ConfirmPopup from 'components/confirm-popup';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import DateFormatter from 'components/dateFormatter';
import RouteRoleLink from 'components/route-role-link';
import { CABINET_ROUTES, REPORT_STATUSES } from 'consts';
import WriterIcon from 'components/svg-icons/writer-icon';
import LogoIcon from 'components/svg-icons/logo-icon';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from 'services/apiService';
import { removeReport } from 'store/reducers/reports/actions';
import { getUser } from 'store/reducers/user/selectors';
import { getIsAdmin } from 'store/reducers/general/selectors';
import Badge from './badge';
import styles from './styles.module.scss';

const ContributorNotEditableStatuses = [REPORT_STATUSES.PENDING, REPORT_STATUSES.APPROVED];

const { REPORTS } = CABINET_ROUTES;

const defaultConfirmState = { show: false, onSubmit: () => {} };

const getTrends = (trends, id) => {
  if (!trends || !trends.length) {
    return null;
  }

  return trends.map((trend) => (
    <div className={styles.trendItem} key={`key_trends_${id}_${trend}`}>{trend}</div>
  ));
};

const getBages = (totalMetrics, id) => {
  if (!totalMetrics) {
    return null;
  }

  const statisticComponents = [];

  const badgesTypes = Object.keys(totalMetrics);

  badgesTypes.forEach((badgeType) => {
    const metricValue = totalMetrics[badgeType];

    if (metricValue) {
      statisticComponents.push(
        <Badge type={badgeType} count={metricValue} key={`key_badge_${id}_${badgeType}`} />,
      );
    }
  });

  return statisticComponents;
};

const ReportWidget = ({ report, imgLink }) => {
  const {
    _id: id,
    updatedAt,
    title,
    description,
    trends,
    totalMetrics,
    status,
    creator,
    currentEditor,
  } = report;

  const user = useSelector(getUser);
  const isAdmin = useSelector(getIsAdmin);

  const reportPath = `${REPORTS}/${id}`;
  const { navigate } = UseRoleNavigation();
  const dispatch = useDispatch();

  const [confirmState, setConfirmState] = useState(defaultConfirmState);
  const hideConfirm = () => setConfirmState(defaultConfirmState);

  const onActionClick = (event) => {
    if (event === 'delete') {
      setConfirmState({
        show: true,
        onSubmit: () => ApiService.removeReport(id)
          .then(() => dispatch(removeReport(id))),
      });
    }
    if (event === 'edit') {
      navigate(`${reportPath}/edit`);
    }
  };

  const editDisabled = currentEditor
    ? currentEditor._id !== user._id
    : (!isAdmin && ContributorNotEditableStatuses.includes(status));

  return (
    <div className={styles.reportContainer}>

      <div className={styles.topLine}>
        <div className={styles.date}>
          <DateFormatter date={updatedAt} />
        </div>
        <div className={styles.creator}>
          <WriterIcon />
          <span className={styles.creatorName}>{creator.email || '-'}</span>
        </div>
      </div>

      <div className={styles.imageWraper}>
        <span className={classNames(styles.status, styles[status])}>
          <FormattedMessage id={status} />
        </span>
        <RouteRoleLink path={reportPath}>
          <div className={styles.image}>
            { imgLink ? <img src={imgLink} alt="report-img" /> : <LogoIcon />}
          </div>
        </RouteRoleLink>
        <ActionsBar
          containerStyle={styles.eventButtons}
          editDisabled={editDisabled}
          onClick={onActionClick}
        />
      </div>

      <RouteRoleLink path={`${REPORTS}/${id}`}>
        <div className={styles.title}>
          {title}
        </div>
      </RouteRoleLink>

      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className={styles.trendsContainer}>
        {getTrends(trends, id)}
      </div>

      <div className={styles.badgesLine}>
        {getBages(totalMetrics, id)}
      </div>
      { confirmState.show && (
        <ConfirmPopup
          onCancel={hideConfirm}
          submitFunction={() => {
            confirmState.onSubmit();
            hideConfirm();
          }}
        />
      ) }
    </div>
  );
};

export default ReportWidget;
