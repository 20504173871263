import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ErrorPage from 'pages/error404';
import { getUser } from 'store/reducers/user/selectors';
import ProtectedRoute from 'components/protected-route';
import { CABINET_ROUTES, LOGIN_ROLES } from 'consts';
import ReportsPage from 'pages/reports';
import ReportPage from 'client/pages/report';
import CompanyUsersPage from 'pages/users-list-page';
import TrendsPage from 'pages/trends';
import TrendItemPage from 'client/pages/trend';
import wsApiService from 'services/wsApiService';
import CollectionsPage from 'pages/collections';
import WordCloudPage from 'pages/word-cloud';
import PeopleItemPage from 'pages/people/people-item-page';
import PeoplePage from 'pages/people';
import PeopleCollectionsPage from 'pages/people-collections';
import PeopleCollectionItemPage from 'pages/people-collections/people-collection-item-page';

const {
  REPORTS, USERS, TRENDS, COLLECTIONS, WORD_CLOUD, PEOPLE, PEOPLE_COLLECTIONS,
} = CABINET_ROUTES;

const { POWER_CLIENT } = LOGIN_ROLES;

const PowerClientCabinet = () => {
  const { path } = useRouteMatch();
  const user = useSelector(getUser);

  useEffect(() => {
    wsApiService.init();
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/${TRENDS}`}>
          <TrendsPage key="trends" />
        </Route>
        <Route exact path={`${path}/${TRENDS}/:id`}>
          <TrendItemPage />
        </Route>
        <Route exact path={`${path}/${USERS}`}>
          <CompanyUsersPage companyId={user?.companyId} />
        </Route>
        <Route exact path={`${path}/${REPORTS}`}>
          <ReportsPage />
        </Route>
        <Route exact path={`${path}/${REPORTS}/:id`}>
          <ReportPage />
        </Route>
        <Route exact path={`${path}/${COLLECTIONS}`}>
          <CollectionsPage />
        </Route>
        <Route exact path={`${path}/${COLLECTIONS}/:id`}>
          <TrendsPage key="collection-trends" isCollection />
        </Route>
        <Route exact path={`${path}/${WORD_CLOUD}`}>
          <WordCloudPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE}`}>
          <PeoplePage />
        </Route>
        <Route exact path={`${path}/${PEOPLE}/:id`}>
          <PeopleItemPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE_COLLECTIONS}`}>
          <PeopleCollectionsPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE_COLLECTIONS}/:id`}>
          <PeopleCollectionItemPage />
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export default () => (
  <ProtectedRoute roleName={POWER_CLIENT}>
    <PowerClientCabinet />
  </ProtectedRoute>
);
