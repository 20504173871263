import React from 'react';

const Recent = () => (
  <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1179_11533)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 2H7.99984C7.44984 2 6.99984 1.55 6.99984 1C6.99984 0.45 7.44984 0 7.99984 0H11.9998C12.5498 0 12.9998 0.45 12.9998 1C12.9998 1.55 12.5498 2 11.9998 2ZM17.7798 5.64L17.0298 6.39C18.0878 7.71341 18.7504 9.30874 18.9414 10.9923C19.1324 12.6758 18.8439 14.379 18.1093 15.9057C17.3746 17.4325 16.2237 18.7207 14.789 19.622C13.3543 20.5232 11.6941 21.0009 9.99984 21C8.79739 21.0007 7.607 20.7604 6.499 20.2933C5.39101 19.8261 4.38787 19.1417 3.54888 18.2803C2.70988 17.4189 2.05204 16.3981 1.61422 15.2782C1.17641 14.1583 0.967497 12.962 0.999837 11.76C1.11984 6.96 5.19984 3 9.99984 3C12.0442 2.99969 14.0272 3.69833 15.6198 4.98L16.3698 4.23C16.5559 4.04509 16.8075 3.94131 17.0698 3.94131C17.3321 3.94131 17.5838 4.04509 17.7698 4.23L17.7798 4.24C17.9647 4.42605 18.0685 4.6777 18.0685 4.94C18.0685 5.2023 17.9647 5.45395 17.7798 5.64ZM7 17.1962C6.08788 16.6695 5.33046 15.9121 4.80385 15L10 12L10 6C11.0532 6 12.0879 6.27724 13 6.80385C13.9121 7.33046 14.6695 8.08788 15.1962 9C15.7228 9.91211 16 10.9468 16 12C16 13.0532 15.7228 14.0879 15.1962 15C14.6695 15.9121 13.9121 16.6695 13 17.1962C12.0879 17.7228 11.0532 18 10 18C8.94678 18 7.91212 17.7228 7 17.1962Z" fill="url(#paint0_radial_1179_11533)" />
    </g>
    <defs>
      <filter id="filter0_i_1179_11533" x="-1.00342" y="0" width="20.002" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1179_11533" />
      </filter>
      <radialGradient id="paint0_radial_1179_11533" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.43189 28.75) rotate(-81.4841) scale(29.0705 11.5939)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Recent;
