import React, { useState } from 'react';
import EmptyPersonIcon from 'components/svg-icons/empty-person-icon';
import { useDispatch, useSelector } from 'react-redux';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import RouteRoleLink from 'components/route-role-link';
import {
  CABINET_ROUTES, COLOR_THEMES, LOGIN_ROLES, ROLE_THEMES,
} from 'consts';
import Button from 'components/button';
import apiService from 'services/apiService';
import EditIcon from 'components/svg-icons/edit-icon';
import DeleteIcon from 'components/svg-icons/delete-icon';
import ConfirmPopup from 'components/confirm-popup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  removePeople, requestPeople, setPeopleCreateShow, setPeopleFilter,
} from 'store/reducers/people/actions';
import PeopleTag from 'pages/people/people-tag';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import classNames from 'classnames/bind';
import PeopleCollectionsIcon from 'components/svg-icons/people-collections-icon';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import { requestPeopleCollection } from 'store/reducers/people-collection/actions';
import CollectionsIcon from 'components/svg-icons/collections-icon';
import styles from './styles.module.scss';

const { PEOPLE } = CABINET_ROUTES;
const { DARK, LIGHT } = ROLE_THEMES;

const PeopleItem = ({
  data, collectionId = '', canDelete, isClient,
}) => {
  const {
    title, trendsCount = 0, imgLink, _id, tags = [],
  } = data;

  const [showModalDelete, setShowModalDelete] = useState(false);
  const intl = useIntl();
  const s3Domain = useSelector(getS3Domain);
  const s3 = s3Domain?.startsWith('http') ? s3Domain : `//${s3Domain}`;
  const peoplePath = `${PEOPLE}/${_id}`;
  const dispatch = useDispatch();

  const editClickHandler = () => {
    dispatch(setPeopleCreateShow(data));
  };

  const addCollectionClick = () => {
    dispatch(setCollectionPopup('addPeopleCollection', { itemId: _id }));
  };

  const confirmDelete = () => {
    const removeMethod = collectionId ? 'deletePeopleFromCollection' : 'deletePeople';

    apiService[removeMethod](_id, collectionId).then(() => {
      if (!collectionId) {
        dispatch(removePeople(_id));
      } else {
        dispatch(requestPeopleCollection(collectionId));
        dispatch(requestPeople(true, collectionId));
      }
      setShowModalDelete(false);
    }).catch(() => {
      setShowModalDelete(false);
    });
  };

  const tagClick = (value) => {
    dispatch(setPeopleFilter({ name: 'search', value: `#${value}` }, false, collectionId));
  };

  const tagComps = Array.isArray(tags) && tags.slice(0, 5).map((tag) => {
    return (
      <PeopleTag key={`tags-${_id}-${tag}`} title={tag} clickHandler={tagClick} />
    );
  });

  return (
    <div className={classNames(styles.wrapper, { [styles.clientWrapper]: isClient })}>
      {showModalDelete && (
        <ConfirmPopup
          text={intl.formatMessage({ id: 'deleteCharacterConfirm' })}
          submitText={intl.formatMessage({ id: 'delete' })}
          onCancel={() => setShowModalDelete(false)}
          submitFunction={confirmDelete}
          colorTheme={isClient ? DARK : LIGHT}
        />
      )}
      <RouteRoleLink path={peoplePath}>
        <div className={styles.imgWrapper}>
          {imgLink ? <img src={`${s3}/${imgLink}`} alt="" /> : <EmptyPersonIcon />}
        </div>
      </RouteRoleLink>
      <div className={styles.infoWrapper}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.statsWrapper}>
          <div className={styles.statItem}>
            <div className={styles.statTitle}>In trends</div>
            <span className={styles.statValue}>{trendsCount}</span>
          </div>
        </div>
        {tagComps?.length ? (
          <div className={styles.tagsContainer}>
            {tagComps}
          </div>
        ) : (
          <div className={styles.tagsEmpty}>
            <div>#</div>
            <div><FormattedMessage id="characterEmptyTags" /></div>
          </div>
        )}
      </div>
      {collectionId && canDelete && (
        <div className={styles.editButtons}>
          <Button
            onlyIcon
            componentStyle={{
              mainContainer: styles.deletePeopleButton,
            }}
            colorTheme={COLOR_THEMES.LIGHT}
            onClick={() => setShowModalDelete(true)}
          >
            <DeleteIcon />
          </Button>
        </div>
      )}
      {!collectionId && (
        <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
          <div className={styles.editButtons}>
            <Button
              onlyIcon
              componentStyle={{
                mainContainer: styles.deleteButton,
              }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={() => setShowModalDelete(true)}
            >
              <DeleteIcon />
            </Button>
            <Button
              onlyIcon
              componentStyle={{ mainContainer: styles.editButton }}
              colorTheme={COLOR_THEMES.LIGHT}
              onClick={editClickHandler}
            >
              <EditIcon />
            </Button>
          </div>
        </RoleVisibleWrapper>
      )}
      {!collectionId && (
        <div className={styles.addCollection}>
          <Button
            onlyIcon
            componentStyle={{ mainContainer: styles.addCollectionButton }}
            colorTheme={COLOR_THEMES.LIGHT}
            onClick={addCollectionClick}
          >
            {isClient ? <CollectionsIcon /> : <PeopleCollectionsIcon /> }
          </Button>
        </div>
      )}
    </div>
  );
};

export default PeopleItem;
