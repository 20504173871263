import React from 'react';

const LandingMainPartIntelligenceIcon = () => (

  <svg width="188" height="188" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M92.5 185C104.647 185 116.676 182.607 127.898 177.959C139.121 173.31 149.318 166.497 157.907 157.907C166.497 149.318 173.31 139.121 177.959 127.898C182.607 116.676 185 104.647 185 92.5" stroke="white" strokeWidth="5" strokeLinecap="round" />
    <path d="M92.5 185C104.647 185 116.676 182.607 127.898 177.959C139.121 173.31 149.318 166.497 157.907 157.907C166.497 149.318 173.31 139.121 177.959 127.898C182.607 116.676 185 104.647 185 92.5" stroke="url(#paint0_linear_1_440)" strokeWidth="5" strokeLinecap="round" />
    <path d="M94 130L94 63" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M120 64C120 60.5522 118.63 57.2456 116.192 54.8076C113.754 52.3696 110.448 51 107 51C103.552 51 100.246 52.3696 97.8076 54.8076C95.3696 57.2456 94 60.5522 94 64" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M119.919 64.7982C120.109 66.206 119.963 67.6386 119.493 68.979C119.023 70.3194 118.242 71.5295 117.214 72.5103C116.187 73.4911 114.942 74.2148 113.581 74.6221C112.22 75.0295 110.782 75.1089 109.385 74.854C107.987 74.599 106.67 74.017 105.541 73.1553C104.412 72.2937 103.502 71.177 102.887 69.8966C102.272 68.6161 101.969 67.2083 102.002 65.7882C102.036 64.3681 102.405 62.9762 103.08 61.7261" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
    <path d="M94 126C94 128.054 94.4868 130.079 95.4205 131.909C96.3543 133.739 97.7083 135.321 99.3717 136.527C101.035 137.732 102.96 138.526 104.99 138.844C107.02 139.161 109.095 138.993 111.048 138.354C113 137.714 114.773 136.621 116.221 135.164C117.669 133.707 118.751 131.928 119.378 129.972C120.006 128.016 120.161 125.939 119.831 123.911C119.501 121.884 118.695 119.963 117.48 118.307" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
    <path d="M118.139 128.166C121.551 127.221 124.7 125.5 127.339 123.139C129.977 120.777 132.035 117.838 133.352 114.55C134.668 111.263 135.208 107.716 134.928 104.186C134.649 100.655 133.558 97.2373 131.741 94.1978" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M120.498 104.53C124.946 102.839 128.744 99.7802 131.344 95.7945C133.944 91.8087 135.214 87.101 134.97 82.3482C134.727 77.5954 132.983 73.0422 129.988 69.3433C126.994 65.6443 122.904 62.99 118.306 61.762" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M112 113C110.462 113 108.967 113.506 107.746 114.441C106.525 115.375 105.645 116.686 105.243 118.17C104.841 119.654 104.939 121.23 105.522 122.653C106.105 124.076 107.14 125.267 108.467 126.043" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M124.27 80.0299C123.182 78.6282 121.819 77.464 120.264 76.6089C118.71 75.7539 116.996 75.2262 115.23 75.0583C113.464 74.8904 111.682 75.0859 109.994 75.6327C108.306 76.1795 106.748 77.0661 105.415 78.2378C104.083 79.4095 103.004 80.8415 102.246 82.4458C101.488 84.05 101.067 85.7924 101.007 87.5658C100.948 89.3391 101.252 91.1058 101.902 92.757C102.551 94.4083 103.531 95.9092 104.783 97.1672" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M100.661 109.339C101.868 110.545 103.308 111.492 104.894 112.121C106.48 112.749 108.178 113.046 109.883 112.994C111.589 112.942 113.265 112.541 114.81 111.816C116.354 111.092 117.734 110.058 118.864 108.78C119.994 107.502 120.851 106.006 121.381 104.385C121.911 102.763 122.104 101.05 121.947 99.3512C121.79 97.6524 121.287 96.0036 120.469 94.5064C119.651 93.0093 118.535 91.6954 117.19 90.6458" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M94 130L94 63" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M68 64C68 60.5522 69.3696 57.2456 71.8076 54.8076C74.2456 52.3696 77.5522 51 81 51C84.4478 51 87.7544 52.3696 90.1924 54.8076C92.6304 57.2456 94 60.5522 94 64" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M68.0806 64.7982C67.8909 66.206 68.0371 67.6386 68.5073 68.979C68.9775 70.3194 69.7582 71.5295 70.7857 72.5103C71.8132 73.4911 73.0583 74.2148 74.4191 74.6221C75.7799 75.0295 77.2178 75.1089 78.6152 74.854C80.0127 74.599 81.3299 74.017 82.4592 73.1553C83.5885 72.2937 84.4978 71.177 85.1128 69.8966C85.7278 68.6161 86.0309 67.2083 85.9975 65.7882C85.9641 64.3681 85.595 62.9762 84.9204 61.7261" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
    <path d="M94 126C94 128.054 93.5132 130.079 92.5795 131.909C91.6457 133.739 90.2917 135.321 88.6283 136.527C86.9649 137.732 85.0395 138.526 83.01 138.844C80.9804 139.161 78.9045 138.993 76.9524 138.354C75.0003 137.714 73.2275 136.621 71.7794 135.164C70.3313 133.707 69.2491 131.928 68.6215 129.972C67.994 128.016 67.8388 125.939 68.1689 123.911C68.499 121.884 69.3049 119.963 70.5205 118.307" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
    <path d="M69.8615 128.166C66.4487 127.221 63.3002 125.5 60.6614 123.139C58.0226 120.777 55.9648 117.838 54.6484 114.55C53.3319 111.263 52.7923 107.716 53.0717 104.186C53.3511 100.655 54.4418 97.2373 56.259 94.1978" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M67.502 104.53C63.0537 102.839 59.2564 99.7802 56.656 95.7945C54.0556 91.8087 52.7859 87.101 53.0295 82.3482C53.2731 77.5954 55.0175 73.0422 58.0118 69.3433C61.0062 65.6443 65.0963 62.99 69.6941 61.762" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M76 113C77.5377 113 79.0326 113.506 80.2539 114.441C81.4751 115.375 82.3546 116.686 82.7566 118.17C83.1586 119.654 83.0606 121.23 82.4779 122.653C81.8952 124.076 80.8601 125.267 79.5326 126.043" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M63.7298 80.0299C64.8176 78.6282 66.1808 77.464 67.7355 76.6089C69.2902 75.7539 71.0035 75.2262 72.7699 75.0583C74.5363 74.8904 76.3183 75.0859 78.0063 75.6327C79.6942 76.1795 81.2524 77.0661 82.5848 78.2378C83.9172 79.4095 84.9957 80.8415 85.7537 82.4458C86.5118 84.05 86.9335 85.7924 86.9927 87.5658C87.052 89.3391 86.7476 91.1058 86.0984 92.757C85.4491 94.4083 84.4687 95.9092 83.2175 97.1672" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M87.3388 109.339C86.1325 110.545 84.6919 111.492 83.1059 112.121C81.5198 112.749 79.8219 113.046 78.1166 112.994C76.4113 112.942 74.7348 112.541 73.1903 111.816C71.6458 111.092 70.266 110.058 69.1358 108.78C68.0057 107.502 67.1491 106.006 66.6189 104.385C66.0887 102.763 65.8961 101.05 66.0529 99.3512C66.2097 97.6524 66.7126 96.0036 67.5306 94.5064C68.3487 93.0093 69.4645 91.6954 70.8095 90.6458" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
    <path d="M91.5 38C91.5 39.3807 92.6193 40.5 94 40.5C95.3807 40.5 96.5 39.3807 96.5 38H91.5ZM96.5 25.5C96.5 24.1193 95.3807 23 94 23C92.6193 23 91.5 24.1193 91.5 25.5H96.5ZM96.5 38V25.5H91.5V38H96.5Z" fill="#1CA5E0" />
    <path d="M122.952 46.5663C122.16 47.6975 122.435 49.2563 123.566 50.0481C124.697 50.8399 126.256 50.5648 127.048 49.4337L122.952 46.5663ZM134.048 39.4337C134.84 38.3025 134.565 36.7437 133.434 35.9519C132.303 35.1601 130.744 35.4352 129.952 36.5663L134.048 39.4337ZM127.048 49.4337L134.048 39.4337L129.952 36.5663L122.952 46.5663L127.048 49.4337Z" fill="#7DE87C" />
    <path d="M61.9519 49.4337C62.7437 50.5648 64.3025 50.8399 65.4337 50.0481C66.5648 49.2563 66.8399 47.6975 66.0481 46.5663L61.9519 49.4337ZM59.0481 36.5663C58.2563 35.4352 56.6975 35.1601 55.5663 35.9519C54.4352 36.7437 54.1601 38.3025 54.9519 39.4337L59.0481 36.5663ZM66.0481 46.5663L59.0481 36.5663L54.9519 39.4337L61.9519 49.4337L66.0481 46.5663Z" fill="#7DE87C" />
    <path d="M143.146 71.6505C141.848 72.1224 141.179 73.5568 141.651 74.8544C142.122 76.1519 143.557 76.8213 144.854 76.3495L143.146 71.6505ZM155.854 72.3495C157.152 71.8776 157.821 70.4432 157.349 69.1456C156.878 67.8481 155.443 67.1787 154.146 67.6505L155.854 72.3495ZM144.854 76.3495L155.854 72.3495L154.146 67.6505L143.146 71.6505L144.854 76.3495Z" fill="#1CA5E0" />
    <path d="M144.854 103.651C143.557 103.179 142.122 103.848 141.651 105.146C141.179 106.443 141.848 107.878 143.146 108.349L144.854 103.651ZM154.146 112.349C155.443 112.821 156.878 112.152 157.349 110.854C157.821 109.557 157.152 108.122 155.854 107.651L154.146 112.349ZM143.146 108.349L154.146 112.349L155.854 107.651L144.854 103.651L143.146 108.349Z" fill="#7DE87C" />
    <path d="M82 144.5C80.6193 144.5 79.5 145.619 79.5 147C79.5 148.381 80.6193 149.5 82 149.5L82 144.5ZM106 149.5C107.381 149.5 108.5 148.381 108.5 147C108.5 145.619 107.381 144.5 106 144.5L106 149.5ZM82 149.5L106 149.5L106 144.5L82 144.5L82 149.5Z" fill="#1CA5E0" />
    <path d="M82 151.5C80.6193 151.5 79.5 152.619 79.5 154C79.5 155.381 80.6193 156.5 82 156.5L82 151.5ZM106 156.5C107.381 156.5 108.5 155.381 108.5 154C108.5 152.619 107.381 151.5 106 151.5L106 156.5ZM82 156.5L106 156.5L106 151.5L82 151.5L82 156.5Z" fill="#1CA5E0" />
    <path d="M90 158.5C88.6193 158.5 87.5 159.619 87.5 161C87.5 162.381 88.6193 163.5 90 163.5V158.5ZM99 163.5C100.381 163.5 101.5 162.381 101.5 161C101.5 159.619 100.381 158.5 99 158.5V163.5ZM90 163.5H99V158.5H90V163.5Z" fill="#1CA5E0" />
    <path d="M44.8544 108.349C46.1519 107.878 46.8213 106.443 46.3495 105.146C45.8776 103.848 44.4432 103.179 43.1456 103.651L44.8544 108.349ZM32.1456 107.651C30.8481 108.122 30.1787 109.557 30.6505 110.854C31.1224 112.152 32.5568 112.821 33.8544 112.349L32.1456 107.651ZM43.1456 103.651L32.1456 107.651L33.8544 112.349L44.8544 108.349L43.1456 103.651Z" fill="#7DE87C" />
    <path d="M43.1456 76.3495C44.4432 76.8213 45.8776 76.1519 46.3495 74.8544C46.8213 73.5568 46.1519 72.1224 44.8544 71.6505L43.1456 76.3495ZM33.8544 67.6505C32.5568 67.1787 31.1224 67.8481 30.6505 69.1456C30.1787 70.4432 30.8481 71.8776 32.1456 72.3495L33.8544 67.6505ZM44.8544 71.6505L33.8544 67.6505L32.1456 72.3495L43.1456 76.3495L44.8544 71.6505Z" fill="#1CA5E0" />
    <defs>
      <linearGradient id="paint0_linear_1_440" x1="0" y1="185" x2="144.512" y2="-7.22778" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7DE87C" />
        <stop offset="1" stopColor="#7CA7E8" />
      </linearGradient>
    </defs>
  </svg>

);

export default LandingMainPartIntelligenceIcon;
