import React, { useEffect, useState } from 'react';
import ReportFilter from 'pages/reports/reportFilter';
import apiService from 'services/apiService';
import styles from './styles.module.scss';

const CommonFilters = () => {
  const [filtersConfig, setFiltersConfig] = useState([]);

  useEffect(async () => {
    const response = await apiService.getMetrics();

    if (response && response.data) {
      const newFiltersConfig = [];
      const { data: metrics } = response;

      Object.keys(metrics).forEach((metricName) => {
        const newConfigItem = {
          name: metricName,
          maxValue: metrics[metricName].max,
          minValue: metrics[metricName].min,
        };

        newFiltersConfig.push(newConfigItem);
      });

      setFiltersConfig(newFiltersConfig);
    }
  }, []);

  return (
    <div>
      {!!filtersConfig.length && filtersConfig.map((filterItem) => (
        <ReportFilter
          name={filterItem.name}
          key={filterItem.name}
          additionalStyle={styles.filtersItemContainer}
          rounded
          minValue={filterItem.minValue}
          maxValue={filterItem.maxValue}
        />
      ))}
    </div>
  );
};

export default CommonFilters;
