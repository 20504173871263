import React from 'react';

const ErrorParsingIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="7" fill="#FF6161" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.54902 3.83491C9.36076 3.84053 9.18207 3.91901 9.05044 4.05379L7.00019 6.10722L4.9499 4.05374C4.88242 3.98471 4.80191 3.9298 4.71304 3.89221C4.62345 3.85431 4.52708 3.83478 4.42982 3.83478C4.28505 3.83482 4.14358 3.8781 4.02351 3.95908C3.90344 4.04006 3.81022 4.15505 3.75576 4.28935C3.7013 4.42364 3.68807 4.57115 3.71775 4.71301C3.74728 4.85412 3.81794 4.98328 3.92077 5.08416L5.97122 7.1378L3.9214 9.1908C3.85273 9.25747 3.79785 9.33704 3.75991 9.42497C3.7215 9.51396 3.70122 9.60972 3.70023 9.70666C3.69925 9.80359 3.71758 9.89975 3.75417 9.9895C3.79076 10.0793 3.84486 10.1608 3.91331 10.2294C3.98176 10.2979 4.06319 10.3521 4.15283 10.3888C4.24247 10.4254 4.33851 10.4438 4.43533 10.4428C4.53215 10.4418 4.6278 10.4215 4.71667 10.383C4.80449 10.345 4.88394 10.29 4.9505 10.2213L7.00019 8.16837L9.0499 10.2213C9.11645 10.29 9.1959 10.345 9.28371 10.383C9.37259 10.4215 9.46824 10.4418 9.56506 10.4428C9.66188 10.4438 9.75793 10.4254 9.84756 10.3888C9.9372 10.3521 10.0186 10.2979 10.0871 10.2294C10.1555 10.1608 10.2096 10.0793 10.2462 9.9895C10.2828 9.89975 10.3011 9.80359 10.3002 9.70666C10.2992 9.60972 10.2789 9.51396 10.2405 9.42497C10.2025 9.33704 10.1477 9.25746 10.079 9.1908L8.02917 7.1378L10.0795 5.08423C10.1838 4.9821 10.2549 4.85098 10.2838 4.70785C10.3129 4.56394 10.2979 4.41458 10.2408 4.27936C10.1836 4.14413 10.087 4.02934 9.9636 3.95002C9.84018 3.87071 9.69561 3.83058 9.54902 3.83491Z" fill="white" />
  </svg>
);

export default ErrorParsingIcon;
