import React from 'react';

const CollectionsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 15.2222V6.33333C17 5.86184 16.8314 5.40965 16.5314 5.07625C16.2313 4.74286 15.8243 4.55556 15.4 4.55556H9.9888C9.69161 4.55554 9.40029 4.46356 9.1475 4.28992C8.89471 4.11628 8.69045 3.86783 8.5576 3.57244L7.8424 1.98311C7.70949 1.68759 7.5051 1.43906 7.25216 1.2654C6.99922 1.09175 6.70773 0.999846 6.4104 1H2.6C2.17565 1 1.76869 1.1873 1.46863 1.5207C1.16857 1.8541 1 2.30628 1 2.77778V15.2222C1 15.6937 1.16857 16.1459 1.46863 16.4793C1.76869 16.8127 2.17565 17 2.6 17H15.4C15.8243 17 16.2313 16.8127 16.5314 16.4793C16.8314 16.1459 17 15.6937 17 15.2222Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.0001 10.7775H6.6001M9.0001 8.11084V10.7775V8.11084ZM9.0001 10.7775V13.4442V10.7775ZM9.0001 10.7775H11.4001H9.0001Z" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

export default CollectionsIcon;
