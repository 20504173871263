import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR_THEMES } from 'consts';
import { getIsClient } from 'store/reducers/general/selectors';
import { getUser } from 'store/reducers/user/selectors';
import Button from 'components/button';
import NotificationIcon from 'components/svg-icons/notification-icon';
import NotificationIconClient from 'components/svg-icons/notification-icon-client';
import NotificationList from 'components/notification-list';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { patchUserProfile } from '../../../store/reducers/user';

const NotificationsButton = () => {
  const isClient = useSelector(getIsClient);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [showList, setShowList] = useState(false);
  const wrapperRef = useRef();

  const onClick = () => {
    dispatch(patchUserProfile({ hasUnreadNotifications: false }));
    setShowList(!showList);
  };

  const closeList = () => {
    setShowList(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const hasUnread = user?.hasUnreadNotifications;

  const componentStyle = {
    mainContainer: classNames(
      styles.notificationButton,
      { [styles.unread]: !isClient && hasUnread },
      { [styles.clientUnread]: isClient && hasUnread },
    ),
  };

  const Icon = isClient && !hasUnread ? NotificationIconClient : NotificationIcon;

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <Button
        isClient={isClient}
        badge={hasUnread}
        onClick={onClick}
        componentStyle={componentStyle}
        onlyIcon
        withBorder
        icon={<Icon />}
        colorTheme={isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.SECONDARY}
      />
      { showList && <NotificationList closeList={closeList} /> }
    </div>
  );
};

export default NotificationsButton;
