import React from 'react';
import ErrorIcon from 'components/svg-icons/error-icon';
import styles from './styles.module.scss';

const ErrorMessage = (props) => {
  const { message = '' } = props;

  if (!message) {
    return null;
  }

  return (
    <div className={styles.errorMessage}>
      <ErrorIcon />
      <span className={styles.message}>{message}</span>
    </div>
  );
};

export default ErrorMessage;
