import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Spinner = ({ size = 'sm' }) => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={classNames(styles.spinner, styles[`size-${size}`])} />
    </div>
  );
};

export default Spinner;
