import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import UseFormData from 'hooks/useFormData';
import { createValidator } from 'hooks/useFormData/validation';
import ErrorMessage from 'components/error-message';
import { addTypeKey, editTypeKey } from 'store/reducers/type-keys/actions';
import Select from 'components/fields/select';
import { POPUP_TYPE } from 'consts';
import styles from './styles.module.scss';

const emojiList = ['🔥', '🚀', '🌐', '🏡', '🚗', '📚', '🤭', '🧸', '🌡️', '💣', '🏢'];

const baseFields = {
  title: '',
  emoji: emojiList[0],
};

const AddTypeKey = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { prefilledData = null, onCancel = () => {} } = props;
  const [error, setError] = useState(null);
  const [proceedRequest, setProceedRequest] = useState(false);
  const [typeKeyData, setTypeKeyData] = useState(prefilledData || baseFields);

  useEffect(() => {
    setTypeKeyData(prefilledData || baseFields);
  }, [prefilledData]);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: typeKeyData,
    validators: {
      title: createValidator([{ type: 'required' }]),
      emoji: createValidator([{ type: 'required' }]),
    },
  });

  const onSubmit = () => {
    const errors = validateForm();
    setError(null);

    if (errors) {
      setProceedRequest(false);
      if (errors.title) {
        setError(intl.formatMessage({ id: 'typeKeyNameRequired' }));
        return;
      }

      return;
    }

    setProceedRequest(true);

    if (prefilledData) {
      dispatch(editTypeKey(prefilledData._id, data));
      return;
    }

    dispatch(addTypeKey(data));
  };

  const getFormFields = () => {
    if (!data) {
      return null;
    }

    const { title, emoji } = data;

    return (
      <div className={styles.formRow}>
        <div className={styles.typeKeyNameField}>
          <Input
            autoFocus
            fullWidth
            placeholder={intl.formatMessage({ id: 'enterTypeKeyName' })}
            value={title}
            onChange={onChange}
            name="title"
          />
        </div>
        <div className={styles.typeKeyEmojiField}>
          <Select
            onChange={onChange}
            name="emoji"
            value={emoji}
            placeholder={intl.formatMessage({ id: 'sortBy' })}
            options={emojiList.map((emoji) => ({ name: emoji, value: emoji }))}
          />
        </div>
      </div>
    );
  };

  return (
    <Popup
      title={intl.formatMessage({ id: !prefilledData ? 'addTypeKey' : 'editTypeKey' })}
      processing={proceedRequest}
      onSubmit={onSubmit}
      onClose={onCancel}
      submitText={intl.formatMessage({ id: !prefilledData ? 'add' : 'save' })}
      type={POPUP_TYPE.FORM}
    >
      {getFormFields()}
      <ErrorMessage message={error} />
    </Popup>
  );
};

export default AddTypeKey;
