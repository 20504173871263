import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CABINET_ROUTES, COLOR_THEMES } from 'consts';
import { getIsClient } from 'store/reducers/general/selectors';
import { getPeopleState } from 'store/reducers/people/selectors';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import Button from 'components/button';
import { setPeopleFilter } from 'store/reducers/people/actions';
import RouteRoleLink from 'components/route-role-link';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import styles from './styles.module.scss';

const {
  TRANSPARENT_LIGHT_WHITE,
  LIGHT,
} = COLOR_THEMES;

const TopPeopleFilters = ({ collectionId = '' }) => {
  const intl = useIntl();
  const isClient = useSelector(getIsClient);
  const { filters: { search, sorting } } = useSelector(getPeopleState);
  const dispatch = useDispatch();
  const theme = isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.LIGHT;
  const onFilterChange = (name, value) => {
    dispatch(setPeopleFilter({ name, value }, false, collectionId));
  };

  return (
    <>
      <RouteRoleLink path={CABINET_ROUTES.PEOPLE_COLLECTIONS}>
        <Button
          componentStyle={{ mainContainer: styles.backButton }}
          icon={<div className={styles.backIcon}><RightArrowIcon /></div>}
          colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : LIGHT}
        >
          <FormattedMessage id="back" />
        </Button>
      </RouteRoleLink>
      <div className={styles.searchInput}>
        <Input
          delay={500}
          placeholder={intl.formatMessage({ id: 'typePeopleName' })}
          colorTheme={theme}
          name="search"
          icon={<SearchIcon />}
          value={search}
          onChange={onFilterChange}
        />
      </div>
      <Select
        colorTheme={theme}
        value={sorting}
        name="sorting"
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        onChange={onFilterChange}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'title_ASC' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'title_DESC' },
          { name: intl.formatMessage({ id: 'popularMore' }), value: 'popular_DESC' },
          { name: intl.formatMessage({ id: 'popularLess' }), value: 'popular_ASC' },
          { name: intl.formatMessage({ id: 'newest' }), value: 'date_DESC' },
          { name: intl.formatMessage({ id: 'oldest' }), value: 'date_ASC' },
        ]}
      />
    </>
  );
};

export default TopPeopleFilters;
