import apiService from 'services/apiService';
import { serializeQueryParams } from 'utils';
import { getPeopleState } from 'store/reducers/people/selectors';

import { CABINET_ROUTES } from 'consts';
import { getUser } from 'store/reducers/user/selectors';
import {
  CLEAR_CHECKED_PEOPLE,
  PEOPLE_LOAD_START,
  PEOPLE_LOAD_SUCCESS,
  REFRESH_STATE,
  REMOVE_PEOPLE,
  RESET_PEOPLE_FILTERS,
  SET_CHECKED_PEOPLE,
  SET_PEOPLE_CREATE_SHOW,
  SET_PEOPLE_FILTER,
} from './action.types';

export const resetPeopleFilters = () => (
  { type: RESET_PEOPLE_FILTERS }
);

export const clearCheckedPeople = () => (
  { type: CLEAR_CHECKED_PEOPLE }
);

export const refreshPeopleState = () => (
  { type: REFRESH_STATE }
);

export const requestPeople = (reset = false, collectionId) => async (dispatch, getState) => {
  const {
    loading,
    page,
    hasMore,
    filters,
  } = getPeopleState(getState());

  const { search = '', sorting } = filters;
  const [sortBy, direction] = sorting.split('_');

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  const options = { page: reset ? 1 : page + 1, search };

  if (sorting) {
    options.sortType = direction;
    options.sortBy = sortBy;
  }

  dispatch({
    type: PEOPLE_LOAD_START,
    payload: {
      reset,
    },
  });

  const getMethodName = collectionId ? 'getCollectionPeople' : 'getPeopleList';

  const resp = await apiService[getMethodName](serializeQueryParams(options), collectionId);

  if (!resp) {
    return;
  }

  const { list, metadata } = resp;

  dispatch({
    type: PEOPLE_LOAD_SUCCESS,
    payload: { data: list, hasMore: metadata.hasMore },
  });
};

export const requestTrends = (reset = false, peopleId) => async (dispatch, getState) => {
  const {
    loading,
    page,
    hasMore,
  } = getPeopleState(getState());

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  const options = { page: reset ? 1 : page + 1, peopleId };

  dispatch({
    type: PEOPLE_LOAD_START,
    payload: {
      reset,
    },
  });

  const resp = await apiService.getPeopleTrends(serializeQueryParams(options));

  if (!resp) {
    return;
  }

  const { list, metadata } = resp;

  dispatch({
    type: PEOPLE_LOAD_SUCCESS,
    payload: { data: list, hasMore: metadata.hasMore },
  });
};

export const requestCollections = (reset = false, peopleId) => async (dispatch, getState) => {
  const {
    loading,
    page,
    hasMore,
  } = getPeopleState(getState());

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  const options = { page: reset ? 1 : page + 1, peopleId };

  dispatch({
    type: PEOPLE_LOAD_START,
    payload: {
      reset,
    },
  });

  const resp = await apiService.getPeopleCollections(serializeQueryParams(options));

  if (!resp) {
    return;
  }

  const { list, metadata } = resp;

  dispatch({
    type: PEOPLE_LOAD_SUCCESS,
    payload: { data: list, hasMore: metadata.hasMore },
  });
};

export const setCheckedPeople = (data) => ({
  type: SET_CHECKED_PEOPLE,
  payload: data,
});

export const setPeopleFilter = (payload, withoutReq = false, collectionId = '') => async (dispatch) => {
  dispatch({ type: SET_PEOPLE_FILTER, payload });
  if (!withoutReq) {
    dispatch(requestPeople(true, collectionId));
  }
};

export const setPeopleCreateShow = (data) => ({
  type: SET_PEOPLE_CREATE_SHOW,
  payload: data,
});

export const addPeople = (data) => (dispatch) => {
  apiService.addPeople(data)
    .then((res) => {
      if (!res.error) {
        dispatch(requestPeople(true));
      }

      dispatch(setPeopleCreateShow(false));
    });
};

export const editPeople = (data, pageId) => (dispatch, getState) => {
  const { role } = getUser(getState());

  apiService.editPeople(data._id, data)
    .then((res) => {
      dispatch(setPeopleCreateShow(false));
      if (!res.error) {
        const pageIdStr = pageId ? `/${pageId}` : '';
        window.location.href = `/${role}/${CABINET_ROUTES.PEOPLE}${pageIdStr}`;
      }
    })
    .catch(() => {
      dispatch(setPeopleCreateShow(false));
    });
};

export const removePeople = (id) => ({ type: REMOVE_PEOPLE, payload: { id } });
