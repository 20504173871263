import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR_THEMES, ROLE_THEMES, COLLECTION_TYPE } from 'consts';
import Button from 'components/button';
import apiService from 'services/apiService';
import { getCollectionsStateList } from 'store/reducers/collections/selectors';
import { getPeopleCollectionState } from 'store/reducers/people-collection/selectors';
import AddIcon from 'components/svg-icons/add-icon';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import classNames from 'classnames';
import { getIsClient } from 'store/reducers/general/selectors';
import {
  resetCollectionFilters,
  setCollectionFilter,
} from 'store/reducers/collections/actions';
import {
  resetPeopleCollectionFilters,
  setPeopleCollectionFilter,
} from 'store/reducers/people-collection/actions';
import CollectionList from './components/collection-list';
import styles from './styles.module.scss';

const { DARK, LIGHT } = ROLE_THEMES;
const {
  PRIMARY,
  PRIMARY_GRADIENT,
  TRANSPARENT_LIGHT_WHITE,
  LIGHT: LIGHT_THEME,
} = COLOR_THEMES;

const { TRENDS, PEOPLE } = COLLECTION_TYPE;

const dataActions = {
  [TRENDS]: {
    getList: getCollectionsStateList,
    resetFilters: resetCollectionFilters,
    setFilters: setCollectionFilter,
    apiMethodName: 'addTrendToCollection',
  },
  [PEOPLE]: {
    getList: getPeopleCollectionState,
    resetFilters: resetPeopleCollectionFilters,
    setFilters: setPeopleCollectionFilter,
    apiMethodName: 'addPeopleToCollection',
  },
};

const AddCollectionPopup = (props) => {
  const {
    onClose = () => {},
    processing = false,
    itemId = '',
    handleCreateCollection = () => {},
    type = TRENDS,
    title = '',
  } = props;

  const {
    getList, resetFilters, setFilters, apiMethodName,
  } = dataActions[type];
  const [parsersDomains, setParsersDomains] = useState(null);
  const { data: collectionList, search } = useSelector(getList);
  const dispatch = useDispatch();

  const intl = useIntl();
  const isClient = useSelector(getIsClient);
  const [chosenCollectionId, setChosenCollectionId] = useState(null);

  useEffect(() => {
    apiService.getParsersInfo()
      .then((res) => {
        const domains = res?.data?.parsersDomains;
        if (Array.isArray(domains)) {
          setParsersDomains(domains);
        }
      })
      .catch(() => {
        console.log('error');
      });

    // todo show notifications
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const onCollectionChoose = (collectionId) => {
    let newCollectionId = null;

    if (collectionId !== chosenCollectionId) {
      newCollectionId = collectionId;
    }
    setChosenCollectionId(newCollectionId);
  };

  const addCollectionHandler = () => {
    const dataToSend = {
      collectionId: chosenCollectionId,
    };

    const idKey = type === TRENDS ? 'trendId' : 'personId';
    dataToSend[idKey] = itemId;

    apiService[apiMethodName](dataToSend)
      .then(() => {
        // todo notificatioin of success
        onClose();
      }).catch((err) => {
        // todo notification of error
        console.log(err);
        onClose();
      });

    setChosenCollectionId(null);
  };

  const createNewCollection = () => {
    onClose();
    handleCreateCollection();
  };

  const onFilterChange = (name, value) => {
    dispatch(setFilters({ name, value }));
  };

  return (
    <Popup
      title={title || intl.formatMessage({ id: 'addTrendToCollection' })}
      onClose={onClose}
      colorTheme={isClient ? DARK : LIGHT}
      withoutButtons
    >
      <div>
        <div className={styles.searchInput}>
          <Input
            delay={1000}
            colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : LIGHT_THEME}
            name="search"
            value={search}
            onChange={onFilterChange}
            icon={<SearchIcon />}
            placeholder={intl.formatMessage({ id: 'searchCollection' })}
          />
        </div>
        <CollectionList
          onChoose={onCollectionChoose}
          chosenItem={chosenCollectionId}
          itemId={itemId}
          domains={parsersDomains}
          isClient={isClient}
          type={type}
        />
        <div className={styles.buttonsBlock}>
          <Button
            fullWidth
            disabled={chosenCollectionId}
            onClick={createNewCollection}
            icon={AddIcon()}
            componentStyle={{
              mainContainer: classNames(
                styles.createNewButtonStyle,
              ),
              textContainer: { [styles.adminAddCollection]: !isClient },
            }}
          >
            <FormattedMessage id="createNewCollection" />
          </Button>
          <Button
            fullWidth
            onClick={addCollectionHandler}
            colorTheme={isClient ? PRIMARY_GRADIENT : PRIMARY}
            loading={processing}
            type="submit"
            disabled={!collectionList.length || !chosenCollectionId}
            componentStyle={{ mainContainer: styles.doneButtonStyle }}
          >
            <FormattedMessage id="done" />
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AddCollectionPopup;
