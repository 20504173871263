import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { CABINET_ROUTES } from 'consts';
import ApiService from 'services/apiService';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import LoadingComponent from 'components/loadingComponent';
import { scrollTo } from 'utils';
import ReportHeader from './components/report-header';
import TrendData from './components/trend-data';
import PreviewHeadActions from './components/preview-head-actions';
import styles from './styles.module.scss';

const Report = (props) => {
  const { isPreview } = props;

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { navigate } = UseRoleNavigation();

  const toggleForceUpdate = () => {
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (!loading) {
      const searchParams = new URLSearchParams(window.location.search);
      const trendParam = searchParams.get('trend');

      if (trendParam === 'all') {
        scrollTo(document.querySelector('[data-section=trends-list]'));
      } else if (trendParam) {
        scrollTo(document.querySelector(`[data-section=trend-section-${trendParam}]`));
      }
    }
  }, [loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    ApiService.getReportView(id).then((res) => {
      if (res?.data) {
        setReportData(res.data);
        setLoading(false);
        return;
      }

      navigate(CABINET_ROUTES.REPORTS);
    });
  }, [id, forceUpdate]);

  if (loading) {
    return <LoadingComponent />;
  }

  const {
    categoryTrends,
    trends,
    title,
    description,
    totalIndexMetrics,
    totalMetrics,
    updatedAt,
    createdAt,
  } = reportData;

  return (
    <div className={classNames(styles.reportPage, { [styles.isPreview]: isPreview })}>
      { isPreview && (
        <PreviewHeadActions reportData={reportData} id={id} toggleForceUpdate={toggleForceUpdate} />
      ) }
      <ReportHeader
        title={title}
        description={description}
        updatedDate={updatedAt}
        createdDate={createdAt}
        trends={trends}
        totalMetrics={totalMetrics}
        totalIndexMetrics={totalIndexMetrics}
      />
      <div className={styles.trendData}>
        <div className="container">
          { Object.keys(categoryTrends).map((key) => {
            const { category, trends = [] } = categoryTrends[key];
            return (
              <div className={styles.categoryTrends} key={key}>
                <h3 className={styles.trendCategory}>{category}</h3>
                { trends.map((trend) => <TrendData data={trend} key={`trend_data_${trend._id}`} />) }
              </div>
            );
          }) }
        </div>
      </div>
    </div>
  );
};

export default Report;
