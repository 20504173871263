import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Layout from 'components/layout';
import Input from 'components/fields/input';
import Button from 'components/button';
import { FormattedMessage } from 'react-intl';
import ApiService from 'services/apiService';
import notificationService from 'services/notificationService';
import { COLOR_THEMES } from 'consts';
import UseFormData from 'hooks/useFormData';
import ReCAPTCHA from 'react-google-recaptcha';
import contactUsValidator from './contact-us-validator';
import styles from './styles.module.scss';

const initialData = {
  email: '',
  subject: '',
  message: '',
  recaptchaValue: '',
};

const ContactUsPage = () => {
  const componentStyle = {
    textContainer: styles.actionButtonText,
    mainContainer: styles.actionButton,
  };

  const [contactData, setContactData] = useState({ ...initialData });
  const [isRequest, setIsRequest] = useState(false);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef();

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: contactData,
    validators: contactUsValidator,
  });

  const {
    email, subject, message, recaptchaValue,
  } = data;

  const submitForm = () => {
    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setIsRequest(true);

    ApiService.sendContactUsMessage({ ...data }).then((res) => {
      recaptchaRef.current?.reset();

      if (res.error) {
        notificationService.addNotification(res.error);
        setIsRequest(false);
        return;
      }

      notificationService.addNotification(
        'The message sent successfully',
        null,
        { success: true },
      );
      setContactData({ ...initialData });
      setIsRequest(false);
    });
  };

  return (
    <Layout>
      <div className="container">
        <div className={styles.contactUsContainer}>
          <h1 className={styles.pageHeader}>
            <FormattedMessage id="contactUs" />
          </h1>
          <div className={styles.contactUsRow}>
            <div className={styles.contactUsLeftCol} />
            <div className={styles.contactUsCol}>
              If you would like to update or correct your information or
              modify your communication preferences, or if you have questions
              regarding our Privacy Policy or the use of information
              collected, you can contact us by email:
            </div>
            <div className={styles.contactUsRightCol}>
              <div>by writing to us at:</div>
              <br />
              <div className={styles.textCol}>
                { 'MindAd Inc.,\n190 West St., Unit 17B,\nBrooklyn, NY 11222' }
              </div>
            </div>
          </div>

          <div className={styles.contactUsRow}>
            <div className={styles.contactUsLeftCol}>Your email address</div>
            <div className={styles.contactUsCol}>
              <Input
                fullWidth
                name="email"
                value={email}
                onChange={onChange}
                placeholder="your@mail.com"
                errorMessage={error?.email}
                notBordered={!error?.email}
              />
            </div>
            <div className={styles.contactUsRightCol} />
          </div>

          <div className={styles.contactUsRow}>
            <div className={styles.contactUsLeftCol}>Subject</div>
            <div className={styles.contactUsCol}>
              <Input
                fullWidth
                name="subject"
                value={subject}
                onChange={onChange}
                placeholder="What is the subject of your message?"
                errorMessage={error?.subject}
                notBordered={!error?.subject}
              />
            </div>
            <div className={styles.contactUsRightCol} />
          </div>

          <div className={styles.contactUsRow}>
            <div className={styles.contactUsLeftCol}>How can we help you?</div>
            <div className={classNames(styles.contactUsCol, styles.messageBlock)}>
              <Input
                name="message"
                type="textarea"
                value={message}
                onChange={onChange}
                errorMessage={error?.message}
                notBordered={!error?.message}
              />
            </div>
            <div className={styles.contactUsRightCol} />
          </div>
          <div className={styles.contactUsRow}>
            <div className={styles.contactUsLeftCol} />
            <div className={classNames(styles.contactUsCol)}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={(value) => onChange('recaptchaValue', value)}
                onErrored={(error) => console.error('ReCaptcha error', error)}
                onExpired={() => onChange('recaptchaValue', '')}
              />
            </div>
            <div className={styles.contactUsRightCol} />
          </div>
          <div className={styles.sendMessageRow}>
            <div className={styles.buttonWrap}>
              <Button
                onClick={submitForm}
                componentStyle={componentStyle}
                colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
                loading={isRequest}
                disabled={!recaptchaValue}
              >
                <FormattedMessage id="sendMessage" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
