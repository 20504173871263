import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ApiService from 'services/apiService';
import { CABINET_ROUTES } from 'consts';

const { GLOSSARY } = CABINET_ROUTES;

const initialData = {
  pageTitle: '',
};

const GlossaryLink = () => {
  const dispatch = useDispatch();
  const [pageData, setpageData] = useState(initialData);

  useEffect(() => {
    ApiService.getGlossary().then((res) => {
      if (res.error) {
        return;
      }

      setpageData(res.data);
    });
  }, [dispatch]);

  return <a href={`/${GLOSSARY}`}>{pageData.pageTitle}</a>;
};

export default GlossaryLink;
