import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { COLOR_THEMES } from 'consts';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import Button from 'components/button';
import SearchIcon from 'components/svg-icons/search-icon';
import { getTypeKeysFilter } from 'store/reducers/type-keys/selectors';
import { setTypeKeysFilter } from 'store/reducers/type-keys/actions';
import AddTypeKey from '../add-type-key';

const TypeKeysFilters = () => {
  const intl = useIntl();
  const filters = useSelector(getTypeKeysFilter);
  const dispatch = useDispatch();

  const [addTypeKey, setAddTypeKey] = useState(false);

  const openAddTypeKeyPopup = () => {
    setAddTypeKey(true);
  };

  const closeAddTypeKeyPopup = () => {
    setAddTypeKey(false);
  };

  const onFilterChange = (name, value) => {
    dispatch(setTypeKeysFilter(name, value));
  };

  const { searchTypeKey, sortingNameDirection } = filters;

  return (
    <>
      { addTypeKey && <AddTypeKey onCancel={closeAddTypeKeyPopup} /> }
      <Button onClick={openAddTypeKeyPopup} colorTheme={COLOR_THEMES.LIGHT}>{intl.formatMessage({ id: 'addTypeKey' })}</Button>
      <Select
        onChange={onFilterChange}
        name="sortingNameDirection"
        value={sortingNameDirection}
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'asc' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'desc' },
        ]}
      />
      <Input
        onChange={onFilterChange}
        name="searchTypeKey"
        value={searchTypeKey}
        icon={<SearchIcon />}
        placeholder={intl.formatMessage({ id: 'searchTypeKey' })}
      />
    </>
  );
};

export default TypeKeysFilters;
