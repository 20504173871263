import React from 'react';

const LandingMainPartMemeIcon = () => (
  <svg width="188" height="188" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_416)">
      <path d="M174 95.5C174 83.3527 171.607 71.3244 166.959 60.1018C162.31 48.8792 155.497 38.682 146.907 30.0926C138.318 21.5032 128.121 14.6897 116.898 10.0411C105.676 5.39258 93.6473 3 81.5 3" stroke="url(#paint0_linear_1_416)" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M61.3176 86.3775C63.079 85.0672 64.565 83.4229 65.6908 81.5383C66.8167 79.6537 67.5603 77.5659 67.8792 75.3939C68.1982 73.2219 68.0862 71.0084 67.5497 68.8797C67.0132 66.7511 66.0627 64.7489 64.7524 62.9876C63.4422 61.2262 61.7978 59.7402 59.9132 58.6143C58.0287 57.4885 55.9408 56.7449 53.7688 56.4259C51.5969 56.107 49.3834 56.2189 47.2547 56.7554C45.126 57.2919 43.1238 58.2425 41.3625 59.5527C39.6011 60.863 38.1151 62.5074 36.9893 64.3919C35.8634 66.2765 35.1198 68.3644 34.8009 70.5363C34.4819 72.7083 34.5939 74.9218 35.1304 77.0505C35.6669 79.1792 36.6174 81.1813 37.9277 82.9427C39.2379 84.704 40.8823 86.19 42.7669 87.3159C44.6514 88.4417 46.7393 89.1853 48.9113 89.5043C51.0832 89.8233 53.2967 89.7113 55.4254 89.1748C57.5541 88.6383 59.5563 87.6878 61.3176 86.3775Z" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
      <path d="M125.593 81.3463C127.627 77.8371 128.315 73.7082 127.53 69.7291C126.745 65.75 124.54 62.192 121.326 59.7182C118.112 57.2444 114.108 56.0234 110.06 56.2828C106.013 56.5422 102.198 58.2643 99.3257 61.1281C96.4537 63.992 94.7209 67.8023 94.4502 71.8491C94.1794 75.8959 95.3891 79.9031 97.8539 83.1241C100.319 86.345 103.87 88.56 107.847 89.3563C111.824 90.1527 115.955 89.476 119.47 87.4524" stroke="#7DE87C" strokeWidth="5" strokeLinecap="round" />
      <path d="M100.269 115.306H108.548C118.608 115.306 126.836 123.32 127.101 133.376C127.108 133.637 126.898 133.852 126.637 133.852H99.7293C92.8121 133.852 86.4943 129.927 83.4307 123.725" stroke="#7DE87C" strokeWidth="5" />
      <path d="M35.3557 133.376L32.8566 133.31L32.8566 133.31L35.3557 133.376ZM51.462 136.352C52.8427 136.352 53.962 135.233 53.962 133.852C53.962 132.472 52.8427 131.352 51.462 131.352V136.352ZM60.1254 131.352C58.7447 131.352 57.6254 132.472 57.6254 133.852C57.6254 135.233 58.7447 136.352 60.1254 136.352V131.352ZM62.1873 112.806H53.9082V117.806H62.1873V112.806ZM76.7845 122.618C74.1422 127.967 68.6933 131.352 62.7273 131.352V136.352C70.5958 136.352 77.7825 131.887 81.2673 124.832L76.7845 122.618ZM32.8566 133.31C32.8127 134.977 34.152 136.352 35.8195 136.352V131.352C36.965 131.352 37.885 132.297 37.8548 133.442L32.8566 133.31ZM37.8548 133.442C38.0838 124.74 45.2036 117.806 53.9082 117.806V112.806C42.4935 112.806 33.1568 121.9 32.8566 133.31L37.8548 133.442ZM35.8195 136.352H51.462V131.352H35.8195V136.352ZM60.1254 136.352H62.7273V131.352H60.1254V136.352Z" fill="#7DE87C" />
      <path d="M90.9963 114.086L89.491 84.557" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M75.6785 84.0681C75.7455 82.689 74.6819 81.5167 73.3028 81.4497C71.9237 81.3827 70.7514 82.4464 70.6844 83.8255L75.6785 84.0681ZM74.2143 114.207L75.6785 84.0681L70.6844 83.8255L69.2202 113.964L74.2143 114.207Z" fill="#1CA5E0" />
      <path d="M87.0681 68.2362C83.2567 66.8506 79.0715 66.8979 75.2922 68.3691" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
      <path d="M88.2861 81.4916C87.5702 80.3263 86.5812 79.353 85.4046 78.6558C84.228 77.9586 82.8993 77.5585 81.5334 77.4901C80.1674 77.4217 78.8054 77.6871 77.5651 78.2634C76.3248 78.8396 75.2435 79.7093 74.4148 80.7973" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
      <path d="M71.7173 113.963C71.7173 116.52 72.7329 118.972 74.5406 120.78C76.3484 122.587 78.8002 123.603 81.3568 123.603C83.9133 123.603 86.3652 122.587 88.1729 120.78C89.9807 118.972 90.9963 116.52 90.9963 113.963" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
      <path d="M72.3274 97.7349C65.0568 102.016 61.7117 107.496 61.7117 113.963C61.7117 114.749 61.8664 115.526 62.1668 116.251C62.4673 116.977 62.9077 117.636 63.4629 118.191C64.0181 118.746 64.6772 119.187 65.4026 119.487C66.128 119.788 66.9055 119.942 67.6907 119.942H73.5476" stroke="#1CA5E0" strokeWidth="5" />
      <path d="M90.2641 97.7349C97.5347 102.016 100.88 107.496 100.88 113.963C100.88 114.749 100.725 115.526 100.425 116.251C100.124 116.977 99.6837 117.636 99.1285 118.191C98.5733 118.746 97.9142 119.187 97.1888 119.487C96.4634 119.788 95.686 119.942 94.9008 119.942H89.0439" stroke="#1CA5E0" strokeWidth="5" />
      <path d="M134.965 68.3327C133.813 62.4076 130.496 57.1238 125.66 53.5116C120.825 49.8993 114.817 48.2177 108.809 48.7945C102.8 49.3714 97.2221 52.1654 93.1619 56.6317C90.1517 59.943 88.1359 63.9974 87.2933 68.3327M134.96 77.6226C133.802 83.5465 130.48 88.8268 125.64 92.434C120.8 96.0411 114.791 97.7164 108.783 97.1333C102.775 96.5501 97.2002 93.7503 93.1447 89.2797C90.9213 86.8288 89.2421 83.972 88.1792 80.8964" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
      <path d="M27.5107 77.6305C28.6704 83.554 31.9947 88.8333 36.8355 92.4388C41.6763 96.0443 47.6864 97.7177 53.694 97.1325C59.7015 96.5473 65.2758 93.7457 69.3298 89.2737C71.63 86.7364 73.3472 83.7647 74.4015 80.5658M27.5027 68.3407C28.6522 62.4152 31.9675 57.1302 36.8021 53.5164C41.6367 49.9026 47.644 48.2189 53.6525 48.7938C57.852 49.1955 61.8417 50.6804 65.2501 53.0625M75.2212 68.5725C74.5659 65.01 73.1198 61.6305 70.9817 58.689" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" />
      <path d="M27.5463 68.2062H21.2014C20.797 68.2062 20.4692 68.534 20.4692 68.9384V76.7476C20.4692 77.1519 20.797 77.4797 21.2014 77.4797H27.5463" stroke="#1CA5E0" strokeWidth="5" />
      <path d="M134.923 68.2062H141.268C141.672 68.2062 142 68.534 142 68.9384V76.7476C142 77.1519 141.672 77.4797 141.268 77.4797H134.923" stroke="#1CA5E0" strokeWidth="5" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1_416" x1="-11" y1="188" x2="133.512" y2="-4.22778" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7DE87C" />
        <stop offset="1" stopColor="#7CA7E8" />
      </linearGradient>
      <clipPath id="clip0_1_416">
        <rect width="188" height="188" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LandingMainPartMemeIcon;
