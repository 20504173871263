import React from 'react';
import styles from './styles.module.scss';

const FormBox = (props) => {
  const { children } = props;

  return (
    <div className={styles.formBox}>
      {children}
    </div>
  );
};

export default FormBox;
