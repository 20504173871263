import React from 'react';

const LandingMainPartMoreIcon = () => (
  <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_376)">
      <path d="M222.128 121.915C222.128 106.408 219.073 91.0525 213.139 76.7257C207.205 62.399 198.507 49.3814 187.541 38.4162C176.576 27.4509 163.559 18.7529 149.232 12.8185C134.905 6.88419 119.55 3.82983 104.043 3.82983" stroke="white" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M146.579 189.891V212.887C146.579 223.462 138.005 232.036 127.43 232.036H26.1489C15.5733 232.036 7 223.462 7 212.887V83.1776M110.234 42H26.1489C15.5733 42 7 50.5733 7 61.1489V62.5888M110.234 42L146.579 78.3446M110.234 42V59.1956C110.234 69.7713 118.807 78.3446 129.383 78.3446H146.579M146.579 78.3446V100.77" stroke="#29B974" strokeWidth="7" />
      <rect x="26.9122" y="94.0038" width="20.1055" height="20.1055" stroke="#1CA5E0" strokeWidth="7" />
      <rect x="26.9122" y="134.601" width="20.1055" height="20.1055" stroke="#1CA5E0" strokeWidth="7" />
      <rect x="26.9122" y="176.359" width="20.1055" height="20.1055" stroke="#29B974" strokeWidth="7" />
      <path d="M60.7436 97.0969H124.54" stroke="#29B974" strokeWidth="7" />
      <path d="M60.9369 111.016H90.7085" stroke="#29B974" strokeWidth="7" />
      <path d="M60.9369 138.081H76.7893" stroke="#29B974" strokeWidth="7" />
      <path d="M60.9369 152H76.7893" stroke="#29B974" strokeWidth="7" />
      <path d="M60.9369 179.452H90.3219" stroke="#29B974" strokeWidth="7" />
      <path d="M60.9369 193.371H116.614" stroke="#29B974" strokeWidth="7" />
      <path d="M172.771 137.351C170.713 125.212 164.101 114.317 154.283 106.888C144.466 99.4584 132.183 96.0561 119.942 97.3751C107.702 98.694 96.4255 104.635 88.4158 113.985C80.4061 123.335 76.2666 135.389 76.8422 147.687C77.4177 159.985 82.665 171.6 91.513 180.161C100.361 188.722 112.143 193.584 124.453 193.753C136.764 193.923 148.675 189.389 157.756 181.075C166.836 172.761 172.402 161.296 173.317 149.018" stroke="#1CA5E0" strokeWidth="7" />
      <path d="M97.2866 166.313C102.487 173.243 110.085 177.984 118.595 179.608C127.106 181.232 135.916 179.624 143.303 175.096C150.69 170.569 156.123 163.449 158.538 155.129C160.954 146.808 160.178 137.886 156.364 130.107C152.549 122.327 145.97 116.251 137.913 113.066C129.855 109.881 120.9 109.816 112.797 112.884C104.694 115.952 98.0278 121.933 94.101 129.656C90.1743 137.379 89.2698 146.289 91.5645 154.644" stroke="#1CA5E0" strokeWidth="7" />
      <path d="M164.944 173.266L175.384 183.705H179.25L194.812 199.267C198.923 203.378 198.923 210.043 194.812 214.153V214.153C190.702 218.264 184.037 218.264 179.927 214.153L163.591 197.818V194.531L153.538 184.478" stroke="#1CA5E0" strokeWidth="7" />
      <path d="M113.52 145.621L121.833 153.933L141.746 134.021" stroke="white" strokeWidth="7" />
    </g>
    <defs>
      <clipPath id="clip0_1_376">
        <rect width="240" height="240" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default LandingMainPartMoreIcon;
