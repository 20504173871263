import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import UseFormData from 'hooks/useFormData';
import { createValidator } from 'hooks/useFormData/validation';
import { acceptedMediaFormats } from 'components/image-uploader';
import { addPeople, editPeople, setPeopleCreateShow } from 'store/reducers/people/actions';
import { getCompanyDataError } from 'store/reducers/companies/selectors';
import FieldRow from 'pages/create-trend/components/field-row';
import notificationService from 'services/notificationService';
import AvatarLoader from 'pages/people/add-people/avatar-loader';
import apiService from 'services/apiService';
import { POPUP_TYPE } from 'consts';
import { getCreateShow } from 'store/reducers/people/selectors';
import { useParams } from 'react-router-dom';
import { socialLinksConfig } from 'pages/people/top-filters/social-links-config';
import SocialLinksForm from './social-links-form';
import styles from './styles.module.scss';

const baseFields = {
  title: '',
  description: '',
  imgLink: '',
  socialLinks: [],
};

const AddPeople = (props) => {
  const { onCancel = () => {} } = props;
  const { id: pageId } = useParams();
  const editData = useSelector(getCreateShow);
  const isEdit = editData && typeof editData === 'object';

  const normalizeData = () => {
    if (!isEdit) {
      return baseFields;
    }

    const socialLinks = editData.socialLinks ? editData.socialLinks.map(({ name, value }) => {
      return {
        name,
        value: {
          link: value,
          domains: socialLinksConfig[name]?.domains,
          name,
        },
      };
    }) : [];

    const normalizedEditData = {
      ...editData,
      socialLinks,
    };

    return normalizedEditData;
  };

  const normalizedData = useMemo(() => normalizeData(), []);

  const [errors, setErrors] = useState(false);
  const [proceedRequest, setProceedRequest] = useState(false);

  const [imgTempUrl, setImgTempUrl] = useState(normalizedData?.imgLink);
  const abortLoadingAttach = useRef(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const serverError = useSelector(getCompanyDataError);

  useEffect(() => {
    if (!serverError) {
      return;
    }

    setProceedRequest(false);
  }, [serverError]);

  useEffect(() => {
    return () => {
      dispatch(setPeopleCreateShow(false));
    };
  }, []);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: normalizedData,
    validators: {
      title: createValidator([
        {
          type: 'required',
          message: intl.formatMessage({ id: 'characterNameRequired' }),
        }]),
      socialLinks: createValidator([{
        type: 'checkArray',
        descr: {
          value: createValidator([{ type: 'linkDomain' }]),
        },
      }]),
    },
  });

  const loadFileHandler = async (file) => {
    if (!file) {
      setImgTempUrl('');
      return;
    }

    const { type: fileType, name: fileName } = file;
    const isFileSupported = acceptedMediaFormats.includes(fileType) && !fileName.endsWith('.jfif');

    abortLoadingAttach.current = false;

    if (!isFileSupported) {
      notificationService.addNotification(intl.formatMessage({ id: 'imgNotSupported' }));
      return;
    }

    const imgFormData = new FormData();
    imgFormData.append('post-img', file);

    const fileUrl = await apiService.sendFile(imgFormData);

    if (fileUrl && !abortLoadingAttach.current) {
      setImgTempUrl(fileUrl);
    }
  };

  const onEdit = () => {
    const errors = validateForm();
    setErrors(null);

    if (errors) {
      setProceedRequest(false);
      setErrors(errors);
      return;
    }

    setProceedRequest(true);

    dispatch(
      editPeople({
        ...data,
        imgLink: imgTempUrl,
        socialLinks: data
          .socialLinks
          .map((item) => ({
            name: item?.name,
            value: item?.value?.link,
          })),
      }, pageId),
    );
  };

  const onSubmit = () => {
    const errors = validateForm();
    setErrors(null);

    if (errors) {
      setProceedRequest(false);
      setErrors(errors);
      return;
    }

    setProceedRequest(true);

    if (isEdit) {
      return;
    }

    dispatch(addPeople({
      ...data,
      imgLink: imgTempUrl,
      socialLinks: data
        .socialLinks
        .map((item) => ({
          name: item?.name,
          value: item?.value?.link,
        })),
    }));
  };

  const getFormFields = () => {
    if (!data) {
      return null;
    }

    const { title, description } = data;

    return (
      <>
        <FieldRow additionalStyle={styles.labelStyle} label={intl.formatMessage({ id: 'mainCharacterName' })}>
          <Input
            autoFocus
            fullWidth
            placeholder={intl.formatMessage({ id: 'enterName' })}
            value={title}
            onChange={onChange}
            name="title"
            errorMessage={errors?.title}
          />
        </FieldRow>
        <AvatarLoader imgLink={imgTempUrl} onChange={loadFileHandler} />
        <SocialLinksForm
          data={data?.socialLinks}
          onChange={onChange}
          errors={errors?.socialLinks}
        />
        <FieldRow additionalStyle={styles.labelStyle} label={intl.formatMessage({ id: 'description' })}>
          <Input
            height={115}
            type="textarea"
            fullWidth
            value={description}
            onChange={onChange}
            name="description"
          />
        </FieldRow>
      </>
    );
  };

  return (
    <Popup
      title={intl.formatMessage({ id: !isEdit ? 'addNewCharacter' : 'editCharacter' })}
      processing={proceedRequest}
      onSubmit={isEdit ? onEdit : onSubmit}
      onClose={onCancel}
      submitText={intl.formatMessage({ id: !isEdit ? 'add' : 'save' })}
      type={POPUP_TYPE.FORM}
      scrollableFooter
    >
      {getFormFields()}
    </Popup>
  );
};

export default AddPeople;
