import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR_THEMES, CABINET_ROUTES, REPORT_STATUSES } from 'consts';
import { useParams } from 'react-router-dom';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import Button from 'components/button';
import UseFormData from 'hooks/useFormData';
import { createEmptyTrend } from 'pages/create-trend/form-factory';
import { requestHashtags } from 'store/reducers/hashtags/actions';
import ApiService from 'services/apiService';
import CreatingLoader from 'components/creating-loader';
import LoadingComponent from 'components/loadingComponent';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import { requestTypeKeys } from 'store/reducers/type-keys/actions';
import notificationService from 'services/notificationService';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import { getCompanies } from 'store/reducers/companies/selectors';
import { requestCompanies } from 'store/reducers/companies/actions';
import { clearCheckedPeople, setCheckedPeople } from 'store/reducers/people/actions';
import { getCheckedPeople } from 'store/reducers/people/selectors';
import TrendForm from './components/trend-form';
import trendValidator from './trend-validator';
import styles from './styles.module.scss';

const { TRENDS } = CABINET_ROUTES;

const initialData = createEmptyTrend();

const CreateTrendPage = (props) => {
  const { isEdit, isAdmin } = props;
  const params = useParams();

  const { navigate } = UseRoleNavigation();

  const intl = useIntl();
  const [loading, setLoading] = useState(isEdit);
  const checkedPeople = useSelector(getCheckedPeople);
  const dispatch = useDispatch();

  const [trendData, setTrendData] = useState(initialData);
  const [skipCancel, setSkipCancel] = useState(false);

  const releaseEditor = useRef();

  useEffect(() => {
    if (!isEdit) {
      setTrendData({ ...initialData });
      return;
    }

    ApiService.getTrend(params.id).then((res) => {
      if (res.error) {
        notificationService.addNotification(res.error);
        navigate(`${TRENDS}/${params.id}`);
        return;
      }
      setTrendData(res.data);
      dispatch(setCheckedPeople(res.data?.people));
      setLoading(false);
    });
  }, [isEdit]);

  const typeKeys = useSelector(getTypeKeys);
  const companies = useSelector(getCompanies);

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [], s3Domain = '' } = parsersData;
        dispatch(setParsersInfo({ parsersDomains, s3Domain }));
      }
    });

    dispatch(requestTypeKeys());
    dispatch(requestHashtags());
    dispatch(requestCompanies());
  }, []);

  const [error, setError] = useState(null);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: trendData,
    validators: trendValidator,
  });

  const cancelSave = async () => {
    await ApiService.cancelEditTrend(params.id);
  };

  const cancelSaveHandler = async () => {
    setSkipCancel(true);
    if (params.id) {
      await cancelSave();
      navigate(`${TRENDS}/${params.id}`);
      return;
    }

    navigate(TRENDS);
  };

  releaseEditor.current = () => {
    if (isEdit && !skipCancel) {
      cancelSave();
    }
  };

  useEffect(() => {
    return () => {
      releaseEditor.current();
      dispatch(clearCheckedPeople());
    };
  }, []);

  const saveHandler = async (status) => {
    const people = checkedPeople.map((item) => (item._id));
    setSkipCancel(true);
    setError(null);
    const validateObj = validateForm();

    if (status) {
      data.status = status;
    }

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setLoading(true);

    const resp = await ApiService.editTrend(params.id, { ...data, people });
    if (resp && resp.status < 400) {
      navigate(`${TRENDS}/${params.id}`);
    }
  };

  const declineApprovedHandler = async () => {
    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setLoading(true);

    const people = checkedPeople.map((item) => (item._id));
    const resp = await ApiService.declineApprovedTrend(params.id, { ...data, people });
    if (resp && resp.status < 400) {
      navigate(`${TRENDS}/${params.id}`);
    }
  };

  const createHandler = async (status) => {
    const people = checkedPeople.map((item) => (item._id));
    const dataToRequest = { ...data, status, people };

    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
    } else {
      setLoading(true);
      const resp = await ApiService.addTrend(dataToRequest);
      if (resp && resp.status < 400) {
        navigate(`${TRENDS}/${resp.data.parentId}`);
      }
    }
  };

  const normalizeTypeKeys = (data) => {
    return data.map((dataItem) => {
      const { _id: value, title: name, emoji } = dataItem;
      return { value, name: `${emoji} ${name}` };
    });
  };

  const getTrendHeaderPage = () => {
    if (!isEdit) {
      return <h2 className={styles.title}><FormattedMessage id="trendGeneration" /></h2>;
    }

    return <h2 className={styles.title}><FormattedMessage id="editTrend" /></h2>;
  };

  const loadingComponentText = intl.formatMessage({ id: trendData === initialData ? 'reportEditInitialMessage' : 'reportEditSavingMessage' });

  return (
    <div className="container">
      {/* eslint-disable-next-line no-nested-ternary */}
      { loading
        ? (isEdit ? <LoadingComponent text={loadingComponentText} /> : <CreatingLoader />)
        : (
          <>
            <div className={styles.row}>
              <div className={styles.asideSection}>
                {isAdmin && data.status === REPORT_STATUSES.APPROVED && (
                <Button
                  componentStyle={{
                    mainContainer: styles.declineButton,
                    textContainer: styles.declineButtonText,
                  }}
                  colorTheme={COLOR_THEMES.LIGHT}
                  onClick={declineApprovedHandler}
                >
                  <FormattedMessage id="declineReport" />
                </Button>
                )}
              </div>
              {getTrendHeaderPage()}
              <div className={styles.asideSection} />
            </div>
            <TrendForm
              key={data._id}
              data={data}
              onChange={onChange}
              clearError={() => setError(null)}
              error={error}
              typeKeys={normalizeTypeKeys(typeKeys)}
              allCompanies={companies}
            />
            <div className={styles.footer}>
              <div className={styles.footerRightContent}>
                <Button
                  colorTheme={COLOR_THEMES.LIGHT}
                  size="medium"
                  onClick={() => (isEdit
                    ? saveHandler(
                      data.status === REPORT_STATUSES.PENDING
                        ? undefined
                        : REPORT_STATUSES.DRAFT,
                    )
                    : createHandler(REPORT_STATUSES.DRAFT))}
                >
                  <FormattedMessage id={isEdit ? 'saveChanges' : 'saveDraft'} />
                </Button>
                <Button
                  colorTheme={COLOR_THEMES.LIGHT}
                  size="medium"
                  onClick={cancelSaveHandler}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  size="medium"
                  disabled={!data?.userExamplePosts?.length && !data?.brandExamplePosts?.length}
                  onClick={() => (isEdit
                    ? saveHandler(REPORT_STATUSES.PENDING)
                    : createHandler(REPORT_STATUSES.PENDING))}
                >
                  <FormattedMessage id="forApproval" />
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default CreateTrendPage;
