import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import UseFormData from 'hooks/useFormData';
import { createValidator } from 'hooks/useFormData/validation';
import ErrorMessage from 'components/error-message';
import { addHashtag, editHashtag } from 'store/reducers/hashtags/actions';
import { POPUP_TYPE } from 'consts';

const baseFields = {
  title: '',
};

const AddHashtag = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { prefilledData = null, onCancel = () => {} } = props;
  const [error, setError] = useState(false);
  const [proceedRequest, setProceedRequest] = useState(false);
  const [categoryData, setCategoryData] = useState(prefilledData || baseFields);

  useEffect(() => {
    setCategoryData(prefilledData || baseFields);
  }, [prefilledData]);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: categoryData,
    validators: {
      title: createValidator([{ type: 'required' }]),
    },
  });

  const onSubmit = () => {
    const errors = validateForm();
    setError(null);

    if (errors) {
      setProceedRequest(false);
      if (errors.title) {
        setError(intl.formatMessage({ id: 'hashtagNameRequired' }));
        return;
      }

      return;
    }

    setProceedRequest(true);

    if (prefilledData) {
      dispatch(editHashtag(prefilledData._id, data));
      return;
    }

    dispatch(addHashtag(data));
  };

  const getFormFields = () => {
    if (!data) {
      return null;
    }

    const { title } = data;

    return (
      <Input
        autoFocus
        prefix="#"
        fullWidth
        placeholder={intl.formatMessage({ id: 'enterHashtag' })}
        value={title}
        onChange={onChange}
        name="title"
      />
    );
  };

  return (
    <Popup
      title={intl.formatMessage({ id: !prefilledData ? 'addHashtag' : 'editHashtag' })}
      processing={proceedRequest}
      onSubmit={onSubmit}
      onClose={onCancel}
      submitText={intl.formatMessage({ id: !prefilledData ? 'add' : 'save' })}
      type={POPUP_TYPE.FORM}
    >
      {getFormFields()}
      <ErrorMessage message={error} />
    </Popup>
  );
};

export default AddHashtag;
