import {
  SET_COMPANIES,
  SET_COMPANIES_LOADING,
  SET_COMPANY_LOADING,
  SET_COMPANY_FILTER,
  SET_USER_FILTER,
  SET_COMPANY_DATA,
  RESET_COMPANY_USERS_FILTERS,
  SET_COMPANY_DATA_ERROR,
} from './action.types';

const initialFilters = {
  companies: {
    searchName: '',
    sorting: '',
  },
  users: {
    searchEmail: '',
    sortNameDest: '',
  },
};

const initialState = {
  data: [],
  selectedData: [],
  companiesLoading: true,
  companyLoading: true,
  companyData: null,
  error: null,
  filters: { ...initialFilters },
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_COMPANY_USERS_FILTERS:
      return { ...state, filters: { ...initialFilters } };
    case SET_COMPANIES:
      return { ...state, data: action.companies, companiesLoading: false };
    case SET_COMPANIES_LOADING:
      return { ...state, companiesLoading: action.loading };
    case SET_COMPANY_LOADING:
      return { ...state, companyLoading: action.loading };
    case SET_COMPANY_DATA:
      return { ...state, companyData: action.companyData };
    case SET_COMPANY_DATA_ERROR:
      return { ...state, error: action.error };
    case SET_COMPANY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          companies: {
            ...state.filters.companies,
            [action.name]: action.value,
          },
        },
      };
    case SET_USER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          users: {
            ...state.filters.users,
            [action.name]: action.value,
          },
        },
      };
    default:
      return state;
  }
};

export default companiesReducer;
