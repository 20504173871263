import React from 'react';

const SuccessParsingIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="7" fill="#29B974" />
    <path d="M4 6.99571L6.25 9.14124L9.5 4.63562" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SuccessParsingIcon;
