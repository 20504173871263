import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTrendFilter } from 'store/reducers/trends/actions';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import { CABINET_ROUTES } from 'consts';
import styles from './styles.module.scss';

const { TRENDS } = CABINET_ROUTES;

const Tags = (props) => {
  const { tagsData = [] } = props;

  const [tags, setTags] = useState([]);
  const { navigate } = UseRoleNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    const uniqueTags = [...new Set(tagsData)];

    setTags(uniqueTags.sort());
  }, [tagsData]);

  const clickTag = (tag) => {
    dispatch(setTrendFilter('search', tag, { silent: true }));
    navigate(`${TRENDS}`);
  };

  return (
    <div className={styles.tagsWrapper}>
      <div className={styles.tags}>
        {tags.map((tag) => (
          <div onClick={() => clickTag(tag)} key={tag}>
            <span key={tag} className={styles.tagWrapper}>
              <span className={styles.hashTag}>#</span>
              <span>{tag}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
