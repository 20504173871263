import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { useIntl } from 'react-intl';
import {
  LOGIN_ROLES, ROLE_THEMES, COLOR_THEMES, POPUP_TYPE, SIDES,
} from 'consts';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import Select from 'components/fields/select';
import ErrorMessage from 'components/error-message';
import SuccessMessage from 'components/success-message';
import Button from 'components/button';
import { createValidator } from 'hooks/useFormData/validation';
import UseFormData from 'hooks/useFormData';
import { addUser, editUser, setCompanyDataError } from 'store/reducers/companies/actions';
import { addStaff, editStaff, setStaffError } from 'store/reducers/staff/actions';
import { getStaffError } from 'store/reducers/staff/selectors';
import { getCompanyDataError } from 'store/reducers/companies/selectors';
import FileItem from './file-item';
import styles from './styles.module.scss';

const {
  ADMIN,
  WRITER,
  CLIENT,
  POWER_CLIENT,
} = LOGIN_ROLES;

const AddUserPopup = (props) => {
  const baseFields = {
    email: '',
    role: '',
  };

  const { LIGHT, DARK } = ROLE_THEMES;
  const { LIGTH: LIGHT_STYLE, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;
  const intl = useIntl();
  const {
    prefilledData = null,
    onCancel = () => {
    },
    isStaff,
    companyId,
    colorTheme = LIGHT,
    defaultFields = null,
    hideSelect = false,
    isBulk = false,
    maxToAdd = 0,
  } = props;

  const dispatch = useDispatch();

  if (defaultFields) {
    Object.keys(defaultFields).forEach((key) => {
      baseFields[key] = defaultFields[key];
    });
  }

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [proceedRequest, setProceedRequest] = useState(false);
  const [userData, setUserData] = useState(prefilledData || baseFields);
  const [fileData, setFileData] = useState(null);
  const [resetFileToggler, setResetFileToggler] = useState(false);
  const serverError = useSelector(isStaff ? getStaffError : getCompanyDataError);

  const generateRolesOptions = () => {
    const availableRoles = isStaff ? [ADMIN, WRITER] : [CLIENT, POWER_CLIENT];
    return availableRoles.map((role) => ({
      name: intl.formatMessage({ id: role }),
      value: role.toLowerCase(),
    }));
  };

  useEffect(() => () => {
    dispatch(setStaffError(null));
    dispatch(setCompanyDataError(null));
  }, []);

  useEffect(() => {
    if (!serverError) {
      return;
    }

    setProceedRequest(false);
    setError(serverError);
  }, [serverError]);

  useEffect(() => {
    setUserData(prefilledData || baseFields);
  }, [prefilledData]);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: userData,
    validators: {
      email: createValidator([{ type: 'required' }, isBulk ? {} : { type: 'email' }]),
      role: createValidator([{ type: 'required' }]),
    },
  });

  const onBulkFileChange = (e) => {
    const file = e.target.files[0];
    const { name } = file;
    const re = /(\.xlsx|\.xls)$/i;

    setSuccessMessage(null);
    setError(null);

    if (!re.exec(name)) {
      setError(intl.formatMessage({ id: 'bulkUploadUserErrorExt' }));
      return;
    }

    readXlsxFile(file).then((rows) => {
      const [headColumns, ...columns] = rows;
      const [mainColumn] = headColumns;

      if (mainColumn !== 'New clients') {
        setError(intl.formatMessage({ id: 'bulkUploadErrorContent' }));
        return;
      }

      const emails = [];

      columns.forEach((column) => {
        const [email] = column;
        if (!email || !email.trim().length) {
          return;
        }

        emails.push(email);
      });

      if (emails.length > maxToAdd) {
        setError(intl.formatMessage({ id: 'addUserLimitError' }));
        setResetFileToggler(!resetFileToggler);
        return;
      }

      setSuccessMessage(intl.formatMessage({ id: 'bulkUploadSuccess' }, { value: emails.length }));

      onChange('email', emails.join(','));

      setFileData(file);
    });
  };

  const onDeleteFile = () => {
    onChange('email', '');
    setSuccessMessage(null);
    setError(null);
    setFileData(null);
    setResetFileToggler(!resetFileToggler);
  };

  const onSubmit = () => {
    const notValid = validateForm();
    setError(null);

    const emailsItems = data.email.split(',').map((item) => item.trim()).filter(Boolean);

    if (isBulk && emailsItems.length > maxToAdd) {
      setError(intl.formatMessage({ id: 'addUserLimitError' }));
      return;
    }

    if (notValid) {
      setProceedRequest(false);
      setError(intl.formatMessage({ id: 'addUserError' }));
      return;
    }

    setProceedRequest(true);
    const dataToSend = { ...data };
    dataToSend.email = dataToSend.email.toLowerCase();

    if (prefilledData) {
      if (isStaff) {
        dispatch(editStaff(dataToSend._id, dataToSend));
        return;
      }
      dispatch(editUser(companyId, dataToSend._id, dataToSend));
      return;
    }

    if (isStaff) {
      dispatch(addStaff(dataToSend));
      return;
    }

    dataToSend.companyId = companyId;
    dispatch(addUser(companyId, dataToSend));
  };

  const { email, role } = data;

  const stylesTheme = colorTheme === DARK ? TRANSPARENT_LIGHT_WHITE : LIGHT_STYLE;

  return (
    <Popup
      title={intl.formatMessage({ id: !prefilledData ? 'addUser' : 'editUser' })}
      processing={proceedRequest}
      submitText={intl.formatMessage({ id: !prefilledData ? 'add' : 'save' })}
      submitDisabled={!email.trim().length}
      onSubmit={onSubmit}
      onClose={onCancel}
      colorTheme={colorTheme}
      type={POPUP_TYPE.FORM}
    >
      <div className={styles.formRow}>
        <div className={styles.formItem}>
          {isBulk ? (
            <>
              <FileItem file={fileData} onDelete={onDeleteFile} />
              { !fileData && (
                <Input
                  autoFocus
                  height={110}
                  placeholder="client1@gmail.com, client2@gmail.com..."
                  name="email"
                  value={email}
                  onChange={onChange}
                  colorTheme={stylesTheme}
                  type="textarea"
                />
              ) }
            </>
          ) : (
            <Input
              autoFocus
              disabled={prefilledData}
              placeholder={intl.formatMessage({ id: 'email' })}
              name="email"
              value={email}
              onChange={onChange}
              colorTheme={stylesTheme}
            />
          )}
        </div>
        {!hideSelect && !isBulk && (
          <div className={styles.formItem}>
            <Select
              placeholder={intl.formatMessage({ id: 'chooseRole' })}
              options={generateRolesOptions()}
              name="role"
              value={role}
              colorTheme={stylesTheme}
              onChange={onChange}
            />
          </div>
        )}
      </div>
      {isBulk && (
        <div className={styles.actions}>
          <a className={styles.linkButton} href="/example-bulk-invite.xlsx" download>
            <Button
              iconSide={SIDES.RIGHT}
              colorTheme={
                colorTheme === LIGHT ? COLOR_THEMES.SECONDARY : COLOR_THEMES.TRANSPARENT_LIGHT_WHITE
              }
              icon={(
                <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.3125 4.4375H7.5625V0.3125H3.4375V4.4375H0.6875L5.5 9.9375L10.3125 4.4375ZM0 11.3125H11V12.6875H0V11.3125Z"
                    fill="#1CA5E0"
                  />
                </svg>
            )}
            >
              {intl.formatMessage({ id: 'addUserDownloadExample' })}
            </Button>
          </a>
          <Button
            iconSide={SIDES.RIGHT}
            colorTheme={
              colorTheme === LIGHT ? COLOR_THEMES.SECONDARY : COLOR_THEMES.TRANSPARENT_LIGHT_WHITE
            }
            icon={(
              <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.9973 5.79704C13.6514 3.07212 11.3183 0.958374 8.49999 0.958374C6.31816 0.958374 4.42291 2.23375 3.55762 4.24379C1.85712 4.75204 0.583328 6.35754 0.583328 8.08337C0.583328 10.266 2.35904 12.0417 4.54166 12.0417H13.25C14.9964 12.0417 16.4167 10.6215 16.4167 8.87504C16.4154 8.1654 16.1765 7.47662 15.738 6.9187C15.2994 6.36077 14.6866 5.96587 13.9973 5.79704ZM9.29166 8.08337V10.4584H7.70833V8.08337H5.33333L8.49999 4.12504L11.6667 8.08337H9.29166Z"
                  fill="#1CA5E0"
                />
              </svg>
            )}
            resetFile={resetFileToggler}
            fileProps={{
              acceptFiles: '.xlsx',
              onChange: onBulkFileChange,
            }}
          >
            {intl.formatMessage({ id: 'addUserUploadEmails' })}
          </Button>
        </div>
      )}
      <ErrorMessage message={error} />
      <SuccessMessage message={successMessage} />
    </Popup>
  );
};

export default AddUserPopup;
