import apiService from 'services/apiService';
import {
  SET_HASHTAGS,
  SET_HASHTAGS_LOADING,
  SET_HASHTAGS_FILTER,
  SET_HASHTAGS_ERROR,
} from './action.types';

const setHashtags = (hashtags) => ({
  type: SET_HASHTAGS,
  hashtags,
});

export const setHashtagsError = (error) => ({
  type: SET_HASHTAGS_ERROR,
  error,
});

export const setHashtagsLoading = (loading) => ({
  type: SET_HASHTAGS_LOADING,
  loading,
});

export const setHashtagsFilter = (name, value) => ({
  type: SET_HASHTAGS_FILTER,
  name,
  value,
});

export const requestHashtags = () => (dispatch) => {
  dispatch(setHashtagsLoading(true));
  apiService.getHashtags()
    .then((data) => {
      dispatch(setHashtags(data));
      dispatch(setHashtagsLoading(false));
    });
};

export const addHashtag = (data) => (dispatch) => apiService.addHashtag(data)
  .then((data) => {
    dispatch(setHashtagsError(null));

    if (!data.error) {
      dispatch(requestHashtags());
      return;
    }

    dispatch(setHashtagsLoading(false));
    dispatch(setHashtagsError(data.error));
  });

export const editHashtag = (id, data) => (dispatch) => apiService
  .editHashtag(id, data)
  .then((res) => {
    dispatch(setHashtagsError(null));

    if (!res.error) {
      dispatch(requestHashtags());
      return;
    }

    dispatch(setHashtagsLoading(false));
    dispatch(setHashtagsError(res.error));
  });

export const deleteHashtag = (id) => (dispatch) => apiService
  .deleteHashtag(id)
  .then(() => {
    dispatch(requestHashtags());
  });
