import Button from 'components/button';
import CloseIcon from 'components/svg-icons/close-icon';
import { COLOR_THEMES } from 'consts';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import { resetReportFilters } from 'store/reducers/reports/actions';
import { getReportFiltersAdded } from 'store/reducers/reports/selectors';
import styles from './styles.module.scss';

const ResetFiltersButton = () => {
  const dispatch = useDispatch();
  const isClient = useSelector(getIsClient);
  const filtersAdded = useSelector(getReportFiltersAdded);

  return (
    <Button
      icon={<CloseIcon />}
      colorTheme={isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE_2 : COLOR_THEMES.LIGHT}
      componentStyle={{
        textContainer: styles.buttonText,
        mainContainer: { [styles.button]: true, [styles.buttonStaff]: !isClient },
      }}
      onClick={() => dispatch(resetReportFilters())}
      disabled={!filtersAdded}
    >
      <FormattedMessage id="reset" />
    </Button>
  );
};

export default ResetFiltersButton;
