import { SET_IS_CLIENT, SET_IS_ADMIN, SET_APP_MAINTENANCE } from './action.types';

const initialState = {
  appInited: false,
  isClient: false,
  isAdmin: false,
  isUnderMaintenance: false,
};

export const setAppMaintenance = () => ({
  type: SET_APP_MAINTENANCE,
});

export const setIsClient = (isClient) => ({
  type: SET_IS_CLIENT,
  isClient,
});

export const setIsAdmin = (isAdmin) => ({
  type: SET_IS_ADMIN,
  isAdmin,
});

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_CLIENT:
      return { ...state, isClient: action.isClient };
    case SET_IS_ADMIN:
      return { ...state, isAdmin: action.isAdmin };
    case SET_APP_MAINTENANCE:
      return { ...state, isUnderMaintenance: true };
    default:
      return state;
  }
};

export default generalReducer;
