import React from 'react';

const PlusIcon = () => (

  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.1875 5.3125V0.5H4.8125V5.3125H0V6.6875H4.8125V11.5H6.1875V6.6875H11V5.3125H6.1875Z" fill="currentColor" />
  </svg>
);

export default PlusIcon;
