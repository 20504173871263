import React from 'react';

const NoPeopleIcon = () => (
  <svg width="102" height="66" viewBox="0 0 102 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M72.1156 50.8531C69.5781 49.9695 65.432 49.6976 63.6195 48.9953C62.3508 48.4968 60.3344 47.9757 59.7 47.1828C59.043 46.4125 59.043 40.8164 59.043 40.8164C59.043 40.8164 60.6289 39.3437 61.2859 37.6898C61.943 36.0359 62.3734 31.55 62.3734 31.55C62.3734 31.55 62.5094 31.5953 62.7133 31.5953C63.1891 31.5953 64.05 31.2781 64.4578 29.1937C64.9562 26.6335 65.9078 25.2968 65.6586 23.4164C65.4773 22.1476 64.9336 21.9437 64.6164 21.9437C64.4578 21.9437 64.3445 21.989 64.3445 21.989C64.3445 21.989 65.6359 20.0859 65.6359 13.6062C65.6359 6.94526 60.493 0.374951 51 0.352295C41.4844 0.374951 36.3641 6.94526 36.3641 13.6062C36.3641 20.0632 37.6555 21.989 37.6555 21.989C37.6555 21.989 37.5422 21.9437 37.3836 21.9437C37.0437 21.9437 36.5 22.1476 36.3414 23.4164C36.0922 25.2968 37.0211 26.6562 37.5422 29.1937C37.95 31.2781 38.8109 31.5953 39.2867 31.5953C39.4906 31.5953 39.6266 31.55 39.6266 31.55C39.6266 31.55 40.057 36.0585 40.7141 37.6898C41.3711 39.3437 42.957 40.8164 42.957 40.8164C42.957 40.8164 42.957 46.4125 42.3 47.1828C41.643 47.9531 39.6492 48.4968 38.3805 48.9953C36.568 49.6976 32.4219 49.9695 29.8844 50.8531C27.3469 51.7367 19.5078 55.4296 19.5078 65.625H82.4922C82.4922 55.4296 74.6758 51.7367 72.1156 50.8531Z" fill="#BFC9D0" />
    <path d="M25.6477 47.9531C27.6867 46.9336 29.6805 46.9336 31.2211 46.7297C31.2211 46.7297 32.082 45.325 29.25 44.8266C29.25 44.8266 25.3758 43.8523 24.9227 43.3086C24.4695 42.7648 24.7414 39.7516 24.7414 39.7516C24.7414 39.7516 30.1562 39.5703 32.3086 37.6672C28.7969 32.4109 30.6773 26.2711 29.9523 20.5391C29.25 14.8297 25.8289 11.25 19.2812 11.25C19.2586 11.25 19.2812 11.25 19.0547 11.25C12.7109 11.25 9.44844 14.8297 8.72344 20.5617C7.99844 26.2938 10.0828 33.1133 6.48047 37.7125C8.49687 39.5023 13.4813 39.593 14.0477 39.593C14.093 39.593 14.093 39.593 14.093 39.593L14.1156 39.5703C14.1156 39.5703 14.3422 42.7875 13.8891 43.3313C13.4359 43.875 12.0992 44.3734 11.193 44.5773C9.19922 45.0078 7.09219 45.7102 5.34766 46.3219C3.60313 46.9109 0.25 50.4453 0.25 54.75H18.4203C18.9187 52.9375 22.3398 49.6297 25.6477 47.9531Z" fill="#BFC9D0" />
    <path d="M83.5799 54.75H101.75C101.75 50.4453 98.3971 46.9109 96.6525 46.2992C94.908 45.6875 92.7783 44.9852 90.8072 44.5547C89.8783 44.3508 88.5643 43.8523 88.1111 43.3086C87.658 42.7648 87.8846 39.5477 87.8846 39.5477L87.9072 39.5703C87.9072 39.5703 87.9299 39.5703 87.9525 39.5703C88.5189 39.5703 93.526 39.5023 95.5424 37.6898C91.94 33.0906 94.0018 26.2711 93.2768 20.5391C92.5518 14.8297 89.2893 11.25 82.9455 11.25C82.7189 11.25 82.7189 11.25 82.7189 11.25C76.1713 11.25 72.7275 14.8297 72.0252 20.5617C71.3002 26.2938 73.1807 32.4336 69.6689 37.6898C71.8213 39.593 77.2361 39.7742 77.2361 39.7742C77.2361 39.7742 77.508 42.7875 77.0549 43.3313C76.6018 43.875 72.7275 44.8492 72.7275 44.8492C69.8955 45.3477 70.7564 46.7523 70.7564 46.7523C72.2971 46.9563 74.3135 46.9563 76.3299 47.9758C79.6603 49.6297 83.0814 52.9375 83.5799 54.75Z" fill="#BFC9D0" />
  </svg>
);

export default NoPeopleIcon;
