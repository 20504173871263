import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LOGIN_ROLES, CABINET_ROUTES } from 'consts';
import ProtectedRoute from 'components/protected-route';
import ReportsPage from 'pages/reports';
import TrendsPage from 'pages/trends';
import CompaniesPage from 'admin/pages/companies';
import GlossaryPage from 'admin/pages/glossary';
import CompanyUsersPage from 'pages/users-list-page';
import CreateReport from 'pages/create-report';
import CreateTrend from 'pages/create-trend';
import ErrorPage from 'pages/error404';
import ReportPage from 'client/pages/report';
import TrendItemPage from 'client/pages/trend';
import StaffPage from 'admin/pages/staff';
import CategoriesPage from 'admin/pages/categories';
import HashtagsPage from 'admin/pages/hashtags';
import wsApiService from 'services/wsApiService';
import CollectionsPage from 'pages/collections';
import PeoplePage from 'pages/people';
import WordCloudPage from 'pages/word-cloud';
import PeopleItemPage from 'pages/people/people-item-page';
import PeopleCollectionsPage from 'pages/people-collections';
import PeopleCollectionItemPage from 'pages/people-collections/people-collection-item-page';
import TypeKeysPage from './pages/type-keys';

const { ADMIN } = LOGIN_ROLES;
const {
  COMPANIES, TRENDS, REPORTS, CREATE_REPORT, CREATE_TREND, STAFF, CATEGORIES, HASHTAGS, TYPE_KEYS,
  GLOSSARY, COLLECTIONS, WORD_CLOUD, PEOPLE, PEOPLE_COLLECTIONS,
} = CABINET_ROUTES;

const AdminCabinet = () => {
  const { path } = useRouteMatch();

  useEffect(() => {
    wsApiService.init();
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}/${CREATE_TREND}`}>
        <CreateTrend isAdmin />
      </Route>
      <Route exact path={`${path}/${TRENDS}`}>
        <TrendsPage key="trends" />
      </Route>
      <Route exact path={`${path}/${TRENDS}/:id/edit`}>
        <CreateTrend isEdit isAdmin />
      </Route>

      <Route exact path={`${path}/${TRENDS}/:id`}>
        <TrendItemPage isPreview />
      </Route>

      <Route exact path={`${path}/${CREATE_REPORT}`}>
        <CreateReport isAdmin />
      </Route>
      <Route exact path={`${path}/${REPORTS}`}>
        <ReportsPage />
      </Route>
      <Route exact path={`${path}/${REPORTS}/:id`}>
        <ReportPage isPreview />
      </Route>
      <Route exact path={`${path}/${REPORTS}/:id/edit`}>
        <CreateReport isEdit isAdmin />
      </Route>
      <Route exact path={`${path}/${COMPANIES}`}>
        <CompaniesPage />
      </Route>
      <Route exact path={`${path}/${COMPANIES}/:id`}>
        <CompanyUsersPage isAdmin />
      </Route>
      <Route exact path={`${path}/${STAFF}`}>
        <StaffPage />
      </Route>

      <Route exact path={`${path}/${CATEGORIES}`}>
        <CategoriesPage />
      </Route>
      <Route exact path={`${path}/${TYPE_KEYS}`}>
        <TypeKeysPage />
      </Route>
      <Route exact path={`${path}/${HASHTAGS}`}>
        <HashtagsPage />
      </Route>
      <Route exact path={`${path}/${GLOSSARY}`}>
        <GlossaryPage />
      </Route>
      <Route exact path={`${path}/${COLLECTIONS}`}>
        <CollectionsPage />
      </Route>
      <Route exact path={`${path}/${COLLECTIONS}/:id`}>
        <TrendsPage key="collection-trends" isCollection />
      </Route>
      <Route exact path={`${path}/${WORD_CLOUD}`}>
        <WordCloudPage />
      </Route>
      <Route exact path={`${path}/${PEOPLE}`}>
        <PeoplePage />
      </Route>
      <Route exact path={`${path}/${PEOPLE}/:id`}>
        <PeopleItemPage />
      </Route>
      <Route exact path={`${path}/${PEOPLE_COLLECTIONS}`}>
        <PeopleCollectionsPage />
      </Route>
      <Route exact path={`${path}/${PEOPLE_COLLECTIONS}/:id`}>
        <PeopleCollectionItemPage />
      </Route>
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default () => <ProtectedRoute roleName={ADMIN}><AdminCabinet /></ProtectedRoute>;
