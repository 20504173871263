import React, { useState, useEffect } from 'react';
import apiService from 'services/apiService';
import ReportFilter from '../reportFilter';
import CollapseComponent from '../collapseComponent';
import styles from './styles.module.scss';

const KymIndexesFilters = () => {
  const [filtersConfig, setFiltersConfig] = useState([]);

  useEffect(async () => {
    const response = await apiService.getIndexMetrics();
    if (response && response.data) {
      const newFiltersConfig = [];
      const { data: metrics } = response;

      Object.keys(metrics).forEach((metricName) => {
        const newConfigItem = {
          name: metricName,
          maxValue: metrics[metricName].max,
          minValue: metrics[metricName].min,
        };

        newFiltersConfig.push(newConfigItem);
      });

      setFiltersConfig(newFiltersConfig);
    }
  }, []);

  return (
    <CollapseComponent name="KYM Index">
      {filtersConfig.map((filterItem) => (
        <ReportFilter
          additionalStyle={styles.filtersItemContainer}
          key={filterItem.name}
          name={filterItem.name}
          maxValue={filterItem.maxValue}
          minValue={filterItem.minValue}
          short
          rounded
        />
      ))}
    </CollapseComponent>
  );
};

export default KymIndexesFilters;
