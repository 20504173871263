import axios from 'axios';
import store from 'store';
import storageService from 'services/storageService';
import { MAIN_ROUTES } from 'consts';
import { clearUserProfile, setUserProfile } from 'store/reducers/user';
import notificationService from 'services/notificationService';
import { setAppMaintenance } from 'store/reducers/general';

class ApiService {
  constructor() {
    if (ApiService.instance) {
      return ApiService.instance;
    }

    ApiService.instance = this;

    this.axios = axios.create();
    this.recursionRefreshCounter = 0;
    this.storageService = storageService;
    this.apiUrl = process.env.REACT_APP_API_BASE_URL;
    this.initInterceptors();
  }

  initInterceptors() {
    this.axios.interceptors.response.use((response) => response.data
      || response, (error) => {
      if (error.response) {
        const status = error?.response?.data?.status;

        if (status === 503) {
          store.dispatch(setAppMaintenance());
        }

        return {
          error: error?.response?.data?.error || error?.response?.data?.message || 'Something went wrong',
          status,
        };
      }

      if (error.status === 503) {
        store.dispatch(setAppMaintenance());
      }

      return { error: error.message, status: error.status };
    });
  }

  async getUser(token) {
    const userData = await this.axios.post(`${this.apiUrl}/auth/get-by-token`, { accessToken: token });
    const { data, error, status } = userData;

    if (status === 401) {
      const refreshedToken = await this.refreshAuthTokens();

      if (!refreshedToken) {
        this.storageService.clear();
        document.location.href = MAIN_ROUTES.LOGIN;

        return false;
      }

      return this.getUser(refreshedToken);
    }

    if (!error) {
      store.dispatch(setUserProfile(data));
    }

    return data;
  }

  logout = async () => {
    notificationService.addNotification('You are now logged out');
    const { pushToken } = store.getState().userData;
    await this.authRequest(
      `${this.apiUrl}/auth/logout`,
      'POST',
      { pushToken },
    );
    this.storageService.clear();
    store.dispatch(clearUserProfile());
    window.location.href = '/login';
  }

  async refreshAuthTokens() {
    const refreshToken = this.storageService.get('refreshToken');
    if (!refreshToken) {
      return null;
    }

    const result = await this.axios.post(`${this.apiUrl}/auth/refresh-token`, { refreshToken });

    if (!result.data) {
      return false;
    }

    const { accessToken, refreshToken: newRefreshToken } = result.data;

    if (!accessToken || !newRefreshToken) {
      return false;
    }

    this.storageService.add('refreshToken', newRefreshToken);
    this.storageService.add('accessToken', accessToken);

    return accessToken;
  }

  async authRequest(url, method = 'GET', bodyObj = {}, showNotifError = true) {
    const methodName = method.toLowerCase().trim();

    const accessToken = this.storageService.get('accessToken');
    const refreshToken = this.storageService.get('refreshToken');

    if (!(accessToken && refreshToken)) {
      document.location.href = '/login';
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const axiosParams = [];
    axiosParams.push(url);

    if (methodName === 'post' || methodName === 'patch') {
      axiosParams.push(bodyObj);
    }

    axiosParams.push(config);

    const result = await this.axios[methodName](...axiosParams);

    if ([400, 404].includes(result?.status) && showNotifError) {
      notificationService.addNotification(result?.error);
      return result;
    }

    if (result?.status === 403) {
      return this.logout();
    }

    if (result?.status === 401) {
      const st = await this.refreshAuthTokens();

      if (st && this.recursionRefreshCounter < 2) {
        this.recursionRefreshCounter += 1;
        return this.authRequest(url, method, bodyObj);
      }

      this.recursionRefreshCounter = 0;
      this.storageService.clear();
      document.location.href = '/login';
    }

    if (!result?.error) {
      return result;
    }

    return {
      error: result.error,
    };
  }

  async getAuthTokens(code, redirectUrl) {
    if (!code && !redirectUrl) {
      return null;
    }

    const conf = { code, redirectUrl };
    const response = await this.axios.post(`${this.apiUrl}/auth/google-auth`, conf);
    return response;
  }

  async getReports(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/reports${params}`);
    const { data } = result;

    return data;
  }

  async getTrend(id) {
    const request = await this.authRequest(`${this.apiUrl}/trends/${id}`);
    return request;
  }

  async getTrendView(id) {
    const request = await this.authRequest(`${this.apiUrl}/trends/view/${id}`);
    return request;
  }

  async getCommentsCompanies(id) {
    const request = await this.authRequest(`${this.apiUrl}/comments/companies/${id}`);
    const { data } = request;
    return data || [];
  }

  async getTrendComments(id, filters = {}) {
    const filtersString = Object.keys(filters).reduce((acc, filterKey) => {
      const filterValue = filters[filterKey];

      if (!filterValue) {
        return acc;
      }

      acc.push(`${filterKey}=${filterValue}`);
      return acc;
    }, []).join('&');
    const request = await this.authRequest(`${this.apiUrl}/comments/trend-comments/${id}?${filtersString}`);
    const { data } = request;
    return data || { list: [] };
  }

  async sendComment(data) {
    const request = await this.authRequest(`${this.apiUrl}/comments/`, 'POST', data);
    return request.data;
  }

  async editComment(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/comments/${id}`, 'PATCH', data);
    return request.data;
  }

  async likeComment(id) {
    const request = await this.authRequest(`${this.apiUrl}/comments/${id}/like`, 'POST');
    return request.data;
  }

  async unlikeComment(id) {
    const request = await this.authRequest(`${this.apiUrl}/comments/${id}/unlike`, 'POST');
    return request.data;
  }

  async getTrends(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/trends${params}`);
    const { data } = result;

    return data;
  }

  async getWordCloud(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/word-cloud${params}`);
    const { data } = result;

    return data;
  }

  async getCompanies() {
    const companiesResponse = await this.authRequest(`${this.apiUrl}/companies`);
    const { data } = companiesResponse;

    return data || [];
  }

  async getCompany(id) {
    const request = await this.authRequest(`${this.apiUrl}/companies/${id}/users`, 'GET');
    return request.data;
  }

  async addCompany(data) {
    const request = await this.authRequest(`${this.apiUrl}/companies`, 'POST', data);
    return request;
  }

  async editCompany(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/companies/${id}`, 'PATCH', data);
    return request;
  }

  async deleteCompany(id) {
    await this.authRequest(`${this.apiUrl}/companies/${id}`, 'DELETE');
  }

  async getCategories() {
    const categoriesResponse = await this.authRequest(`${this.apiUrl}/categories`);
    const { data } = categoriesResponse;

    return data || [];
  }

  async addCategory(data) {
    const request = await this.authRequest(`${this.apiUrl}/categories`, 'POST', data);
    return request;
  }

  async editCategory(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/categories/${id}`, 'PATCH', data);
    return request;
  }

  async deleteCategory(id) {
    await this.authRequest(`${this.apiUrl}/categories/${id}`, 'DELETE');
  }

  async getTypeKeys() {
    const response = await this.authRequest(`${this.apiUrl}/type-keys`);
    return response.data || [];
  }

  async addTypeKey(data) {
    const request = await this.authRequest(`${this.apiUrl}/type-keys`, 'POST', data);
    return request;
  }

  async editTypeKey(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/type-keys/${id}`, 'PATCH', data);
    return request;
  }

  async deleteTypeKey(id) {
    await this.authRequest(`${this.apiUrl}/type-keys/${id}`, 'DELETE');
  }

  async getHashtags() {
    const hashtagsReponse = await this.authRequest(`${this.apiUrl}/hashtags`);
    const { data } = hashtagsReponse;

    return data || [];
  }

  async addHashtag(data) {
    const request = await this.authRequest(`${this.apiUrl}/hashtags`, 'POST', data);
    return request;
  }

  async editHashtag(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/hashtags/${id}`, 'PATCH', data);
    return request;
  }

  async deleteHashtag(id) {
    await this.authRequest(`${this.apiUrl}/hashtags/${id}`, 'DELETE');
  }

  async getUsers(queryParams = '') {
    const usersResponse = await this.authRequest(`${this.apiUrl}/users${queryParams}`);
    const { data } = usersResponse;

    return data || [];
  }

  async addUser(data) {
    const request = await this.authRequest(`${this.apiUrl}/users`, 'POST', data);
    return request;
  }

  async addTrend(data) {
    const request = await this.authRequest(`${this.apiUrl}/trends`, 'POST', data);
    return request;
  }

  async addReport(data) {
    const request = await this.authRequest(`${this.apiUrl}/reports`, 'POST', data);
    return request;
  }

  async editReport(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/reports/${id}`, 'PATCH', data);
    return request;
  }

  async editTrend(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/trends/${id}`, 'PATCH', data);
    return request;
  }

  async editUser(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/users/${id}`, 'PATCH', data);
    return request;
  }

  async sendFile(formData) {
    const request = await this.authRequest(`${this.apiUrl}/reports/upload-image`, 'POST', formData);
    return request?.data || null;
  }

  async deleteUser(id) {
    await this.authRequest(`${this.apiUrl}/users/${id}`, 'DELETE');
  }

  async getReport(id) {
    const request = await this.authRequest(`${this.apiUrl}/reports/${id}`);
    return request;
  }

  async removeReport(id) {
    const request = await this.authRequest(`${this.apiUrl}/reports/${id}`, 'DELETE');
    return request;
  }

  async removeTrend(id) {
    const request = await this.authRequest(`${this.apiUrl}/trends/${id}`, 'DELETE');
    return request;
  }

  async getReportView(id) {
    const request = await this.authRequest(`${this.apiUrl}/reports/view/${id}`);
    return request;
  }

  async approveReport(id) {
    const request = await this.authRequest(`${this.apiUrl}/reports/approve/${id}`, 'POST');
    return request;
  }

  async approveTrend(id) {
    const request = await this.authRequest(`${this.apiUrl}/trends/approve/${id}`, 'POST');
    return request;
  }

  async declineApprovedReport(id, data) {
    const url = `${this.apiUrl}/reports/decline-approved/${id}`;
    const request = await this.authRequest(url, 'POST', data);
    return request;
  }

  async declineApprovedTrend(id, data) {
    const url = `${this.apiUrl}/trends/decline-approved/${id}`;
    const request = await this.authRequest(url, 'POST', data);
    return request;
  }

  async cancelEditTrend(id) {
    const url = `${this.apiUrl}/trends/edit-cancellation/${id}`;
    const request = await this.authRequest(url, 'POST');
    return request;
  }

  async cancelEditReport(id) {
    const url = `${this.apiUrl}/reports/edit-cancellation/${id}`;
    const request = await this.authRequest(url, 'POST');
    return request;
  }

  async declineReport(id) {
    const request = await this.authRequest(`${this.apiUrl}/reports/decline/${id}`, 'POST');
    return request;
  }

  async declineTrend(id) {
    const request = await this.authRequest(`${this.apiUrl}/trends/decline/${id}`, 'POST');
    return request;
  }

  async getNotifications() {
    const resp = await this.authRequest(`${this.apiUrl}/notifications`);

    return resp.data;
  }

  async deleteNotifications() {
    await this.authRequest(`${this.apiUrl}/notifications`, 'DELETE');
  }

  async setPushToken(token) {
    await this.authRequest(
      `${this.apiUrl}/notifications/token`,
      'POST',
      { token },
    );
  }

  async getMetrics() {
    const request = await this.authRequest(`${this.apiUrl}/reports/metrics/peaks`);
    return request;
  }

  async getIndexMetrics() {
    const request = await this.authRequest(`${this.apiUrl}/reports/index-metrics/peaks`);
    return request;
  }

  async sendParseLinkRequest(link) {
    const request = await this.authRequest(`${this.apiUrl}/posts-parser/parse`, 'POST', { link });
    return request;
  }

  async sendContactUsMessage(contactUsData) {
    if (!contactUsData) {
      return null;
    }

    const response = await this.axios.post(`${this.apiUrl}/contact-us`, contactUsData);
    return response;
  }

  async checkParsedData(requestId) {
    const request = await this.authRequest(`${this.apiUrl}/posts-parser/parse-status`, 'POST', { requestId });
    return request;
  }

  async getParsersInfo() {
    const request = await this.authRequest(`${this.apiUrl}/posts-parser/parsers-info`);
    return request;
  }

  async getGlossary() {
    const request = await this.axios.get(`${this.apiUrl}/glossary`);
    return request;
  }

  async editGlossary(data) {
    const request = await this.authRequest(`${this.apiUrl}/glossary/edit`, 'POST', data);
    return request;
  }

  async getMentionUsers(query) {
    const request = await this.authRequest(`${this.apiUrl}/users/mention`, 'POST', { query });
    return request;
  }

  async addCollection(data) {
    const request = await this.authRequest(`${this.apiUrl}/collections`, 'POST', data);
    return request;
  }

  async getCollections(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/collections${params}`);
    const { data } = result;

    return data;
  }

  async getCollection(id) {
    const request = await this.authRequest(`${this.apiUrl}/collections/${id}`);
    return request;
  }

  async getCollectionTrends(params = '', id) {
    const result = await this.authRequest(`${this.apiUrl}/collections/trends/${id}${params}`);
    const { data } = result;

    return data;
  }

  async addTrendToCollection(data) {
    const request = await this.authRequest(`${this.apiUrl}/collections/trends`, 'POST', data);
    return request;
  }

  async deleteCollection(id) {
    const request = await this.authRequest(`${this.apiUrl}/collections/${id}`, 'DELETE');
    return request;
  }

  async editCollection(data, id) {
    const request = await this.authRequest(`${this.apiUrl}/collections/${id}`, 'POST', data);
    return request;
  }

  async deleteTrendCollection(collectionId, trendId) {
    const request = await this.authRequest(`${this.apiUrl}/collections/trends/${trendId}?collectionId=${collectionId}`, 'DELETE');
    return request;
  }

  async shareCollectionCompanies(data) {
    const request = await this.authRequest(`${this.apiUrl}/collections/share/companies`, 'POST', data);
    return request;
  }

  async addPeople(data) {
    const request = await this.authRequest(`${this.apiUrl}/people`, 'POST', data);
    return request;
  }

  async getPeopleList(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/people${params}`);
    const { data } = result;

    return data;
  }

  async getPeople(id) {
    const request = await this.authRequest(`${this.apiUrl}/people/${id}`);
    return request;
  }

  async getPeopleTrends(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/people/trends${params}`);
    const { data } = result;

    return data;
  }

  async getPeopleCollections(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/people/collections${params}`);
    const { data } = result;

    return data;
  }

  async deletePeople(id) {
    await this.authRequest(`${this.apiUrl}/people/${id}`, 'DELETE');
  }

  async editPeople(id, data) {
    const request = await this.authRequest(`${this.apiUrl}/people/${id}`, 'POST', data);
    return request;
  }

  async getPeopleCollectionsList(params = '') {
    const result = await this.authRequest(`${this.apiUrl}/people-collections${params}`);
    const { data } = result;

    return data;
  }

  async getPeopleCollection(id) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections/${id}`, undefined, {}, false);
    return request;
  }

  async editPeopleCollection(data, id) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections/${id}`, 'POST', data);
    return request;
  }

  async addPeopleCollection(data) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections`, 'POST', data);
    return request;
  }

  async addPeopleToCollection(data) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections/people`, 'POST', data);
    return request;
  }

  async getCollectionPeople(params = '', id) {
    const result = await this.authRequest(`${this.apiUrl}/people-collections/people/${id}${params}`);
    const { data } = result;
    return data;
  }

  async deletePeopleCollection(id) {
    await this.authRequest(`${this.apiUrl}/people-collections/${id}`, 'DELETE');
  }

  async sharePeopleCollectionCompanies(data) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections/share/companies`, 'POST', data);
    return request;
  }

  async deletePeopleFromCollection(peopleId, collectionId) {
    const request = await this.authRequest(`${this.apiUrl}/people-collections/people/${peopleId}?collectionId=${collectionId}`, 'DELETE');
    return request;
  }
}

export default new ApiService();
