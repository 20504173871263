import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import styles from './styles.module.scss';

const CommentText = (props) => {
  const { text } = props;

  if (!text) {
    return null;
  }
  return (
    <MentionsInput disabled value={text} className="comment" classNames={styles}>
      <Mention displayTransform={(id, display) => `@${display}`} className={styles.mention} />
    </MentionsInput>
  );
};

export default CommentText;
