import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import EmptyIcon from 'components/svg-icons/empty-icon';
import { COLLECTION_TYPE } from 'consts';
import CharacterChainWidget from 'pages/people-collections/character-chain-widget';
import styles from '../../styles.module.scss';

const { TRENDS, PEOPLE } = COLLECTION_TYPE;

const CollectionItem = ({
  img,
  id = 0,
  name,
  total,
  checked = false,
  onClick = () => {},
  isClient,
  type = TRENDS,
}) => {
  const typeLowerCase = type.toLowerCase();
  return (
    <div
      className={
        classNames(styles.collectionsItemWrap,
          { [styles.chosenItem]: checked },
          { [styles.adminStyleWrap]: !isClient })
      }
      onClick={() => onClick(id)}
    >
      { type === PEOPLE ? (
        <div className={styles.collectionsItemImg}>
          { img?.length ? (
            <div className={styles.test}>
              <CharacterChainWidget
                capacity={3}
                id={id}
                peopleAvatars={img}
              />
            </div>
          ) : <EmptyIcon />}
        </div>
      ) : (
        <div className={styles.collectionsItemImg}>
          { img ? <img src={img} alt="collection-item-img" /> : <EmptyIcon />}
        </div>
      )}

      <div className={styles.infoBlock}>
        <div title={name}>{name}</div>
        {!!total && (
          <div>{total} <FormattedMessage id={total === 1 ? `${typeLowerCase}TotalOne` : `${typeLowerCase}Total`} /></div>
        )}
      </div>
    </div>
  );
};

export default CollectionItem;
