import apiService from 'services/apiService';
import { getTrendsState, getWordCloudLoading } from 'store/reducers/trends/selectors';
import { serializeQueryParams, getISODate } from 'utils';
import {
  SET_TREND_FILTER,
  RESET_TREND_FILTERS,
  REMOVE_TREND,
  TRENDS_LOAD_START,
  TRENDS_LOAD_SUCCESS,
  TRENDS_LOAD_ERROR,
  SET_COLLECTION_POPUP,
  WORD_CLOUD_LOAD_START,
  WORD_CLOUD_LOAD_SUCCESS,
  SET_MAX_WORDS,
  SET_MAX_COUNT,
  SET_EXCLUDE_WORDS,
  SET_WORD_CLOUD_SEARCH,
} from './action.types';

export const setWordCloudSearch = (payload) => {
  return {
    type: SET_WORD_CLOUD_SEARCH,
    payload,
  };
};

export const setExcludeWords = (payload) => {
  return {
    type: SET_EXCLUDE_WORDS,
    payload,
  };
};

export const setMaxWords = (payload) => {
  return {
    type: SET_MAX_WORDS,
    payload,
  };
};

export const setMaxCount = (payload) => {
  return {
    type: SET_MAX_COUNT,
    payload,
  };
};

export const setCollectionPopup = (name, data = {}) => {
  return {
    type: SET_COLLECTION_POPUP,
    payload: {
      name,
      data,
    },
  };
};

const buildFilters = (filtersObj) => {
  const filters = {};

  Object.keys(filtersObj).forEach((filterKey) => {
    const filterValue = filtersObj[filterKey];
    if (filterValue
      && (typeof filterValue === 'string'
        || (Array.isArray(filterValue) && filterValue.length))
    ) {
      filters[filterKey] = filterValue;
    }
  });

  if (filtersObj.trendDate) {
    filters.trendDateStart = filtersObj.trendDate.startDate
      ? getISODate(filtersObj.trendDate.startDate)
      : null;
    filters.trendDateEnd = filtersObj.trendDate.endDate
      ? getISODate(filtersObj.trendDate.endDate)
      : null;
  }

  if (filtersObj.sorting) {
    const [sortBy, direction] = filtersObj.sorting.split('_');
    filters.sortType = direction;
    filters.sortBy = sortBy;
    delete filters.sorting;
  }

  return filters;
};

export const requestWordCloud = (reset = false) => async (dispatch, getState) => {
  const {
    filters,
  } = getTrendsState(getState());

  const loading = getWordCloudLoading(getState());

  if (loading && !reset) {
    return;
  }

  dispatch({
    type: WORD_CLOUD_LOAD_START,
    payload: {
      reset,
    },
  });

  const clearFilters = { ...filters };
  delete clearFilters.sorting;

  const params = buildFilters(clearFilters);
  const wordCloud = await apiService.getWordCloud(serializeQueryParams(params));

  dispatch({
    type: WORD_CLOUD_LOAD_SUCCESS,
    payload: wordCloud,
  });

  dispatch(setMaxWords([wordCloud.length]));
  dispatch(setMaxCount([wordCloud.length]));
};

export const requestTrends = (reset = false, collectionId) => async (dispatch, getState) => {
  const {
    loading,
    hasMore,
    lastId,
    lastValue,
    filters,
  } = getTrendsState(getState());

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  dispatch({
    type: TRENDS_LOAD_START,
    payload: {
      reset,
    },
  });

  const isRequestActual = () => {
    if (reset) {
      return true;
    }

    const newState = getTrendsState(getState());
    return newState.loading
      && newState.lastId === lastId
      && newState.lastValue === lastValue
      && newState.filters === filters;
  };

  try {
    const params = buildFilters(filters);

    if (!reset) {
      params.lastId = lastId;
      params.lastValue = lastValue;
    }

    const getTrendList = collectionId ? 'getCollectionTrends' : 'getTrends';

    const {
      list,
      hasMore,
    } = await apiService[getTrendList](serializeQueryParams(params), collectionId);

    if (isRequestActual()) {
      dispatch({
        type: TRENDS_LOAD_SUCCESS,
        payload: { data: list, hasMore },
      });
    }
  } catch (error) {
    if (isRequestActual()) {
      dispatch({
        type: TRENDS_LOAD_ERROR,
        payload: { error },
      });
    }
  }
};

export const resetTrendFilters = (collectionId) => (dispatch) => {
  dispatch({ type: RESET_TREND_FILTERS });
  dispatch(requestTrends(true, collectionId));
};

export const resetWordCloud = () => (dispatch) => {
  dispatch({ type: RESET_TREND_FILTERS });
  dispatch(requestWordCloud(true));
};

export const clearTrendFilters = () => (dispatch) => {
  dispatch({ type: RESET_TREND_FILTERS });
};

export const removeTrend = (id) => ({ type: REMOVE_TREND, payload: { id } });

export const setTrendFilter = (name, value, options, collectionId) => (dispatch) => {
  // eslint-disable-next-line no-param-reassign
  options = options || {};
  const { isWordCloud, silent } = options;

  dispatch({
    type: SET_TREND_FILTER,
    name,
    value,
  });

  if (!silent) {
    const fn = isWordCloud ? requestWordCloud : requestTrends;
    dispatch(fn(true, collectionId));
  }
};
