import React from 'react';
import styles from './styles.module.scss';

const FieldRow = (props) => {
  const { label, children } = props;

  return (
    <label className={styles.fieldRow}>
      {label && <span className={styles.fieldLabel}>{label}</span>}
      {children}
    </label>
  );
};

export default FieldRow;
