import {
  SET_NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS,
} from './action.types';

const initialState = {
  data: [],
  loading: true,
  error: null,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, data: action.data };
    case SET_NOTIFICATIONS_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

export default notificationsReducer;
