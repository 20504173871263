import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import Button from 'components/button';
import { COLOR_THEMES, CURRENT_YEAR, MAIN_ROUTES } from 'consts';
import LandingMainPartMemeIcon from 'components/svg-icons/landing-main-part-meme-icon';
import LandingMainPartIntelligenceIcon from 'components/svg-icons/landing-main-part-intelligence-icon';
import LandingMainPartRealTimeIcon from 'components/svg-icons/landing-main-part-real-time-icon';
import LandingMainPartSocialDataIcon from 'components/svg-icons/landing-main-part-social-data-icon';
import LandingMainPartProductsItemIcon from 'components/svg-icons/landing-main-part-products-item-icon';
import LandingMainPartMoreIcon from 'components/svg-icons/landing-main-part-more-icon';
import CollapseIcon from 'components/svg-icons/collapse-icon';
import LandingSliderRightArrow from 'components/svg-icons/landing-slider-right-arrow-icon';
import LandingSliderLeftArrow from 'components/svg-icons/landing-slider-left-arrow-icon';
import GlossaryLink from 'admin/pages/glossary/link';
import styles from './styles.module.scss';

const {
  LOGIN, CONTACT_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS,
} = MAIN_ROUTES;

const LandingPage = () => {
  const [sliderOffset, setSliderOffset] = useState(0);

  const mainPartContainer = useRef(null);
  const goToSubscribe = () => {
    window.open('https://iag7l9jv864.typeform.com/to/CDKEzbAo', '_blank');
  };

  const scrollToNextClickHandler = () => {
    mainPartContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const sliderLeftHandler = () => {
    if (sliderOffset >= 100) {
      setSliderOffset(sliderOffset - 100);
    }
  };

  const sliderRightHandler = () => {
    if (sliderOffset < 300) {
      setSliderOffset(sliderOffset + 100);
    }
  };

  return (
    <div className={styles.landingPageContainer}>
      <div className={styles.landingPageHeader}>
        <div className={classNames(styles.landingPageRow, styles.headingRow)}>
          <a href="/">
            <ClientLogoIcon />
          </a>
          <a href={LOGIN}>
            <FormattedMessage id="loginButton" />
          </a>
        </div>
      </div>
      <div className={styles.introducingContainerWrap}>
        <div className={styles.introducingContainer}>
          <div className={styles.landingPageRow}>
            <div className={styles.scrollToNextButton} onClick={scrollToNextClickHandler}>
              <CollapseIcon />
            </div>
            <div className={styles.introducingLeftColumn}>
              <h1 className={styles.landingPageDesc}>{'If we’re not\ntelling you about\nit, it didn’t matter'}</h1>
              <div className={styles.landingBadge} />
              <div className={styles.landingTitleMobileWrap}>
                <h1 className={styles.landingPageDesc}>{'If we’re not\ntelling you about\nit, it didn’t matter'}</h1>
                <div className={styles.landingBadge} />
              </div>

              <div className={styles.landingMobileImg}>
                <img alt="mobile-background-img" src="/img/landing-background-mobile.png" />
              </div>

              <p className={styles.landingPageText}>
                Powered by the world’s largest internet culture encyclopedia,
                our custom research and consulting maximizes your brand’s social media impact
              </p>
              <div className={styles.introducingButtonWrapper}>
                <Button
                  colorTheme={COLOR_THEMES.LANDING_THEME}
                  onClick={goToSubscribe}
                >
                  <FormattedMessage id="contactUs" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainPartContainer} ref={mainPartContainer}>
        <div
          className={classNames(styles.sliderLeftButton,
            { [styles.sliderHidden]: sliderOffset === 0 })}
          onClick={sliderLeftHandler}
        >
          <LandingSliderLeftArrow />
        </div>
        <div
          className={classNames(styles.sliderRightButton,
            { [styles.sliderHidden]: sliderOffset === 300 })}
          onClick={sliderRightHandler}
        >
          <LandingSliderRightArrow />
        </div>
        <div className={styles.landingPageRow} style={{ transform: `translate(-${sliderOffset}%, 0)` }}>
          <div className={styles.mainPartContainerColumn}>
            <div className={styles.mainPartCartWrapper}>
              <LandingMainPartMemeIcon />
              <div className={styles.mainPartTitle}>
                Meme literacy
              </div>
              <div className={styles.landingBadge} />
              <div className={styles.mainPartDescription}>
                A team of dedicated experts backed by the world’s largest internet culture
                database dedicated to researching and documenting the online history and
                viral phenomena.
              </div>
            </div>
          </div>
          <div className={styles.mainPartContainerColumn}>
            <div className={styles.mainPartCartWrapper}>
              <LandingMainPartIntelligenceIcon />
              <div className={styles.mainPartTitle}>
                {'Intelligence\nto Action'}
              </div>
              <div className={styles.landingBadge} />
              <div className={styles.mainPartDescription}>
                Our insights tools offer curation where you need it: audience, topic, brand
                safety, and more.
              </div>
            </div>
          </div>
          <div className={styles.mainPartContainerColumn}>
            <div className={styles.mainPartCartWrapper}>
              <LandingMainPartRealTimeIcon />
              <div className={styles.mainPartTitle}>
                {'Real-Time\nCollaboration'}
              </div>
              <div className={styles.landingBadge} />
              <div className={styles.mainPartDescription}>
                Internet culture is always changing and we make sure you know how
                to adapt by engaging with you regularly in 1on1s, brainstorms, workshops, and more.
              </div>
            </div>
          </div>
          <div className={styles.mainPartContainerColumn}>
            <div className={styles.mainPartCartWrapper}>
              <LandingMainPartSocialDataIcon />
              <div className={styles.mainPartTitle}>
                Social Data
              </div>
              <div className={styles.landingBadge} />
              <div className={styles.mainPartDescription}>
                We combine qualitative information with quantitative social data to make sure you
                can always see the signal through the noise.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.aboutPartContainer}>
        <div className={styles.landingPageRow}>
          <div className={styles.aboutSidePart}>
            <img alt="about-img" className={styles.aboutImgWrap} src="/img/landing-about.png" />
          </div>
          <div className={styles.aboutSidePart}>
            <div className={styles.landingBlockTitle}>About</div>
            <div className={styles.landingBadge} />
            <div className={styles.aboutDescription}>
              <p>
                {'It\'s nearly impossible to exist as a brand or a creative without engaging with internet culture. From viral TikToks to trending memes, subreddits, and mainstream pop culture, the general zeitgeist is both captured and reflected online. Know Your Meme Insights delivers the knowledge you need to effectively engage with-and learn from-what\'s happening today.'}
              </p>
              <p>
                Why Know Your Meme Insights? Know Your Meme has been tracking and documenting
                internet trends, culture, and phenomena since 2008. Since its inception,
                Know Your Meme editors, researchers, and users have documented nearly 25
                thousand memes, trends, and notable moments in internet history,
                and has racked up a user base of over 2 million users.
              </p>
              <p>
                {'Know Your Meme\'s research and reporting has appeared in major news publications and outlets, including the New York Times, Los Angeles Times, The Wall Street Journal, NPR, PBS, CNN, MSNBC, Wired, TIME, Newsweek, The Atlantic, The Guardian, The Telegraph, Le Nouvel Observateur, Le Monde, Der Spiegel, and The Estadão among others. Know Your Meme has also earned several recognitions and awards for its research including TIME Magazine\'s 50 Best Websites of 2009, the 2010 Streamy Award for Best Guest Star in a Web Series, and the 2012 Webby Award for Best Cultural Blog. In 2014, Know Your Meme was inducted into the Web Archiving Program run by The American Folklife Center at the U.S. Library of Congress.'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.productsPartContainer}>
        <div className={styles.landingPageRow}>
          <div className={styles.productsSidePart}>
            <div className={styles.landingBlockTitle}>Products</div>
            <div className={styles.landingBadge} />
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>viral internet trends report</div>
            </div>
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>platform reports</div>
            </div>
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>community reports</div>
            </div>
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>topic reports</div>
            </div>
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>event reports</div>
            </div>
            <div className={styles.productsItem}>
              <LandingMainPartProductsItemIcon />
              <div className={styles.landingBlockTitle}>influencer reports</div>
            </div>
          </div>
          <div className={styles.productsSidePart}>
            <div className={styles.productsImgWrapper}>
              <img alt="products-net" src="/img/landing-products.png" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.morePartContainer}>
        <div className={styles.landingPageRow}>
          <div className={styles.moreIconWrap}>
            <LandingMainPartMoreIcon />
          </div>
          <div className={styles.moreRightPart}>
            <div className={styles.landingBlockTitle}>Want to know more?</div>
            <div className={styles.landingBadge} />
            <div className={styles.moreIconMobileWrap}>
              <LandingMainPartMoreIcon />
            </div>
            <div className={styles.moreButtonWrap}>
              <Button
                colorTheme={COLOR_THEMES.LANDING_THEME}
                onClick={goToSubscribe}
              >
                <FormattedMessage id="contactUs" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.landingPageFooter}>
        <div className={styles.landingPageRow}>
          <a href="/">
            <ClientLogoIcon />
          </a>

          <div className={styles.landingFooterLinks}>
            <a href={CONTACT_US}>
              <FormattedMessage id="contactUs" />
            </a>
            <a href="https://kyminsights.medium.com/" target="_blank" rel="noreferrer">
              <FormattedMessage id="blog" />
            </a>
            <a href={PRIVACY_POLICY}>
              <FormattedMessage id="privacyPolicy" />
            </a>
            <a href={TERMS_AND_CONDITIONS}>
              <FormattedMessage id="termsAndConditions" />
            </a>
            <GlossaryLink />
          </div>
          <div className={styles.footerCopyright}>
            {`Copyright © 2010-${CURRENT_YEAR} ${process.env.REACT_APP_TITLE}. All rights reserved.`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
