import apiService from 'services/apiService';
import { serializeQueryParams } from 'utils';
import { LOGIN_ROLES } from 'consts';
import {
  SET_STAFF,
  SET_STAFF_LOADING,
  SET_STAFF_FILTER,
  SET_STAFF_ERROR,
} from './action.types';

const setStaff = (staff) => ({
  type: SET_STAFF,
  staff,
});

export const setStaffError = (error) => ({
  type: SET_STAFF_ERROR,
  error,
});

export const setStaffLoading = (loading) => ({
  type: SET_STAFF_LOADING,
  loading,
});

export const setStaffFilter = (name, value) => ({
  type: SET_STAFF_FILTER,
  name,
  value,
});

export const requestStaff = () => (dispatch) => {
  dispatch(setStaffLoading(true));
  apiService.getUsers(serializeQueryParams({
    roles: [LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER],
  }))
    .then((data) => {
      dispatch(setStaff(data));
      dispatch(setStaffLoading(false));
    });
};

export const addStaff = (data) => (dispatch) => apiService.addUser(data)
  .then((data) => {
    dispatch(setStaffError(null));

    if (!data.error) {
      dispatch(requestStaff());
      return;
    }

    dispatch(setStaffLoading(false));
    dispatch(setStaffError(data.error));
  });

export const editStaff = (id, data) => (dispatch) => apiService
  .editUser(id, data)
  .then((res) => {
    dispatch(setStaffError(null));

    if (!res.error) {
      dispatch(requestStaff());
      return;
    }

    dispatch(setStaffLoading(false));
    dispatch(setStaffError(res.error));
  });

export const deleteStaff = (staffId) => (dispatch) => apiService
  .deleteUser(staffId)
  .then(() => {
    dispatch(requestStaff());
  });
