import { v4 as uuidv4 } from 'uuid';
import WebSocketConnector from './webSocketConnector';

export const WS_ACTIONS = {
  COMMENTS: {
    CREATE_COMMENT_TREND: 'createCommentTrend',
    EDIT_COMMENT_TREND: 'editCommentTrend',
    DELETE_COMMENT_TREND: 'deleteCommentTrend',
    LIKE_COMMENT_TREND: 'likeComment',
    UNLIKE_COMMENT_TREND: 'unlikeComment',
  },
};

class WsApiService {
  constructor() {
    if (WsApiService.instance) {
      return WsApiService.instance;
    }

    WsApiService.instance = this;

    this.listeners = new Map();
    this.commentListeners = new Map();

    this.ws = new WebSocketConnector();
    this.ws.registerMessageHandler(this.onMessageHandler.bind(this));
  }

  once(rid, cb) {
    this.listeners.set(rid, cb);
  }

  async sendComment(commentData) {
    return this.send(WS_ACTIONS.COMMENTS.CREATE_COMMENT_TREND, commentData);
  }

  async editComment(editData) {
    return this.send(WS_ACTIONS.COMMENTS.EDIT_COMMENT_TREND, editData);
  }

  async deleteCommentTrend(deleteData) {
    return this.send(WS_ACTIONS.COMMENTS.DELETE_COMMENT_TREND, deleteData);
  }

  async likeComment(likeCommentData) {
    return this.send(WS_ACTIONS.COMMENTS.LIKE_COMMENT_TREND, likeCommentData);
  }

  async unLikeComment(unLikeCommentData) {
    return this.send(WS_ACTIONS.COMMENTS.UNLIKE_COMMENT_TREND, unLikeCommentData);
  }

  send(action, sendData) {
    if (!this.ws) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const rid = uuidv4();

      this.once(rid, ({ error, data }) => {
        if (error) {
          return reject(data);
        }
        return resolve(data);
      });

      this.ws.send(JSON.stringify({
        rid,
        data: { action, ...sendData },
        event: 'events',
      }));
    });
  }

  onMessageHandler(wsMessage) {
    const wsMessageData = wsMessage?.data;

    if (!wsMessageData) {
      return;
    }

    try {
      const resp = JSON.parse(wsMessageData);
      const {
        rid,
        action,
        data,
        error = false,
      } = resp;

      if (!data || error) {
        return;
      }

      if (Object.values(WS_ACTIONS.COMMENTS).includes(action) && this.commentListeners.size) {
        this.commentListeners
          .forEach((commentCb) => (commentCb(action, data)));
      }

      if (rid) {
        const cb = this.listeners.get(rid);

        if (!cb) {
          return;
        }

        this.listeners.delete(rid);
        cb(data);
      }
    } catch (err) {
      console.log(err);
      // todo add error handler
    }
  }

  registerCommentListener(trendId, commentCb) {
    this.commentListeners.set(trendId, commentCb);
  }

  deleteCommentListener(trendId) {
    this.commentListeners.delete(trendId);
  }

  init() {
    if (!this.isInited) {
      this.isInited = true;
    }
  }
}

export default new WsApiService();
