import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_ROLES } from 'consts';
import { requestCompanies } from 'store/reducers/companies/actions';
import ApiService from 'services/apiService';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import { requestTypeKeys } from 'store/reducers/type-keys/actions';
import COUNTRIES from 'consts/countries';
import { getExcludeWords, getTrendFilters } from 'store/reducers/trends/selectors';
import { isWin } from 'utils';
import { getUser } from 'store/reducers/user/selectors';
import { getCompanies } from 'store/reducers/companies/selectors';

const adminRoles = [LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER];

const normalizePlatforms = (platformHosts) => {
  if (!platformHosts || !platformHosts.length) {
    return [];
  }

  return platformHosts.map((pHost) => {
    const { platform, domains } = pHost;
    const domain = domains && domains[0];

    const logoLink = typeof domain === 'string'
      ? `/img/post-logos/${domain.split('.').join('-')}-logo.svg` : '';

    return {
      name: platform,
      value: platform,
      logoLink,
    };
  }).sort((a, b) => a.name.localeCompare(b.name));
};

const normalizeCompanies = (companies) => {
  if (!companies || !companies.length) {
    return [];
  }

  return companies.map(({ _id, title }) => ({ name: title, value: _id }));
};

const initialSearchState = {
  typesKeys: '',
  countries: '',
};

const useTrendAsideFilters = () => {
  const [parsersDomains, setParsersDomains] = useState(null);
  const [allPlatforms, setAllPlatforms] = useState([]);
  const [filterSearchState, setFilterSearchState] = useState(initialSearchState);

  const dispatch = useDispatch();
  const isWindows = isWin();
  const allTypesKeys = useSelector(getTypeKeys);
  const user = useSelector(getUser);
  const allFilters = useSelector(getTrendFilters);
  const excludeWords = useSelector(getExcludeWords) || [];
  const companies = useSelector(getCompanies);

  useEffect(() => {
    if (adminRoles.includes(user?.role)) {
      dispatch(requestCompanies());
    }

    dispatch(requestTypeKeys());

    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [], platformHosts = [] } = parsersData;
        setParsersDomains(parsersDomains);
        setAllPlatforms(normalizePlatforms(platformHosts));
      }
    });
  }, [dispatch]);

  const typeKeysForFilterData = allTypesKeys
    && allTypesKeys.map((tKey) => ({ name: `${tKey.emoji} ${tKey.title}`, value: tKey._id }));

  const countriesForFilterData = COUNTRIES.map((country) => {
    const { emoji, name, code: value } = country;

    const emojiPrefix = isWindows ? '' : `${emoji} `;

    return {
      name: `${emojiPrefix}${name}`,
      value,
      flag: isWindows && value,
    };
  });

  const companiesForSelect = normalizeCompanies(companies);

  const filterSearchHandler = (filterType, value) => {
    const newFilterSearchState = { ...filterSearchState };
    newFilterSearchState[filterType] = value;
    setFilterSearchState(newFilterSearchState);
  };

  const resetFiltersActive = (allFilters
    && ((allFilters.countries && allFilters.countries.length)
      || (allFilters.platforms && allFilters.platforms.length)
      || (allFilters.typesKeys && allFilters.typesKeys.length)))
    || allFilters.trendDate
    || filterSearchState.typesKeys
    || filterSearchState.countries
    || excludeWords.length;

  const clearSearchState = () => {
    setFilterSearchState(initialSearchState);
  };

  return {
    clearSearchState,
    resetFiltersActive,
    filterSearchHandler,
    countriesForFilterData,
    typeKeysForFilterData,
    parsersDomains,
    allPlatforms,
    allTypesKeys,
    filterSearchState,
    companiesForSelect,
  };
};

export default useTrendAsideFilters;
