import React from 'react';

const EditReportIcon = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.375 11.5H7.625V14H6.375V11.5Z" fill="currentColor" />
    <path d="M3.25 7.75H4.5V14H3.25V7.75Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.75 2.125H12.625C12.9565 2.125 13.2745 2.2567 13.5089 2.49112C13.7433 2.72554 13.875 3.04348 13.875 3.375V10.3634C13.4808 10.2039 13.0617 10.0931 12.625 10.0387V3.375H10.75V5.25H3.25V3.375H1.375V16.5H7.2289C7.36929 16.947 7.57075 17.3669 7.82351 17.75H1.375C1.04348 17.75 0.725537 17.6183 0.491116 17.3839C0.256696 17.1495 0.125 16.8315 0.125 16.5V3.375C0.125 3.04348 0.256696 2.72554 0.491116 2.49112C0.725537 2.2567 1.04348 2.125 1.375 2.125H3.25V1.5C3.25 1.16848 3.3817 0.850537 3.61612 0.616116C3.85054 0.381696 4.16848 0.25 4.5 0.25H9.5C9.83152 0.25 10.1495 0.381696 10.3839 0.616116C10.6183 0.850537 10.75 1.16848 10.75 1.5V2.125ZM9.5 1.5H4.5V4H9.5V1.5Z" fill="currentColor" />
    <path d="M14.0036 14.4048L11.0292 17.3798C10.8696 17.5393 10.6698 17.6524 10.4509 17.7071L9.30987 17.9925C9.26809 18.003 9.2243 18.0025 9.18277 17.991C9.14125 17.9796 9.1034 17.9575 9.07294 17.9271C9.04248 17.8966 9.02044 17.8587 9.00898 17.8172C8.99751 17.7757 8.99702 17.7319 9.00755 17.6901L9.29293 16.5495C9.34768 16.3304 9.46096 16.1304 9.62066 15.9707L12.5946 12.9957L14.0036 14.4048ZM14.7083 12.2919C14.8951 12.4787 15 12.7321 15 12.9962C15 13.2603 14.8951 13.5137 14.7083 13.7005L14.3557 14.0521L12.9467 12.6436L13.2993 12.2919C13.3918 12.1994 13.5017 12.126 13.6226 12.0759C13.7434 12.0258 13.873 12 14.0038 12C14.1347 12 14.2643 12.0258 14.3851 12.0759C14.506 12.126 14.6158 12.1994 14.7083 12.2919Z" fill="currentColor" />
  </svg>
);

export default EditReportIcon;
