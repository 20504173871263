import React from 'react';
import { useIntl } from 'react-intl';
import Input from 'components/fields/input';
import Select from 'components/fields/select';
import TextEditor from 'pages/create-report/components/text-editor';
import Checkbox from 'components/checkbox';
import FormBox from '../form-box';
import FieldRow from '../field-row';
import styles from './styles.module.scss';

const ReportGeneralInfo = (props) => {
  const intl = useIntl();
  const {
    onChange, data = {}, error, companies,
  } = props;
  const {
    title, description, sharedCompanyId, dontSendEmail,
  } = data;

  const getSelectValue = () => {
    if (companies?.length && sharedCompanyId) {
      const company = companies.find((comp) => comp.value === sharedCompanyId);
      return company?.value;
    }

    return null;
  };

  const reportDescriptionChange = (newText) => { onChange('description', newText); };

  return (
    <FormBox>
      <div className={styles.formRow}>
        <div className={styles.formRowFields}>
          <FieldRow label={intl.formatMessage({ id: 'reportName' })}>
            <Input
              name="title"
              placeholder={intl.formatMessage({ id: 'reportNamePlaceholder' })}
              fullWidth
              value={title}
              onChange={onChange}
              errorMessage={error && error.title}
            />
          </FieldRow>
          <FieldRow label={intl.formatMessage({ id: 'shareWith' })}>
            <Select
              placeholder={intl.formatMessage({ id: 'enterCompanyName' })}
              fullWidth
              value={getSelectValue()}
              options={companies}
              onChange={
                (name, value) => {
                  onChange('sharedCompanyId', value);
                }
              }
              errorMessage={error && error.sharedCompanyId}
            />
          </FieldRow>
          <FieldRow>
            <Checkbox
              componentStyle={{ container: styles.checkboxContainer }}
              checked={!!dontSendEmail}
              onChange={({ checked }) => onChange('dontSendEmail', checked)}
              title={intl.formatMessage({ id: 'dontSendEmail' })}
            />
          </FieldRow>
        </div>
        <div className={styles.formRowFields}>
          <FieldRow label={intl.formatMessage({ id: 'description' })}>
            <div className={styles.textEditorWrap}>
              <TextEditor
                initialText={description}
                onChange={reportDescriptionChange}
                placeholder={intl.formatMessage({ id: 'shortReportDescription' })}
              />
            </div>
          </FieldRow>
        </div>
      </div>
    </FormBox>
  );
};

export default ReportGeneralInfo;
