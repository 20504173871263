import { sortData } from 'utils';

export const getTypeKeysLoading = (state) => state.typeKeys.loading;
export const getTypeKeysFilter = (state) => state.typeKeys.filters;

export const getTypeKeys = (state) => {
  const { typeKeys } = state;
  const { data = [], filters = {} } = typeKeys;

  const { searchTypeKey, sortingNameDirection } = filters;

  const filteredByName = data.filter(({ title }) => {
    if (!searchTypeKey) {
      return true;
    }

    return title.toLowerCase().includes(searchTypeKey.trim().toLowerCase());
  });

  if (!sortingNameDirection) {
    return filteredByName;
  }

  return sortData(filteredByName, 'title', sortingNameDirection);
};
