import React, { useState } from 'react';
import classNames from 'classnames';
import CollapseIcon from 'components/svg-icons/collapse-icon';
import { useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const CollapseComponent = ({ children, name, expanded = true }) => {
  const [collapsed, setCollapsed] = useState(!expanded);
  const isClient = useSelector(getIsClient);

  const containerClassNames = classNames(
    styles.collapseContainer,
    { [styles.collapseContainerClient]: isClient },
  );

  const separatorClassNames = classNames({
    [styles.separator]: isClient,
    [styles.transparent]: !isClient || collapsed,
  });

  return (
    <div className={containerClassNames}>
      <div className={styles.kymIndexesTitle} onClick={() => setCollapsed(!collapsed)}>
        <div className={classNames(styles.kymTitle, { [styles.kymTitleClient]: isClient })}>
          {name}
        </div>
        <div
          className={classNames(styles.collapseButton, { [styles.collapsed]: collapsed })}
        >
          <CollapseIcon />
        </div>
      </div>
      <div className={separatorClassNames} />
      <div
        className={classNames(styles.collapseInnerContainer, { [styles.collapsed]: collapsed })}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapseComponent;
