import LikeIcon from 'components/svg-icons/like-icon';
import ShareIcon from 'components/svg-icons/share-icon';
import CommentIcon from 'components/svg-icons/comment-icon';
import ViewIcon from 'components/svg-icons/view-icon';
import { SOCIAL_STAT_TYPES, BIG_NUMS } from 'consts';

const {
  THOUSAND, MILLION, BILLION, TRILLION,
} = BIG_NUMS;

const {
  LIKE, SHARE, COMMENT, VIEW,
} = SOCIAL_STAT_TYPES;

export const iconComponents = {
  [LIKE]: LikeIcon,
  [SHARE]: ShareIcon,
  [COMMENT]: CommentIcon,
  [VIEW]: ViewIcon,
};

export const socialStatsFormatter = (likeCount, cortege = false) => {
  if (!Number.isNaN(likeCount)) {
    if (likeCount < THOUSAND) {
      if (cortege) {
        return [likeCount, ''];
      }

      return likeCount;
    }
    if (likeCount >= THOUSAND && likeCount < MILLION) {
      let nc = (likeCount / THOUSAND).toFixed(1);
      if (nc.endsWith('.0')) {
        nc = nc.slice(0, -2);
      }

      if (cortege) {
        return [nc, 'K'];
      }

      return `${nc}K`;
    }
    if (likeCount >= MILLION && likeCount < BILLION) {
      let nc = (likeCount / MILLION).toFixed(1);
      if (nc.endsWith('.0')) {
        nc = nc.slice(0, -2);
      }

      if (cortege) {
        return [nc, 'M'];
      }

      return `${nc}M`;
    }

    if (likeCount >= BILLION && likeCount < TRILLION) {
      let nc = (likeCount / BILLION).toFixed(1);
      if (nc.endsWith('.0')) {
        nc = nc.slice(0, -2);
      }

      if (cortege) {
        return [nc, 'B'];
      }

      return `${nc}B`;
    }
  }
  return '0';
};
