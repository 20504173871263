import { sortData } from 'utils';

export const getCategoriesLoading = (state) => state.categories.loading;
export const getCategoriesFilter = (state) => state.categories.filters;

export const getCategories = (state) => {
  const { categories } = state;
  const { data = [], filters = {} } = categories;

  const { searchCategory, sortingNameDirection } = filters;

  const filteredByName = data.filter(({ title }) => {
    if (!searchCategory) {
      return true;
    }

    return title.toLowerCase().includes(searchCategory.trim().toLowerCase());
  });

  if (!sortingNameDirection) {
    return filteredByName;
  }

  return sortData(filteredByName, 'title', sortingNameDirection);
};
