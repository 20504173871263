import classNames from 'classnames';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import TrendFilters from 'pages/trends/filters';
import InfiniteScroll from 'components/infinity-scroll';
import { getIsClient, getIsAdmin } from 'store/reducers/general/selectors';
import TrendWidget from 'components/trend-widget';
import ClientTrendWidget from 'components/client-trend-widget';
import CloseIcon from 'components/svg-icons/close-icon';
import {
  clearTrendFilters,
  removeTrend,
  requestTrends,
  resetTrendFilters,
  setCollectionPopup, setTrendFilter,
} from 'store/reducers/trends/actions';
import { getCollectionPopup, getTrendFilters, getTrendsState } from 'store/reducers/trends/selectors';
import ApiService from 'services/apiService';
import { getImages } from 'utils';
import { getUser } from 'store/reducers/user/selectors';
import TrendsSideFilter from 'pages/trends/aside-filters/trendsSideFilter';
import TrendsDateFilter from 'pages/trends/aside-filters/trendsDateFilter';
import Button from 'components/button';
import { COLOR_THEMES, ROLE_THEMES, CABINET_ROUTES } from 'consts';
import { useParams, useLocation } from 'react-router-dom';
import CollectionInfoBlock from 'pages/trends/collection-info';
import { requestCollection, resetCollection } from 'store/reducers/collections/actions';
import { getCollection, getCollectionLoadError } from 'store/reducers/collections/selectors';
import AddCollectionPopup from 'components/add-collection-popup';
import CreateCollectionPopup from 'components/create-collection-popup';
import ConfirmPopup from 'components/confirm-popup';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import useTrendAsideFilters from 'hooks/useTrendAsideFilters';
import PeopleNoResults from 'pages/people/people-no-results';
import EmptyListMessage from 'components/empty-list-message';
import styles from './styles.module.scss';

const { PRIMARY, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;
const { DARK, LIGHT } = ROLE_THEMES;

const TrendsPage = ({ isCollection = false }) => {
  const dispatch = useDispatch();
  const isClient = useSelector(getIsClient);
  const isAdmin = useSelector(getIsAdmin);
  const {
    search: trendsSearch = '',
    status = '',
    countries = '',
    platforms = '',
    typesKeys = '',
  } = useSelector(getTrendFilters);
  const intl = useIntl();
  const { navigate } = UseRoleNavigation();
  const { id: collectionId } = useParams();
  const location = useLocation();

  const { search } = location;
  const query = new URLSearchParams(search);
  const searchString = query.get('search');

  const {
    clearSearchState,
    resetFiltersActive,
    filterSearchHandler,
    countriesForFilterData,
    typeKeysForFilterData,
    parsersDomains,
    allPlatforms,
    allTypesKeys,
    filterSearchState,
  } = useTrendAsideFilters();

  useEffect(() => {
    return () => {
      dispatch(clearTrendFilters());
      dispatch(resetCollection());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!searchString) {
      return;
    }

    dispatch(setTrendFilter('search', searchString));
  }, [dispatch, searchString]);

  useEffect(() => {
    dispatch(resetCollection());
    dispatch(setCollectionPopup(null));
  }, [collectionId, dispatch]);

  const user = useSelector(getUser);
  const collectionData = useSelector(getCollection);
  const collectionLoadError = useSelector(getCollectionLoadError);
  const collectionPopup = useSelector(getCollectionPopup);
  const isOwnCollection = user && user._id === collectionData?.creatorId;

  const closeCollectionPopups = () => {
    dispatch(setCollectionPopup(null));
  };

  const handleCreateCollection = () => {
    dispatch(setCollectionPopup('createCollection', collectionPopup.data));
  };

  const handleDeleteFromCollection = () => {
    const trendId = collectionPopup?.data?.itemId;
    if (!collectionId || !trendId) {
      return;
    }

    ApiService.deleteTrendCollection(collectionId, trendId)
      .then(() => {
        dispatch(removeTrend(trendId));
        dispatch(requestCollection(collectionId));
        closeCollectionPopups();
      })
      .catch(() => {
        closeCollectionPopups();
      });
  };

  const resetHandler = () => {
    dispatch(resetTrendFilters(collectionId));
    clearSearchState();
  };

  useEffect(() => {
    const errorCollectionId = collectionLoadError?.collectionId;

    if (errorCollectionId && errorCollectionId === collectionId) {
      navigate(CABINET_ROUTES.COLLECTIONS);
    }
  }, [collectionLoadError]);

  useEffect(() => {
    if (isCollection && collectionId) {
      dispatch(requestCollection(collectionId));
    }
  }, [dispatch]);

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  const TrendComponent = isClient ? ClientTrendWidget : TrendWidget;
  const isMessageEmpty = trendsSearch
    || searchString
    || status
    || typesKeys?.length
    || countries?.length
    || platforms?.length;

  const ListComponent = ({ data, onRemove, isCollection }) => {
    const { image, firstPostLink } = data;
    const { postImageUrl } = getImages(image, firstPostLink, parsersDomains);

    return (
      <div className={styles.itemContainer} key={`trend_id_${data._id}`}>
        <TrendComponent
          onRemove={onRemove}
          trend={data}
          imgLink={postImageUrl}
          typeKeys={allTypesKeys}
          collectionId={collectionId}
          isCollection={isCollection}
          isOwnCollection={isOwnCollection}
          isAdmin={isAdmin}
        />
      </div>
    );
  };

  return (
    <>
      { collectionPopup && collectionPopup.name === 'addCollection' && (
        <AddCollectionPopup
          onClose={closeCollectionPopups}
          handleCreateCollection={handleCreateCollection}
          {...collectionPopup.data}
        />
      ) }

      { collectionPopup && collectionPopup.name === 'createCollection' && (
        <CreateCollectionPopup
          onClose={closeCollectionPopups}
          isClient={isClient}
          {...collectionPopup.data}
        />
      ) }

      { collectionPopup && collectionPopup.name === 'deleteTrendCollection' && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deleteTrendCollectionTitle' })}
          text={intl.formatMessage({ id: 'deleteTrendCollection' })}
          submitText={intl.formatMessage({ id: 'yes' })}
          onCancel={closeCollectionPopups}
          colorTheme={isClient ? DARK : LIGHT}
          submitFunction={handleDeleteFromCollection}
        />
      ) }

      <HeadingFilters
        isCollection={isCollection}
        title={!isCollection && <FormattedMessage id="trends" />}
        FiltersComponent={<TrendFilters collectionId={collectionId} />}
      />
      <div className="container">
        <div className={styles.reportsWrapper}>
          <div className={styles.filters}>
            <div className={classNames(styles.filters, { [styles.filtersClient]: isClient })}>
              <div className={styles.filtersTopPanel}>
                <div className={styles.filtersTopTitle}>Filters</div>
                <Button
                  disabled={!resetFiltersActive}
                  colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : PRIMARY}
                  onClick={resetHandler}
                  icon={<CloseIcon />}
                >
                  Reset
                </Button>
              </div>

              <TrendsDateFilter
                filterType="trendDate"
                title={intl.formatMessage({ id: 'filtersDateRange' })}
                collectionId={isCollection && collectionId}
              />

              <TrendsSideFilter
                filterType="platforms"
                title={intl.formatMessage({ id: 'filtersPlatforms' })}
                data={allPlatforms}
                collectionId={isCollection && collectionId}
              />

              <TrendsSideFilter
                filterType="typesKeys"
                title={intl.formatMessage({ id: 'filtersTypes' })}
                data={typeKeysForFilterData}
                withSearch
                search={filterSearchState.typesKeys}
                searchHandler={filterSearchHandler}
                collectionId={isCollection && collectionId}
              />

              <TrendsSideFilter
                filterType="countries"
                title={intl.formatMessage({ id: 'filtersCountry' })}
                data={countriesForFilterData}
                withSearch
                search={filterSearchState.countries}
                searchHandler={filterSearchHandler}
                collectionId={isCollection && collectionId}
              />
            </div>
          </div>

          <div className={styles.reportsContent}>
            {collectionId && collectionData && (
              <CollectionInfoBlock
                collectionData={collectionData}
                domains={parsersDomains}
                isClient={isClient}
                isAdmin={isAdmin}
              />
            )}
            {(!collectionId || collectionData)
            && (
            <InfiniteScroll
              querySearch={searchString}
              key={`infinite-scroll${collectionId}`}
              RootComponent={RootList}
              RenderComponent={ListComponent}
              dataSelector={getTrendsState}
              loadAction={requestTrends}
              parentId={isCollection && collectionId}
              emptyMessage={isMessageEmpty
                ? <PeopleNoResults message={intl.formatMessage({ id: 'noTrends' })} />
                : <EmptyListMessage messageId={isCollection ? 'emptyCollection' : 'emptyTrends'} />}
            />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendsPage;
