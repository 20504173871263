import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCommentForm,
  putNewComment,
  updateCommentInList,
  removeComment,
} from 'store/reducers/trend-comments/actions';
import WsApiService, { WS_ACTIONS } from 'services/wsApiService';
import { FORM_COMMENT_WRAPPER_ID } from '../comment-form';
import CommentItem from './comment-item';

const isTrendIdEquals = (currentTrendId, commentTrendId) => {
  return commentTrendId && currentTrendId && commentTrendId.trim() === currentTrendId.trim();
};

const isFilterCompanyEquals = (filterCompanyId, commentCompanyId) => {
  if (!filterCompanyId) {
    return true;
  }

  return filterCompanyId === commentCompanyId;
};

const CommentsList = (props) => {
  const {
    trendId, imgOrigin, list = [], filterCompanyId,
  } = props;
  const dispatch = useDispatch();

  const onReply = (comment) => {
    const formWrapper = document.getElementById(FORM_COMMENT_WRAPPER_ID);
    window.scrollTo({
      behavior: 'smooth',
      top: formWrapper.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
    });
    dispatch(setCommentForm('repliedComment', comment));
  };

  const onCreateComment = useCallback((data) => {
    const comment = data?.comment;
    const commentTrendId = comment?.trendId;

    if (
      isTrendIdEquals(trendId, commentTrendId)
      && isFilterCompanyEquals(filterCompanyId, comment?.user?.companyId)
    ) {
      dispatch(putNewComment(data.comment));
    }
  }, [dispatch, trendId, filterCompanyId]);
  const onEditComment = useCallback((data) => {
    const commentTrendId = data?.trendId;

    if (isTrendIdEquals(trendId, commentTrendId)) {
      dispatch(updateCommentInList(data));
    }
  }, [dispatch, trendId]);
  const onDeleteComment = useCallback((data) => {
    if (data.deleted) {
      dispatch(removeComment(data.id));
    }
  }, [dispatch]);

  const actions = useMemo(() => {
    return {
      [WS_ACTIONS.COMMENTS.CREATE_COMMENT_TREND]: onCreateComment,
      [WS_ACTIONS.COMMENTS.EDIT_COMMENT_TREND]: onEditComment,
      [WS_ACTIONS.COMMENTS.DELETE_COMMENT_TREND]: onDeleteComment,
      [WS_ACTIONS.COMMENTS.LIKE_COMMENT_TREND]: onEditComment,
      [WS_ACTIONS.COMMENTS.UNLIKE_COMMENT_TREND]: onEditComment,
    };
  }, [onCreateComment, onEditComment]);

  const commentListener = useCallback((action, data) => {
    if (!actions[action]) {
      return;
    }

    actions[action](data);
  }, [actions]);

  useEffect(() => {
    WsApiService.registerCommentListener(trendId, commentListener);

    return () => {
      WsApiService.deleteCommentListener(trendId);
    };
  }, [trendId, commentListener]);

  return (
    <div>
      {list.map((item) => {
        return (
          <CommentItem imgOrigin={imgOrigin} key={item._id} comment={item} onReply={onReply} />
        );
      })}
    </div>
  );
};

export default React.memo(CommentsList);
