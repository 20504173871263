import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import EmptyPersonIcon from 'components/svg-icons/empty-person-icon';
import { getIsClient } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const shiftX = 26;
const avaFullWidth = 56;
const avaWidth = avaFullWidth - shiftX;

const CharacterChainWidget = (props) => {
  const {
    peopleAvatars = [],
    windowWidth,
    capacity,
  } = props;

  const [restCount, setRestCount] = useState(0);
  const containerRef = useRef();
  const s3Domain = useSelector(getS3Domain);
  const s3 = s3Domain?.startsWith('http') ? s3Domain : `//${s3Domain}`;
  const isClient = useSelector(getIsClient);

  useEffect(() => {
    if (!windowWidth || capacity) {
      return;
    }

    let containerWidth = containerRef?.current?.clientWidth;

    if (!containerWidth) { return; }

    containerWidth = parseInt(containerWidth, 10);
    const showCount = Math.floor((containerWidth - shiftX) / avaWidth);
    const newRestCount = showCount < peopleAvatars.length
      ? peopleAvatars.length - showCount + 1
      : 0;

    setRestCount(newRestCount);
  }, [windowWidth]);

  const avatars = peopleAvatars.map((link) => {
    return (
      <div
        className={classNames(styles.avatarWrap)}
      >
        {link ? <img src={`${s3}/${link}`} alt="" /> : <EmptyPersonIcon />}
      </div>
    );
  }).slice(0, capacity || peopleAvatars.length - restCount);

  return (
    <div
      ref={containerRef}
      className={classNames(
        styles.characterChainContainer,
        { [styles.clientStyle]: isClient },
        { [styles.capa4]: capacity === 4 },
        { [styles.capa3]: capacity === 3 },
      )}
    >
      {avatars}
      {!!restCount && (
        <div className={classNames(styles.avatarWrap, styles.avatarLastWrap)}>
          <div>
            +{restCount}
          </div>
        </div>
      )}
    </div>
  );
};

export default CharacterChainWidget;
