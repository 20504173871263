import ApiService from 'services/apiService';
import WsApiService from 'services/wsApiService';
import {
  SET_COMMENT_FORM,
  SET_COMMENTS_DATA,
  SET_COMMENTS_LIST_LOADING,
  RESET_COMMENT_FORM,
  PUT_NEW_COMMENT,
  UPDATE_COMMENT_IN_LIST,
  REMOVE_COMMENT,
} from './action.types';

// eslint-disable-next-line no-unused-vars
const setCommentsListLoading = (value) => ({
  type: SET_COMMENTS_LIST_LOADING,
  payload: value,
});

export const setCommentsData = (data) => ({
  type: SET_COMMENTS_DATA,
  payload: data,
});

export const putNewComment = (data) => ({
  type: PUT_NEW_COMMENT,
  payload: data,
});

export const setCommentForm = (fieldName, fieldValue) => ({
  type: SET_COMMENT_FORM,
  payload: { fieldName, fieldValue },
});

export const resetCommentForm = () => ({
  type: RESET_COMMENT_FORM,
});

export const updateCommentInList = (comment) => ({
  type: UPDATE_COMMENT_IN_LIST,
  payload: comment,
});

export const likeComment = (commentId, isWs = false) => (dispatch) => {
  if (isWs) {
    return WsApiService.likeComment({ id: commentId }).then((comment) => {
      if (!comment) {
        return;
      }

      dispatch(updateCommentInList(comment));
    });
  }

  return ApiService.likeComment(commentId).then((comment) => {
    if (!comment) {
      return;
    }

    dispatch(updateCommentInList(comment));
  });
};

export const unlikeComment = (commentId, isWs = false) => (dispatch) => {
  if (isWs) {
    return WsApiService.unLikeComment({ id: commentId }).then((comment) => {
      if (!comment) {
        return;
      }

      dispatch(updateCommentInList(comment));
    });
  }

  return ApiService.unlikeComment(commentId).then((comment) => {
    if (!comment) {
      return;
    }

    dispatch(updateCommentInList(comment));
  });
};

export const sendCommentForm = (trendId, commentData, isWs = false) => (dispatch) => {
  const data = {
    trendId,
    text: commentData.text || '',
    repliedId: commentData?.repliedComment?._id,
    imgs: commentData?.imgs,
  };

  if (isWs) {
    return WsApiService.sendComment(data).then((response) => {
      const commentTrendId = response?.comment?.trendId;

      if (commentTrendId && commentTrendId === trendId) {
        dispatch(putNewComment(response.comment));
      }
    });
  }

  return ApiService.sendComment(data).then((res) => {
    dispatch(putNewComment(res.comment));
    dispatch(resetCommentForm());
  });
};

export const editCommentForm = (trendId, commentData, isWs = false) => (dispatch) => {
  const data = {
    trendId,
    id: commentData.id,
    text: commentData.text || '',
    repliedId: commentData?.repliedComment?._id,
    imgs: commentData?.imgs,
  };

  if (isWs) {
    return WsApiService.editComment(data).then((response) => {
      dispatch(updateCommentInList(response));
    });
  }

  return ApiService.editComment(commentData.id, data).then((response) => {
    dispatch(updateCommentInList(response));
  });
};

export const getCommentsList = (trendId, filters) => (dispatch) => {
  ApiService.getTrendComments(trendId, filters).then((res) => {
    dispatch(setCommentsData(res));
  });
};

export const removeComment = (id) => ({ type: REMOVE_COMMENT, payload: { id } });
