import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { socialLinksConfig } from 'pages/people/top-filters/social-links-config';
import Input from 'components/fields/input';
import styles from './styles.module.scss';

const SocialLinksForm = (props) => {
  const { data = [], onChange = () => {}, errors = [] } = props;

  const onChangeValue = (name, value) => {
    let newData = data.filter((item) => item.name !== name);
    const findItem = socialLinksConfig[name];

    if (value && findItem) {
      newData = [...newData, {
        name,
        value: {
          link: value,
          domains: findItem.domains,
          name,
        },
      }];
    }

    onChange('socialLinks', newData);
  };

  const innerFields = Object
    .keys(socialLinksConfig)
    .map((fieldName) => {
      const { name, icon } = socialLinksConfig[fieldName];

      let socialLink;
      let errorMessage;

      data.forEach((item) => {
        if (item.name !== fieldName) {
          return;
        }

        socialLink = item;
      });

      errors.forEach((err) => {
        const { value = '' } = err;
        const [errFieldName, message] = value.split('::');

        if (errFieldName !== fieldName) {
          return;
        }

        errorMessage = message;
      });

      const value = socialLink?.value?.link || '';

      return (
        <div
          className={classNames(styles.socialField, { [styles.filledField]: value })}
          key={`social-link-field-${fieldName}`}
        >
          <Input
            name={fieldName}
            icon={icon()}
            placeholder={name}
            value={value}
            onChange={onChangeValue}
            errorMessage={errorMessage}
          />
        </div>
      );
    });

  return (
    <div className={styles.socialContainer}>
      <div className={styles.socialTitle}>
        <FormattedMessage id="socialMedia" />
      </div>
      <div className={styles.socialFields}>
        {innerFields}
      </div>
    </div>
  );
};

export default SocialLinksForm;
