import {
  SET_STAFF_FILTER,
  SET_STAFF,
  SET_STAFF_LOADING,
  SET_STAFF_ERROR,
} from './action.types';

const initialFilters = {
  searchEmail: '',
  sortEmailDest: '',
};

const initialState = {
  data: [],
  loading: true,
  error: false,
  filters: { ...initialFilters },
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF:
      return { ...state, data: action.staff };
    case SET_STAFF_ERROR:
      return { ...state, error: action.error };
    case SET_STAFF_LOADING:
      return { ...state, loading: action.loading };
    case SET_STAFF_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

export default staffReducer;
