import apiService from 'services/apiService';
import {
  SET_CATEGORIES,
  SET_CATEGORIES_LOADING,
  SET_CATEGORIES_FILTER,
  SET_CATEGORIES_ERROR,
} from './action.types';

const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  categories,
});

export const setCategoriesError = (error) => ({
  type: SET_CATEGORIES_ERROR,
  error,
});

export const setCategoriesLoading = (loading) => ({
  type: SET_CATEGORIES_LOADING,
  loading,
});

export const setCategoriesFilter = (name, value) => ({
  type: SET_CATEGORIES_FILTER,
  name,
  value,
});

export const requestCategories = () => (dispatch) => {
  dispatch(setCategoriesLoading(true));
  apiService.getCategories()
    .then((data) => {
      dispatch(setCategories(data));
      dispatch(setCategoriesLoading(false));
    });
};

export const addCategory = (data) => (dispatch) => apiService.addCategory(data)
  .then((data) => {
    dispatch(setCategoriesError(null));

    if (!data.error) {
      dispatch(requestCategories());
      return;
    }

    dispatch(setCategoriesLoading(false));
    dispatch(setCategoriesError(data.error));
  });

export const editCategory = (id, data) => (dispatch) => apiService
  .editCategory(id, data)
  .then((res) => {
    dispatch(setCategoriesError(null));

    if (!res.error) {
      dispatch(requestCategories());
      return;
    }

    dispatch(setCategoriesLoading(false));
    dispatch(setCategoriesError(res.error));
  });

export const deleteCategory = (id) => (dispatch) => apiService
  .deleteCategory(id)
  .then(() => {
    dispatch(requestCategories());
  });
