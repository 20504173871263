import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ROLE_THEMES } from 'consts';
import { getUser } from 'store/reducers/user/selectors';
import UserItem from './item';
import styles from './styles.module.scss';

const UsersList = (props) => {
  const { LIGHT } = ROLE_THEMES;
  const { colorTheme = LIGHT, users = [], changeRoles = false } = props;
  const currentUser = useSelector(getUser);

  if (!users.length) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles.usersList, styles[colorTheme])}>
        {users.map((user) => (
          <div className={classNames(styles.userItem, styles[colorTheme])} key={user._id}>
            <UserItem
              colorTheme={colorTheme}
              data={user}
              changeRoles={changeRoles}
              disableActions={currentUser?._id === user._id}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default UsersList;
