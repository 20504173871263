import React from 'react';

const KymIndexIcon = () => {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_1023_9333)">
        <path d="M5.5 0H14.5L9 7H16.5L4.5 20L7 10.5H0L5.5 0Z" fill="url(#paint0_radial_1023_9333)" />
      </g>
      <defs>
        <filter id="filter0_i_1023_9333" x="-2" y="0" width="18.5" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2" dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_9333" />
        </filter>
        <radialGradient id="paint0_radial_1023_9333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.06522 27.381) rotate(-81.8) scale(27.6638 10.6351)">
          <stop stopColor="#1CA5E0" />
          <stop offset="1" stopColor="#3439C1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default KymIndexIcon;
