import React from 'react';

const ClientLogoIcon = () => (
  <svg width="198" height="48" viewBox="0 0 198 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="106.128" y="21.6709" width="91.8723" height="26.3292" rx="4" fill="url(#paint0_radial_173:590)" />
    <path
      d="M6.04136 0V2.27241H4.64207V7.24052L8.61782 3.47545C8.988 3.13385 9.28415 2.87393 9.50626 2.6957C9.74317 2.51747 9.96528 2.39123 10.1726 2.31697V2.27241H8.52898V0H14.8369V2.27241H13.6153L7.88486 7.41875L13.8818 13.3226H15.1256V15.595H8.28466V13.3226H10.106V13.278C9.86903 13.1146 9.65433 12.9661 9.46183 12.8324C9.28415 12.6988 9.14348 12.5799 9.03983 12.476L4.64207 8.17622V13.3226H6.04136V15.595H0V13.3226H1.39929V2.27241H0V0H6.04136Z"
      fill="white"
    />
    <path
      d="M28.2229 13.3226H29.5556V15.595H23.8474V13.3226H25.1134V8.46584C25.1134 7.73808 24.9283 7.20339 24.5581 6.86179C24.2027 6.52018 23.7141 6.34938 23.0922 6.34938C22.2038 6.34938 21.53 6.63158 21.071 7.19596C20.612 7.7455 20.3825 8.46584 20.3825 9.35698V13.3226H21.7151V15.595H16.1402V13.3226H17.2729V6.21571H15.7626V3.9433H20.3158C20.3158 4.37402 20.3084 4.77503 20.2936 5.14634C20.2788 5.51765 20.227 5.91866 20.1382 6.34938H20.1826C20.4787 5.57706 20.9674 4.93841 21.6485 4.43343C22.3296 3.92845 23.181 3.67596 24.2027 3.67596C24.8987 3.67596 25.4984 3.78735 26.0018 4.01013C26.5053 4.23292 26.9199 4.54482 27.2456 4.94583C27.5862 5.33199 27.8305 5.79984 27.9786 6.34938C28.1415 6.88407 28.2229 7.47816 28.2229 8.13166V13.3226Z"
      fill="white"
    />
    <path
      d="M37.1421 13.3226C38.1194 13.3226 38.8154 13.0329 39.23 12.4537C39.6594 11.8596 39.8741 11.0279 39.8741 9.9585C39.8741 8.69605 39.6594 7.76035 39.23 7.15141C38.8006 6.52761 38.0972 6.21571 37.1199 6.21571C36.3203 6.21571 35.6688 6.52761 35.1654 7.15141C34.6619 7.76035 34.4102 8.69605 34.4102 9.9585C34.4102 11.0279 34.6175 11.8596 35.0321 12.4537C35.4615 13.0329 36.1649 13.3226 37.1421 13.3226ZM43.2501 9.89167C43.2501 10.768 43.1021 11.57 42.8059 12.2977C42.5098 13.0255 42.0952 13.6567 41.5621 14.1914C41.029 14.7113 40.3849 15.1197 39.6298 15.4167C38.8894 15.7138 38.0602 15.8623 37.1421 15.8623C35.1876 15.8623 33.6772 15.3425 32.6111 14.3028C31.5598 13.2631 31.0341 11.7928 31.0341 9.89167C31.0341 9.03023 31.1822 8.22078 31.4784 7.46331C31.7745 6.70584 32.1891 6.05233 32.7222 5.5028C33.2552 4.93841 33.892 4.49284 34.6323 4.16609C35.3875 3.83933 36.2241 3.67596 37.1421 3.67596C39.1411 3.67596 40.6589 4.21064 41.6954 5.28001C42.7319 6.33453 43.2501 7.87175 43.2501 9.89167Z"
      fill="white"
    />
    <path
      d="M49.1522 3.9433V6.21571H48.0638C48.1082 6.28997 48.1453 6.36423 48.1749 6.43849C48.2193 6.4979 48.2563 6.57217 48.2859 6.66128C48.3303 6.75039 48.3748 6.86921 48.4192 7.01774C48.4784 7.15141 48.5525 7.32964 48.6413 7.55242L50.0406 11.4289C50.0702 11.5031 50.122 11.6739 50.1961 11.9413C50.2701 12.2086 50.3367 12.4908 50.396 12.7879H50.4404C50.4996 12.5354 50.5885 12.2755 50.7069 12.0081C50.8254 11.7259 50.9068 11.5329 50.9512 11.4289L52.6837 7.55242L52.1506 6.21571H50.9068V3.9433H56.5484V6.21571H55.349C55.4823 6.42364 55.5933 6.65385 55.6822 6.90634C55.771 7.15883 55.8672 7.41875 55.9709 7.68609L57.3035 11.3398C57.3628 11.518 57.4072 11.6591 57.4368 11.7631C57.4812 11.8522 57.5108 11.9339 57.5257 12.0081C57.5553 12.0824 57.5775 12.1789 57.5923 12.2977C57.6219 12.4017 57.6589 12.5651 57.7033 12.7879H57.7478C57.7922 12.5502 57.8292 12.3572 57.8588 12.2086C57.9032 12.0601 57.9403 11.9413 57.9699 11.8522C57.9995 11.7631 58.0217 11.6888 58.0365 11.6294C58.0513 11.57 58.0735 11.5106 58.1031 11.4512L59.458 7.64153C59.6209 7.16626 59.7393 6.83951 59.8134 6.66128C59.9022 6.48305 59.9837 6.33453 60.0577 6.21571H58.5251V3.9433H64.0779V6.21571H63.0117L58.9916 15.595H55.7488L54.4828 12.2755C54.4087 12.0675 54.3051 11.8002 54.1718 11.4734C54.0534 11.1467 53.9719 10.8422 53.9275 10.56H53.8831C53.8535 10.7531 53.8164 10.9239 53.772 11.0724C53.7276 11.2061 53.6684 11.3695 53.5943 11.5626L51.8397 15.595H48.5969L44.6878 6.21571H43.5994V3.9433H49.1522Z"
      fill="white"
    />
    <path
      d="M75.5982 0V0.980256H73.9324C74.0212 1.06937 74.0879 1.14363 74.1323 1.20304C74.1767 1.2476 74.2137 1.29216 74.2433 1.33671C74.2878 1.38127 74.3248 1.42583 74.3544 1.47038C74.384 1.51494 74.4284 1.58178 74.4877 1.67089L78.6855 7.53014L82.9722 1.58178C83.0018 1.52237 83.0536 1.4481 83.1277 1.35899C83.2017 1.25502 83.3128 1.12878 83.4609 0.980256H81.4174V0H86.1928V0.980256H84.7269L79.3296 8.64407L79.2408 14.6147H81.1731V15.595H76.1979V14.6147H78.0192L78.0858 8.64407L72.4887 0.980256H70.8229V0H75.5982Z"
      fill="white"
    />
    <path
      d="M86.5993 9.86939C86.5993 11.3992 86.9547 12.6022 87.6655 13.4785C88.3762 14.3548 89.3757 14.7929 90.6639 14.7929C91.9522 14.7929 92.9517 14.3548 93.6624 13.4785C94.3732 12.6022 94.7285 11.3992 94.7285 9.86939C94.7285 8.3396 94.3732 7.13655 93.6624 6.26027C92.9517 5.38398 91.9448 4.94583 90.6417 4.94583C89.3831 4.94583 88.391 5.3914 87.6655 6.28255C86.9547 7.15883 86.5993 8.35445 86.5993 9.86939ZM90.6639 3.87646C91.4635 3.87646 92.1891 4.02499 92.8406 4.32203C93.5069 4.60423 94.0696 5.01267 94.5286 5.54735C94.9877 6.06719 95.343 6.69841 95.5948 7.44103C95.8465 8.16879 95.9723 8.97825 95.9723 9.86939C95.9723 10.7605 95.8465 11.5774 95.5948 12.32C95.3578 13.0626 95.0099 13.6939 94.5508 14.2137C94.0918 14.7335 93.5291 15.142 92.8628 15.439C92.2113 15.7212 91.4783 15.8623 90.6639 15.8623C89.8495 15.8623 89.1092 15.7138 88.4428 15.4167C87.7913 15.1197 87.2361 14.7113 86.777 14.1914C86.318 13.6567 85.9626 13.0255 85.7109 12.2977C85.474 11.5551 85.3555 10.7457 85.3555 9.86939C85.3555 8.97825 85.474 8.16879 85.7109 7.44103C85.9626 6.69841 86.318 6.06719 86.777 5.54735C87.2361 5.01267 87.7913 4.60423 88.4428 4.32203C89.1092 4.02499 89.8495 3.87646 90.6639 3.87646Z"
      fill="white"
    />
    <path
      d="M100.997 4.14381L100.908 11.1615C100.893 12.4685 101.123 13.4042 101.597 13.9686C102.085 14.5182 102.84 14.7929 103.862 14.7929C105.047 14.7929 105.95 14.3845 106.572 13.5676C107.194 12.7507 107.519 11.5997 107.549 10.1145L107.638 5.12406H105.595V4.14381H108.793L108.704 14.6147H110.481V15.595H107.571C107.571 15.2534 107.586 14.8524 107.616 14.3919C107.66 13.9315 107.734 13.4191 107.838 12.8547H107.793C107.527 13.7607 107.031 14.4885 106.305 15.038C105.595 15.5875 104.765 15.8623 103.818 15.8623C102.441 15.8623 101.411 15.4984 100.73 14.7707C100.049 14.028 99.7234 12.8473 99.753 11.2284L99.8419 5.12406H97.8873V4.14381H100.997Z"
      fill="white"
    />
    <path
      d="M119.331 3.87646C119.627 3.87646 119.871 3.89874 120.064 3.9433C120.271 3.973 120.56 4.03241 120.93 4.12153L120.752 5.23545C120.53 5.13149 120.308 5.05723 120.086 5.01267C119.878 4.96811 119.634 4.94583 119.353 4.94583C118.82 4.94583 118.324 5.10921 117.865 5.43596C117.406 5.76271 117.006 6.186 116.665 6.70584C116.325 7.22567 116.051 7.81976 115.843 8.48812C115.651 9.14162 115.555 9.80998 115.555 10.4932L115.51 14.6147H117.643V15.595H112.534V14.6147H114.378L114.444 5.12406H112.29V4.14381H115.644C115.644 4.66364 115.629 5.22803 115.599 5.83697C115.57 6.44592 115.525 7.10685 115.466 7.81976H115.51C115.866 6.55731 116.369 5.58448 117.021 4.90128C117.672 4.21807 118.442 3.87646 119.331 3.87646Z"
      fill="white"
    />
    <path
      d="M133.672 0L137.67 10.3372C137.773 10.6046 137.892 10.9165 138.025 11.2729C138.158 11.6145 138.247 12.0081 138.292 12.4537H138.336C138.351 12.3052 138.366 12.1641 138.38 12.0304C138.41 11.8819 138.447 11.7111 138.491 11.518C138.551 11.3101 138.625 11.065 138.714 10.7828C138.817 10.4858 138.95 10.1219 139.113 9.69116L142.734 0H148.375V2.27241H146.754V13.3226H148.309V15.595H142.334V13.3226H143.644V4.34431H143.6C143.541 4.77503 143.437 5.19832 143.289 5.61419C143.141 6.03006 142.985 6.44592 142.823 6.86179L139.491 15.595H136.381L132.85 6.41622C132.717 6.05976 132.576 5.69588 132.428 5.32457C132.28 4.95326 132.191 4.57452 132.161 4.18836H132.117V13.3226H133.738V15.595H128.23V13.3226H129.585V2.27241H127.963V0H133.672Z"
      fill="white"
    />
    <path
      d="M157.635 8.33217C157.635 7.53014 157.427 6.91377 157.013 6.48305C156.598 6.03748 156.013 5.8147 155.258 5.8147C154.577 5.8147 154.029 6.03006 153.615 6.46077C153.215 6.89149 152.978 7.51529 152.904 8.33217H157.635ZM152.926 10.4709C152.97 11.4066 153.318 12.1418 153.97 12.6765C154.636 13.1963 155.525 13.4562 156.635 13.4562C157.346 13.4562 157.975 13.3968 158.523 13.278C159.071 13.1592 159.686 12.9513 160.367 12.6542L160.211 15.1271C159.486 15.3945 158.79 15.5801 158.123 15.6841C157.472 15.8029 156.746 15.8623 155.947 15.8623C154.925 15.8623 154.014 15.7212 153.215 15.439C152.43 15.1568 151.764 14.7558 151.216 14.236C150.683 13.7013 150.268 13.0626 149.972 12.32C149.691 11.5774 149.55 10.7457 149.55 9.82483C149.55 8.88913 149.683 8.04255 149.95 7.28508C150.231 6.52761 150.624 5.88153 151.127 5.34685C151.645 4.81216 152.26 4.40372 152.97 4.12153C153.696 3.82448 154.503 3.67596 155.391 3.67596C156.25 3.67596 157.028 3.82448 157.724 4.12153C158.42 4.40372 159.012 4.82701 159.5 5.3914C159.989 5.95579 160.359 6.66871 160.611 7.53014C160.878 8.37673 161.011 9.35698 161.011 10.4709H152.926Z"
      fill="white"
    />
    <path
      d="M174.165 6.30482C174.446 5.48794 174.905 4.84929 175.542 4.38887C176.193 3.91359 177.03 3.67596 178.051 3.67596C178.747 3.67596 179.347 3.78735 179.851 4.01013C180.354 4.23292 180.769 4.54482 181.094 4.94583C181.435 5.33199 181.679 5.79984 181.827 6.34938C181.99 6.88407 182.072 7.47816 182.072 8.13166V13.3226H183.404V15.595H177.829V13.3226H178.962V8.46584C178.962 7.73808 178.777 7.20339 178.407 6.86179C178.051 6.52018 177.563 6.34938 176.941 6.34938C176.171 6.34938 175.564 6.639 175.12 7.21824C174.69 7.78263 174.475 8.49554 174.475 9.35698V13.3226H175.808V15.595H170.233V13.3226H171.366V8.46584C171.366 7.73808 171.181 7.20339 170.811 6.86179C170.455 6.52018 169.967 6.34938 169.345 6.34938C168.545 6.34938 167.931 6.639 167.501 7.21824C167.087 7.79748 166.879 8.5104 166.879 9.35698V13.3226H168.212V15.595H162.504V13.3226H163.77V6.21571H162.259V3.9433H166.813C166.813 4.35917 166.805 4.76018 166.791 5.14634C166.776 5.51765 166.724 5.90381 166.635 6.30482H166.679C167.005 5.45824 167.509 4.81216 168.19 4.36659C168.886 3.90617 169.722 3.67596 170.7 3.67596C172.654 3.67596 173.794 4.55225 174.12 6.30482H174.165Z"
      fill="white"
    />
    <path
      d="M192.99 8.33217C192.99 7.53014 192.783 6.91377 192.368 6.48305C191.953 6.03748 191.369 5.8147 190.613 5.8147C189.932 5.8147 189.384 6.03006 188.97 6.46077C188.57 6.89149 188.333 7.51529 188.259 8.33217H192.99ZM188.281 10.4709C188.326 11.4066 188.674 12.1418 189.325 12.6765C189.992 13.1963 190.88 13.4562 191.99 13.4562C192.701 13.4562 193.331 13.3968 193.878 13.278C194.426 13.1592 195.041 12.9513 195.722 12.6542L195.566 15.1271C194.841 15.3945 194.145 15.5801 193.479 15.6841C192.827 15.8029 192.102 15.8623 191.302 15.8623C190.28 15.8623 189.37 15.7212 188.57 15.439C187.785 15.1568 187.119 14.7558 186.571 14.236C186.038 13.7013 185.623 13.0626 185.327 12.32C185.046 11.5774 184.905 10.7457 184.905 9.82483C184.905 8.88913 185.038 8.04255 185.305 7.28508C185.586 6.52761 185.979 5.88153 186.482 5.34685C187 4.81216 187.615 4.40372 188.326 4.12153C189.051 3.82448 189.858 3.67596 190.747 3.67596C191.605 3.67596 192.383 3.82448 193.079 4.12153C193.775 4.40372 194.367 4.82701 194.856 5.3914C195.344 5.95579 195.715 6.66871 195.966 7.53014C196.233 8.37673 196.366 9.35698 196.366 10.4709H188.281Z"
      fill="white"
    />
    <path d="M118.209 29.103V40.5866H115.447V29.103H118.209Z" fill="white" />
    <path
      d="M120.611 29.103H123.155L125.663 32.9491C126.111 33.6418 126.541 34.3526 126.953 35.0818C127.377 35.7987 127.704 36.4488 127.935 37.0321H127.971C127.91 36.303 127.874 35.5739 127.862 34.8448C127.85 34.1035 127.844 33.3683 127.844 32.6392V29.103H130.388V40.5866H127.844L125.191 36.6676C124.754 36.0235 124.355 35.3673 123.991 34.699C123.628 34.0306 123.319 33.3258 123.064 32.5845H123.028C123.089 33.3501 123.125 34.0853 123.137 34.7901C123.149 35.4949 123.155 36.2119 123.155 36.941V40.5866H120.611V29.103Z"
      fill="white"
    />
    <path
      d="M136.656 28.8843C137.456 28.8843 138.146 28.9693 138.728 29.1395C139.309 29.2974 139.812 29.4919 140.236 29.7228L139.618 31.7461C139.146 31.503 138.667 31.3147 138.182 31.181C137.71 31.0352 137.256 30.9623 136.82 30.9623C136.262 30.9623 135.838 31.0655 135.547 31.2721C135.257 31.4787 135.111 31.7521 135.111 32.0924C135.111 32.4812 135.251 32.7668 135.529 32.9491C135.808 33.1192 136.238 33.3015 136.82 33.4959L137.892 33.8423C138.316 33.9881 138.709 34.1704 139.073 34.3891C139.436 34.5957 139.745 34.8387 140 35.1182C140.266 35.3977 140.472 35.7137 140.618 36.0661C140.763 36.4185 140.836 36.8013 140.836 37.2144C140.836 37.7005 140.745 38.1623 140.563 38.5997C140.381 39.0251 140.103 39.4018 139.727 39.7299C139.364 40.058 138.897 40.3192 138.328 40.5137C137.771 40.6959 137.11 40.7871 136.347 40.7871C135.632 40.7871 134.948 40.7142 134.294 40.5684C133.651 40.4104 133.058 40.1916 132.513 39.9121L133.131 37.8889C133.651 38.144 134.16 38.3445 134.657 38.4904C135.154 38.6362 135.675 38.7091 136.22 38.7091C136.862 38.7091 137.322 38.6058 137.601 38.3992C137.892 38.1805 138.037 37.8889 138.037 37.5243C138.037 37.4028 138.013 37.2813 137.964 37.1597C137.916 37.0382 137.819 36.9167 137.674 36.7952C137.54 36.6737 137.347 36.5582 137.092 36.4488C136.85 36.3273 136.535 36.2058 136.147 36.0843L135.257 35.7744C134.833 35.6164 134.439 35.4402 134.075 35.2458C133.712 35.0514 133.397 34.8205 133.131 34.5531C132.876 34.2858 132.676 33.9759 132.531 33.6235C132.385 33.2711 132.313 32.8519 132.313 32.3658C132.313 31.8676 132.404 31.4058 132.585 30.9805C132.779 30.5552 133.058 30.1906 133.421 29.8868C133.797 29.5709 134.251 29.3278 134.784 29.1577C135.329 28.9754 135.953 28.8843 136.656 28.8843Z"
      fill="white"
    />
    <path d="M145.486 29.103V40.5866H142.723V29.103H145.486Z" fill="white" />
    <path
      d="M153.539 28.8843C154.266 28.8843 154.908 28.945 155.466 29.0666C156.035 29.1881 156.538 29.3521 156.974 29.5587L156.411 31.3815C155.999 31.1749 155.562 31.0291 155.102 30.944C154.654 30.8468 154.218 30.7982 153.794 30.7982C153.309 30.7982 152.855 30.8772 152.431 31.0352C152.007 31.1931 151.631 31.4423 151.304 31.7825C150.989 32.1106 150.741 32.5299 150.559 33.0402C150.377 33.5506 150.286 34.1582 150.286 34.863C150.286 36.1511 150.565 37.1415 151.122 37.8342C151.692 38.5147 152.503 38.8549 153.557 38.8549C153.812 38.8549 154.042 38.8428 154.248 38.8185C154.454 38.7942 154.611 38.7577 154.72 38.7091V36.2119H152.921V34.1704H157.374V40.0762C156.901 40.2828 156.344 40.4529 155.702 40.5866C155.06 40.7203 154.339 40.7871 153.539 40.7871C152.667 40.7871 151.855 40.6595 151.104 40.4043C150.365 40.1491 149.717 39.7724 149.16 39.2742C148.602 38.7638 148.166 38.144 147.851 37.4149C147.536 36.6737 147.379 35.823 147.379 34.863C147.379 33.903 147.536 33.0524 147.851 32.3111C148.166 31.5577 148.602 30.9319 149.16 30.4336C149.717 29.9233 150.365 29.5405 151.104 29.2853C151.855 29.0179 152.667 28.8843 153.539 28.8843Z"
      fill="white"
    />
    <path
      d="M159.441 40.5866V29.103H162.203V33.5688H166.183V29.103H168.945V40.5866H166.183V35.7197H162.203V40.5866H159.441Z"
      fill="white"
    />
    <path d="M179.272 29.103V31.2539H176.146V40.5866H173.384V31.2539H170.331V29.103H179.272Z" fill="white" />
    <path
      d="M184.501 28.8843C185.3 28.8843 185.991 28.9693 186.573 29.1395C187.154 29.2974 187.657 29.4919 188.081 29.7228L187.463 31.7461C186.99 31.503 186.512 31.3147 186.027 31.181C185.555 31.0352 185.101 30.9623 184.664 30.9623C184.107 30.9623 183.683 31.0655 183.392 31.2721C183.102 31.4787 182.956 31.7521 182.956 32.0924C182.956 32.4812 183.096 32.7668 183.374 32.9491C183.653 33.1192 184.083 33.3015 184.664 33.4959L185.737 33.8423C186.161 33.9881 186.554 34.1704 186.918 34.3891C187.281 34.5957 187.59 34.8387 187.845 35.1182C188.111 35.3977 188.317 35.7137 188.462 36.0661C188.608 36.4185 188.681 36.8013 188.681 37.2144C188.681 37.7005 188.59 38.1623 188.408 38.5997C188.226 39.0251 187.948 39.4018 187.572 39.7299C187.209 40.058 186.742 40.3192 186.173 40.5137C185.615 40.6959 184.955 40.7871 184.192 40.7871C183.477 40.7871 182.793 40.7142 182.138 40.5684C181.496 40.4104 180.903 40.1916 180.358 39.9121L180.975 37.8889C181.496 38.144 182.005 38.3445 182.502 38.4904C182.999 38.6362 183.52 38.7091 184.065 38.7091C184.707 38.7091 185.167 38.6058 185.446 38.3992C185.737 38.1805 185.882 37.8889 185.882 37.5243C185.882 37.4028 185.858 37.2813 185.809 37.1597C185.761 37.0382 185.664 36.9167 185.519 36.7952C185.385 36.6737 185.191 36.5582 184.937 36.4488C184.695 36.3273 184.38 36.2058 183.992 36.0843L183.102 35.7744C182.678 35.6164 182.284 35.4402 181.92 35.2458C181.557 35.0514 181.242 34.8205 180.975 34.5531C180.721 34.2858 180.521 33.9759 180.376 33.6235C180.23 33.2711 180.158 32.8519 180.158 32.3658C180.158 31.8676 180.248 31.4058 180.43 30.9805C180.624 30.5552 180.903 30.1906 181.266 29.8868C181.642 29.5709 182.096 29.3278 182.629 29.1577C183.174 28.9754 183.798 28.8843 184.501 28.8843Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_173:590"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128.763 57.7168) rotate(-58.6383) scale(42.2133 51.0873)"
      >
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default ClientLogoIcon;
