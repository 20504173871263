import React from 'react';
import CategoryItem from './item';
import styles from './styles.module.scss';

const CategoriesList = (props) => {
  const { categories = [] } = props;

  if (!categories.length) {
    return null;
  }

  return (
    <>
      <div className={styles.categoriesList}>
        {categories.map((category) => (
          <div className={styles.categoryItem} key={category._id}>
            <CategoryItem data={category} />
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoriesList;
