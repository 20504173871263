import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { COLOR_THEMES } from 'consts';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import Button from 'components/button';
import SearchIcon from 'components/svg-icons/search-icon';
import { getCategoriesFilter } from 'store/reducers/categories/selectors';
import { setCategoriesFilter } from 'store/reducers/categories/actions';
import AddCategory from '../add-category';

const CategoriesFilters = () => {
  const intl = useIntl();
  const filters = useSelector(getCategoriesFilter);
  const dispatch = useDispatch();

  const [addCategory, setAddCategory] = useState(false);

  const openAddCategoryPopup = () => {
    setAddCategory(true);
  };

  const closeAddCategoryPopup = () => {
    setAddCategory(false);
  };

  const onFilterChange = (name, value) => {
    dispatch(setCategoriesFilter(name, value));
  };

  const { searchCategory, sortingNameDirection } = filters;

  return (
    <>
      { addCategory && <AddCategory onCancel={closeAddCategoryPopup} /> }
      <Button onClick={openAddCategoryPopup} colorTheme={COLOR_THEMES.LIGHT}>{intl.formatMessage({ id: 'addCategory' })}</Button>
      <Select
        onChange={onFilterChange}
        name="sortingNameDirection"
        value={sortingNameDirection}
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'asc' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'desc' },
        ]}
      />
      <Input
        onChange={onFilterChange}
        name="searchCategory"
        value={searchCategory}
        icon={<SearchIcon />}
        placeholder={intl.formatMessage({ id: 'searchCategory' })}
      />
    </>
  );
};

export default CategoriesFilters;
