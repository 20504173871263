import React from 'react';
import { NavLink } from 'react-router-dom';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import styles from './styles.module.scss';

const RouteRoleLink = (props) => {
  const { children, path = '', disabled = false } = props;
  const { rolePath } = UseRoleNavigation();

  if (disabled) {
    return children;
  }

  return (
    <NavLink className={styles.navLink} to={`${rolePath}/${path}`}>{children}</NavLink>
  );
};

export default RouteRoleLink;
