import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { COLOR_THEMES } from 'consts';
import Button from 'components/button';
import SendIcon from 'components/svg-icons/send-icon';
import CloseIcon from 'components/svg-icons/close-icon';
import ReplyIcon from 'components/svg-icons/reply-icon';
import {
  setCommentForm,
  resetCommentForm,
  sendCommentForm,
  editCommentForm,
} from 'store/reducers/trend-comments/actions';
import ApiService from 'services/apiService';
import { getCommentsForm } from 'store/reducers/trend-comments/selectors';
import AddImageIcon from 'components/svg-icons/add-image-icon';
import { acceptedMediaFormats } from 'components/image-uploader';
import AttachedImgItem from 'client/pages/trend/components/trend-comments/attached-img-item';
import EmojiComment from 'components/emoji-comment';
import notificationService from 'services/notificationService';
import CommentText from '../comment-text';
import MentionWrapper from './mention';
import styles from './styles.module.scss';

export const FORM_COMMENT_WRAPPER_ID = 'form-comment-wrapper';

const maxImgSize = 5; // 5mb;
const maxUploadedImgs = 5;

const RepliedComment = (props) => {
  const { commentData, removeReply, imgOrigin } = props;
  if (!commentData) {
    return null;
  }

  const {
    user, text, deleted = false, imgs = [],
  } = commentData;
  const { firstName, lastName } = user || {};

  return (
    <div className={styles.repliedComment}>
      <div className={styles.repliedCommentHeader}>
        <div className={styles.replyHeadTitle}>
          <ReplyIcon /> <FormattedMessage id="replyingTo" />
          {deleted ? (<> <FormattedMessage id="deleted" /></>) : (<> @{firstName} {lastName}</>)}
        </div>
        <div className={styles.removeReplyButton} onClick={removeReply}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.repliedContent}>
        {deleted ? (<FormattedMessage id="commentWasDeleted" />) : <CommentText text={text} />}
      </div>
      {imgs && !!imgs.length && (
        <div className={styles.attachmentsContainer}>
          {imgs.map((imgUrl) => (
            <div key={imgUrl} className={styles.attachedImgWrap}>
              <AttachedImgItem
                origin={imgOrigin}
                imgUrl={imgUrl}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const clearFormData = {
  text: '',
  repliedComment: null,
};

const CommentForm = (props) => {
  const {
    trendId,
    imgOrigin,
    editComment = clearFormData,
    isEdit = false,
    cancelEvent = () => {},
    imgList = [],
  } = props;
  const formDataGlobal = useSelector(getCommentsForm);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ...clearFormData,
    ...{
      id: isEdit ? editComment._id : null,
      text: editComment.text,
      repliedComment: editComment.repliedComment,
    },
  });
  const intl = useIntl();

  const [attachImgList, setAttachImgList] = useState(imgList);
  const [loadingAttachImg, setLoadingAttachImg] = useState(false);

  const dispatch = useDispatch();
  const { text, repliedComment } = formData;
  const clearCommentForm = useCallback(() => {
    setFormData(clearFormData);
    setAttachImgList([]);
    dispatch(resetCommentForm());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      return;
    }

    setFormData({
      ...formData,
      ...{
        repliedComment: formDataGlobal.repliedComment,
      },
    });
  }, [formDataGlobal.repliedComment, isEdit]);

  const isEmptyTextArea = !text.trim();

  const isImagesEdited = () => {
    let isImgEdited = imgList.length !== attachImgList.length;

    if (isImgEdited) {
      return true;
    }

    imgList.forEach((imgUrl) => {
      if (!isImgEdited && !attachImgList.includes(imgUrl)) {
        isImgEdited = true;
      }
    });

    return isImgEdited;
  };

  const sendButtonStyles = {
    mainContainer: classNames(
      styles.sendButton,
    ),
  };

  const onSubmit = () => {
    setLoading(true);

    const commentRequest = isEdit ? editCommentForm : sendCommentForm;

    const imgs = [];
    attachImgList.forEach(({ url, temp }) => {
      if (!temp) {
        imgs.push(url);
      }
    });

    const formDataWithImgs = {
      ...formData,
      imgs,
    };

    dispatch(commentRequest(trendId, formDataWithImgs, true)).then(() => {
      clearCommentForm();
      setLoading(false);

      if (isEdit) {
        cancelEvent();
      }
    });
  };

  const onChangeText = (e) => {
    setFormData({
      ...formData,
      ...{
        text: e.target.value,
      },
    });
  };

  const removeReply = () => {
    if (isEdit) {
      setFormData({
        ...formData,
        ...{
          repliedComment: null,
        },
      });

      return;
    }

    dispatch(setCommentForm('repliedComment', null));
  };

  const abortLoadingAttach = useRef(null);

  const removeAttachImg = (imgUrl, loading) => {
    const newAttachImgList = attachImgList.filter(({ url }) => url !== imgUrl);
    setAttachImgList(newAttachImgList);
    if (loading) {
      abortLoadingAttach.current = true;
      setLoadingAttachImg(false);
    }
  };

  const loadFileHandler = async (e) => {
    const file = e.currentTarget?.files[0];
    const { type: fileType, name: fileName } = file;
    const isFileSupported = acceptedMediaFormats.includes(fileType) && !fileName.endsWith('.jfif');

    abortLoadingAttach.current = false;
    e.currentTarget.value = null;

    if (!isFileSupported) {
      notificationService.addNotification(intl.formatMessage({ id: 'imgNotSupported' }));
      return;
    }

    if (!file
      || !acceptedMediaFormats.includes(file.type)
      || (maxImgSize && (file.size / 1000000) > maxImgSize)
    ) {
      notificationService.addNotification(intl.formatMessage({ id: 'imgUploadError' }));
      return;
    }

    setLoadingAttachImg(true);

    const imgFormData = new FormData();
    imgFormData.append('post-img', file);

    const tempImgUrl = URL.createObjectURL(file);
    setAttachImgList([...attachImgList, { url: tempImgUrl, temp: true }]);
    const fileUrl = await ApiService.sendFile(imgFormData);

    if (fileUrl && !abortLoadingAttach.current) {
      setAttachImgList([...attachImgList, { url: fileUrl, temp: false }]);
    }

    setLoadingAttachImg(false);
  };

  const attachDisabled = loadingAttachImg || attachImgList.length >= maxUploadedImgs;

  const imgsBlockClick = () => {
    if (!attachImgList.length) {
      const textAreaElem = inputRef?.current?.querySelector('textarea');
      if (textAreaElem) {
        textAreaElem.focus();
      }
    }
  };

  const onEmojiClick = (emoji, endPosition) => {
    const { text } = formData;
    const matches = [];
    let clearedStr = text;

    const maxMentionsCount = 20;

    for (let i = 0; i < maxMentionsCount; i += 1) {
      const matchOnce = clearedStr.match(/@\[(.*?)\]\((.*?)\)/);
      if (!matchOnce) {
        break;
      }

      const [matchAll, matchMention] = matchOnce;
      matches.push({ matchAll, matchMention });
      clearedStr = clearedStr.replace(matchAll, `@${matchMention}`);
    }

    let newText = clearedStr.slice(0, endPosition) + emoji + clearedStr.slice(endPosition);

    matches.forEach((matchObj) => {
      const { matchAll, matchMention } = matchObj;
      newText = newText.replace(`@${matchMention}`, matchAll);
    });

    setFormData({ ...formData, text: newText });
  };

  const textAreaElem = inputRef?.current?.querySelector('textarea');

  return (
    <div className={styles.formWrapper} id={FORM_COMMENT_WRAPPER_ID}>
      <RepliedComment
        imgOrigin={imgOrigin}
        commentData={repliedComment}
        removeReply={removeReply}
      />
      <div className={classNames(styles.inputWrapper, { [styles.editStateWrapper]: isEdit })}>
        <div
          ref={inputRef}
          className={classNames(styles.textFieldWrapper, { [styles.textStateWrapper]: isEdit })}
        >
          <MentionWrapper
            value={text}
            onSubmit={onSubmit}
            onChange={onChangeText}
          />
        </div>
      </div>
      <div>
        <div className={styles.uploadedImgsContainer}>
          <div className={styles.imgItemBlock} onClick={imgsBlockClick}>
            {attachImgList.map(({ url: imgUrl, temp }) => (
              <div key={imgUrl} className={styles.attachedImgWrap}>
                <AttachedImgItem
                  removeHandler={removeAttachImg}
                  origin={imgOrigin}
                  imgUrl={imgUrl}
                  loading={temp}
                />
              </div>
            ))}
          </div>
          <label
            className={classNames(
              styles.attachIconWrapper,
              {
                [styles.attachIconDisabled]: (attachImgList.length >= maxUploadedImgs)
                || loadingAttachImg,
              },
            )}
          >
            <input
              onChange={loadFileHandler}
              className={styles.input}
              type="file"
              accept=".png,.gif,.jpeg,.jpg,.tiff"
              disabled={attachDisabled}
            />
            <AddImageIcon />
          </label>
          <div className={styles.emojiWrapper}>
            <EmojiComment
              onEmojiClick={onEmojiClick}
              inputElem={textAreaElem}
            />
          </div>
          {isEdit ? (
            <div className={styles.actionsWrapper}>
              <div className={styles.submitWrapper}>
                <Button
                  disabled={loading}
                  onClick={cancelEvent}
                  componentStyle={{ mainContainer: styles.actionButton }}
                  colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  disabled={isEmptyTextArea && !(isImagesEdited() && attachImgList.length)}
                  onClick={onSubmit}
                  loading={loading}
                  componentStyle={{ mainContainer: styles.actionButton }}
                  colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
                >
                  <FormattedMessage id="save" />
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.actionsWrapper}>
              <div className={styles.submitWrapper}>
                <Button
                  disabled={loadingAttachImg || (isEmptyTextArea && !attachImgList.length)}
                  onClick={onSubmit}
                  loading={loading}
                  componentStyle={sendButtonStyles}
                  icon={<SendIcon />}
                  colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                />
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default CommentForm;
