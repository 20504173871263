/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import { setReportFilter } from 'store/reducers/reports/actions';
import { getReportFilterByName } from 'store/reducers/reports/selectors';
import { formatNumber } from 'utils';
import styles from './styles.module.scss';

const defaultValues = [0, 100];

const ReportFilter = (props) => {
  const {
    name = 'Likes',
    minValue = 0,
    maxValue = 100,
    additionalStyle,
    short = false,
    rounded = false,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValues);
  const isClient = useSelector(getIsClient);
  const calcValue = (percent) => {
    let numValue = (minValue + ((percent * (maxValue - minValue)) / 100));

    if (rounded) {
      numValue = Math.round(numValue);
    }

    const numValueArr = `${numValue}`.split('.');
    return Number(`${numValueArr[0]}${numValueArr[1] ? `.${numValueArr[1].slice(0, 2)}` : ''}`);
  };

  const storeMinMax = useSelector(getReportFilterByName(name));
  useEffect(() => {
    if (storeMinMax && (
      storeMinMax.min !== calcValue(values[0]) || storeMinMax.max !== calcValue(values[1])
    )) {
      const toPercent = (value) => (value / (maxValue - minValue)) * 100;
      setValues([toPercent(storeMinMax.min), toPercent(storeMinMax.max)]);
    }
    if (!storeMinMax) {
      setValues(defaultValues);
    }
  }, [storeMinMax]);

  const nameLocalized = intl.formatMessage({ id: `filters${name[0].toUpperCase()}${name.substring(1)}` });
  const filterEmptyColor = isClient ? 'rgba(255, 255, 255, 0.1)' : '#DDE4E9';

  const toPercent = (value) => ((value - minValue) / (maxValue - minValue)) * 100;
  const onChange = (values) => setValues(rounded
    ? values.map((value) => toPercent(calcValue(value)))
    : values);
  const onFinalChange = (values) => {
    let newFilterValue;
    if (values[0] !== defaultValues[0] || values[1] !== defaultValues[1]) {
      const min = calcValue(values[0]);
      const max = calcValue(values[1]);
      newFilterValue = { min, max };
      if (rounded) {
        setValues([toPercent(min), toPercent(max)]);
      }
    }

    dispatch(setReportFilter(name, newFilterValue));
  };

  return (
    <div className={classNames(styles.filterContainer, additionalStyle)}>
      <div>{nameLocalized}</div>
      <Range
        values={values}
        step={1}
        min={0}
        max={100}
        onChange={onChange}
        onFinalChange={onFinalChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{ ...props.style }}
            className={styles.trackItem}
          >
            <div
              ref={props.ref}
              className={styles.trackLine}
              style={{
                background: getTrackBackground({
                  values,
                  colors: [filterEmptyColor, '#1CA5E0', filterEmptyColor],
                  min: 0,
                  max: 100,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{ ...props.style }}
            className={styles.thumbItem}
          />
        )}
      />

      <div className={styles.numbersLine}>
        <div className={classNames(
          styles.numbersMinValue,
          { [styles.notShortWidth]: !short, [styles.valueClient]: isClient },
        )}
        >
          {formatNumber(calcValue(values[0]))}
        </div>
        <div className={classNames(
          styles.numbersMaxValue,
          { [styles.notShortWidth]: !short, [styles.valueClient]: isClient },
        )}
        >
          {formatNumber(calcValue(values[1]))}
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
