import React from 'react';
import { FormattedMessage } from 'react-intl';
import TrendItem from './trend-item';
import styles from './styles.module.scss';

const Trends = (props) => {
  const { trends = [] } = props;

  return (
    <div className={styles.trendsWrapper}>
      <h3 className={styles.trendsTitle} data-section="trends-list"><FormattedMessage id="allTrends" /></h3>
      <div className={styles.trends}>
        { trends.map((trendData) => <TrendItem data={trendData} key={trendData._id} />) }
      </div>
    </div>
  );
};

export default Trends;
