import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { CABINET_ROUTES, NOTIFICATION_TYPES } from 'consts';
import { onImageError } from 'utils';
import RouteRoleLink from 'components/route-role-link';
import DateFormatter from 'components/dateFormatter';
import CommentText from 'client/pages/trend/components/trend-comments/comment-text';
import styles from './styles.module.scss';

TimeAgo.addDefaultLocale(en);

const { REPORTS, TRENDS } = CABINET_ROUTES;

const icons = {
  [NOTIFICATION_TYPES.REPORT_APPROVED]: () => {
    return (
      <div className={styles.notificationItemIcon}>👍</div>
    );
  },
  [NOTIFICATION_TYPES.REPORT_REJECTED]: () => {
    return (
      <div className={styles.notificationItemIcon}>👎</div>
    );
  },
  [NOTIFICATION_TYPES.REPORT_PUBLISHED]: () => {
    return (
      <div className={styles.notificationItemIcon}>🎊</div>
    );
  },
  [NOTIFICATION_TYPES.REPORT_FOR_APPROVAL]: () => {
    return (
      <div className={styles.notificationItemIcon}>🔦</div>
    );
  },
  [NOTIFICATION_TYPES.TREND_MENTION_COMMENT]: (notification) => {
    const { additionalData: { user: { picture } } } = notification;
    return (
      <div className={styles.notificationItemIcon}>
        <img src={picture} alt="" onError={onImageError} />
      </div>
    );
  },
  default: '❓',
};

export function getNotificationPath(notification) {
  const { type, additionalData } = notification;

  switch (type) {
    case NOTIFICATION_TYPES.REPORT_APPROVED:
    case NOTIFICATION_TYPES.REPORT_PUBLISHED:
    case NOTIFICATION_TYPES.REPORT_REJECTED:
    case NOTIFICATION_TYPES.REPORT_FOR_APPROVAL:
      return `${REPORTS}/${additionalData.report.id}`;
    case NOTIFICATION_TYPES.TREND_MENTION_COMMENT:
      return `${TRENDS}/${additionalData.trend.id}?comment=${additionalData.comment.id}`;
    default:
      return null;
  }
}

export function getNotificationTitle(notification) {
  const { type, additionalData } = notification;

  switch (type) {
    case NOTIFICATION_TYPES.REPORT_APPROVED:
    case NOTIFICATION_TYPES.REPORT_FOR_APPROVAL:
    case NOTIFICATION_TYPES.REPORT_PUBLISHED:
    case NOTIFICATION_TYPES.REPORT_REJECTED:
      return <span className={styles.title}>{additionalData.report.title}</span>;
    case NOTIFICATION_TYPES.TREND_MENTION_COMMENT:
    {
      const { name } = additionalData.user;
      return (
        <div className={styles.userNameTitle}>
          <span className={styles.userName}>{name}</span> &nbsp;
          <FormattedMessage id="mentionCommentNotifText" />
        </div>
      );
    }
    default:
      return '';
  }
}

export function getNotificationDescription(notification) {
  const { type, additionalData } = notification;

  switch (type) {
    case NOTIFICATION_TYPES.REPORT_APPROVED:
      return <FormattedMessage id="notificationReportApprovedDescription" />;
    case NOTIFICATION_TYPES.REPORT_PUBLISHED:
      return <FormattedMessage id="notificationReportPublishedDescription" />;
    case NOTIFICATION_TYPES.REPORT_REJECTED:
      return <FormattedMessage id="notificationReportRejectedDescription" />;
    case NOTIFICATION_TYPES.REPORT_FOR_APPROVAL:
      return (
        <FormattedMessage
          id="notificationReportForReviewDescription"
        />
      );
    case NOTIFICATION_TYPES.TREND_MENTION_COMMENT:
      return (
        <CommentText text={additionalData.comment.text} />
      );
    default:
      return type;
  }
}

const MS_IN_DAY = 1000 * 3600 * 24;
const timeAgo = new TimeAgo('en-US');

const NotificationItem = (props) => {
  const { data, isClient, closeList } = props;
  const { type, createdAt: createdAtProp, unread } = data;

  const createdAt = new Date(createdAtProp);
  const olderThanDay = Date.now() - createdAt.getTime() > MS_IN_DAY;

  const path = getNotificationPath(data);

  const itemContainerClassName = classNames(
    styles.notificationItemContainer,
    { [styles.unread]: unread },
    { [styles.unreadClient]: unread && isClient },
  );

  return (
    <RouteRoleLink path={path}>
      <div
        onClick={closeList}
        className={classNames(styles.notificationItem, styles[type], { [styles.client]: isClient })}
      >
        <div className={itemContainerClassName}>
          <div className={styles.notificationContent}>
            {icons[data.type](data) ?? icons.default}
            <div className={styles.notificationItem}>
              <div className={styles.notificationItemTitle}>
                {getNotificationTitle(data)}
              </div>
              <div className={styles.description}>
                {getNotificationDescription(data)}
              </div>
            </div>
          </div>
          <div className={styles.notificationDate}>
            {olderThanDay
              ? <DateFormatter date={createdAt} showYear={false} singleLine={false} />
              : timeAgo.format(createdAt)}
          </div>
        </div>
      </div>
    </RouteRoleLink>
  );
};

export default NotificationItem;
