import {
  SET_REPORT_FILTER,
  RESET_REPORT_FILTERS,
  REMOVE_REPORT,
  REPORTS_LOAD_START,
  REPORTS_LOAD_SUCCESS,
  REPORTS_LOAD_ERROR,
} from './action.types';

const initialState = {
  data: [],
  loading: true,
  hasMore: true,
  lastId: undefined,
  lastValue: undefined,
  filtersAdded: false,
  filters: {
    search: '',
    sorting: 'updatedAt_DESC',
    status: '',
  },
};

const reportsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT_FILTER: {
      const filters = { ...state.filters };
      if (typeof action.value === 'undefined') {
        delete filters[action.name];
      } else {
        filters[action.name] = action.value;
      }

      const filtersKeys = Object.keys(filters);
      const filtersCountChanged = filtersKeys.length !== Object.keys(initialState.filters).length;
      const filtersAdded = filtersCountChanged || filtersKeys.some(
        (key) => initialState.filters[key] !== filters[key],
      );

      return {
        ...state,
        filtersAdded,
        filters,
      };
    }
    case RESET_REPORT_FILTERS:
      return {
        ...state,
        filtersAdded: false,
        filters: initialState.filters,
      };
    case REMOVE_REPORT:
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== payload.id),
      };
    case REPORTS_LOAD_START:
      return {
        ...state,
        loading: true,
        data: payload.reset ? [] : state.data,
      };
    case REPORTS_LOAD_SUCCESS: {
      const lastItem = payload.data[payload.data.length - 1] || {};
      const [sortBy] = state.filters.sorting.split('_');

      return {
        ...state,
        hasMore: payload.hasMore,
        data: [...state.data, ...payload.data],
        loading: false,
        lastId: lastItem._id,
        lastValue: lastItem[sortBy],
      };
    }
    case REPORTS_LOAD_ERROR:
      return {
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default reportsReducer;
