import React from 'react';

const SuccessIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="currentColor" />
    <path d="M9 13.5C11.475 13.5 13.5 11.475 13.5 9C13.5 6.525 11.475 4.5 9 4.5C6.525 4.5 4.5 6.525 4.5 9C4.5 11.475 6.525 13.5 9 13.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.0874 8.99994L8.3609 10.2734L10.9124 7.72644" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SuccessIcon;
