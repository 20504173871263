import React from 'react';
import Layout from 'components/layout';
import styles from './styles.module.scss';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <div className="container">
        <div className={styles.privacyMainContainer}>
          <h1 className={styles.privacyTitle}>
            KYM insights PRIVACY POLICY
          </h1>
          <p>
            This privacy policy for the KYM insights website, operated by Literally
            Media (&quot;KYM insights&quot;),
            describes the privacy practices for KYM insights (the &quot;Privacy Policy&quot;).
            The purpose of this Privacy Policy is to inform you about the types of
            information we gather about you, including information gathered when you visit
            KYM insights’ website located at -
            <a className={styles.paragraphLink} href="https://literally.media/kym-insights/">
              https://literally.media/kym-insights/
            </a>
            , KYM insights’ social media pages, KYM insights’ mobile site or downloadable mobile
            applications (collectively, the &quot;Site&quot;), and information we may receive
            from third parties or affiliates, how we may use that information,
            whether we disclose that information to anyone, and the choices you have regarding our
            use of, and your ability to correct, the information. KYM insights strives to help
            visitors of the Site protect their privacy while using our Site. By using the Site,
            you expressly consent to the use and disclosure of information as described in this
            Privacy Policy.
          </p>
          <h3 className={styles.paragraphHeader}>
            How We Collect and Use Information
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            We may collect information in several ways by your use of this Site, as well as from forms you submit to us, or personal correspondence, such as emails or letters that you send to us. We may ask you to voluntarily provide us with personal information on this Site. In addition, when you log onto the Site, our servers may log your IP address, the URL of your request and other information that is provided in the HTTP header. Also, our third-party service providers may ask you voluntarily to provide personal information on their websites and may share such personal information with us, as governed by their respective privacy policies. Our use of information received from a service provider will be dictated by the service provider&apos;s privacy policies and not this Privacy Policy.
          </p>
          <p>We collect personally identifiable information.
            {/* eslint-disable-next-line max-len */}
            We collect personally identifiable information. Personally identifiable information is information that identifies you or can be used to identify or contact you such as your name last name, company details and  email address (&quot;Personally Identifiable Information&quot;).
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Like many other websites, KYM insights may use google tag manager, google analytics, some  session recording tools like &quot;craztegg&quot; or other tracking technologies to customize content specific to your interests and track your use of the Site.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you elect to use or participate in any mobile programs or services offered by KYM insights, we may collect information on your geographic location (“Location Information”) through such programs or services. We will use Location Information collected and combined in that manner in accordance with this Privacy Policy. When you use our services of the Site, our servers may automatically record certain other information, including information such as activity (including the date and time your account is opened or terminated and date and time you log in to and out of your account), number of visits, your physical location when logged in to your account), data displayed or clicked on (including user-interface elements, advertisements, and links); and other information (including browser type, IP addresses, date and time of access, cookie ID, and referrer URL).
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            Personally Identifiable Information we collect may include your name, email addresses, geolocation information upon user login, user name and password, social media account information, and other information either desirable or necessary to provide quality services to our customers and visitors.
          </p>
          <p>
            {/* eslint-disable-next-line max-len */}
            KYM insights may use the information collected from you to provide services which you have requested from us. We may also use your personal information to send you targeted messages, publications, news, and information about events, special offers, promotions and benefits. KYM insights may use third-party service providers to send and distribute e-mail and to perform other marketing and support functions. We may use information we collect from you to improve the content of this Site, to enhance users&apos; experiences when visiting this Site, and to customize the content and layout of this Site for each individual user.
          </p>
          <h3 className={styles.paragraphHeader}>
            Sharing of Personal Information
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            Except as set forth in this Privacy Policy or as specifically agreed to by you, we will not disclose any information we gather from you on our Site. KYM insights may provide your personal information to service providers or contractors to perform functions on its behalf, such as, but not limited to, companies or individuals hired to: communicate news, deliver KYM insights’ promotional materials via e-mail and direct mail, promote KYM insights’ social media presence and activities, to develop, enhance, market or provide any of KYM insights’ products, to process credit card payments and to assist KYM insights with sending communications and performing other marketing and support functions. The Site allows Users to manage their personal information and preferences, including, but not limited to, changing personal information or opting out of communications.
          </p>

          <p className={styles.paragraphHeader}>
            KYM insights also may disclose an individual&apos;s personal information:
          </p>
          <ul>
            <li>
              {/* eslint-disable-next-line max-len */}
              to a person who in the reasonable judgment of KYM insights is seeking the information as an agent of the individual;
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              in situations where sharing or disclosing your information is required in order to offer you products or services you desire;
            </li>
            <li>
              to a third party or parties, where disclosure is required or permitted by law;
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              if, in the reasonable judgment of KYM insights, it is necessary enforce compliance with our internal policies or the Terms of Use or to protect our Site, customers, or others; and
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              to any other entity that acquires all or a portion of our organization by merger, reorganization, operation of law, or a sale of some or all of KYM insights’ assets.
            </li>
            <li>
              {/* eslint-disable-next-line max-len */}
              KYM insights may be required to use or disclose your information in connection with a legal action or other proceeding, including without limitation, in response to a court order or a subpoena. We also may disclose such information in response to a law enforcement or regulatory agency&apos;s request.
            </li>
          </ul>

          <h3 className={styles.paragraphHeader}>
            How We Store Your Information
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            KYM insights is committed to protecting the security of any personal information you provide to us. We maintain commercially reasonable safeguards to maintain the security and privacy of personal information that you provide to us. After you have submitted your information online to KYM insights, we recommend that you end your browser session before leaving your computer. In spite of these protections, KYM insights cannot guarantee the security of any data submitted over the Internet. After your information reaches KYM insights, however, it is stored on servers (or in the case of information received from you in person, in writing or over the phone, in physical storage areas) protected by procedures and technology designed to block reasonably foreseeable intrusions by unauthorized third parties.
          </p>
          <h3 className={styles.paragraphHeader}>
            Opt-Out From Mailings
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            From time to time, you may receive periodic mailings or e-mails from KYM insights with news or other information on products, services or other offers from or on behalf of KYM insights. If at any time you wish to stop receiving emails or mailings from KYM insights please send us an email to insights@knowyourmeme.com with the phrase “Privacy Opt-out: KYM insights’ Mailings”. In the subject line, or write to us at the address provided below, and we will remove you from KYM insights’ mailing list. Alternatively, for e-mail communications, you may opt out of receiving such communications by following the unsubscribe instructions set forth at the bottom of most promotional e-mail messages from KYM insights.
          </p>
          <h3 className={styles.paragraphHeader}>
            Third-Party Websites
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            This Privacy Policy applies solely to information collected by KYM insights. This Privacy Policy does not apply to other websites that are accessible through this Site, including but not limited to any third-party websites that process payments on behalf of KYM insights. Please be aware that third-party websites that process payments for KYM insights and websites for our advertisers, sponsors, affiliated entities and other third parties that are accessible through this Site may have their own privacy and data collection policies and practices. KYM insights is not responsible for the privacy practices of such sites and will not be responsible for any actions or policies of such third parties. We encourage our users to be aware when they leave the Site and to read the privacy statements of each and every website that you visit.
          </p>
          <h3 className={styles.paragraphHeader}>
            Contact Us
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            If you would like to update or correct your information or modify your communication preferences, or if you have questions regarding our Privacy Policy or the use of information collected, you can contact us:
          </p>
          <p>
            by email:<br />
            <div className={styles.mailLine}>insights@knowyourmeme.com</div>
          </p>
          <p>
            by writing to us at:<br />
            <b>MindAd Inc., 190 West St., Unit 17B, Brooklyn, NY 11222</b>
          </p>
          <h3 className={styles.paragraphHeader}>
            Policy Changes
          </h3>
          <p>
            {/* eslint-disable-next-line max-len */}
            KYM insights may amend this Privacy Policy from time to time. Should this Privacy Policy be amended in any way, changes will be posted to this webpage. As we may make changes from time to time without notifying you, we suggest that you periodically consult this Privacy Policy. Your continued use of the Site after the effective date of any modification to the Privacy Policy will be deemed to be your agreement to the changed terms.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
