import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderElement } from 'components/loadingComponent';
import { FormattedMessage } from 'react-intl';
import { getIsClient, getIsAdmin } from 'store/reducers/general/selectors';
import classNames from 'classnames';
import styles from './styles.module.scss';

const InfiniteScroll = (props) => {
  const {
    RenderComponent,
    RootComponent,
    dataSelector,
    loadAction,
    preloadAction,
    parentId,
    listItemProps = {},
    filterDataFunction = null,
    querySearch,
    emptyMessage = <FormattedMessage id="noTrends" />,
  } = props;

  const loader = useRef(null);
  const dispatch = useDispatch();
  const { data, loading, hasMore } = useSelector(dataSelector);
  const isClient = useSelector(getIsClient);
  const isAdmin = useSelector(getIsAdmin);
  let filteredData = data;

  if (typeof filterDataFunction === 'function') {
    filteredData = data.filter(filterDataFunction);
  }

  useEffect(() => {
    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        dispatch(loadAction(false, parentId));
      }
    };

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    if (typeof preloadAction === 'function') {
      preloadAction(dispatch);
    }

    if (querySearch) {
      return;
    }

    dispatch(loadAction(true, parentId));
  }, [dispatch, loadAction, parentId]);

  const hideMoreLoading = !hasMore || !filteredData.length;
  const styleLoader = hideMoreLoading ? 'none' : 'flex';

  return (
    <>
      { (loading && !filteredData.length) ? (
        <div
          style={{
            display: 'flex',
            padding: 35,
            justifyContent: 'center',
            marginTop: 40,
          }}
        >
          <LoaderElement />
        </div>
      ) : (
        <div key="infinite-scroll-root-component">
          { filteredData?.length ? (
            <RootComponent>
              {filteredData
                .map((item, index) => (
                  <RenderComponent
                    data={item}
                    key={item.id || index}
                    isCollection={!!parentId}
                    isClient={isClient}
                    isAdmin={isAdmin}
                    {...listItemProps}
                  />
                ))}
            </RootComponent>
          ) : (
            <h3 className={classNames(
              styles.emptyListMessage,
              { [styles.colorWhite7]: isClient },
            )}
            >
              {(filteredData?.length === 0) && emptyMessage}
            </h3>
          ) }
        </div>
      ) }
      <div
        ref={loader}
        style={{
          display: styleLoader,
          padding: 35,
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <LoaderElement />
      </div>
    </>
  );
};

export default InfiniteScroll;
