import InfoIcon from 'components/svg-icons/info-icon';
import InfoIconTransparent from 'components/svg-icons/info-icon-transparent';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CountUp from 'react-countup';
import { socialStatsFormatter } from 'utils/social-helpers';
import { isInteger } from 'utils';
import Like from './icons/like';
import Shares from './icons/shares';
import Comments from './icons/comments';
import Views from './icons/views';
import Kym from './icons/kym';
import Viral from './icons/viral';
import Relevant from './icons/relevant';
import Recent from './icons/recent';
import Spread from './icons/spread';
import Volume from './icons/volume';

import styles from './styles.module.scss';

const mapConfig = {
  viral: {
    title: <FormattedMessage id="viral" />,
    icon: <Viral />,
    tooltipMessage: <FormattedMessage id="viralTooltip" />,
  },
  relevant: {
    title: <FormattedMessage id="relevant" />,
    icon: <Relevant />,
    tooltipMessage: <FormattedMessage id="relevantTooltip" />,
  },
  recent: {
    title: <FormattedMessage id="recent" />,
    icon: <Recent />,
    tooltipMessage: <FormattedMessage id="recentTooltip" />,
  },
  spread: {
    title: <FormattedMessage id="spread" />,
    icon: <Spread />,
    tooltipMessage: <FormattedMessage id="spreadTooltip" />,
  },
  volume: {
    title: <FormattedMessage id="volume" />,
    icon: <Volume />,
    tooltipMessage: <FormattedMessage id="volumeTooltip" />,
  },
  likes: {
    title: <FormattedMessage id="totalLikes" />,
    icon: <Like />,
  },
  shares: {
    title: <FormattedMessage id="totalReposts" />,
    icon: <Shares />,
  },
  comments: {
    title: <FormattedMessage id="totalComments" />,
    icon: <Comments />,
  },
  views: {
    title: <FormattedMessage id="totalViews" />,
    icon: <Views />,
  },
  kym: {
    title: <FormattedMessage id="kymIndex" />,
    icon: <Kym />,
  },
};

const kymIndexes = ['viral', 'relevant', 'recent', 'spread', 'volume', 'kym'];

const SocialStatistic = (props) => {
  const { totalMetrics, totalIndexMetrics } = props;

  const fullMetrics = {
    ...totalMetrics,
    ...totalIndexMetrics,
  };

  return (
    <div className={styles.socialStatistic}>
      {Object.keys(mapConfig).map((statKey) => {
        const statDataConfig = mapConfig[statKey];
        const counter = fullMetrics[statKey] || 0;

        if (!statDataConfig || !counter) {
          return null;
        }

        const { title, icon, tooltipMessage } = statDataConfig;
        const [value, suffix] = socialStatsFormatter(counter, true);
        const statValue = kymIndexes.includes(statKey) ? Math.round(value) : value;

        return (
          <div className={styles.socialStatItem} key={statKey}>
            <div className={styles.iconWrapper}>{icon}</div>
            <div className={styles.stats}>
              <span className={styles.statTitle}>{title}</span>
              <div className={styles.statsRow}>
                <span className={styles.statCounter}>
                  <CountUp
                    start={0}
                    end={statValue}
                    decimals={isInteger(statValue) ? 0 : 2}
                    suffix={suffix}
                    duration={2}
                  />
                </span>
                {tooltipMessage && (
                <span className={styles.tooltipIconCont}>
                  <InfoIconTransparent className={styles.tooltipIcon} />
                  <div className={styles.tooltipCont}>
                    <div className={styles.tooltipInnerIcon}>
                      <InfoIcon />
                    </div>
                    <div className={styles.tooltipTextCont}>
                      {tooltipMessage}
                    </div>
                  </div>
                </span>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SocialStatistic;
