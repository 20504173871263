const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const requiredRule = (desc) => (string) => {
  const value = typeof string === 'string' ? string.trim() : string;
  if (Array.isArray(value)) {
    return (value.length ? false : desc.message || 'Field is required');
  }

  const invalid = typeof value === 'undefined' || value === null || value === '';
  return !invalid ? false : desc.message || 'Field is required';
};

const maxLengthRule = (desc) => (string) => {
  if (string.length > desc.value) {
    return desc.message || `This fields allows only ${desc.value} characters`;
  }

  return false;
};

const linkRule = (desc) => (string) => {
  let isValidUrl = null;
  const errorMessage = desc.message || 'Field should a be link';

  try {
    isValidUrl = new URL(string);
  } catch (_) {
    return errorMessage;
  }

  if (!(isValidUrl.protocol === 'http:' || isValidUrl.protocol === 'https:')) {
    return errorMessage;
  }

  return null;
};

const linkRuleWithDomain = (desc) => (itemObj) => {
  const {
    message: errorMessage = 'Invalid link format',
  } = desc;

  const badLinkMessage = 'Field should be a link';

  let { link: linkStr = '' } = itemObj;
  const { domains = [], name } = itemObj;

  linkStr = `https://${linkStr.replace(/^https?:(\/\/)?/, '')}`;

  try {
    const url = new URL(linkStr);
    const findDomain = domains.find((domainStr) => url.host === domainStr);

    if (!findDomain) {
      return [name, errorMessage].join('::');
    }
  } catch (_) {
    return [name, badLinkMessage].join('::');
  }

  return null;
};

const patternRule = (desc) => (value) => {
  const { pattern, message } = desc;
  return value && pattern.test(value) ? false : message || 'Value is not correctly';
};

const emailRule = (desc) => (value) => (value && emailRegExp.test(String(value).toLowerCase()) ? false : desc.message || 'Email is invalid');

const checkArrayRule = (param) => (values) => {
  const { descr = {} } = param;

  if (Array.isArray(values)) {
    const resultErrors = [];

    // eslint-disable-next-line react/destructuring-assignment
    values.forEach((value, index) => {
      const errors = {};

      Object.keys(value).forEach((key) => {
        const val = value[key];
        const validator = descr[key];

        const error = typeof validator === 'function' ? validator(val) : false;

        if (!error) {
          return;
        }

        errors[key] = error;
      });

      if (Object.keys(errors).length) {
        resultErrors[index] = errors;
      }
    });
    return resultErrors.length ? resultErrors : null;
  }

  return null;
};

const checkObjectRule = (param) => (value) => {
  const { descr } = param;
  const errors = {};

  Object.keys(value).forEach((key) => {
    // eslint-disable-next-line react/destructuring-assignment
    const val = value[key];
    const validator = descr[key];

    const error = typeof validator === 'function' ? validator(val) : false;

    if (!error) {
      return;
    }

    errors[key] = error;
  });

  return Object.keys(errors).length ? errors : null;
};

const basicRules = {
  email: emailRule,
  pattern: patternRule,
  required: requiredRule,
  checkArray: checkArrayRule,
  checkObject: checkObjectRule,
  maxLength: maxLengthRule,
  link: linkRule,
  linkDomain: linkRuleWithDomain,
};

const buildRules = (options) => (
  options.reduce((rules, desc) => {
    if (typeof (desc.type) === 'string') {
      rules.push(basicRules[desc.type](desc));
    }

    return rules;
  }, [])
);

export const createValidator = (rulesDesc) => {
  const rules = buildRules(rulesDesc);
  return (value) => {
    for (let i = 0, l = rules.length, error; i < l; i += 1) {
      error = rules[i](value);
      if (error) {
        return error;
      }
    }
    return false;
  };
};
