import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const defaultText = 'Your trend is being generated. It can take a couple of minutes if you selected a large amount';

const CreatingLoader = (props) => {
  const { text = defaultText } = props;

  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loaderWindow}>
        <div className={styles.title}>
          <FormattedMessage id="wait" />
        </div>
        <div className={styles.description}>
          {text}
        </div>
        <div className={styles.spinnerWrapper}>
          <div className={styles.spinnerContainer}>
            <div className={styles.spinner} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatingLoader;
