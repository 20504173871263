import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Popup from 'components/popup';
import ApiService from 'services/apiService';
import { getCompanies } from 'store/reducers/companies/selectors';
import Select from 'components/fields/select';
import { useDispatch, useSelector } from 'react-redux';
import { requestCollection, requestCollections } from 'store/reducers/collections/actions';
import classNames from 'classnames';
import CompaniesIcon from 'components/svg-icons/companies-icon';
import { COLLECTION_TYPE } from 'consts';
import { requestPeopleCollection, requestPeopleCollections } from 'store/reducers/people-collection/actions';
import styles from './styles.module.scss';

const { TRENDS, PEOPLE } = COLLECTION_TYPE;

export const renderCompanyOption = (data, intl, chosenCompanies) => {
  const { name, value: itemValue, users } = data;

  return (
    <div className={classNames(
      styles.itemWrapper,
      {
        [styles.checked]: chosenCompanies.includes(itemValue),
      },
    )}
    >
      <div className={styles.iconContainer}>
        {CompaniesIcon()}
      </div>
      <div className={styles.nameContainer}>
        {name}
      </div>
      <div className={styles.users}>
        {users} {intl.formatMessage({ id: 'users' })}
      </div>
    </div>
  );
};

export const getCompaniesOptions = (companies) => {
  if (!companies || !Array.isArray(companies)) {
    return null;
  }

  return [...companies].map((company) => {
    const { title: name, _id: value, users } = company;
    return { name, value, users };
  }).sort(({ name: a }, { name: b }) => a.localeCompare(b));
};

const dataActions = {
  [TRENDS]: {
    shareMethodName: 'shareCollectionCompanies',
    requestOne: requestCollection,
    requestMany: requestCollections,
  },
  [PEOPLE]: {
    shareMethodName: 'sharePeopleCollectionCompanies',
    requestOne: requestPeopleCollection,
    requestMany: requestPeopleCollections,
  },
};

const ShareCompaniesPopup = (props) => {
  const {
    onCancel = () => {},
    collectionData,
    type = TRENDS,
    needToRefresh = false,
  } = props;
  const { sharedCompanies = [], _id: collectionId = '' } = collectionData;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [chosenCompanies, setChosenCompanies] = useState([]);
  const companies = useSelector(getCompanies);
  const dispatch = useDispatch();
  const { shareMethodName, requestOne, requestMany } = dataActions[type];

  useEffect(() => {
    if (Array.isArray(sharedCompanies)) {
      setChosenCompanies([...sharedCompanies]);
    }
  }, [sharedCompanies]);

  const onSubmit = async () => {
    setLoading(true);

    ApiService[shareMethodName]({
      collectionId,
      sharedCompanies: chosenCompanies,
    }).then(() => {
      if (needToRefresh) {
        dispatch(requestMany(true));
      } else {
        dispatch(requestOne(collectionId));
      }
      onCancel();
    });
  };

  const onCompaniesChange = (name, values) => {
    setChosenCompanies(values);
  };

  return (
    <Popup
      processing={loading}
      title={intl.formatMessage({ id: 'shareWith' })}
      onSubmit={onSubmit}
      onClose={onCancel}
      submitText={intl.formatMessage({ id: 'share' })}
    >
      <div className={styles.shareCollectionContainer}>
        <Select
          multiple
          canScroll
          withSearch
          renderOption={renderCompanyOption}
          placeholder={intl.formatMessage({ id: 'trendCompanies' })}
          searchPlaceholder={intl.formatMessage({ id: 'searchCompany' })}
          fullWidth
          options={getCompaniesOptions(companies)}
          value={chosenCompanies}
          onChange={onCompaniesChange}
          openLock
        />
      </div>
    </Popup>
  );
};

export default ShareCompaniesPopup;
