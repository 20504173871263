import {
  SET_USER_PROFILE,
  CLEAR_USER_PROFILE,
  PATCH_USER_PROFILE,
  SET_PUSH_TOKEN,
} from './action.types';

const initialState = {
  user: null,
  pushToken: '',
};

export const setPushToken = (token) => ({
  type: SET_PUSH_TOKEN,
  token,
});

export const setUserProfile = (data) => ({
  type: SET_USER_PROFILE,
  data,
});

export const patchUserProfile = (data) => ({
  type: PATCH_USER_PROFILE,
  data,
});

export const clearUserProfile = () => ({
  type: CLEAR_USER_PROFILE,
});

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return { ...state, user: action.data };
    case CLEAR_USER_PROFILE:
      return { ...state, user: null };
    case PATCH_USER_PROFILE:
      return { ...state, user: { ...state.user, ...action.data } };
    case SET_PUSH_TOKEN:
      return { ...state, pushToken: action.token };
    default:
      return state;
  }
};

export default userReducer;
