import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfiniteScroll from 'components/infinity-scroll';
import { getCheckedPeople, getCreateShow, getPeopleState } from 'store/reducers/people/selectors';
import {
  requestPeople,
  resetPeopleFilters,
  setCheckedPeople,
  setPeopleCreateShow,
  setPeopleFilter,
} from 'store/reducers/people/actions';
import { COLOR_THEMES } from 'consts';
import Button from 'components/button';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import { useDispatch, useSelector } from 'react-redux';
import AddPeople from 'pages/people/add-people';
import Tag from 'components/fields/select/tag';
import styles from './styles.module.scss';

const ListComponent = ({ data }) => {
  const { title, _id: id, trendsCount = 0 } = data;

  const checkedItems = useSelector(getCheckedPeople);
  const dispatch = useDispatch();

  const clickHandler = () => {
    let newCheckedItems = checkedItems.filter((item) => (item._id !== id));

    newCheckedItems = newCheckedItems.length !== checkedItems.length
      ? newCheckedItems
      : [...newCheckedItems, data];

    dispatch(setCheckedPeople(newCheckedItems));
  };

  const isChecked = checkedItems?.some((item) => (item._id === id));

  return (
    <div
      className={classNames(
        styles.peopleItemContainer,
        { [styles.peopleChecked]: isChecked },
      )}
      onClick={clickHandler}
    >
      <div className={styles.peopleItemTitle}>
        {title}
      </div>
      <div className={classNames(styles.badgeContainer, { [styles.badgeHidden]: !trendsCount })}>
        <div className={styles.badgeCount}>{trendsCount}</div>
        <div>trends</div>
      </div>
    </div>
  );
};

const PeopleSelect = (props) => {
  const {
    fullWidth = false,
    isTabs = false,
    openLock = false,
    multiple = true,
  } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(openLock);
  const { filters: { search } } = useSelector(getPeopleState);
  const isCreateShow = useSelector(getCreateShow);
  const refSelect = useRef();
  const intl = useIntl();
  const checkedPeople = useSelector(getCheckedPeople);
  const placeholder = intl.formatMessage({ id: 'mainCharacter' });

  const onFilterChange = (name, value) => {
    dispatch(setPeopleFilter({ name, value }));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && refSelect.current && !refSelect.current.contains(e.target)) {
        setOpen(false);
        dispatch(resetPeopleFilters());
      }
    };

    if (!openLock) {
      document.addEventListener('mousedown', checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
      dispatch(resetPeopleFilters());
    };
  }, [open]);

  const toggleOpen = (e, isCloseBut) => {
    if (openLock) {
      return null;
    }

    e.preventDefault();
    e.stopPropagation();

    if (e?.currentTarget?.className?.includes('selectText')) {
      return !(isTabs && open) && setOpen(!open);
    }

    if (!isCloseBut && open && refSelect?.current?.contains(e.target)) {
      return null;
    }

    return setOpen(!open);
  };

  const tabClickHandler = (e) => {
    if (openLock) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const removeHandler = (removeId) => () => {
    const newCheckedItems = checkedPeople.filter((item) => (item._id !== removeId));
    dispatch(setCheckedPeople(newCheckedItems));
  };

  const getCurrentNameValue = () => {
    if (!checkedPeople || !checkedPeople.length) {
      return placeholder;
    }

    return checkedPeople.map((item) => {
      const { title, _id } = item;
      return <Tag name={title} value={_id} key={`tag_people_${_id}`} onRemove={removeHandler(_id)} />;
    });
  };

  const toggleAddPeople = (value) => {
    return () => {
      if (value) {
        setOpen(false);
        dispatch(resetPeopleFilters());
      }
      const toggleVal = typeof value !== 'undefined' ? value : !isCreateShow;
      dispatch(setPeopleCreateShow(toggleVal));
    };
  };

  const RootList = ({ children }) => <div>{ children }</div>;

  return (
    <div
      className={classNames(
        styles.selectWrapper,
        { [styles.fullWidth]: fullWidth },
        { [styles.selectWrapperActive]: open || openLock },
      )}
      ref={refSelect}
    >
      { isCreateShow && <AddPeople onCancel={toggleAddPeople(false)} /> }
      <div
        className={classNames(styles.selectItem, { [styles.active]: open })}
        onClick={toggleOpen}
      >
        <span
          className={classNames(styles.selectText, { [styles.whiteSpaceNormal]: multiple })}
          onClick={toggleOpen}
        >
          {getCurrentNameValue()}
        </span>
        {!openLock && (
          <div
            className={styles.selectIcon}
            onClick={isTabs ? tabClickHandler : (e) => toggleOpen(e, true)}
          >
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="currentColor" strokeWidth="1.5" />
            </svg>
          </div>
        )}

        <div
          aria-label="select options"
          className={classNames(
            styles.selectOptions,
            { [styles.open]: open || openLock },
          )}
        >
          <div className={styles.searchBlock}>
            <Input
              delay={500}
              placeholder={intl.formatMessage({ id: 'typePeopleName' })}
              colorTheme={COLOR_THEMES.LIGHT}
              name="search"
              icon={<SearchIcon />}
              value={search}
              onChange={onFilterChange}
            />
          </div>
          {open && (
            <div className={styles.infiniteScrollBlock}>
              <InfiniteScroll
                RootComponent={RootList}
                RenderComponent={ListComponent}
                dataSelector={getPeopleState}
                loadAction={requestPeople}
                emptyMessage={<FormattedMessage id="noPeople" />}
              />
            </div>
          )}
          <div className={styles.footerBlock}>
            <Button
              colorTheme={COLOR_THEMES.PRIMARY}
              componentStyle={{ mainContainer: styles.addNewButtonStyle }}
              onClick={toggleAddPeople(true)}
            >
              <FormattedMessage id="addNew" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleSelect;
