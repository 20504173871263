import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import RouteRoleLink from 'components/route-role-link';
import { CABINET_ROUTES, COLOR_THEMES } from 'consts';
import Button from 'components/button';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const HeadingFilters = (props) => {
  const {
    title,
    FiltersComponent = null,
    withContainer = true,
    isCollection = false,
  } = props;
  const isClient = useSelector(getIsClient);

  const Filters = (
    <div className={styles.filtersWrapper}>
      {isCollection && (
        <RouteRoleLink path={CABINET_ROUTES.COLLECTIONS}>
          <Button
            componentStyle={{
              mainContainer: isClient
                ? styles.backButton
                : styles.adminBackButton,
            }}
            icon={<div className={styles.backIcon}><RightArrowIcon /></div>}
            colorTheme={(!isClient && isCollection)
              ? COLOR_THEMES.SECONDARY
              : COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
          >
            <FormattedMessage id="back" />
          </Button>
        </RouteRoleLink>
      )}
      { title && <div className={styles.title}>{title}</div> }
      { FiltersComponent && (
        <div className={classNames(styles.filters, { [styles.collectionStyle]: isCollection })}>
          {FiltersComponent}
        </div>
      ) }
    </div>
  );

  return (
    <div className={classNames(
      styles.headingFilters,
      { [styles.client]: isClient },
    )}
    >
      { withContainer ? <div className="container">{Filters}</div> : Filters }
    </div>
  );
};

export default HeadingFilters;
