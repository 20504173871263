import React from 'react';
import { CABINET_ROUTES } from 'consts';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import CompanyItem from './item';
import styles from './styles.module.scss';

const CompaniesList = (props) => {
  const { companies = [] } = props;
  const { COMPANIES } = CABINET_ROUTES;
  const { navigate } = UseRoleNavigation();

  const onViewUsers = (id) => {
    navigate(`${COMPANIES}/${id}`);
  };

  if (!companies.length) {
    return null;
  }

  return (
    <div className={styles.companiesList}>
      {companies.map((company) => (
        <div className={styles.companyItem} key={company._id}>
          <CompanyItem
            data={company}
            onViewClick={onViewUsers}
          />
        </div>
      ))}
    </div>
  );
};

export default CompaniesList;
