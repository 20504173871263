import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import CloseIcon from 'components/svg-icons/close-icon';
import Button from 'components/button';
import {
  COLOR_THEMES, MODAL_PORTAL_ID, POPUP_TYPE, ROLE_THEMES,
} from 'consts';
import styles from './styles.module.scss';

const TYPE_COMPONENTS = {
  [POPUP_TYPE.MESSAGE]: 'div',
  [POPUP_TYPE.FORM]: 'form',
};

const Popup = (props) => {
  const { LIGHT, DARK } = ROLE_THEMES;
  const { SECONDARY, TRANSPARENT_LIGHT_WHITE } = COLOR_THEMES;

  const {
    title = '',
    children,
    cancelText = <FormattedMessage id="cancel" />,
    submitText = <FormattedMessage id="add" />,
    onSubmit = () => {},
    onClose = () => {},
    processing = false,
    colorTheme = LIGHT,
    type = POPUP_TYPE.MESSAGE,
    withoutButtons = false,
    submitDisabled = false,
    scrollableFooter = false,
  } = props;

  const modalRoot = document.getElementById(MODAL_PORTAL_ID);
  const TypeComponent = TYPE_COMPONENTS[type];

  const PopupLayout = (
    <div className={styles.popupLayout}>
      <div className={classNames(styles.overlay, styles[colorTheme])} />
      <TypeComponent
        className={classNames(styles.popup, styles[colorTheme])}
        onSubmit={type !== POPUP_TYPE.FORM ? undefined : (e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div className={styles.popupHeader}>
          <span className={styles.popupTitle}>{title}</span>
          <button type="button" disabled={processing} className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classNames(
          styles.popupBody,
          { [styles.scrollBody]: scrollableFooter },
        )}
        >
          {children}
          {!withoutButtons && scrollableFooter && (
            <div className={classNames(
              styles.popupFooter,
              styles.popupScrollFooter,
            )}
            >
              <div className={styles.popupAction}>
                <Button
                  fullWidth
                  disabled={processing}
                  onClick={onClose}
                  colorTheme={colorTheme === LIGHT ? SECONDARY : TRANSPARENT_LIGHT_WHITE}
                >
                  {cancelText}
                </Button>
              </div>
              <div className={styles.popupAction}>
                <Button
                  fullWidth
                  onClick={type !== POPUP_TYPE.FORM ? onSubmit : undefined}
                  colorTheme={colorTheme === DARK && COLOR_THEMES.PRIMARY_GRADIENT}
                  loading={processing}
                  disabled={submitDisabled}
                  type="submit"
                >
                  {submitText}
                </Button>
              </div>
            </div>
          )}
        </div>
        {!withoutButtons && !scrollableFooter && (
          <div className={styles.popupFooter}>
            <div className={styles.popupAction}>
              <Button
                fullWidth
                disabled={processing}
                onClick={onClose}
                colorTheme={colorTheme === LIGHT ? SECONDARY : TRANSPARENT_LIGHT_WHITE}
              >
                {cancelText}
              </Button>
            </div>
            <div className={styles.popupAction}>
              <Button
                fullWidth
                onClick={type !== POPUP_TYPE.FORM ? onSubmit : undefined}
                colorTheme={colorTheme === DARK && COLOR_THEMES.PRIMARY_GRADIENT}
                loading={processing}
                disabled={submitDisabled}
                type="submit"
              >
                {submitText}
              </Button>
            </div>
          </div>
        )}
      </TypeComponent>
    </div>
  );

  return ReactDOM.createPortal(
    PopupLayout,
    modalRoot,
  );
};

export default Popup;
