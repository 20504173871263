import React from 'react';

const Volume = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1159_47731)">
      <path d="M18 6.5V9.5C18 11.985 13.97 14 9 14C4.03 14 0 11.985 0 9.5V6.5C0 8.985 4.03 11 9 11C13.97 11 18 8.985 18 6.5ZM0 11.5C0 13.985 4.03 16 9 16C13.97 16 18 13.985 18 11.5V14.5C18 16.985 13.97 19 9 19C4.03 19 0 16.985 0 14.5V11.5ZM9 9C4.03 9 0 6.985 0 4.5C0 2.015 4.03 0 9 0C13.97 0 18 2.015 18 4.5C18 6.985 13.97 9 9 9Z" fill="url(#paint0_radial_1159_47731)" />
    </g>
    <defs>
      <filter id="filter0_i_1159_47731" x="-2" y="0" width="20" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1159_47731" />
      </filter>
      <radialGradient id="paint0_radial_1159_47731" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.43478 26.0119) rotate(-80.6041) scale(26.3656 11.5645)">
        <stop stopColor="#1CA5E0" />
        <stop offset="1" stopColor="#3439C1" />
      </radialGradient>
    </defs>
  </svg>
);

export default Volume;
