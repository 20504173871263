import React from 'react';

const DotsIcon = () => (
  <svg width="4" height="15" viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 0C0.9 0 0 0.75 0 1.66667C0 2.58333 0.9 3.33333 2 3.33333C3.1 3.33333 4 2.58333 4 1.66667C4 0.75 3.1 0 2 0ZM2 11.6667C0.9 11.6667 0 12.4167 0 13.3333C0 14.25 0.9 15 2 15C3.1 15 4 14.25 4 13.3333C4 12.4167 3.1 11.6667 2 11.6667ZM2 5.83333C0.9 5.83333 0 6.58333 0 7.5C0 8.41667 0.9 9.16667 2 9.16667C3.1 9.16667 4 8.41667 4 7.5C4 6.58333 3.1 5.83333 2 5.83333Z" fill="#ABABAB" />
  </svg>

);

export default DotsIcon;
