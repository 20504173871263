import React from 'react';

const LandingMainPartRealTimeIcon = () => (
  <svg width="188" height="188" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_479)">
      <path d="M6 92.5C6 104.647 8.39258 116.676 13.0411 127.898C17.6897 139.121 24.5032 149.318 33.0926 157.907C41.682 166.497 51.8792 173.31 63.1018 177.959C74.3244 182.607 86.3527 185 98.5 185" stroke="url(#paint0_linear_1_479)" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M58.7178 43.5304L72.9162 53.3432M33 75.0906L46.1268 86.4948M46.1268 86.4948L59.5215 103.468M46.1268 86.4948L72.9162 53.3432M72.9162 53.3432L86.3108 57.8519M133.46 115.933C133.46 115.933 138.55 116.729 141.765 113.016C144.98 109.303 141.765 103.999 141.765 103.999L108.546 72.1733M133.46 115.933C133.46 115.933 134.8 120.972 131.853 123.624C128.906 126.277 123.548 125.481 123.548 125.481M133.46 115.933L114.172 96.3076M123.548 125.481C123.548 125.481 124.888 130.568 122.476 133.172C119.53 136.355 114.172 135.294 114.172 135.294M123.548 125.481L110.153 111.955M114.172 135.294C114.172 135.294 114.975 141.128 111.493 143.515C105.562 147.58 100.777 140.333 100.777 140.333M114.172 135.294L106.135 126.542" stroke="#7DE87C" strokeWidth="5" />
      <path d="M127.299 52.8129C127.299 52.8129 113.357 52.5476 106.135 53.3433C102.652 53.7269 86.3108 57.8519 86.3108 57.8519C86.3108 57.8519 77.2024 72.7038 73.7198 79.5993C72.4165 82.1799 74.0194 84.8287 76.3988 86.4948C78.8757 88.2293 83.0761 88.0446 84.9714 85.6992C89.2577 80.3949 93.8119 71.3777 93.8119 71.3777L108.546 72.1733L141.765 103.999C141.765 103.999 147.316 92.1991 150.873 84.6383M127.299 52.8129L141.765 43M127.299 52.8129C127.299 52.8129 141.667 72.2097 150.873 84.6383M164 72.1733C164 72.1733 156 79.7704 150.873 84.6383M93.2761 128.133C93.2761 128.133 98.3188 128.133 100.777 131.316C104.26 135.824 100.777 140.333 100.777 140.333L95.6871 144.576C95.6871 144.576 91.6687 147.78 87.3824 144.576C83.8337 141.924 85.2393 136.355 85.2393 136.355M93.2761 128.133C93.2761 128.133 95.955 123.235 92.7403 119.646C89.1771 115.668 82.8282 119.116 82.8282 119.116M93.2761 128.133L85.2393 136.355M82.8282 119.116C82.8282 119.116 86.558 113.546 82.8282 109.833C78.8098 105.833 72.6483 109.833 72.6483 109.833M82.8282 119.116L74.7914 127.337M72.6483 109.833C72.6483 109.833 74.5235 104.939 71.0409 101.612C68.6298 99.3082 66.135 99.4304 63.272 100.551C61.478 101.253 59.5215 103.468 59.5215 103.468C59.5215 103.468 54.4315 108.773 57.1104 113.016C60.2917 118.055 66.7546 115.933 66.7546 115.933M72.6483 109.833L66.7546 115.933M66.7546 115.933C66.7546 115.933 62.7362 121.503 66.2188 125.481C69.7014 129.459 74.7914 127.337 74.7914 127.337M74.7914 127.337C74.7914 127.337 73.067 132.642 76.6667 135.824C80.5663 139.272 85.2393 136.355 85.2393 136.355" stroke="#1CA5E0" strokeWidth="5" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1_479" x1="6" y1="185" x2="150.512" y2="-7.22778" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7DE87C" />
        <stop offset="1" stopColor="#7CA7E8" />
      </linearGradient>
      <clipPath id="clip0_1_479">
        <rect width="188" height="188" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default LandingMainPartRealTimeIcon;
