import React from 'react';
import { iconComponents, socialStatsFormatter } from 'utils/social-helpers';
import styles from './styles.module.scss';

const Badge = (props) => {
  const {
    type,
    count,
  } = props;

  const Icon = iconComponents[type];

  const getCounts = () => {
    try {
      const countNum = parseInt(count, 10);
      return socialStatsFormatter(countNum);
    } catch {
      return null;
    }
  };

  return (
    <div className={styles.badgeContainer}>
      <Icon className={styles.badgeIcon} />
      <div>
        {getCounts()}
      </div>
    </div>
  );
};

export default Badge;
