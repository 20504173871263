import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import Button from 'components/button';
import CloseIcon from 'components/svg-icons/close-icon';
import ErrorMessage from 'components/error-message';
import styles from './styles.module.scss';

export const acceptedMediaFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/tiff', 'image/gif'];

const ImageUploader = (props) => {
  const {
    withPreview = true,
    title,
    onChange = () => {},
    imageData = '',
    maxSize = 5,
    parseTrigger,
    previewComponent,
    uploadButtonStyle = {
      mainContainer: styles.buttonUploadStyle,
      textContainer: styles.buttonUploadTextStyle,
    },
  } = props;

  const [filename, setFilename] = useState('');
  const [error, setError] = useState(null);
  const [imgPreviewSrc, setImgPreviewSrc] = useState(null);
  const inputRef = useRef(null);
  const s3Domain = useSelector(getS3Domain);
  const PreviewComponent = previewComponent;

  const getOrigin = () => {
    if (s3Domain && typeof s3Domain === 'string') {
      return s3Domain.startsWith('http') ? s3Domain : `https://${s3Domain}`;
    }
    return '';
  };

  useEffect(() => {
    setFilename('');
  }, [parseTrigger]);

  useEffect(() => {
    if (imageData && typeof imageData === 'string') {
      let imgUrlWithDomain = '';

      if (imageData.includes(s3Domain)) {
        if (imageData.startsWith('http')) {
          imgUrlWithDomain = imageData;
        } else {
          imgUrlWithDomain = `//${imageData}`;
        }
      }

      if (!imgUrlWithDomain) {
        const origin = getOrigin();
        imgUrlWithDomain = `${origin}/${imageData}`;
      }

      setImgPreviewSrc(imgUrlWithDomain);
      setFilename('Image');
    } else {
      setImgPreviewSrc(null);
    }
  }, [imageData]);

  const changeHandler = (e) => {
    setError(null);
    const file = e.currentTarget?.files[0];
    if (!file) {
      return;
    }

    if (!acceptedMediaFormats.includes(file.type) || file.name?.toLowerCase()?.endsWith('.jfif')) {
      setError(<FormattedMessage id="imgNotSupported" />);
      return;
    }

    if (maxSize && (file.size / 1000000) > maxSize) {
      setError(<FormattedMessage id="imageMaxSize" values={{ size: maxSize }} />);
      return;
    }

    setImgPreviewSrc(URL.createObjectURL(file));
    setFilename(file.name);

    onChange(file);
  };

  const deleteHandler = () => {
    setImgPreviewSrc(null);
    setFilename('');
    inputRef.current.value = '';
    setError(null);
    onChange('');
  };

  const stPreview = withPreview && imgPreviewSrc;

  return (
    <div className={styles.imgUploaderContainer}>
      {title && <div className={styles.imageTitle}>{title}</div>}
      {stPreview && !previewComponent && (
        <div className={styles.imgPreviewContainer}>
          <img alt="preview-img" src={imgPreviewSrc} />
        </div>
      )}

      {withPreview && previewComponent && (
        <PreviewComponent link={imgPreviewSrc} onDelete={deleteHandler} />
      )}

      <div className={styles.imgUploaderInputContainer}>
        {!previewComponent && (
          <div className={styles.inputContainer}>
            {filename && <ImageNameTag filename={filename} clickDelete={deleteHandler} />}
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            componentStyle={uploadButtonStyle}
          >
            <label className={styles.label}>
              <input
                ref={inputRef}
                onChange={changeHandler}
                className={styles.input}
                type="file"
                accept="image/png, image/gif, image/jpeg, image/tiff"
              />
            </label>
            <FormattedMessage id="upload" />
          </Button>
        </div>
      </div>
      { error && <ErrorMessage message={error} /> }
    </div>

  );
};

const ImageNameTag = ({
  filename, clickDelete = () => {
  },
}) => (
  <div className={styles.imageTagCont}>
    <span>{filename}</span>
    <div className={styles.closeIconCont} onClick={clickDelete}>
      <CloseIcon />
    </div>
  </div>
);

export default ImageUploader;
