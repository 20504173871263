import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import apiService from 'services/apiService';
import { CABINET_ROUTES, COLOR_THEMES } from 'consts';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import Button from 'components/button';
import RightArrowIcon from 'components/svg-icons/right-arrow-icon';
import { FormattedMessage, useIntl } from 'react-intl';
import RouteRoleLink from 'components/route-role-link';
import EmptyPersonIcon from 'components/svg-icons/empty-person-icon';
import { useDispatch, useSelector } from 'react-redux';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import InfiniteScroll from 'components/infinity-scroll';
import TrendWidget from 'components/trend-widget';
import { getImages } from 'utils';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import CollectionCard from 'pages/collections/components/collection-card';
import {
  refreshPeopleState,
  requestCollections,
  requestTrends,
  setPeopleCreateShow, setPeopleFilter,
} from 'store/reducers/people/actions';
import { getCreateShow, getPeopleState } from 'store/reducers/people/selectors';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import ConfirmPopup from 'components/confirm-popup';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import AddPeople from 'pages/people/add-people';
import PeopleTag from 'pages/people/people-tag';
import classNames from 'classnames/bind';
import { getIsClient } from 'store/reducers/general/selectors';
import ClientTrendWidget from 'components/client-trend-widget';
import ReadMore from 'components/read-more';
import useTrendAsideFilters from 'hooks/useTrendAsideFilters';
import { socialLinksConfig } from 'pages/people/top-filters/social-links-config';
import styles from './styles.module.scss';

const {
  TRANSPARENT_LIGHT_WHITE,
  LIGHT,
  PRIMARY,
  SECONDARY,
  TRANSPARENT_FULL,
} = COLOR_THEMES;

const RootList = ({ children }) => (
  <div className={styles.tabsContentContainer}>
    {children}
  </div>
);

const TrendComponent = ({
  data, onRemove, domains, isClient = false,
}) => {
  const { image, firstPostLink } = data;

  const res = getImages(image, firstPostLink, domains);
  const { postImageUrl } = res;
  const allTypesKeys = useSelector(getTypeKeys);
  const ItemWidget = isClient ? ClientTrendWidget : TrendWidget;

  return (
    <div className={styles.itemContainer} key={`trend_id_${data._id}`}>
      <ItemWidget
        onRemove={onRemove}
        trend={data}
        imgLink={postImageUrl}
        typeKeys={allTypesKeys}
        isAdmin
        hideActionBar
      />
    </div>
  );
};

const CollectionComponent = (props) => {
  const {
    userId, domains, data, isClient = false, isAdmin = false,
  } = props;
  return (
    <div className={styles.itemContainer}>
      <CollectionCard
        isClient={isClient}
        isAdmin={isAdmin}
        collectionItem={data}
        userId={userId}
        domains={domains}
        hideActionBar
      />
    </div>
  );
};

const PeopleItemPage = () => {
  const { id } = useParams();
  const { navigate } = UseRoleNavigation();
  const [isTrendsTabActive, setIsTrendsTabActive] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [peopleData, setPeopleData] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const s3Domain = useSelector(getS3Domain);
  const s3 = s3Domain?.startsWith('http') ? s3Domain : `//${s3Domain}`;
  const isCreateShow = useSelector(getCreateShow);
  const isClient = useSelector(getIsClient);
  const { parsersDomains } = useTrendAsideFilters();

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(refreshPeopleState());

    if (!s3Domain) {
      apiService.getParsersInfo().then((result) => {
        dispatch(setParsersInfo(result.data));
      });
    }

    apiService.getPeople(id).then((res) => {
      if (res && res.data) {
        setPeopleData(res.data);
        setDataReady(true);
        return;
      }

      navigate(CABINET_ROUTES.PEOPLE);
    });
  }, [id]);

  const toggleAddPeople = (value) => {
    return () => {
      const toggleVal = typeof value !== 'undefined' ? value : !isCreateShow;
      dispatch(setPeopleCreateShow(toggleVal));
    };
  };

  const editClickHandler = () => {
    dispatch(setPeopleCreateShow(peopleData));
  };

  const deleteClickHandler = () => {
    setShowModalDelete(true);
  };

  const confirmDelete = () => {
    apiService.deletePeople(id).then(() => {
      navigate(CABINET_ROUTES.PEOPLE);
    }).catch(() => {
      setShowModalDelete(false);
    });
  };

  const tabButtonHandler = (value) => {
    if (value === isTrendsTabActive) {
      return;
    }
    dispatch(refreshPeopleState());
    setIsTrendsTabActive(value);
  };

  const tagClick = (value) => {
    dispatch(setPeopleFilter({ name: 'search', value: `#${value}` }, true));
    navigate(`${CABINET_ROUTES.PEOPLE}`);
  };

  const tags = peopleData
    && Array.isArray(peopleData.tags)
    && peopleData.tags.map((tag) => {
      return (
        <PeopleTag key={`tags-${id}-${tag}`} title={tag} clickHandler={tagClick} />
      );
    });

  const tabActiveColor = isClient ? TRANSPARENT_LIGHT_WHITE : PRIMARY;
  const tabColor = isClient ? TRANSPARENT_FULL : SECONDARY;

  const normalizeLink = useCallback((link) => {
    if (typeof link !== 'string') {
      return '';
    }

    return `https://${link.replace(/^https?:(\/\/)?/, '')}`;
  }, []);

  const socialLinkBadges = peopleData
    && Array.isArray(peopleData.socialLinks)
    && peopleData.socialLinks.map((linkData) => {
      const { name, value } = linkData;
      const { icon: Icon, color } = socialLinksConfig[name];

      return (
        <a
          className={styles.socialLinkWrap}
          href={normalizeLink(value)}
          target="_blank"
          rel="noreferrer"
          style={{ background: color }}
          key={`social-link-icon-${name}`}
        >
          {Icon && <Icon />}
        </a>
      );
    });

  return (
    <div
      className="container"
    >
      <div className={styles.peopleItemHeader}>
        <RouteRoleLink path={CABINET_ROUTES.PEOPLE}>
          <Button
            componentStyle={{ mainContainer: styles.backButton }}
            icon={<div className={styles.backIcon}><RightArrowIcon /></div>}
            colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : LIGHT}
          >
            <FormattedMessage id="back" />
          </Button>
        </RouteRoleLink>
      </div>
      <div className={classNames(
        styles.peopleInfoContainer,
        { [styles.peopleClientContainer]: isClient },
      )}
      >
        <div className={styles.peopleAvatar}>
          {peopleData?.imgLink
            ? <img src={`${s3}/${peopleData?.imgLink}`} alt="" />
            : <EmptyPersonIcon />}
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.titleLine}>
            <div className={styles.peopleTitle}>{peopleData?.title}</div>
            {!isClient && (
              <div className={styles.editButtonsPanel}>
                {showModalDelete && (
                  <ConfirmPopup
                    text={intl.formatMessage({ id: 'deleteCharacterConfirm' })}
                    submitText={intl.formatMessage({ id: 'delete' })}
                    onCancel={() => setShowModalDelete(false)}
                    submitFunction={confirmDelete}
                  />
                )}
                {isCreateShow && <AddPeople onCancel={toggleAddPeople(false)} />}
                <Button
                  onlyIcon
                  componentStyle={{
                    mainContainer: styles.editButton,
                  }}
                  colorTheme={COLOR_THEMES.LIGHT}
                  onClick={deleteClickHandler}
                >
                  <DeleteIcon />
                </Button>
                <Button
                  onlyIcon
                  componentStyle={{ mainContainer: styles.editButton }}
                  colorTheme={COLOR_THEMES.LIGHT}
                  onClick={editClickHandler}
                >
                  <EditIcon />
                </Button>
              </div>
            )}
          </div>
          { !!socialLinkBadges?.length && (
            <div className={styles.socialLinksLine}>
              {socialLinkBadges}
            </div>
          )}
          <div className={styles.counterLine}>
            <div className={styles.counterBlock}>
              <div><FormattedMessage id="inTrends" /></div>
              <div>{peopleData?.trendsCount}</div>
            </div>
            <div className={styles.counterBlock}>
              <div><FormattedMessage id="addedToCollection" /></div>
              <div>{peopleData?.collectionsCount}</div>
            </div>
          </div>
          <div className={styles.descriptionLine}>
            <ReadMore>{peopleData?.description || ''}</ReadMore>
          </div>
          {tags && (
            <div className={styles.tagsContainer}>
              {tags}
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.tabsBlock, { [styles.clientTabsBlock]: isClient })}>
        {!!peopleData?.trendsCount && (
          <div className={styles.tabsButtons}>
            <Button
              onClick={() => tabButtonHandler(true)}
              colorTheme={isTrendsTabActive ? tabActiveColor : tabColor}
            >
              {`${intl.formatMessage({ id: 'trends' })} (${peopleData?.trendsCount || '0'})`}
            </Button>
            <Button
              onClick={() => tabButtonHandler(false)}
              colorTheme={isTrendsTabActive ? tabColor : tabActiveColor}
            >
              {`${intl.formatMessage({ id: 'collections' })} (${peopleData?.collectionsCount || '0'})`}
            </Button>
          </div>
        )}
        {dataReady && !!peopleData?.trendsCount && (
          <div className={styles.tabsContent}>
            {isTrendsTabActive ? (
              <InfiniteScroll
                key="people-scroll-trends"
                RootComponent={RootList}
                RenderComponent={TrendComponent}
                dataSelector={getPeopleState}
                loadAction={requestTrends}
                listItemProps={{ domains: parsersDomains, isClient }}
                parentId={id}
              />
            ) : (
              <InfiniteScroll
                key="people-scroll-collections"
                loadAction={requestCollections}
                emptyMessage={<FormattedMessage id="emptyCollectionList" />}
                RenderComponent={CollectionComponent}
                RootComponent={RootList}
                dataSelector={getPeopleState}
                listItemProps={{ domains: parsersDomains, isClient }}
                parentId={id}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PeopleItemPage;
