export const SET_PEOPLE = 'SET_PEOPLE';
export const SET_PEOPLE_LOADING = 'SET_PEOPLE_LOADING';
export const SET_PEOPLE_FILTER = 'SET_PEOPLE_FILTER';
export const SET_PEOPLE_CREATE_SHOW = 'SET_PEOPLE_CREATE_SHOW';
export const PEOPLE_LOAD_START = 'PEOPLE_LOAD_START';
export const PEOPLE_LOAD_SUCCESS = 'PEOPLE_LOAD_SUCCESS';
export const RESET_PEOPLE_FILTERS = 'RESET_PEOPLE_FILTERS';
export const SET_CHECKED_PEOPLE = 'SET_CHECKED_PEOPLE';
export const CLEAR_CHECKED_PEOPLE = 'CLEAR_CHECKED_PEOPLE';
export const REFRESH_STATE = 'REFRESH_STATE';
export const REMOVE_PEOPLE = 'REMOVE_PEOPLE';
