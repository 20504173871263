import React from 'react';

const AddImageIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#252B30" fillOpacity="0.77" />
    <path d="M15.6225 9.97502C15.6225 9.8301 15.565 9.69111 15.4625 9.58863C15.36 9.48616 15.221 9.42859 15.0761 9.42859C14.9312 9.42859 14.7922 9.48616 14.6897 9.58863C14.5873 9.69111 14.5297 9.8301 14.5297 9.97502V14.5286H9.97612C9.83119 14.5286 9.69221 14.5862 9.58973 14.6886C9.48726 14.7911 9.42969 14.9301 9.42969 15.075C9.42969 15.2199 9.48726 15.3589 9.58973 15.4614C9.69221 15.5639 9.83119 15.6214 9.97612 15.6214H14.5297V20.175C14.5297 20.3199 14.5873 20.4589 14.6897 20.5614C14.7922 20.6639 14.9312 20.7214 15.0761 20.7214C15.221 20.7214 15.36 20.6639 15.4625 20.5614C15.565 20.4589 15.6225 20.3199 15.6225 20.175V15.6214H20.1761C20.321 15.6214 20.46 15.5639 20.5625 15.4614C20.665 15.3589 20.7225 15.2199 20.7225 15.075C20.7225 14.9301 20.665 14.7911 20.5625 14.6886C20.46 14.5862 20.321 14.5286 20.1761 14.5286H15.6225V9.97502Z" fill="white" />
  </svg>
);

export default AddImageIcon;
