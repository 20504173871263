import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from 'components/protected-route';
import { CABINET_ROUTES, LOGIN_ROLES } from 'consts';
import ReportsPage from 'pages/reports';
import ErrorPage from 'pages/error404';
import ReportPage from 'client/pages/report';
import CreateReportPage from 'pages/create-report';
import CreateTrend from 'pages/create-trend';
import TrendsPage from 'pages/trends';
import TrendItemPage from 'client/pages/trend';
import WordCloudPage from 'pages/word-cloud';
import wsApiService from 'services/wsApiService';
import CollectionsPage from 'pages/collections';
import PeoplePage from 'pages/people';
import PeopleItemPage from 'pages/people/people-item-page';
import PeopleCollectionsPage from 'pages/people-collections';
import PeopleCollectionItemPage from 'pages/people-collections/people-collection-item-page';

const { WRITER } = LOGIN_ROLES;
const {
  REPORTS,
  CREATE_REPORT,
  TRENDS,
  CREATE_TREND,
  COLLECTIONS,
  WORD_CLOUD,
  PEOPLE,
  PEOPLE_COLLECTIONS,
} = CABINET_ROUTES;

const WriterCabinet = () => {
  const { path } = useRouteMatch();

  useEffect(() => {
    wsApiService.init();
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/${CREATE_TREND}`}>
          <CreateTrend isAdmin />
        </Route>
        <Route exact path={`${path}/${TRENDS}`}>
          <TrendsPage key="trends" />
        </Route>
        <Route exact path={`${path}/${TRENDS}/:id/edit`}>
          <CreateTrend isEdit isAdmin />
        </Route>

        <Route exact path={`${path}/${TRENDS}/:id`}>
          <TrendItemPage isPreview />
        </Route>

        <Route exact path={`${path}/${CREATE_REPORT}`}>
          <CreateReportPage />
        </Route>
        <Route exact path={`${path}/${REPORTS}`}>
          <ReportsPage />
        </Route>
        <Route exact path={`${path}/${REPORTS}/:id`}>
          <ReportPage isPreview />
        </Route>
        <Route exact path={`${path}/${REPORTS}/:id/edit`}>
          <CreateReportPage isEdit />
        </Route>
        <Route exact path={`${path}/${COLLECTIONS}`}>
          <CollectionsPage />
        </Route>
        <Route exact path={`${path}/${COLLECTIONS}/:id`}>
          <TrendsPage key="collection-trends" isCollection />
        </Route>
        <Route exact path={`${path}/${WORD_CLOUD}`}>
          <WordCloudPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE}`}>
          <PeoplePage />
        </Route>
        <Route exact path={`${path}/${PEOPLE}/:id`}>
          <PeopleItemPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE_COLLECTIONS}`}>
          <PeopleCollectionsPage />
        </Route>
        <Route exact path={`${path}/${PEOPLE_COLLECTIONS}/:id`}>
          <PeopleCollectionItemPage />
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export default () => <ProtectedRoute roleName={WRITER}><WriterCabinet /></ProtectedRoute>;
