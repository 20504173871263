import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { CURRENT_YEAR, MAIN_ROUTES } from 'consts';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import GlossaryLink from 'admin/pages/glossary/link';
import styles from './styles.module.scss';

const { CONTACT_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS } = MAIN_ROUTES;

const ClientFooter = () => (
  <div className={classNames(styles.footer, styles.client)}>
    <div className="container">
      <div className={styles.footerContent}>
        <a href="/">
          <ClientLogoIcon />
        </a>
        <div className={styles.footerClientLinksBlock}>
          <a href={CONTACT_US}>
            <FormattedMessage id="contactUs" />
          </a>
          <a href={PRIVACY_POLICY}>
            <FormattedMessage id="privacyPolicy" />
          </a>
          <a href={TERMS_AND_CONDITIONS}>
            <FormattedMessage id="termsAndConditions" />
          </a>
          <GlossaryLink />
        </div>
        <span className={classNames(styles.copyright, styles.client)}>
          Copyright &copy; &nbsp;
          2010-{CURRENT_YEAR} {process.env.REACT_APP_TITLE} .All rights reserved
        </span>
      </div>
    </div>
  </div>
);

export default ClientFooter;
