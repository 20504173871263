import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR_THEMES, CABINET_ROUTES, REPORT_STATUSES } from 'consts';
import { useParams } from 'react-router-dom';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import { getCategories } from 'store/reducers/categories/selectors';
import { requestCategories } from 'store/reducers/categories/actions';
import Button from 'components/button';
import UseFormData from 'hooks/useFormData';
import { createEmptyTrend } from 'pages/create-report/form-factory';
import { getCompanies } from 'store/reducers/companies/selectors';
import { requestCompanies } from 'store/reducers/companies/actions';
import { requestHashtags } from 'store/reducers/hashtags/actions';
import ApiService from 'services/apiService';
import CreatingLoader from 'components/creating-loader';
import LoadingComponent from 'components/loadingComponent';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import { requestTypeKeys } from 'store/reducers/type-keys/actions';
import notificationService from 'services/notificationService';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import ReportGeneralInfo from './components/report-general-info';
import TrendForm from './components/trend-form';
import reportValidator from './report-validator';
import styles from './styles.module.scss';

const { REPORTS } = CABINET_ROUTES;

const initialData = {
  title: '',
  description: '',
  trends: [createEmptyTrend()],
  sharedCompanyId: '',
  status: '',
};

const CreateReportPage = (props) => {
  const { isEdit, isAdmin } = props;
  const params = useParams();

  const { navigate } = UseRoleNavigation();

  const intl = useIntl();
  const [loading, setLoading] = useState(isEdit);

  const dispatch = useDispatch();

  const [reportData, setReportData] = useState(initialData);

  useEffect(() => {
    if (!isEdit) {
      setReportData({ ...initialData });
      return;
    }

    ApiService.getReport(params.id).then((res) => {
      if (res.error) {
        notificationService.addNotification(res.error);
        navigate(`${REPORTS}/${params.id}`);
        return;
      }
      setReportData(res.data);
      setLoading(false);
    });
  }, [isEdit]);

  const companies = useSelector(getCompanies);
  const trendCategories = useSelector(getCategories);
  const typeKeys = useSelector(getTypeKeys);

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [], s3Domain = '' } = parsersData;
        dispatch(setParsersInfo({ parsersDomains, s3Domain }));
      }
    });

    dispatch(requestCompanies());
    dispatch(requestCategories());
    dispatch(requestTypeKeys());
    dispatch(requestHashtags());
  }, []);

  const [error, setError] = useState(null);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: reportData,
    validators: reportValidator,
  });

  const onAddNewTrend = () => {
    const { trends } = data;
    onChange('trends', [...trends, createEmptyTrend()]);
  };

  const onDeleteTrend = (deletedId) => {
    setError(null);
    const { trends } = data;
    onChange('trends', trends.filter(({ _id }) => deletedId !== _id));
  };

  const cancelSaveHandler = async () => {
    await ApiService.cancelEditReport(params.id);
    navigate(`${REPORTS}/${params.id}`);
  };

  const saveHandler = async (status) => {
    setError(null);
    const validateObj = validateForm();

    if (status) {
      data.status = status;
    }

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setLoading(true);

    const resp = await ApiService.editReport(params.id, data);
    if (resp && resp.status < 400) {
      navigate(`${REPORTS}/${params.id}`);
    }
  };

  const declineApprovedHandler = async () => {
    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setLoading(true);

    const resp = await ApiService.declineApprovedReport(params.id, data);
    if (resp && resp.status < 400) {
      navigate(`${REPORTS}/${params.id}`);
    }
  };

  const createHandler = async (status) => {
    const dataToRequest = { ...data, status };

    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
    } else {
      setLoading(true);
      const resp = await ApiService.addReport(dataToRequest);
      if (resp && resp.status < 400) {
        navigate(`${REPORTS}/${resp.data.parentId}`);
      }
    }
  };

  const { trends = [], status } = data;

  const normalizeData = (data) => {
    return data.map((dataItem) => {
      const { _id: value, title: name } = dataItem;
      return { value, name };
    });
  };

  const normalizeTypeKeys = (data) => {
    return data.map((dataItem) => {
      const { _id: value, title: name, emoji } = dataItem;
      return { value, name: `${emoji} ${name}` };
    });
  };

  const getReportHeaderPage = () => {
    if (!isEdit) {
      return <h2 className={styles.title}><FormattedMessage id="reportGeneration" /></h2>;
    }

    return <h2 className={styles.title}><FormattedMessage id="editReport" /></h2>;
  };

  const canSendToApprove = trends.length;

  const loadingComponentText = intl.formatMessage({ id: reportData === initialData ? 'reportEditInitialMessage' : 'reportEditSavingMessage' });

  return (
    <div className="container">
      {/* eslint-disable-next-line no-nested-ternary */}
      { loading
        ? (isEdit ? <LoadingComponent text={loadingComponentText} /> : <CreatingLoader />)
        : (
          <>
            <div className={styles.row}>
              <div className={styles.asideSection}>=
                {isAdmin && status === REPORT_STATUSES.APPROVED && (
                <Button
                  componentStyle={{
                    mainContainer: styles.declineButton,
                    textContainer: styles.declineButtonText,
                  }}
                  colorTheme={COLOR_THEMES.LIGHT}
                  onClick={declineApprovedHandler}
                >
                  <FormattedMessage id="declineReport" />
                </Button>
                )}
              </div>
              {getReportHeaderPage()}
              <div className={styles.asideSection} />
            </div>
            <ReportGeneralInfo
              onChange={onChange}
              data={data}
              error={error}
              companies={normalizeData(companies)}
            />
            { trends.map((trendData, index) => (
              <TrendForm
                trends={trends}
                key={trendData._id}
                data={trendData}
                indexTrend={index}
                onDelete={onDeleteTrend}
                onChange={onChange}
                clearError={() => setError(null)}
                error={error && error.trends && error.trends[index]}
                typeKeys={normalizeTypeKeys(typeKeys)}
                categories={normalizeData(trendCategories)}
              />
            )) }
            <div className={styles.footer}>
              <Button colorTheme={COLOR_THEMES.LIGHT} onClick={onAddNewTrend} size="medium">
                <FormattedMessage id="addTrend" />
              </Button>
              <div className={styles.footerRightContent}>
                <Button
                  colorTheme={COLOR_THEMES.LIGHT}
                  size="medium"
                  onClick={() => (isEdit
                    ? saveHandler(
                      status === REPORT_STATUSES.PENDING
                        ? undefined
                        : REPORT_STATUSES.DRAFT,
                    )
                    : createHandler(REPORT_STATUSES.DRAFT))}
                >
                  <FormattedMessage id={isEdit ? 'saveChanges' : 'saveDraft'} />
                </Button>
                <Button
                  colorTheme={COLOR_THEMES.LIGHT}
                  size="medium"
                  onClick={cancelSaveHandler}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  size="medium"
                  disabled={!canSendToApprove}
                  onClick={() => (isEdit
                    ? saveHandler(REPORT_STATUSES.PENDING)
                    : createHandler(REPORT_STATUSES.PENDING))}
                >
                  <FormattedMessage id="forApproval" />
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default CreateReportPage;
