import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { COLOR_THEMES } from 'consts';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import Button from 'components/button';
import SearchIcon from 'components/svg-icons/search-icon';
import AddUserPopup from 'components/add-user-popup';
import { getStaffFilters } from 'store/reducers/staff/selectors';
import { setStaffFilter } from 'store/reducers/staff/actions';
import styles from './styles.module.scss';

const UsersFilters = (props) => {
  const intl = useIntl();
  const { usersCount } = props;
  const filters = useSelector(getStaffFilters);
  const dispatch = useDispatch();

  const [addUser, setAddUser] = useState(false);

  const openAddUserPopup = () => {
    setAddUser(true);
  };

  const closeAddUserPopup = () => {
    setAddUser(false);
  };

  const onFilterChange = (name, value) => {
    dispatch(setStaffFilter(name, value));
  };

  const { searchEmail, sortEmailDest } = filters;

  return (
    <>
      { addUser && <AddUserPopup isStaff onCancel={closeAddUserPopup} /> }
      <Button onClick={openAddUserPopup} colorTheme={COLOR_THEMES.LIGHT}>{intl.formatMessage({ id: 'addUser' })}</Button>
      <Select
        onChange={onFilterChange}
        name="sortEmailDest"
        value={sortEmailDest}
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'asc' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'desc' },
        ]}
      />
      <Input
        onChange={onFilterChange}
        name="searchEmail"
        value={searchEmail}
        icon={<SearchIcon />}
        placeholder={intl.formatMessage({ id: 'typeUsersEmail' })}
      />
      <div className={styles.usersCount}>
        {usersCount}
        &nbsp;
        {intl.formatMessage({ id: 'users' })}
      </div>
    </>
  );
};

export default UsersFilters;
