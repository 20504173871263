import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Input from 'components/fields/input';
import Button from 'components/button';
import ImageUploader from 'components/image-uploader';
import CloseIcon from 'components/svg-icons/close-icon';
import Spinner from 'components/spinner';
import SuccessParsingIcon from 'components/svg-icons/success-parsing-icon';
import ErrorParsingIcon from 'components/svg-icons/error-parsing-icon';
import notificationService from 'services/notificationService';
import FieldRow from 'pages/create-report/components/field-row';
import ApiService from 'services/apiService';
import { validateUrl } from 'utils';
import { getParsersDomains } from 'store/reducers/parsers/selectors';
import MetricsItem from './metrics-item';
import styles from './styles.module.scss';

const checkParsedDataDelay = 3000;

const LinkForm = (props) => {
  const {
    data,
    indexLink = 0,
    onDelete = () => {
    },
    onChange = () => {
    },
    error,
  } = props;

  const {
    _id,
    link,
    title,
    image,
    metrics = {
      likes: 0,
      shares: 0,
      comments: 0,
      views: 0,
    },
  } = data;

  const linkId = `id-link-${_id}`;

  const [manualFormShow, setManualFormShow] = useState(true);
  const [parsingStatus, setParsingStatus] = useState(null);
  const [parsingLink, setParsingLink] = useState(false);
  const [imgParseTrigger, setImgParseTrigger] = useState(false);

  const [checkParsedInterval, setCheckParsedInterval] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [notifId, setNotifId] = useState(null);

  const [objectToChange, setObjectToChange] = useState(null);

  const parsersDomains = useSelector(getParsersDomains);
  const intl = useIntl();

  useEffect(() => {
    return () => {
      if (checkParsedInterval) {
        clearInterval(checkParsedInterval);
      }
    };
  }, []);

  const removeNotification = useCallback(() => {
    notificationService.removeNotification(notifId);
  }, [notifId]);

  useEffect(() => {
    return removeNotification;
  }, [removeNotification]);

  const showNotiffication = (success, existingLink) => {
    const newNotifId = notificationService.addNotification(
      intl.formatMessage(
        { id: success ? 'parsingPostSuccess' : 'unableToParseLink' },
      ),
      null,
      {
        linkName: existingLink || parsingLink,
        linkId,
        success,
      },
    );

    if (notifId) {
      notificationService.removeNotification(notifId);
    }

    setParsingStatus(success ? 'Success' : 'Error');
    setNotifId(newNotifId);
    setParsingLink(null);
  };

  useEffect(() => {
    if (requestId) {
      if (checkParsedInterval) {
        clearInterval(checkParsedInterval);
      }

      const newParseInterval = setInterval(async () => {
        const response = await ApiService.checkParsedData(requestId);
        const parsedData = response?.data?.parsedData;
        const parsingError = response?.error;
        const paramObj = { link: parsingLink };

        if (!response || parsingError) {
          showNotiffication(false);
          clearInterval(newParseInterval);
          setObjectToChange(paramObj);
          return;
        }

        if (parsedData) {
          clearInterval(newParseInterval);
          const paramObj = { link: parsingLink };

          showNotiffication(true);

          paramObj.title = parsedData.title || '';

          const maxTitleSize = 40;
          if (paramObj.title.length > maxTitleSize) {
            paramObj.title = `${paramObj.title.substring(0, maxTitleSize - 3)}...`;
          }

          if (parsedData.metrics) {
            const {
              views = 0, comments = 0, shares = 0, likes = 0,
            } = parsedData.metrics;

            const newMetricCounts = {
              views, comments, shares, likes,
            };

            paramObj.metrics = newMetricCounts;
          }

          paramObj.image = parsedData.imgLink;
          setImgParseTrigger(!imgParseTrigger);
          setRequestId(null);

          setObjectToChange(paramObj);
        }
      }, checkParsedDataDelay);

      setCheckParsedInterval(newParseInterval);
    }
  }, [requestId]);

  useEffect(() => {
    if (objectToChange) {
      onChange(_id, objectToChange);
    }
  }, [objectToChange]);

  const isDomainExists = (link) => {
    const linkUrl = new URL(link);
    const linkDomain = linkUrl.host;

    return parsersDomains && parsersDomains.length && parsersDomains.includes(linkDomain);
  };

  const onPostDataChange = async (name, value) => {
    if (name !== 'link') {
      return onChange(_id, name, value);
    }

    const valueLength = value?.length;

    if (!valueLength || valueLength < 10 || !validateUrl(value.trim())) {
      return null;
    }

    const paramObj = { link: value };

    setParsingLink(value);

    if (!isDomainExists(value)) {
      showNotiffication(false, value);
      return onChange(_id, paramObj);
    }

    const response = await ApiService.sendParseLinkRequest(value);

    const { data, status } = response;

    if (data && status === 200) {
      const { requestId } = data;

      if (!requestId) {
        return showNotiffication(false);
      }

      return setRequestId(requestId);
    }

    return onChange(_id, paramObj);
  };

  const onDeleteLink = () => {
    onDelete(_id);
  };

  const buttonStyle = {
    mainContainer: styles.addManualButtonMain,
    textContainer: styles.addManualButtonText,
  };

  const onMetricCountsChange = (type, value) => {
    const newMetricCounts = { ...metrics };
    newMetricCounts[type] = value;
    onChange(_id, 'metrics', newMetricCounts);
  };

  const onImageChange = async (file) => {
    if (!file) {
      onChange(_id, 'image', undefined);
      return;
    }

    const formData = new FormData();
    formData.append('post-img', file);

    const fileUrl = await ApiService.sendFile(formData);
    onChange(_id, 'image', fileUrl);
  };

  const parsingMessageId = parsingStatus && `parsingPost${parsingStatus}`;
  const parsingMessageStyle = parsingStatus && `parsingMessage${parsingStatus}`;
  const ParsingIcon = parsingStatus && parsingStatus === 'Success' ? SuccessParsingIcon : ErrorParsingIcon;

  return (
    <div className={styles.linkForm} id={linkId}>
      <span className={styles.removeLinkButton} onClick={onDeleteLink}>
        <CloseIcon />
      </span>
      <FieldRow label={intl.formatMessage({ id: 'headline' })}>
        <Input
          value={title}
          placeholder={intl.formatMessage({ id: 'enterHeadlinePost' })}
          fullWidth
          name="title"
          onChange={onPostDataChange}
          errorMessage={error && error.title}
        />
      </FieldRow>
      <FieldRow label={`${intl.formatMessage({ id: 'link' })} #${indexLink + 1}`}>
        <div className={classNames(
          styles.inputLinkWrapper,
          { [styles.loadingStateStyle]: parsingLink },
          { [styles.notValid]: (error && error.link && (!parsingStatus || !link)) },
        )}
        >
          <Input
            value={link}
            placeholder={intl.formatMessage({ id: 'putLinkOfPost' })}
            fullWidth
            name="link"
            onChange={onPostDataChange}
            delay={2000}
            notBordered
            disabled={parsingLink}
          />
          {parsingLink && (
            <div className={styles.indicatorWrapper}>
              <Spinner size="sm" />
            </div>
          )}
          {parsingStatus && !parsingLink && (
            <div className={styles.indicatorWrapper}>
              <ParsingIcon />
            </div>
          )}
        </div>

        {parsingLink && (
          <div className={classNames(styles.parsingMessageContainer, styles.parsingLinkMessage)}>
            <FormattedMessage id="parsing" />...
          </div>
        )}
        {parsingStatus && !parsingLink && (
          <div className={classNames(styles.parsingMessageContainer, styles[parsingMessageStyle])}>
            <FormattedMessage id={parsingMessageId} />
          </div>
        )}
        {error && error.link && (!parsingStatus || !link) && (
          <div className={classNames(styles.parsingMessageContainer, styles.parsingMessageError)}>
            {error.link}
          </div>
        )}

      </FieldRow>

      <div className={styles.manualForm}>
        <div className={styles.addManualButtonRow}>
          {!manualFormShow
          && (
            <Button onClick={() => setManualFormShow(!manualFormShow)} componentStyle={buttonStyle}>
              <FormattedMessage id="addInfoManually" />
            </Button>
          )}
        </div>

        <div
          className={classNames(
            styles.addManualContainer,
            { [styles.addManualShow]: manualFormShow },
          )}
        >
          <div className={styles.innerCont}>
            <ImageUploader
              maxSize={5}
              title={intl.formatMessage({ id: 'image' })}
              withPreview
              onChange={onImageChange}
              imageData={image}
              parseTrigger={imgParseTrigger}
            />

            <div className={styles.metricsLineContainer}>
              {Object.keys(metrics)
                .map((type) => (
                  <MetricsItem
                    onChange={onMetricCountsChange}
                    key={`metrics_${_id}_${type}`}
                    type={type}
                    value={metrics[type]}
                  />
                ))}
            </div>
            <span className={styles.leaveMetricHint}><FormattedMessage id="emptyMetricLeave" /></span>
          </div>

          <div className={styles.addManualButtonRow}>
            <Button componentStyle={buttonStyle} disabled>
              <FormattedMessage id="useParsedInfo" />
            </Button>
          </div>
        </div>

      </div>

    </div>
  );
};

export default LinkForm;
