import { createValidator } from 'hooks/useFormData/validation';

const contactUsValidator = {
  email: createValidator([{ type: 'required' }, { type: 'email', message: 'Wrong email format' }]),
  subject: createValidator([{ type: 'required' }, { type: 'maxLength', value: 63, message: 'Wrong subject format' }]),
  message: createValidator([{ type: 'required' }]),
  recaptchaValue: createValidator([{ type: 'required' }]),
};

export default contactUsValidator;
