import React from 'react';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'components/dateFormatter';
import Button from 'components/button';
import RouteRoleLink from 'components/route-role-link';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import { COLOR_THEMES } from 'consts';
import Badge from './badge';
import styles from './styles.module.scss';

const ClientReportWidget = ({ report, imgLink }) => {
  const {
    _id: id,
    createdAt,
    title,
    description,
    trends,
    totalMetrics,
  } = report;

  const getTrends = () => {
    if (!trends || !trends.length) {
      return null;
    }

    return trends.map((trend) => (
      <div className={styles.trendItem} key={`key_trends_${id}_${trend}`}>{trend}</div>
    ));
  };

  const getBadges = () => {
    if (!totalMetrics) {
      return null;
    }

    const badgesTypes = Object.keys(totalMetrics);
    const badgeComponents = [];

    badgesTypes
      .forEach((badgeType) => {
        const metricValue = totalMetrics[badgeType];

        if (metricValue) {
          badgeComponents.push(<Badge type={badgeType} count={metricValue} key={`key_badge_${id}_${badgeType}`} />);
        }
      });

    return badgeComponents;
  };

  return (
    <div className={styles.reportContainer}>
      <div className={styles.date}>
        <DateFormatter date={createdAt} />
      </div>

      <div className={styles.imageWraper}>
        <div className={styles.image}>
          { imgLink ? <img src={imgLink} alt="report-img" /> : <ClientLogoIcon />}
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.title}>
          {title}
        </div>

        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className={styles.trends}>
          <FormattedMessage id="trends" />
        </div>

        <div className={styles.trendsContainer}>
          {getTrends()}
        </div>

        <div className={styles.badgesLine}>
          {getBadges()}
        </div>
      </div>
      <RouteRoleLink path={`reports/${id}`}>
        <Button
          colorTheme={COLOR_THEMES.PRIMARY_GRADIENT}
          componentStyle={{
            mainContainer: styles.viewButton,
            textContainer: styles.viewButtonText,
          }}
        >
          <FormattedMessage id="viewFullReport" />
        </Button>
      </RouteRoleLink>
    </div>
  );
};

export default ClientReportWidget;
