import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Creatable from 'react-select/creatable';
import { useSelector } from 'react-redux';
import { getHashtags } from 'store/reducers/hashtags/selectors';
import './styles.module.scss';

const TagsRow = (props) => {
  const { tags, onChange = () => {} } = props;

  const intl = useIntl();
  const hashtags = useSelector(getHashtags);

  const tagVals = tags && tags.length ? tags.map((tag) => ({ value: tag, label: `# ${tag}` })) : [];

  const options = hashtags ? hashtags.map((hashtag) => ({
    value: hashtag.title, label: `# ${hashtag.title}`,
  })) : [];

  return (
    <div>
      <Creatable
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder={intl.formatMessage({ id: 'enterHashtags' })}
        maxMenuHeight={300}
        isValidNewOption={(value) => !/(?!^)#/.test(value)}
        onChange={onChange}
        value={tagVals}
        isMulti
      />
      <div className="tags-footer-description">
        <FormattedMessage id="enterTagsDescription" />
      </div>
    </div>
  );
};

export default TagsRow;
