import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import HeadingFilters from 'components/heading-filters';
import CompaniesFilters from 'admin/pages/companies/filters';
import CompaniesList from 'admin/pages/companies/list';
import { getCompaniesLoading, getCompanies } from 'store/reducers/companies/selectors';
import { requestCompanies, resetCompanyUsersFilters } from 'store/reducers/companies/actions';
import LoadingComponent from 'components/loadingComponent';

const CompaniesPage = () => {
  const companies = useSelector(getCompanies);
  const isLoading = useSelector(getCompaniesLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCompanies());

    return () => {
      dispatch(resetCompanyUsersFilters());
    };
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <HeadingFilters title={<FormattedMessage id="companiesList" />} FiltersComponent={<CompaniesFilters />} />
      <div className="container">
        <CompaniesList companies={companies} />
      </div>
    </>
  );
};

export default CompaniesPage;
