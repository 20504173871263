import apiService from 'services/apiService';
import {
  SET_TREND_TYPE_KEYS,
  SET_TREND_TYPE_KEYS_LOADING,
  SET_TREND_TYPE_KEYS_FILTER,
  SET_TREND_TYPE_KEYS_ERROR,
} from './action.types';

const setTypeKeys = (payload) => ({
  type: SET_TREND_TYPE_KEYS,
  payload,
});

export const setTypeKeysError = (error) => ({
  type: SET_TREND_TYPE_KEYS_ERROR,
  error,
});

export const setTypeKeysLoading = (loading) => ({
  type: SET_TREND_TYPE_KEYS_LOADING,
  loading,
});

export const setTypeKeysFilter = (name, value) => ({
  type: SET_TREND_TYPE_KEYS_FILTER,
  name,
  value,
});

export const requestTypeKeys = () => (dispatch) => {
  dispatch(setTypeKeysLoading(true));
  apiService.getTypeKeys()
    .then((data) => {
      dispatch(setTypeKeys(data));
      dispatch(setTypeKeysLoading(false));
    });
};

export const addTypeKey = (data) => (dispatch) => apiService.addTypeKey(data)
  .then((data) => {
    dispatch(setTypeKeysError(null));

    if (!data.error) {
      dispatch(requestTypeKeys());
      return;
    }

    dispatch(setTypeKeysLoading(false));
    dispatch(setTypeKeysError(data.error));
  });

export const editTypeKey = (id, data) => (dispatch) => apiService
  .editTypeKey(id, data)
  .then((res) => {
    dispatch(setTypeKeysError(null));

    if (!res.error) {
      dispatch(requestTypeKeys());
      return;
    }

    dispatch(setTypeKeysLoading(false));
    dispatch(setTypeKeysError(res.error));
  });

export const deleteTypeKey = (id) => (dispatch) => apiService
  .deleteTypeKey(id)
  .then(() => {
    dispatch(requestTypeKeys());
  });
