import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import InfiniteScroll from 'components/infinity-scroll';
import { requestPeopleCollections, resetPeopleCollectionFilters } from 'store/reducers/people-collection/actions';
import PeopleNoResults from 'pages/people/people-no-results';
import { getPeopleCollectionFilters, getPeopleCollectionState } from 'store/reducers/people-collection/selectors';
import PeopleCollectionsItem from 'pages/people-collections/people-collections-item';
import useWindowSize from 'hooks/useWindowSize';
import CreateCollectionPopup from 'components/create-collection-popup';
import { getCollectionPopup } from 'store/reducers/trends/selectors';
import { getIsClient } from 'store/reducers/general/selectors';
import { setCollectionPopup } from 'store/reducers/trends/actions';
import apiService from 'services/apiService';
import { COLLECTION_TYPE } from 'consts';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import { getUser } from 'store/reducers/user/selectors';
import EmptyListMessage from 'components/empty-list-message';
import styles from './styles.module.scss';
import CollectionFilters from './components/collection-filters';

const ListComponent = ({
  data, windowWidth, isAdmin, userId,
}) => {
  const { _id = 0 } = data;

  return (
    <div className={styles.itemContainer} key={`people_collection_id_${_id}`}>
      <PeopleCollectionsItem
        userId={userId}
        data={data}
        windowWidth={windowWidth}
        isAdmin={isAdmin}
      />
    </div>
  );
};

const PeopleCollectionsPage = () => {
  const [windowWidth] = useWindowSize();
  const collectionPopup = useSelector(getCollectionPopup);
  const { search } = useSelector(getPeopleCollectionFilters);
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(getUser);

  useEffect(() => {
    apiService.getParsersInfo().then((result) => {
      dispatch(setParsersInfo(result.data));
    });

    return () => {
      dispatch(resetPeopleCollectionFilters());
    };
  }, []);

  const closeCollectionPopups = () => {
    dispatch(setCollectionPopup(null));
  };

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="peopleCollections" />}
        FiltersComponent={<CollectionFilters />}
      />
      { collectionPopup && collectionPopup.name === 'createPeopleCollection' && (
        <CreateCollectionPopup
          onClose={closeCollectionPopups}
          isClient={isClient}
          needToRefresh
          type={COLLECTION_TYPE.PEOPLE}
          {...collectionPopup.data}
        />
      ) }
      <div className="container">
        <div>
          <InfiniteScroll
            RootComponent={RootList}
            RenderComponent={ListComponent}
            dataSelector={getPeopleCollectionState}
            loadAction={requestPeopleCollections}
            emptyMessage={search
              ? <PeopleNoResults message={intl.formatMessage({ id: 'noPeopleCollections' })} />
              : <EmptyListMessage messageId="emptyPeopleCollectionList" />}
            listItemProps={{ windowWidth, userId: user?._id }}
          />
        </div>
      </div>
    </>
  );
};

export default PeopleCollectionsPage;
