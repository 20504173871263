import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import LoadingComponent from 'components/loadingComponent';
import { requestTypeKeys, setTypeKeysLoading } from 'store/reducers/type-keys/actions';
import { getTypeKeys, getTypeKeysLoading } from 'store/reducers/type-keys/selectors';
import TypeKeysList from './list';
import TypeKeysFilters from './filters';

const TypeKeysPage = () => {
  const typeKeys = useSelector(getTypeKeys);
  const isLoading = useSelector(getTypeKeysLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestTypeKeys());

    return () => {
      dispatch(setTypeKeysLoading(true));
    };
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <HeadingFilters
        title={<FormattedMessage id="typeKeys" />}
        FiltersComponent={<TypeKeysFilters />}
      />
      <div className="container">
        <TypeKeysList typeKeys={typeKeys} />
      </div>
    </>
  );
};

export default TypeKeysPage;
