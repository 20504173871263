import {
  SET_COLLECTIONS,
  SET_COLLECTION,
  COLLECTIONS_LOAD_START,
  DELETE_COLLECTION_SUCCESS,
  RESET_COLLECTION,
  COLLECTION_LOAD_ERROR,
  SET_COLLECTION_FILTER,
  RESET_COLLECTION_FILTERS,
  FILTER_EXCLUDED_TREND,
} from 'store/reducers/collections/action.types';

const initialState = {
  list: {
    data: [],
    loading: true,
    page: 1,
    hasMore: true,
    search: '',
    sorting: 'date_DESC',
    requestAll: false,
    excludedTrend: null,
  },
  collectionItem: null,
  collectionLoadError: null,
};

const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_LOAD_ERROR:
      return { ...state, collectionLoadError: action.payload };
    case RESET_COLLECTION:
      return { ...state, collectionItem: null };
    case SET_COLLECTION: {
      let result = { ...state, collectionItem: action.collection };

      if (state.list.data.length) {
        const data = [...state.list.data];

        const index = data.findIndex((item) => {
          return item._id === action.collection._id;
        });

        if (index !== -1) {
          const existItem = data[index];
          data[index] = { ...existItem, ...action.collection };

          result = {
            ...result,
            list: {
              ...state.list,
              data,
            },
          };
        }
      }

      return result;
    }
    case COLLECTIONS_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          page: action.reset ? 1 : state.list.page + 1,
          data: action.reset ? [] : [...state.list.data],
        },
      };
    case SET_COLLECTIONS:
      return {
        ...state,
        list: {
          ...state.list,
          data: [...state.list.data, ...action.payload.data],
          hasMore: action.payload.hasMore,
          loading: false,
        },
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter(({ _id }) => _id !== action.payload.id),
        },
      };
    case SET_COLLECTION_FILTER:
      // eslint-disable-next-line no-case-declarations
      const { name, value } = action.payload;

      return {
        ...state,
        list: { ...state.list, [name]: value },
      };

    case RESET_COLLECTION_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          search: '',
          sorting: 'date_DESC',
          requestAll: false,
          excludedTrend: null,
        },
      };

    case FILTER_EXCLUDED_TREND:
      return {
        ...state,
        list: {
          ...state.list,
          excludedTrend: action.trendId,
        },
      };

    default:
      return state;
  }
};

export default collectionsReducer;
