import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import CollapseIcon from 'components/svg-icons/collapse-icon';
import styles from './styles.module.scss';

const ReadMore = ({ children, symbols = 350 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const isNeedReadMore = text.length > symbols;

  return (
    <>
      { isReadMore && isNeedReadMore ? `${text.slice(0, symbols)}...` : text }
      { isNeedReadMore && (
        <>
          &nbsp;
          <span
            onClick={toggleReadMore}
            className={styles.toggleButton}
          >
            <FormattedMessage id={isReadMore ? 'readMore' : 'showLess'} />
            <span className={classNames(styles.icon, { [styles.opened]: isReadMore })}>
              <CollapseIcon />
            </span>
          </span>
        </>
      ) }
    </>
  );
};

export default ReadMore;
