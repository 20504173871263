export const SET_PEOPLE_COLLECTION = 'SET_PEOPLE_COLLECTION';
export const SET_PEOPLE_COLLECTION_LOADING = 'SET_PEOPLE_COLLECTION_LOADING';
export const SET_PEOPLE_COLLECTION_FILTER = 'SET_PEOPLE_COLLECTION_FILTER';
export const SET_PEOPLE_COLLECTION_CREATE_SHOW = 'SET_PEOPLE_COLLECTION_CREATE_SHOW';
export const PEOPLE_COLLECTION_LOAD_START = 'PEOPLE_COLLECTION_LOAD_START';
export const PEOPLE_COLLECTION_LOAD_SUCCESS = 'PEOPLE_COLLECTION_LOAD_SUCCESS';
export const RESET_PEOPLE_COLLECTION_FILTERS = 'RESET_PEOPLE_COLLECTION_FILTERS';
export const SET_CHECKED_PEOPLE_COLLECTION = 'SET_CHECKED_PEOPLE_COLLECTION';
export const CLEAR_CHECKED_PEOPLE_COLLECTION = 'CLEAR_CHECKED_PEOPLE_COLLECTION';
export const REFRESH_STATE = 'REFRESH_STATE';
export const REMOVE_PEOPLE_COLLECTION = 'REMOVE_PEOPLE_COLLECTION';
export const FILTER_EXCLUDED_CHARACTER = 'FILTER_EXCLUDED_CHARACTER';
export const PEOPLE_COLLECTION_LOAD_ERROR = 'PEOPLE_COLLECTION_LOAD_ERROR';
