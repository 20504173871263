import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'components/button';
import Footer from 'components/footer';
import notificationService from 'services/notificationService';
import apiService from 'services/apiService';
import storageService from 'services/storageService';
import { MAIN_ROUTES } from 'consts';
import LoadingComponent from 'components/loadingComponent';
import styles from './styles.module.scss';

const LoginPage = () => {
  const { search } = window.location;
  const { code } = queryString.parse(search);
  const redirectUri = `${window.location.origin}${MAIN_ROUTES.LOGIN}`;
  const [loading, setLoading] = useState(code);

  useEffect(() => {
    if (code) {
      (async () => {
        const res = await apiService.getAuthTokens(code, redirectUri);

        if (res.error) {
          notificationService.addNotification(res.error);
          setLoading(false);
          return;
        }

        if (!res.data) {
          return;
        }

        const { accessToken, refreshToken } = res.data;
        storageService.add('accessToken', accessToken);
        storageService.add('refreshToken', refreshToken);

        window.location.href = '/';
      })();
    }
  }, []);

  const loginClickHandler = () => {
    const stringifiedParams = queryString.stringify({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
    });

    window.open(`https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`, '_self');
  };

  return (
    <div className={styles.container}>
      { loading ? <LoadingComponent /> : (
        <div className={styles.loginPageMain}>
          <div>
            <div
              className={classNames(styles.loginPageContainer,
                { [styles.loginPageContainerShow]: false })}
            >
              <div className={styles.topLine}>👋</div>
              <div className={styles.titleLine}>
                <span className={styles.welcomeLine}>
                  <FormattedMessage id="loginWelcome" />
                </span>
                <span className={styles.brand}>
                  {process.env.REACT_APP_TITLE}
                </span>
              </div>
              <div className={styles.content}>
                <FormattedMessage id="loginContent" />
              </div>
              <div className={styles.buttonLine}>
                <Button
                  onClick={loginClickHandler}
                  componentStyle={{
                    mainContainer: styles.loginButton,
                    textContainer: styles.loginButtonText,
                  }}
                >
                  <FormattedMessage id="loginButton" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
