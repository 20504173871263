import FacebookSocialsIcon from 'components/svg-icons/facebook-socials-icon';
import TiktokSocialsIcon from 'components/svg-icons/tiktok-socials-icon';
import YoutubeSocialsIcon from 'components/svg-icons/youtube-socials-icon';
import RedditSocialsIcon from 'components/svg-icons/reddit-socials-icon';
import InstagramSocialsIcon from 'components/svg-icons/instagram-socials-icon';
import TwitterSocialsIcon from 'components/svg-icons/twitter-socials-icon';

export const socialLinksConfig = {
  facebook: {
    name: 'Facebook',
    icon: FacebookSocialsIcon,
    domains: ['www.facebook.com', 'facebook.com'],
    color: '#3B5998',
  },
  tiktok: {
    name: 'TikTok',
    icon: TiktokSocialsIcon,
    domains: ['www.tiktok.com', 'tiktok.com'],
    color: '#010101',
  },
  youtube: {
    name: 'YouTube',
    icon: YoutubeSocialsIcon,
    domains: [
      'www.youtube.com',
      'youtu.be.com',
      'youtube.com',
    ],
    color: '#FE0000',
  },
  reddit: {
    name: 'Reddit',
    icon: RedditSocialsIcon,
    domains: ['www.reddit.com', 'reddit.com'],
    color: '#010101',
  },
  instagram: {
    name: 'Instagram',
    icon: InstagramSocialsIcon,
    domains: ['www.instagram.com', 'instagram.com'],
    color: '#0077B5',
  },
  twitter: {
    name: 'Twitter',
    icon: TwitterSocialsIcon,
    domains: ['twitter.com', 'www.twitter.com'],
    color: '#1DA1F2',
  },
};
