import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { COLOR_THEMES, LOGIN_ROLES } from 'consts';
import ApiService from 'services/apiService';
import { LoaderElement } from 'components/loadingComponent';
import Select from 'components/fields/select';
import { getCommentsData, getCommentsListLoading } from 'store/reducers/trend-comments/selectors';
import { getCommentsList } from 'store/reducers/trend-comments/actions';
import { getIsClient } from 'store/reducers/general/selectors';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import CommentsList from './comments-list';
import CommentForm from './comment-form';
import styles from './styles.module.scss';

const TrendComments = (props) => {
  const { trendId } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const loadingList = useSelector(getCommentsListLoading);
  const commentsData = useSelector(getCommentsData);
  const isClient = useSelector(getIsClient);
  const location = useLocation();
  const [imgOrigin, setImgOrigin] = useState(null);

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const s3Domain = result?.data?.s3Domain;

      if (s3Domain) {
        const origin = s3Domain.startsWith('http')
          ? s3Domain
          : `https://${s3Domain}`;
        setImgOrigin(origin);
        dispatch(setParsersInfo(result.data));
      }
    });
  }, []);

  useEffect(() => {
    const { search } = location;
    const query = new URLSearchParams(search);
    const commentId = query.get('comment');

    if (!commentId || loadingList) {
      return;
    }

    const comment = document.querySelector(`[data-comment-id="${commentId}"]`);

    if (!comment) {
      return;
    }

    setTimeout(() => {
      comment.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);
  }, [location, loadingList]);

  const [filters, setFilters] = useState({
    sortBy: 'createdAt',
    companyId: '',
  });

  const [companiesFromComments, setCompaniesFromComments] = useState([]);
  const { sortBy, companyId } = filters;

  const onChangeFilter = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isClient) {
      return;
    }

    ApiService.getCommentsCompanies(trendId).then((res) => {
      setCompaniesFromComments(res);
    });
  }, [isClient]);

  useEffect(() => {
    dispatch(getCommentsList(trendId, { sortBy, companyId }));
  }, [dispatch, trendId, sortBy, companyId]);

  const { list = [] } = commentsData;

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.commentsHeader}>
        <div className={styles.commentsTitleWrapper}>
          <h2 className={styles.commentsTitle}><FormattedMessage id="trendComments" /></h2>
          <span className={styles.badgeCount}>{list.length}</span>
        </div>
        <RoleVisibleWrapper role={[LOGIN_ROLES.WRITER, LOGIN_ROLES.ADMIN]}>
          { !!companiesFromComments.length && (
            <div className={styles.filterItem}>
              <Select
                colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
                name="companyId"
                placeholder={intl.formatMessage({ id: 'filterByCompany' })}
                onChange={onChangeFilter}
                value={companyId}
                options={[{ name: intl.formatMessage({ id: 'all' }), value: '' }, ...companiesFromComments]}
              />
            </div>
          ) }
        </RoleVisibleWrapper>
        <div className={styles.filterItem}>
          <Select
            colorTheme={COLOR_THEMES.TRANSPARENT_LIGHT_WHITE}
            name="sortBy"
            placeholder={intl.formatMessage({ id: 'sortBy' })}
            onChange={onChangeFilter}
            value={sortBy}
            options={[
              { name: intl.formatMessage({ id: 'commentsSortPopular' }), value: 'repliesCount' },
              { name: intl.formatMessage({ id: 'commentsSortDate' }), value: 'createdAt' },
              { name: intl.formatMessage({ id: 'commentsSortMostLiked' }), value: 'likesCount' },
            ]}
          />
        </div>
      </div>
      <RoleVisibleWrapper role={[LOGIN_ROLES.CLIENT, LOGIN_ROLES.POWER_CLIENT]}>
        <CommentForm trendId={trendId} imgOrigin={imgOrigin} />
      </RoleVisibleWrapper>
      { loadingList ? <LoaderElement />
        : (
          <CommentsList
            trendId={trendId}
            list={list}
            filterCompanyId={companyId}
            imgOrigin={imgOrigin}
          />
        ) }
    </div>
  );
};

export default TrendComments;
