import React from 'react';

const WordCloudIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3496_38174)">
        <path d="M14.7812 10.75H6.71875V12.0938H14.7812V10.75Z" fill="currentColor" />
        <path d="M12.0938 13.4375H6.71875V14.7812H12.0938V13.4375Z" fill="currentColor" />
        <path d="M10.7501 4.70312C12.0001 4.70487 13.2107 5.14099 14.1746 5.93685C15.1386 6.73272 15.796 7.83884 16.0343 9.06595L16.2084 9.97029L17.1146 10.1342C18.0227 10.2979 18.8368 10.7954 19.3968 11.5288C19.9568 12.2622 20.2223 13.1786 20.141 14.0977C20.0597 15.0169 19.6376 15.8725 18.9576 16.4962C18.2776 17.12 17.3889 17.4669 16.4661 17.4688H5.03606C4.11305 17.4676 3.22385 17.1212 2.54331 16.4977C1.86276 15.8741 1.44011 15.0186 1.35846 14.0992C1.27681 13.1798 1.54207 12.2631 2.10207 11.5294C2.66208 10.7957 3.47631 10.298 4.38468 10.1342L5.2909 9.97029L5.46498 9.06595C5.70363 7.83883 6.36123 6.73276 7.32529 5.93693C8.28936 5.1411 9.49998 4.70496 10.7501 4.70312M10.7501 3.35938C9.18764 3.36109 7.6744 3.90596 6.46952 4.9007C5.26464 5.89543 4.44308 7.27812 4.14556 8.81198C2.90725 9.03548 1.79732 9.71409 1.03396 10.7144C0.270588 11.7147 -0.0910114 12.9644 0.0202354 14.2178C0.131482 15.4712 0.707529 16.6376 1.63515 17.4878C2.56276 18.3381 3.77485 18.8106 5.03317 18.8125H16.4661C17.7245 18.8106 18.9366 18.3381 19.8642 17.4879C20.7919 16.6377 21.3679 15.4712 21.4792 14.2178C21.5905 12.9644 21.2289 11.7148 20.4655 10.7144C19.7021 9.7141 18.5922 9.03549 17.3539 8.81198C17.0563 7.27684 16.2337 5.89311 15.0274 4.8982C13.821 3.9033 12.3059 3.35924 10.7422 3.35938H10.7501Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3496_38174">
          <rect width="21.5" height="21.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WordCloudIcon;
