import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Input from 'components/fields/input';
import Select from 'components/fields/select';
import Datepicker from 'components/fields/datepicker';
import TagsRow from 'pages/create-trend/components/tags-row';
import { createEmptyLinkForm } from 'pages/create-trend/form-factory';
import TextEditor from 'pages/create-report/components/text-editor';
import COUNTRIES from 'consts/countries';
import { isWin } from 'utils';
import PeopleSelect from 'pages/people/people-select';
import FormBox from '../form-box';
import FieldRow from '../field-row';
import LinkForm from './link-form';
import styles from './styles.module.scss';

const postTypesByBrand = {
  userExamplePosts: 'user-example',
  brandExamplePosts: 'brand-example',
};

const TrendForm = (props) => {
  const intl = useIntl();
  const {
    data = {},
    onChange = () => {},
    clearError = () => {},
    error,
    typeKeys,
    allCompanies,
  } = props;

  const clearDate = new Date();
  clearDate.setHours(0, 0, 0, 0);

  const {
    title,
    description,
    typesKeys = [],
    tags = [],
    countries = [],
    sharedCompanies = [],
    trendDate = clearDate,
  } = data;

  const onAddLink = (postsKey) => {
    return () => {
      onChange(
        postsKey,
        [...data[postsKey],
          createEmptyLinkForm({ type: postTypesByBrand[postsKey] })],
      );
    };
  };
  const isWindows = isWin();

  const onDeleteLink = (postsKey) => {
    return (linkId) => {
      clearError();
      onChange(postsKey, data[postsKey].filter(({ _id }) => _id !== linkId));
    };
  };

  const onPostChange = (postsKey) => {
    return (linkId, params, value) => {
      let newPosts = [];

      if (typeof params === 'string') {
        const name = params;

        newPosts = data[postsKey].map((post) => {
          if (post._id !== linkId) {
            return post;
          }

          return {
            ...post,
            [name]: value,
          };
        });
      }

      if (typeof params === 'object') {
        newPosts = data[postsKey].map((post) => {
          if (post._id !== linkId) {
            return post;
          }

          return { ...post, ...params };
        });
      }

      onChange(postsKey, newPosts);
    };
  };

  const onChangeCountry = (name, value) => {
    onChange('countries', value);
  };
  const onTagsChange = (value) => onChange('tags', value.map((tag) => (tag.value.replace(/#+/g, ''))));
  const onTrendChange = (name, value) => onChange(name, value);
  const onChangeDescription = (value) => onTrendChange('description', value);

  const getCountriesData = () => {
    if (!COUNTRIES || !COUNTRIES.length) {
      return null;
    }

    const countriesData = COUNTRIES.map((country) => {
      const { name, emoji, code: value } = country;
      const emojiPrefix = isWindows ? '' : `${emoji} `;

      return {
        value,
        name: `${emojiPrefix}${name}`,
        flag: isWindows && value,
      };
    });

    return countriesData;
  };

  const getCompaniesData = () => {
    if (!allCompanies || !Array.isArray(allCompanies)) {
      return null;
    }

    return allCompanies.map((company) => {
      const { title: name, _id: value } = company;
      return { name, value };
    });
  };

  const onCompaniesChange = (name, value) => {
    onChange('sharedCompanies', value);
  };

  const getFormPostColumn = (postsKey, title) => {
    const posts = data[postsKey] || [];
    return (
      <div className={styles.postTypeWrapper}>
        <div className={styles.postTypeTitle}>{title}</div>
        {posts.map((postData, index) => (
          <div className={styles.linkForm} key={postData._id}>
            <LinkForm
              data={postData}
              indexLink={index}
              onChange={onPostChange(postsKey)}
              onDelete={onDeleteLink(postsKey)}
              error={error && error[postsKey] && error[postsKey][index]}
            />
          </div>
        ))}
        <div className={styles.addLinkWrapper} onClick={onAddLink(postsKey)}>
          <span className={styles.addLinkText}>
            + {intl.formatMessage({ id: 'addLink' })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.trendForm}>
      <FormBox>
        <div className={styles.generalTrendData}>
          <div className={styles.formRow}>
            <div className={styles.formRowFields}>
              <FieldRow label={`${intl.formatMessage({ id: 'trend' })}`}>
                <Input
                  name="title"
                  value={title}
                  placeholder={intl.formatMessage({ id: 'trendTitlePlaceholder' })}
                  fullWidth
                  onChange={onTrendChange}
                  errorMessage={error && error.title}
                />
              </FieldRow>
              <FieldRow label={`${intl.formatMessage({ id: 'trendDate' })}`}>
                <Datepicker
                  name="trendDate"
                  value={trendDate}
                  placeholder={intl.formatMessage({ id: 'trendDatePlaceholder' })}
                  fullWidth
                  onChange={onTrendChange}
                  errorMessage={error && error.trendDate}
                />
              </FieldRow>
              <FieldRow label={intl.formatMessage({ id: 'trendCountry' })}>
                <Select
                  multiple
                  canScroll
                  placeholder={intl.formatMessage({ id: 'trendCountry' })}
                  fullWidth
                  options={getCountriesData()}
                  value={countries}
                  onChange={onChangeCountry}
                  withSearch
                  searchPlaceholder={intl.formatMessage({ id: 'searchCountry' })}
                />
              </FieldRow>
              <FieldRow label={intl.formatMessage({ id: 'trendCompanies' })}>
                <Select
                  selectAll
                  multiple
                  canScroll
                  placeholder={intl.formatMessage({ id: 'trendCompanies' })}
                  fullWidth
                  options={getCompaniesData()}
                  value={sharedCompanies}
                  onChange={onCompaniesChange}
                  errorMessage={error && error.sharedCompanies}
                />
              </FieldRow>
              <FieldRow label={intl.formatMessage({ id: 'trendMainCharacter' })}>
                <PeopleSelect />
              </FieldRow>
              <FieldRow label={intl.formatMessage({ id: 'trendTypesKeys' })}>
                <Select
                  multiple
                  value={typesKeys}
                  name="typesKeys"
                  options={typeKeys}
                  onChange={onTrendChange}
                  placeholder={intl.formatMessage({ id: 'trendTypesKeys' })}
                  fullWidth
                  errorMessage={error && error.typesKeys}
                  isTabs
                />
              </FieldRow>
            </div>
            <div className={styles.formRowFields}>
              <FieldRow label={intl.formatMessage({ id: 'trendDescription' })}>
                <div className={styles.textEditorWrap}>
                  <TextEditor
                    initialText={description}
                    onChange={onChangeDescription}
                    errorMessage={error && error.description}
                    placeholder={intl.formatMessage({ id: 'shortTrendDescription' })}
                  />
                </div>
              </FieldRow>
            </div>
          </div>

          <div className={classNames(styles.formRow, styles.tagsFormRow)}>
            <div className={styles.formRowFields}>
              <div>
                <div className={styles.blockTitle}><FormattedMessage id="trendTags" /></div>
                <TagsRow onChange={onTagsChange} tags={tags} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.postTypesWrapper}>
          {getFormPostColumn('userExamplePosts', intl.formatMessage({ id: 'userExamplePosts' }))}
          {getFormPostColumn('brandExamplePosts', intl.formatMessage({ id: 'brandExamplePosts' }))}
        </div>
      </FormBox>
    </div>
  );
};

export default TrendForm;
