import React from 'react';
import styles from './styles.module.scss';

export const LoaderElement = ({ text = '' }) => {
  return (
    <div className={styles.loaderElement}>
      <div className={styles.wheel} />
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
};

const LoadingComponent = ({ text }) => (
  <div className={styles.container}>
    <LoaderElement text={text} />
  </div>
);

export default LoadingComponent;
