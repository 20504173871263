import apiService from 'services/apiService';
import { serializeQueryParams } from 'utils';
import { CABINET_ROUTES } from 'consts';
import { getUser } from 'store/reducers/user/selectors';
import { getPeopleCollectionState } from 'store/reducers/people-collection/selectors';
import {
  CLEAR_CHECKED_PEOPLE_COLLECTION,
  PEOPLE_COLLECTION_LOAD_START,
  PEOPLE_COLLECTION_LOAD_SUCCESS,
  REFRESH_STATE,
  REMOVE_PEOPLE_COLLECTION,
  RESET_PEOPLE_COLLECTION_FILTERS,
  SET_CHECKED_PEOPLE_COLLECTION,
  SET_PEOPLE_COLLECTION_CREATE_SHOW,
  SET_PEOPLE_COLLECTION_FILTER,
  FILTER_EXCLUDED_CHARACTER,
  SET_PEOPLE_COLLECTION,
  PEOPLE_COLLECTION_LOAD_ERROR,
} from './action.types';

export const resetPeopleCollectionFilters = () => (
  { type: RESET_PEOPLE_COLLECTION_FILTERS }
);

export const clearCheckedPeople = () => (
  { type: CLEAR_CHECKED_PEOPLE_COLLECTION }
);

export const refreshPeopleState = () => (
  { type: REFRESH_STATE }
);

export const setFilterExcludedCharacter = (characterId) => ({
  type: FILTER_EXCLUDED_CHARACTER,
  characterId,
});

export const requestPeopleCollections = (reset = false) => async (dispatch, getState) => {
  const {
    loading,
    page,
    hasMore,
    filters,
    excludedCharacter,
  } = getPeopleCollectionState(getState());

  const { search = '', sorting } = filters;
  const [sortBy, direction] = sorting.split('_');

  if ((!reset && !hasMore) || (!reset && loading)) {
    return;
  }

  const options = { page: reset ? 1 : page + 1, search, excludedCharacter };

  if (sorting) {
    options.sortType = direction;
    options.sortBy = sortBy;
  }

  dispatch({
    type: PEOPLE_COLLECTION_LOAD_START,
    payload: {
      reset,
    },
  });

  const resp = await apiService.getPeopleCollectionsList(serializeQueryParams(options));

  if (!resp) {
    return;
  }

  const { list, metadata } = resp;

  dispatch({
    type: PEOPLE_COLLECTION_LOAD_SUCCESS,
    payload: { data: list, hasMore: metadata.hasMore },
  });
};

export const setCheckedPeople = (data) => ({
  type: SET_CHECKED_PEOPLE_COLLECTION,
  payload: data,
});

export const setPeopleCollectionFilter = (payload, withoutReq = false) => async (dispatch) => {
  dispatch({ type: SET_PEOPLE_COLLECTION_FILTER, payload });
  if (!withoutReq) {
    dispatch(requestPeopleCollections(true));
  }
};

export const setPeopleCollectionCreateShow = (data) => ({
  type: SET_PEOPLE_COLLECTION_CREATE_SHOW,
  payload: data,
});

export const addPeople = (data) => (dispatch) => {
  apiService.addPeople(data)
    .then((res) => {
      if (!res.error) {
        dispatch(requestPeopleCollections(true));
      }

      dispatch(setPeopleCollectionCreateShow(false));
    });
};

export const editPeople = (data, pageId) => (dispatch, getState) => {
  const { role } = getUser(getState());

  apiService.editPeople(data._id, data)
    .then((res) => {
      dispatch(setPeopleCollectionCreateShow(false));
      if (!res.error) {
        const pageIdStr = pageId ? `/${pageId}` : '';
        window.location.href = `/${role}/${CABINET_ROUTES.PEOPLE_COLLECTIONS}${pageIdStr}`;
      }
    })
    .catch(() => {
      dispatch(setPeopleCollectionCreateShow(false));
    });
};

export const removePeopleCollection = (id) => ({ type: REMOVE_PEOPLE_COLLECTION, payload: { id } });

export const setPeopleCollection = (payload) => ({
  type: SET_PEOPLE_COLLECTION,
  payload,
});

export const setPeopleCollectionLoadError = (payload) => ({
  type: PEOPLE_COLLECTION_LOAD_ERROR,
  payload,
});

export const requestPeopleCollection = (id) => (dispatch) => {
  apiService.getPeopleCollection(id)
    .then((res) => {
      const collection = res?.data;
      const collectionError = res?.error;

      if (collection) {
        dispatch(setPeopleCollection(collection));
      }

      if (collectionError) {
        dispatch(setPeopleCollectionLoadError({
          collectionId: id,
          error: collectionError,
        }));
      }
    });
};
