import React from 'react';

const SmileIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9993 23.8335C18.9824 23.8335 23.8327 18.9833 23.8327 13.0002C23.8327 7.01712 18.9824 2.16687 12.9993 2.16687C7.01626 2.16687 2.16602 7.01712 2.16602 13.0002C2.16602 18.9833 7.01626 23.8335 12.9993 23.8335Z" stroke="#CBCBCB" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.66602 15.1669C8.66602 15.1669 10.291 17.3335 12.9993 17.3335C15.7077 17.3335 17.3327 15.1669 17.3327 15.1669" stroke="#CBCBCB" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.75 9.75H9.75552" stroke="#CBCBCB" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.25 9.75H16.2562" stroke="#CBCBCB" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SmileIcon;
