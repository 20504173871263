import React from 'react';
import { FormattedMessage } from 'react-intl';
import { onImageError } from 'utils';
import styles from 'pages/collections/styles.module.scss';

const CollectionCreatorRow = (props) => {
  const { creator } = props;

  if (!creator) {
    return null;
  }

  return (
    <>
      <div className={styles.createdLabel}>
        <FormattedMessage id="createdBy" />:
      </div>
      <div className={styles.userAvatarWrapper}>
        <img
          src={creator.picture}
          width={24}
          height={24}
          alt=""
          onError={(e) => onImageError(e, 12)}
        />
      </div>
      <div className={styles.userEmail}>{creator.email}</div>
    </>
  );
};

export default CollectionCreatorRow;
