import {
  SET_CATEGORIES_FILTER,
  SET_CATEGORIES,
  SET_CATEGORIES_LOADING,
  SET_CATEGORIES_ERROR,
} from './action.types';

const initialFilters = {
  searchCategory: '',
  sortingNameDirection: '',
};

const initialState = {
  data: [],
  loading: true,
  error: false,
  filters: { ...initialFilters },
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return { ...state, data: action.categories };
    case SET_CATEGORIES_ERROR:
      return { ...state, error: action.error };
    case SET_CATEGORIES_LOADING:
      return { ...state, loading: action.loading };
    case SET_CATEGORIES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

export default categoriesReducer;
