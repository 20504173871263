import React from 'react';

const LandingMainPartSocialDataIcon = () => (
  <svg width="188" height="188" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M95.5 3C83.3527 3 71.3244 5.39258 60.1018 10.0411C48.8792 14.6897 38.682 21.5032 30.0926 30.0926C21.5032 38.682 14.6897 48.8792 10.0411 60.1018C5.39258 71.3244 3 83.3527 3 95.5" stroke="url(#paint0_linear_1_490)" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M59.2854 146.822V136.316C59.2854 132.097 55.8648 128.676 51.6452 128.676V128.676C47.4257 128.676 44.0051 132.097 44.0051 136.316L44.0051 146.822" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M85.0708 146.822V116.739C85.0708 112.783 81.864 109.576 77.9082 109.576V109.576C73.9524 109.576 70.7456 112.783 70.7456 116.739L70.7456 146.822" stroke="#7DE87C" strokeWidth="5" />
    <path d="M110.856 146.822V138.704C110.856 134.748 107.649 131.542 103.694 131.542V131.542C99.7378 131.542 96.531 134.748 96.531 138.704L96.531 146.822" stroke="#1CA5E0" strokeWidth="5" />
    <path d="M137.597 146.822V114.351C137.597 110.132 134.176 106.711 129.957 106.711V106.711C125.737 106.711 122.316 110.132 122.316 114.351L122.316 146.822" stroke="#7DE87C" strokeWidth="5" />
    <path d="M33.5 57.5C33.5 51.9772 37.9772 47.5 43.5 47.5H138.102C143.625 47.5 148.102 51.9772 148.102 57.5V135.564C148.102 141.087 143.625 145.564 138.102 145.564H43.5C37.9772 145.564 33.5 141.087 33.5 135.564V57.5Z" stroke="#1CA5E0" strokeWidth="5" />
    <circle cx="103.729" cy="113.07" r="5.93973" stroke="#7DE87C" strokeWidth="5" />
    <circle cx="129.817" cy="85.3511" r="5.93973" stroke="#7DE87C" strokeWidth="5" />
    <circle cx="76.2427" cy="85.3511" r="5.93973" stroke="#7DE87C" strokeWidth="5" />
    <circle cx="51.552" cy="110.741" r="5.93973" stroke="#7DE87C" strokeWidth="5" />
    <path d="M55.6284 106.665L72.3994 89.8936" stroke="#7DE87C" strokeWidth="5" />
    <path d="M33.5 64.2712L148.102 64.2712" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M83.1143 55.8855L135.757 55.8855" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44.4478 76.8494L55.6284 76.8494" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44.4478 88.03L55.6284 88.03" stroke="#1CA5E0" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M108.038 108.528L126.672 89.8936" stroke="#7DE87C" strokeWidth="5" />
    <path d="M99.8853 108.528L81.2508 89.8936" stroke="#7DE87C" strokeWidth="5" />
    <circle cx="46.0783" cy="55.8852" r="2.32931" fill="#1CA5E0" />
    <circle cx="55.3955" cy="55.8852" r="2.32931" fill="#1CA5E0" />
    <circle cx="64.7126" cy="55.8852" r="2.32931" fill="#1CA5E0" />
    <defs>
      <linearGradient id="paint0_linear_1_490" x1="3" y1="188" x2="147.512" y2="-4.22778" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7DE87C" />
        <stop offset="1" stopColor="#7CA7E8" />
      </linearGradient>
    </defs>
  </svg>
);

export default LandingMainPartSocialDataIcon;
