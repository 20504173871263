export const getTrendsLoading = (state) => state.trends.loading;
export const getTrendFilters = (state) => state.trends.filters;
export const getTrendFilterByName = (name) => (state) => state.trends.filters[name];
export const getTrendFiltersAdded = (state) => state.trends.filtersAdded;
export const getTrends = (state) => state.trends.data;
export const getTrendsState = (state) => state.trends;
export const getCollectionPopup = (state) => state.trends.collectionPopup;
export const getWordCloudLoading = (state) => state.trends.wordCloud.loading;
export const getWordCloudData = (state) => state.trends.wordCloud.data;
export const getWordCloudMaxWords = (state) => state.trends.wordCloud.maxWords;
export const getWordCloudMaxCount = (state) => state.trends.wordCloud.maxCount;
export const getWordCloudFiltered = (state) => state.trends.wordCloud.filteredData;
export const getExcludeWords = (state) => state.trends.wordCloud.excludeWords;
export const getWordCloudSearch = (state) => state.trends.wordCloud.search;
