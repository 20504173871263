import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

const DateFormatter = ({
  date, showYear = true, singleLine = true, showTime = true,
}) => (
  <div>
    <FormattedDate
      value={date}
      year={showYear ? 'numeric' : undefined}
      month="short"
      day="numeric"
    />
    {singleLine ? <>&nbsp;</> : <br /> }
    {showTime ? (
      <FormattedTime
        value={date}
        hour12={false}
        hour="2-digit"
        minute="numeric"
      />
    ) : null}
  </div>
);

export default DateFormatter;
