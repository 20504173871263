import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { COLOR_THEMES, LOGIN_ROLES } from 'consts';
import { getIsClient } from 'store/reducers/general/selectors';
import { setTrendFilter, setWordCloudSearch } from 'store/reducers/trends/actions';
import { getTrendFilterByName, getWordCloudSearch } from 'store/reducers/trends/selectors';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import SearchIcon from 'components/svg-icons/search-icon';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import styles from './styles.module.scss';

const TopWordCloudFilters = (props) => {
  const intl = useIntl();
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const { companies } = props;
  const activeCompany = useSelector(getTrendFilterByName('companyId'));
  const searchString = useSelector(getWordCloudSearch);

  const theme = isClient ? COLOR_THEMES.TRANSPARENT_LIGHT_WHITE : COLOR_THEMES.LIGHT;

  const onCompanyChange = (name, value) => {
    dispatch(setTrendFilter(name, value, { isWordCloud: true }));
  };

  const onSearch = (name, value) => {
    dispatch(setWordCloudSearch(value));
  };

  return (
    <>
      <div className={styles.searchInput}>
        <Input
          value={searchString}
          delay={500}
          colorTheme={theme}
          name="search"
          onChange={onSearch}
          icon={<SearchIcon />}
        />
      </div>
      <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
        <div className={styles.companySelect}>
          <Select
            colorTheme={theme}
            name="companyId"
            value={activeCompany}
            placeholder={intl.formatMessage({ id: 'allCompanies' })}
            onChange={onCompanyChange}
            options={[
              { name: intl.formatMessage({ id: 'allCompanies' }), value: '' },
              ...companies,
            ]}
          />
        </div>
      </RoleVisibleWrapper>
    </>
  );
};

export default TopWordCloudFilters;
