import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { getIsClient } from 'store/reducers/general/selectors';
import styles from './styles.module.scss';

const PeopleNoResults = ({ message }) => {
  const isClient = useSelector(getIsClient);

  return (
    <div className={classNames(styles.noResult, { [styles.clientNoResult]: isClient })}>
      <svg width="424" height="266" viewBox="0 0 424 266" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M213.803 210.012C271.244 210.012 317.809 163.447 317.809 106.006C317.809 48.5652 271.244 2 213.803 2C156.362 2 109.797 48.5652 109.797 106.006C109.797 163.447 156.362 210.012 213.803 210.012Z" fill="#F3F7FF" fillOpacity="0.01" stroke="#1CA5E0" strokeWidth="2.5" />
        <path d="M197.138 186.708C202.599 187.716 208.154 188.24 213.804 188.28C259.242 188.28 296.077 151.444 296.077 106.006C296.077 60.5676 259.242 23.7324 213.804 23.7324C202.131 23.7324 191.027 26.1632 180.968 30.546C163.489 38.1625 149.17 51.6742 140.521 68.5696C134.773 79.7994 131.53 92.524 131.53 106.006C131.53 118.238 134.2 129.847 138.988 140.282C142.407 147.731 146.905 154.582 152.283 160.634" stroke="#1CA5E0" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M161.947 169.884C168.616 175.304 176.151 179.702 184.312 182.836" stroke="#1CA5E0" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M299.181 188.28L317.809 206.908" stroke="#1CA5E0" strokeWidth="2.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M314.798 203.897C308.922 209.773 308.922 219.3 314.798 225.176L349.214 259.593C355.09 265.469 364.617 265.469 370.494 259.593C376.37 253.716 376.37 244.189 370.494 238.313L336.078 203.897C330.201 198.02 320.674 198.02 314.798 203.897Z" fill="#1CA5E0" fillOpacity="0.16" stroke="#1CA5E0" strokeWidth="2.5" />
        <path d="M330.229 210.012L364.38 244.164" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M193.624 70.3027C193.624 106.31 222.814 135.501 258.822 135.501C265.895 135.501 272.705 134.374 279.083 132.291C268.673 158.124 243.368 176.36 213.804 176.36C174.949 176.36 143.45 144.862 143.45 106.006C143.45 70.798 169.313 41.6303 203.077 36.4651C197.079 46.3324 193.624 57.914 193.624 70.3027Z" fill="#1CA5E0" fillOpacity="0.1" />
        <path d="M215.356 48.5698C211.402 48.5698 207.54 48.9587 203.804 49.7004M192.52 53.1526C171.282 62.0776 156.367 83.0763 156.367 107.558" stroke="#1CA5E0" strokeOpacity="0.49" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M380.449 106.867H355.066M400.084 82.7212H346.759H400.084ZM421.816 82.7212H414.92H421.816Z" stroke="#1CA5E0" strokeOpacity="0.49" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M101.029 175.17H75.6463M86.5126 147.919H33.1876H86.5126ZM15.1054 147.919H2H15.1054Z" stroke="#1CA5E0" strokeOpacity="0.49" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <div className={styles.firstTitle}><FormattedMessage id="characterNoResults" /></div>
      <div className={styles.secondTitle}>{message || <FormattedMessage id="characterNoPeople" />}</div>
    </div>
  );
};

export default PeopleNoResults;
