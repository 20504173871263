import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Popup from 'components/popup';
import Input from 'components/fields/input';
import UseFormData from 'hooks/useFormData';
import { createValidator } from 'hooks/useFormData/validation';
import ErrorMessage from 'components/error-message';
import { addCompany, editCompany } from 'store/reducers/companies/actions';
import { getCompanyDataError } from 'store/reducers/companies/selectors';
import { POPUP_TYPE } from 'consts';
import styles from './styles.module.scss';

const baseFields = {
  title: '',
  usersLimit: '',
};

const AddCompany = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const serverError = useSelector(getCompanyDataError);
  const { prefilledData = null, onCancel = () => {} } = props;
  const [error, setError] = useState(false);
  const [proceedRequest, setProceedRequest] = useState(false);
  const [companyData, setCompanyData] = useState(prefilledData || baseFields);

  useEffect(() => {
    setCompanyData(prefilledData || baseFields);
  }, [prefilledData]);

  useEffect(() => {
    if (!serverError) {
      return;
    }

    setProceedRequest(false);
    setError(serverError);
  }, [serverError]);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: companyData,
    validators: {
      title: createValidator([{ type: 'required' }]),
      usersLimit: createValidator([{ type: 'required' }]),
    },
  });

  const onSubmit = () => {
    const errors = validateForm();
    setError(null);

    if (errors) {
      setProceedRequest(false);
      if (errors.title) {
        setError(intl.formatMessage({ id: 'companyNameRequired' }));
        return;
      }

      if (errors.usersLimit) {
        setError(intl.formatMessage({ id: 'userLimitRequired' }));
        return;
      }

      return;
    }

    setProceedRequest(true);

    if (prefilledData) {
      dispatch(editCompany(prefilledData._id, data));
      return;
    }

    dispatch(addCompany(data));
  };

  const getFormFields = () => {
    if (!data) {
      return null;
    }

    const { title, usersLimit } = data;

    return (
      <div className={styles.formRow}>
        <div className={styles.companyNameField}>
          <Input
            autoFocus
            fullWidth
            placeholder={intl.formatMessage({ id: 'enterCompanyName' })}
            value={title}
            onChange={onChange}
            name="title"
          />
        </div>
        <div className={styles.userLimitField}>
          <Input
            fullWidth
            placeholder={intl.formatMessage({ id: 'userLimit' })}
            value={usersLimit}
            type="number"
            onChange={onChange}
            name="usersLimit"
          />
        </div>
      </div>
    );
  };

  return (
    <Popup
      title={intl.formatMessage({ id: !prefilledData ? 'addCompany' : 'editCompany' })}
      processing={proceedRequest}
      onSubmit={onSubmit}
      onClose={onCancel}
      submitText={intl.formatMessage({ id: !prefilledData ? 'add' : 'save' })}
      type={POPUP_TYPE.FORM}
    >
      {getFormFields()}
      <ErrorMessage message={error} />
    </Popup>
  );
};

export default AddCompany;
