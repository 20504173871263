import {
  SET_PARSERS_INFO,
} from './action.types';

const initialState = {
  parsersDomains: null,
  s3Domain: '',
  platforms: null,
};

const parsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARSERS_INFO:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default parsersReducer;
