import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { getImages } from 'utils';
import { iconComponents, socialStatsFormatter } from 'utils/social-helpers';
import ClientLogoIcon from 'components/svg-icons/client-logo-icon';
import ApiService from 'services/apiService';
import styles from './styles.module.scss';

const TrendData = (props) => {
  const { posts = [], title } = props;
  const [inUserViewPort, setInUserViewPort] = useState(false);
  const [parsersDomains, setParsersDomains] = useState(null);
  const fullTrendWidth = posts.length % 2 !== 0;
  const trendDataRef = useRef();

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [] } = parsersData;
        setParsersDomains(parsersDomains);
      }
    });
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setInUserViewPort(true);
    }
  };

  const initObserver = useCallback(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (!trendDataRef.current) {
      return;
    }

    observer.observe(trendDataRef.current);
  }, []);

  useEffect(() => {
    initObserver();
  }, [initObserver]);

  const getStatistic = (statistic) => {
    if (!statistic) {
      return null;
    }

    const statisticComponents = [];

    const badgesTypes = Object.keys(statistic);

    badgesTypes.forEach((badgeType) => {
      const Icon = iconComponents[badgeType];
      const statValue = socialStatsFormatter(statistic[badgeType]);

      if (statistic[badgeType] && statValue) {
        statisticComponents.push(
          <div className={styles.statCounter} key={badgeType}>
            <Icon className={styles.statIcon} />
            <span className={styles.count}>{statValue}</span>
          </div>,
        );
      }
    });

    return statisticComponents;
  };

  if (!posts.length) {
    return null;
  }

  return (
    <>
      { title && <h2 className={styles.title}>{title}</h2> }
      <div
        className={classNames(styles.trendWrapper, { [styles.initScale]: inUserViewPort })}
        ref={trendDataRef}
      >
        { posts.map((post, index) => {
          const {
            _id,
            title,
            image,
            metrics = {},
            link,
          } = post;

          const { postImageUrl, postLogoUrl } = getImages(image, link, parsersDomains);

          return (
            <a
              className={classNames(
                styles.postItem,
                styles.cartItem,
                { [styles.fullWidth]: (fullTrendWidth && !index) },
              )}
              key={_id}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              { postImageUrl ? (
                <img className={styles.postImg} src={postImageUrl} alt="" />
              ) : (
                <div className={styles.postImg}>
                  <ClientLogoIcon />
                </div>
              ) }
              { postLogoUrl && (
                <div className={styles.postLogo}>
                  <img alt="" src={postLogoUrl} />
                </div>
              ) }

              <div className={styles.postContent}>
                <h4 className={styles.postTitle}>{title}</h4>
                <div className={styles.statistics}>
                  {getStatistic(metrics)}
                </div>
              </div>
            </a>
          );
        }) }
      </div>
    </>
  );
};

export default TrendData;
