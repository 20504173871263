import React, { useEffect } from 'react';
import Layout from 'components/layout';
import styles from './styles.module.scss';

const UnderMaintenancePage = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <div className={styles.wrapper}>
      <Layout withFooter={false} centerContent>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.title}>Oopps!</div>
            <div className={styles.description}>Website under maintenance</div>
            <div className={styles.soonText}>We will back soon!</div>
          </div>
          <div className={styles.col}>
            <img src="/img/maintenance-img.svg" alt="" />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default UnderMaintenancePage;
