import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { COLOR_THEMES, ROLE_THEMES, LOGIN_ROLES } from 'consts';
import Select from 'components/fields/select';
import Input from 'components/fields/input';
import Button from 'components/button';
import SearchIcon from 'components/svg-icons/search-icon';
import AddUserPopup from 'components/add-user-popup';
import { getUsersFilters } from 'store/reducers/companies/selectors';
import { setUsersFilter } from 'store/reducers/companies/actions';
import styles from './styles.module.scss';

const UsersFilters = (props) => {
  const { CLIENT } = LOGIN_ROLES;
  const { TRANSPARENT_LIGHT_WHITE, LIGHT: LIGHT_THEME } = COLOR_THEMES;
  const { DARK, LIGHT } = ROLE_THEMES;
  const intl = useIntl();
  const {
    usersCount, companyId, colorTheme = LIGHT, changeRoles = false, usersLimit,
  } = props;
  const filters = useSelector(getUsersFilters);
  const dispatch = useDispatch();

  const [addUser, setAddUser] = useState(false);

  const openAddUserPopup = () => {
    setAddUser(true);
  };

  const closeAddUserPopup = () => {
    setAddUser(false);
  };

  const onFilterChange = (name, value) => {
    dispatch(setUsersFilter(name, value));
  };

  const { searchEmail, sortNameDest } = filters;

  const stylesTheme = colorTheme === DARK ? TRANSPARENT_LIGHT_WHITE : LIGHT_THEME;

  return (
    <>
      { addUser
        && (
        <AddUserPopup
          isBulk
          maxToAdd={usersLimit - usersCount}
          colorTheme={colorTheme}
          companyId={companyId}
          onCancel={closeAddUserPopup}
          defaultFields={{ role: CLIENT }}
          hideSelect={!changeRoles}
        />
        ) }
      <Button
        disabled={usersLimit <= usersCount}
        onClick={openAddUserPopup}
        colorTheme={stylesTheme}
      >
        {intl.formatMessage({ id: 'addUser' })}
      </Button>
      <Select
        onChange={onFilterChange}
        name="sortNameDest"
        value={sortNameDest}
        colorTheme={stylesTheme}
        placeholder={intl.formatMessage({ id: 'sortBy' })}
        options={[
          { name: intl.formatMessage({ id: 'sortAlphabetAsc' }), value: 'asc' },
          { name: intl.formatMessage({ id: 'sortAlphabetDesc' }), value: 'desc' },
        ]}
      />
      <Input
        onChange={onFilterChange}
        name="searchEmail"
        value={searchEmail}
        icon={<SearchIcon />}
        colorTheme={stylesTheme}
        placeholder={intl.formatMessage({ id: 'typeUsersEmail' })}
      />
      <div
        className={
          classNames(
            styles.usersCount,
            styles[colorTheme],
            { [styles.limitUsers]: usersLimit <= usersCount },
          )
        }
      >
        {usersCount}/{usersLimit}
        &nbsp;
        {intl.formatMessage({ id: 'users' })}
      </div>
    </>
  );
};

export default UsersFilters;
