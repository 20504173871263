import React from 'react';
import CloseIcon from 'components/svg-icons/close-icon';
import styles from './styles.module.scss';

const FileItem = (props) => {
  const { file, onDelete = () => {} } = props;

  if (!file) {
    return null;
  }

  const { name } = file;
  const fileExtension = name.split('.')[name.split('.').length - 1];

  return (
    <div className={styles.fileWrapper}>
      <div className={styles.fileExtension}>{fileExtension}</div>
      <div className={styles.fileContent}>
        <div className={styles.fileName}>{name}</div>
      </div>
      <div className={styles.removeIcon} onClick={onDelete}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default FileItem;
