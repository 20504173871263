import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  CABINET_ROUTES, COLLECTION_TYPE, COLOR_THEMES, LOGIN_ROLES, ROLE_THEMES,
} from 'consts';
import Button from 'components/button';
import { getIsAdmin, getIsClient } from 'store/reducers/general/selectors';
import { getS3Domain } from 'store/reducers/parsers/selectors';
import apiService from 'services/apiService';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import { setParsersInfo } from 'store/reducers/parsers/actions';
import { getPeopleState } from 'store/reducers/people/selectors';
import ConfirmPopup from 'components/confirm-popup';
import DeleteIcon from 'components/svg-icons/delete-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import ReadMore from 'components/read-more';
import NoPeopleIcon from 'components/svg-icons/no-people-icon';
import ShareCollectionIcon from 'components/svg-icons/share-collection-icon';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import CollectionCreatorRow from 'pages/collections/components/collection-card/creator-row';
import CollectionSharedRow from 'pages/collections/components/collection-card/shared-row';
import { getUser } from 'store/reducers/user/selectors';
import PeopleItem from 'pages/people/people-item';
import InfiniteScroll from 'components/infinity-scroll';
import { requestPeople, resetPeopleFilters } from 'store/reducers/people/actions';
import CharacterChainWidget from 'pages/people-collections/character-chain-widget';
import CreateCollectionPopup from 'components/create-collection-popup';
import {
  requestPeopleCollection,
  resetPeopleCollectionFilters,
  setPeopleCollectionLoadError,
  refreshPeopleState,
} from 'store/reducers/people-collection/actions';
import { getPeopleCollection, getPeopleCollectionError } from 'store/reducers/people-collection/selectors';
import ShareCompaniesPopup from 'components/share-companies-popup';
import { requestCompanies } from 'store/reducers/companies/actions';
import HeadingFilters from 'components/heading-filters';
import PeopleNoResults from 'pages/people/people-no-results';
import EmptyListMessage from 'components/empty-list-message';
import styles from './styles.module.scss';
import TopPeopleFilters from '../top-filters';

const {
  TRANSPARENT_LIGHT_WHITE,
  SECONDARY,
} = COLOR_THEMES;
const { PEOPLE_COLLECTIONS } = CABINET_ROUTES;
const { DARK, LIGHT: THEME_LIGHT } = ROLE_THEMES;

const ListComponent = ({
  data, isClient, canDelete, collectionId,
}) => {
  const { id = 0 } = data;

  return (
    <div className={styles.itemContainer} key={`people_id_${id}`}>
      <PeopleItem
        data={data}
        isClient={isClient}
        canDelete={canDelete}
        collectionId={collectionId}
      />
    </div>
  );
};

const PeopleCollectionItemPage = () => {
  const { id } = useParams();
  const isClient = useSelector(getIsClient);
  const isAdmin = useSelector(getIsAdmin);
  const { filters } = useSelector(getPeopleState);
  const dispatch = useDispatch();
  const { navigate } = UseRoleNavigation();
  const intl = useIntl();
  const collectionData = useSelector(getPeopleCollection);
  const s3Domain = useSelector(getS3Domain);
  const user = useSelector(getUser);
  const peopleCollectionError = useSelector(getPeopleCollectionError);
  const isOwnCollection = collectionData
    && user
    && user._id === collectionData.creatorId;
  const canDelete = isAdmin || isOwnCollection;
  const [deleteAction, setDeleteAction] = useState(false);
  const [showCreateCollectionPopup, setShowCreateCollectionPopup] = useState(false);
  const [showShareCompanies, setShowShareCompanies] = useState(false);

  useEffect(() => {
    dispatch(requestCompanies());

    return () => {
      dispatch(resetPeopleCollectionFilters());
      dispatch(resetPeopleFilters());
      dispatch(refreshPeopleState());
    };
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (peopleCollectionError) {
      navigate(CABINET_ROUTES.PEOPLE_COLLECTIONS);
      dispatch(setPeopleCollectionLoadError(null));
      return;
    }

    dispatch(requestPeopleCollection(id));

    if (s3Domain) {
      return;
    }

    apiService.getParsersInfo().then((result) => {
      dispatch(setParsersInfo(result.data));
    });
  }, [id, peopleCollectionError]);

  const confirmDelete = () => {
    apiService.deletePeopleCollection(id).then(() => {
      setDeleteAction(false);
      navigate(`${PEOPLE_COLLECTIONS}`);
    }).catch(() => {
      setDeleteAction(false);
    });
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  return (
    <>
      <HeadingFilters
        withoutBackground
        FiltersComponent={<TopPeopleFilters collectionId={id} />}
      />
      <div
        className="container"
      >
        { deleteAction
      && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deletePeopleCollection' })}
          text={intl.formatMessage({ id: 'deletePeopleCollectionText' })}
          colorTheme={isClient ? DARK : THEME_LIGHT}
          onCancel={toggleDelete}
          submitFunction={confirmDelete}
          submitText={intl.formatMessage({ id: 'delete' })}
        />
      ) }
        { showCreateCollectionPopup && (
        <CreateCollectionPopup
          onClose={() => setShowCreateCollectionPopup(false)}
          title={collectionData?.title}
          description={collectionData?.description}
          collectionId={id}
          isClient={isClient}
          type={COLLECTION_TYPE.PEOPLE}
        />
        ) }
        {
        showShareCompanies && (
          <ShareCompaniesPopup
            onCancel={() => setShowShareCompanies(false)}
            collectionData={collectionData}
            type={COLLECTION_TYPE.PEOPLE}
          />
        )
      }
        {collectionData && (
          <div className={classNames(
            styles.peopleInfoContainer,
            { [styles.peopleClientContainer]: isClient },
          )}
          >
            {collectionData?.imgs?.length ? (
              <div className={styles.avatarsContainer}>
                <CharacterChainWidget
                  capacity={4}
                  id={collectionData?._id}
                  peopleAvatars={collectionData.imgs}
                />
              </div>
            ) : (
              <div className={styles.peopleEmptyAvatar}>
                <NoPeopleIcon />
              </div>
            )}
            <div className={styles.rightBlock}>
              <div className={styles.titleLine}>
                <div className={styles.peopleTitle}>
                  {collectionData?.title}
                </div>
              </div>

              <div className={styles.descriptionLine}>
                <ReadMore>
                  {collectionData?.description || ''}
                </ReadMore>
              </div>
              <div className={styles.collectionButtonsBlock}>
                {((!isClient && isOwnCollection) || isAdmin) && (
                  <Button
                    disabled={0}
                    colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                    icon={<ShareCollectionIcon />}
                    componentStyle={{ mainContainer: styles.shareButton }}
                    onClick={() => setShowShareCompanies(true)}
                  >
                    <FormattedMessage id="share" />
                  </Button>
                )}
                {(isOwnCollection || isAdmin) && (
                  <>
                    <Button
                      disabled={0}
                      colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                      onlyIcon
                      icon={<DeleteIcon />}
                      componentStyle={{ mainContainer: styles.deleteButton }}
                      onClick={toggleDelete}
                    />

                    <Button
                      disabled={0}
                      colorTheme={isClient ? TRANSPARENT_LIGHT_WHITE : SECONDARY}
                      icon={<EditIcon />}
                      onlyIcon
                      componentStyle={{ mainContainer: styles.editButton }}
                      onClick={() => setShowCreateCollectionPopup(true)}
                    />
                  </>
                )}
              </div>
              <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN]}>
                <div className={styles.additionalInfoRow}>
                  <CollectionCreatorRow creator={collectionData?.creator} />
                </div>
              </RoleVisibleWrapper>
              <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN, LOGIN_ROLES.WRITER]}>
                {!!collectionData?.companyList?.length && (
                  <div className={styles.additionalInfoRow}>
                    <CollectionSharedRow companyList={collectionData?.companyList} withNewRow />
                  </div>
                )}
              </RoleVisibleWrapper>
            </div>
          </div>
        )}

        <div className={styles.scrollContainer}>
          <InfiniteScroll
            RootComponent={RootList}
            RenderComponent={ListComponent}
            dataSelector={getPeopleState}
            loadAction={requestPeople}
            emptyMessage={filters?.search
              ? <PeopleNoResults message={intl.formatMessage({ id: 'noPeople' })} />
              : <EmptyListMessage messageId="emptyCollection" />}
            parentId={id}
            listItemProps={{ collectionId: id, canDelete }}
          />
        </div>
      </div>
    </>
  );
};

export default PeopleCollectionItemPage;
