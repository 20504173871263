import { createValidator } from 'hooks/useFormData/validation';

const reportValidator = {
  title: createValidator([{ type: 'required' }, { type: 'maxLength', value: 63, message: 'The "Report Name" is too long (maximum is 63 characters)' }]),
  sharedCompanyId: createValidator([{ type: 'required' }]),
  trends: createValidator([{
    type: 'checkArray',
    descr: {
      title: createValidator([{ type: 'required' }, { type: 'maxLength', value: 63, message: 'The "Trend #" is too long (maximum is 63 characters)' }]),
      description: createValidator([{ type: 'required' }]),
      categoryId: createValidator([{ type: 'required' }]),
      posts: createValidator([{
        type: 'checkArray',
        descr: {
          link: createValidator([{ type: 'required' }, { type: 'link' }]),
          title: createValidator([{ type: 'required' }, { type: 'maxLength', value: 40, message: 'The "Post title" is too long (maximum is 40 characters)' }]),
        },
      }]),
    },
  }]),
};

export default reportValidator;
