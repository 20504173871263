import React, { useState } from 'react';
import {
  CABINET_ROUTES,
  COLOR_THEMES,
  ROLE_THEMES,
  LOGIN_ROLES,
} from 'consts';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import Button from 'components/button';
import DeleteIcon from 'components/svg-icons/delete-icon';
import ConfirmPopup from 'components/confirm-popup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteCollection } from 'store/reducers/collections/actions';
import { getImages } from 'utils';
import classNames from 'classnames';
import ShareCollectionIcon from 'components/svg-icons/share-collection-icon';
import ShareCompaniesPopup from 'components/share-companies-popup';
import RoleVisibleWrapper from 'components/role-visible-wrapper';
import EmptyIcon from 'components/svg-icons/empty-icon';
import CollectionSharedRow from './shared-row';
import CollectionCreatorRow from './creator-row';
import styles from '../../styles.module.scss';

const { SECONDARY } = COLOR_THEMES;
const { COLLECTIONS } = CABINET_ROUTES;
const { DARK, LIGHT } = ROLE_THEMES;

const CollectionCard = ({
  collectionItem, userId, domains, isClient, isAdmin, hideActionBar,
}) => {
  const {
    title,
    _id: id,
    trends = [],
    creatorId,
    description,
    postWithImgLink,
    imgLink,
    creator,
    companyList = [],
    approvedTrends = [],
  } = collectionItem;

  const [deleteAction, setDeleteAction] = useState(false);
  const [showShareCompanies, setShowShareCompanies] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { postImageUrl } = getImages(imgLink, postWithImgLink, domains);

  const onDelete = async () => {
    dispatch(deleteCollection(id));
    setDeleteAction(!deleteAction);
  };

  const toggleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAction(!deleteAction);
  };

  const toggleShareCollection = () => {
    setShowShareCompanies(true);
  };

  const { navigate } = UseRoleNavigation();

  const goTo = (path) => () => {
    navigate(path);
  };

  const trendsCount = isClient ? approvedTrends.length : trends.length;
  const isOwnCollection = userId === creatorId;

  return (
    <div className={classNames(styles.collectionItemWrapper,
      { [styles.adminStyleWrap]: !isClient })}
    >
      { deleteAction
      && (
        <ConfirmPopup
          title={intl.formatMessage({ id: 'deleteCollection' })}
          text={intl.formatMessage({ id: 'deleteCollectionText' })}
          colorTheme={isClient ? DARK : LIGHT}
          onCancel={toggleDelete}
          submitFunction={onDelete}
          submitText={intl.formatMessage({ id: 'delete' })}
        />
      ) }

      {
        showShareCompanies && (
          <ShareCompaniesPopup
            onCancel={() => setShowShareCompanies(false)}
            collectionData={collectionItem}
          />
        )
      }
      <div className={styles.collectionItemHeader}>
        <div className={styles.infoBlock}>
          <div className={styles.collectionItemHeaderTitle}>{title}</div>
          <div className={styles.collectionItemHeaderTotal}>
            {trendsCount} <FormattedMessage id={trendsCount === 1 ? 'trendsTotalOne' : 'trendsTotal'} />
          </div>
        </div>

        {(isOwnCollection || isAdmin) && !hideActionBar && (
        <div className={styles.optionsBlock}>
          <Button
            colorTheme={SECONDARY}
            withBorder
            onClick={toggleDelete}
            onlyIcon
            componentStyle={{
              mainContainer: isClient ? styles.deleteButton : styles.deleteButtonAdmin,
            }}
          >
            <DeleteIcon />
          </Button>
          {!isClient && (
            <Button
              colorTheme={SECONDARY}
              withBorder
              onClick={toggleShareCollection}
              onlyIcon
              componentStyle={{
                mainContainer: isClient ? styles.deleteButton : styles.deleteButtonAdmin,
              }}
            >
              <ShareCollectionIcon />
            </Button>
          )}
        </div>
        ) }
      </div>
      <div className={classNames(styles.imgContainer, { [styles.clientView]: isClient })}>
        <div
          className={classNames(styles.imgWrap, { [styles.adminView]: isAdmin && creator })}
          onClick={goTo(`${COLLECTIONS}/${id}`)}
        >
          { postImageUrl ? <img src={postImageUrl} alt="collection-img" /> : <EmptyIcon />}
          <div className={styles.descriptionBlock}>
            <div className={styles.descriptionText}>
              {description}
            </div>
          </div>
        </div>
      </div>
      {creator && (
        <RoleVisibleWrapper role={[LOGIN_ROLES.ADMIN]}>
          <div className={styles.adminWrapper}>
            <div className={styles.adminItem}>
              <CollectionCreatorRow creator={creator} />
            </div>
            <div className={styles.adminItem}>
              <CollectionSharedRow companyList={companyList} />
            </div>
          </div>
        </RoleVisibleWrapper>
      )}
    </div>
  );
};

export default CollectionCard;
