import classNames from 'classnames';
import ResetFiltersButton from 'pages/reports/resetFiltersButton';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import HeadingFilters from 'components/heading-filters';
import ReportFilters from 'pages/reports/filters';
import ReportWidget from 'components/report-widget';
import InfiniteScroll from 'components/infinity-scroll';
import { getIsClient } from 'store/reducers/general/selectors';
import ClientReportWidget from 'components/client-report-widget';
import { requestReports } from 'store/reducers/reports/actions';
import { getReportsState } from 'store/reducers/reports/selectors';
import CollapseComponent from 'pages/reports/collapseComponent';
import ApiService from 'services/apiService';
import { getImages } from 'utils';
import KymIndexesFilters from './kymIndexesFilters';
import CategoriesCollapsedFilters from './categoriesCollapsedFilters';
import CommonFilters from './commonFilters';
import styles from './styles.module.scss';

const ReportsPage = () => {
  const [parsersDomains, setParsersDomains] = useState(null);
  const isClient = useSelector(getIsClient);
  const intl = useIntl();

  const ReportComponent = isClient ? ClientReportWidget : ReportWidget;

  useEffect(() => {
    ApiService.getParsersInfo().then((result) => {
      const parsersData = result?.data;
      const statusOk = result && result.status && result.status === 200;

      if (parsersData && statusOk) {
        const { parsersDomains = [] } = parsersData;
        setParsersDomains(parsersDomains);
      }
    });
  }, []);

  const RootList = ({ children }) => <div className={styles.containerGrid}>{ children }</div>;

  const ListComponent = ({ data, onRemove }) => {
    const { image, link } = data;
    const { postImageUrl } = getImages(image, link, parsersDomains);

    return (
      <div className={styles.itemContainer} key={`report_id_${data._id}`}>
        <ReportComponent
          onRemove={onRemove}
          report={data}
          imgLink={postImageUrl}
        />
      </div>
    );
  };

  return (
    <>
      <HeadingFilters title={<FormattedMessage id="reports" />} FiltersComponent={<ReportFilters />} />
      <div className="container">
        <div className={styles.reportsWrapper}>
          <div className={styles.mobileFilters}>
            <CollapseComponent name={intl.formatMessage({ id: 'filters' })} expanded={false}>
              <div className={classNames({ [styles.filtersClient]: isClient })}>
                <div className={styles.filtersTitleContainer}>
                  <ResetFiltersButton />
                </div>
                <CommonFilters />
                {!isClient && (
                  <CategoriesCollapsedFilters />
                )}
                <KymIndexesFilters />
              </div>
            </CollapseComponent>
          </div>

          <div className={styles.filters}>
            <div className={classNames(styles.filters, { [styles.filtersClient]: isClient })}>
              <div className={styles.filtersTitleContainer}>
                <h2 className={styles.filtersTitle}><FormattedMessage id="filters" /></h2>
                <ResetFiltersButton />
              </div>
              <CommonFilters />
              {!isClient && (
                <CategoriesCollapsedFilters />
              )}
              <KymIndexesFilters />
            </div>
          </div>

          <div className={styles.reportsContent}>
            <InfiniteScroll
              RootComponent={RootList}
              RenderComponent={ListComponent}
              dataSelector={getReportsState}
              loadAction={requestReports}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsPage;
