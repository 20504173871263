import React from 'react';
import classNames from 'classnames';
import { COLOR_THEMES } from 'consts';
import styles from './styles.module.scss';

const Link = (props) => {
  const {
    onClick = () => null,
    children,
    color = COLOR_THEMES.PRIMARY,
  } = props;

  return <a onClick={onClick} className={classNames(styles.link, styles[color])}>{children}</a>;
};

export default Link;
