import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import DateFormatter from 'components/dateFormatter';
import RouteRoleLink from 'components/route-role-link';
import { CABINET_ROUTES, COLOR_THEMES } from 'consts';
import COUNTRIES from 'consts/countries';
import LogoIcon from 'components/svg-icons/logo-icon';
import { useDispatch, useSelector } from 'react-redux';
import { getTypeKeys } from 'store/reducers/type-keys/selectors';
import { isWin, stripHtmlTags, changeTimeZone } from 'utils';
import ActionsBar from 'components/actions-bar';
import WriterIcon from 'components/svg-icons/writer-icon';
import ApiService from 'services/apiService';
import { removeTrend, setCollectionPopup, setTrendFilter } from 'store/reducers/trends/actions';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import ConfirmPopup from 'components/confirm-popup';
import Button from 'components/button';
import styles from './styles.module.scss';

const { TRENDS } = CABINET_ROUTES;
const defaultConfirmState = { show: false, onSubmit: () => {} };

const TrendWidget = ({
  trend, imgLink, isCollection, collectionId, isOwnCollection, isAdmin, hideActionBar = false,
}) => {
  const {
    _id: id,
    trendDate,
    title,
    description,
    typesKeys,
    countries,
    tags,
    status,
    creator,
  } = trend;

  const trendPath = `${TRENDS}/${id}`;
  const allTypesKeys = useSelector(getTypeKeys);
  const { navigate } = UseRoleNavigation();
  const [confirmState, setConfirmState] = useState(defaultConfirmState);
  const dispatch = useDispatch();
  const hideConfirm = () => setConfirmState(defaultConfirmState);
  const isWindows = isWin();
  const isDeclinedTrend = status === 'declined';

  const actionsBarClickHandler = (eventType) => {
    if (eventType === 'edit') {
      navigate(`${TRENDS}/${id}/edit`);
    }

    if (eventType === 'delete') {
      setConfirmState({
        show: true,
        onSubmit: () => ApiService.removeTrend(id)
          .then(() => dispatch(removeTrend(id))),
      });
    }

    if (eventType === 'collection') {
      dispatch(setCollectionPopup('addCollection', { itemId: id }));
    }

    if (eventType === 'deleteCollectionTrend') {
      dispatch(setCollectionPopup('deleteTrendCollection', { itemId: id }));
    }
  };

  const getEmoji = () => {
    const allEmojiItems = [];

    if (typesKeys
      && typesKeys.length
      && allTypesKeys
      && allTypesKeys.length
    ) {
      allTypesKeys.forEach((typeKey) => {
        if (typesKeys.includes(typeKey._id)) {
          allEmojiItems.push({ emoji: typeKey.emoji, name: typeKey.title });
        }
      });
    }

    if (countries && countries.length) {
      COUNTRIES.forEach((country) => {
        if (countries.includes(country.code)) {
          const { emoji, name, code } = country;
          allEmojiItems.push({ emoji: isWindows ? code : emoji, name, isWrapNeeded: isWindows });
        }
      });
    }

    return allEmojiItems.map((eItem) => (
      <div
        title={eItem.name}
        key={`emoji-key-${id}-${eItem.name}`}
        className={classNames(styles.emojiTag, { [styles.emojiTagWrap]: eItem?.isWrapNeeded })}
      >
        {eItem.emoji}
      </div>
    ));
  };

  const clickTag = (tag) => {
    dispatch(setTrendFilter('search', tag, null, collectionId));
  };

  const getClicableTags = () => {
    if (!tags || !tags.length) {
      return null;
    }

    return tags.map((tag) => (
      <div
        key={`tags-${id}-${tag}`}
        className={styles.tagItem}
        onClick={() => clickTag(tag)}
      >
        {`#${tag}`}
      </div>
    ));
  };

  return (
    <div className={styles.trendContainer}>
      <div className={styles.topLine}>
        <div className={styles.date}>
          <DateFormatter date={changeTimeZone(trendDate)} showTime={false} />
        </div>
        <div className={styles.creator}>
          <WriterIcon />
          <span className={styles.creatorName}>{creator.email || '-'}</span>
        </div>
      </div>
      <div className={styles.imageWraper}>
        <span className={classNames(styles.status, styles[status])}>
          <FormattedMessage id={status} />
        </span>
        <RouteRoleLink path={trendPath}>
          <div className={styles.image}>
            { imgLink ? <img src={imgLink} alt="report-img" /> : <LogoIcon />}
          </div>
        </RouteRoleLink>
        {!hideActionBar && (
          <ActionsBar
            isAdmin={isAdmin}
            isOwnCollection={isOwnCollection}
            containerStyle={styles.eventButtons}
            editDisabled={false}
            onClick={actionsBarClickHandler}
            isCollection={isCollection}
            isDeclinedTrend={isDeclinedTrend}
          />
        )}
      </div>
      <RouteRoleLink path={`${TRENDS}/${id}`}>
        <div className={styles.title} title={title}>
          {title}
        </div>
      </RouteRoleLink>
      <div className={styles.emojiWrapper}>
        {getEmoji()}
        <div className={styles.fadeBlock} />
      </div>
      <div className={styles.tagsWrapper}>
        {getClicableTags()}
        <div className={styles.fadeBlock} />
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: stripHtmlTags(description) }}
      />
      <div className={styles.viewReportButtonContainer}>
        <RouteRoleLink path={`${CABINET_ROUTES.TRENDS}/${id}`}>
          <Button
            colorTheme={COLOR_THEMES.PRIMARY}
          >
            <FormattedMessage id="viewFullTrend" />
          </Button>
        </RouteRoleLink>
      </div>
      { confirmState.show && (
        <ConfirmPopup
          onCancel={hideConfirm}
          submitFunction={() => {
            confirmState.onSubmit();
            hideConfirm();
          }}
        />
      ) }
    </div>
  );
};

export default TrendWidget;
