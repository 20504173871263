import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const FieldRow = (props) => {
  const { label, children, additionalStyle } = props;

  return (
    <label className={classNames(styles.fieldRow, additionalStyle)}>
      {label && <span className={styles.fieldLabel}>{label}</span>}
      {children}
    </label>
  );
};

export default FieldRow;
