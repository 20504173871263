import {
  SET_HASHTAGS_FILTER,
  SET_HASHTAGS,
  SET_HASHTAGS_LOADING,
  SET_HASHTAGS_ERROR,
} from './action.types';

const initialFilters = {
  searchTag: '',
  sortingNameDirection: '',
};

const initialState = {
  data: [],
  loading: true,
  error: false,
  filters: { ...initialFilters },
};

const hashtagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HASHTAGS:
      return { ...state, data: action.hashtags };
    case SET_HASHTAGS_ERROR:
      return { ...state, error: action.error };
    case SET_HASHTAGS_LOADING:
      return { ...state, loading: action.loading };
    case SET_HASHTAGS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

export default hashtagsReducer;
