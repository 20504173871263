import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import nextId from 'react-id-generator';
import UseRoleNavigation from 'hooks/useRoleNavigation';
import UseFormData from 'hooks/useFormData';
import LoadingComponent from 'components/loadingComponent';
import Input from 'components/fields/input';
import Button from 'components/button';
import ConfirmPopup from 'components/confirm-popup';
import TextEditor from 'pages/create-report/components/text-editor';
import ApiService from 'services/apiService';
import notificationService from 'services/notificationService';
import { COLOR_THEMES, CABINET_ROUTES } from 'consts';
import FormBox from './form-box';
import FieldRow from './field-row';
import styles from './styles.module.scss';
import glossaryValidator from './glossary-validator';

const { TRENDS } = CABINET_ROUTES;

const initialData = {
  _id: nextId(),
  pageTitle: '',
  title: '',
  description: '',
};
const defaultConfirmState = { show: false, onSubmit: () => {} };

const GlossaryPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { navigate } = UseRoleNavigation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageData, setpageData] = useState(initialData);
  const [confirmState, setConfirmState] = useState(defaultConfirmState);
  const hideConfirm = () => setConfirmState(defaultConfirmState);

  const {
    pageTitle,
    title,
    description,
  } = pageData;

  useEffect(() => {
    ApiService.getGlossary().then((res) => {
      if (res.error) {
        notificationService.addNotification(res.error);
        navigate(`${TRENDS}`);
        return;
      }

      setpageData(res.data);
      setLoading(false);
    });
  }, [dispatch]);

  const {
    data,
    onChange,
    validateForm,
  } = UseFormData({
    data: pageData,
    validators: glossaryValidator,
  });

  const onChangeDescription = (value) => onChange('description', value);

  const cancelHandler = async () => {
    setConfirmState({
      show: true,
      onSubmit: () => {
        navigate(`${TRENDS}`);
      },
    });
  };

  const saveHandler = async () => {
    setError(null);
    const validateObj = validateForm();

    if (validateObj) {
      setError(validateObj);
      return;
    }

    setLoading(true);

    const resp = await ApiService.editGlossary(data);

    if (resp && resp.status < 400) {
      navigate(`${TRENDS}`, true);
    }
  };

  const loadingComponentText = intl.formatMessage({ id: 'loading' });

  return (
    <>
      { loading
        ? <LoadingComponent text={loadingComponentText} />
        : (
          <div className="container">
            <div className={styles.row}>
              <h2 className={styles.title}>
                {title
                  ? `Edit ${title}`
                  : <FormattedMessage id="glossaryTitleEdit" />}
              </h2>
            </div>
            <FormBox>
              <div className={styles.generalData}>
                <div className={styles.formRow}>
                  <div className={styles.formRowFields}>
                    <FieldRow label={`${intl.formatMessage({ id: 'glossaryPageTitle' })}`}>
                      <Input
                        name="pageTitle"
                        value={pageTitle}
                        placeholder={intl.formatMessage({ id: 'glossaryPageTitle' })}
                        fullWidth
                        onChange={onChange}
                        errorMessage={error && error.pageTitle}
                      />
                    </FieldRow>
                    <FieldRow label={`${intl.formatMessage({ id: 'glossaryMainTitle' })}`}>
                      <Input
                        name="title"
                        value={title}
                        placeholder={intl.formatMessage({ id: 'glossaryMainTitle' })}
                        fullWidth
                        onChange={onChange}
                        errorMessage={error && error.title}
                      />
                    </FieldRow>
                    <FieldRow label={intl.formatMessage({ id: 'glossaryDescription' })}>
                      <div className={styles.textEditorWrap}>
                        <TextEditor
                          big
                          dark
                          initialText={description}
                          onChange={onChangeDescription}
                          placeholder={intl.formatMessage({ id: 'glossaryDescription' })}
                          allOptions
                        />
                        {
                          error && error.description
                          && <div className={styles.errorText}>{error.description}</div>
                        }
                      </div>
                    </FieldRow>
                  </div>
                </div>
              </div>
            </FormBox>
            <div className={styles.footer}>
              <div className={styles.footerRightContent}>
                <Button
                  colorTheme={COLOR_THEMES.LIGHT}
                  size="medium"
                  onClick={cancelHandler}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  size="medium"
                  onClick={saveHandler}
                >
                  <FormattedMessage id="save" />
                </Button>
              </div>
            </div>
            { confirmState.show && (
              <ConfirmPopup
                title={intl.formatMessage({ id: 'glossaryCancelTitle' })}
                text={intl.formatMessage({ id: 'glossaryCancelDescription' })}
                onCancel={hideConfirm}
                submitFunction={() => {
                  confirmState.onSubmit();
                  hideConfirm();
                }}
              />
            ) }
          </div>
        )}
    </>
  );
};

export default GlossaryPage;
