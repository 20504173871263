import React, { useState, useEffect, useRef } from 'react';
import IconPath from 'components/emoji-comment/emoji-picker/icon';
import styles from './styles.module.scss';
import emojiGroup from './data/emoji-group-data.json';

let emojiList = [];

Object.keys(emojiGroup).forEach((groupName) => {
  const groupList = emojiGroup[groupName];
  if (groupList && groupList.length) {
    emojiList = [...emojiList, ...groupList];
  }
});

const EmojiPicker = (props) => {
  const {
    output = () => {},
    highlight = 'lightblue',
    recently = true,
    recentlyData = null,
    clearRecently,
  } = props;

  const [search, setSearch] = useState('');
  const [recentlyLocal, setRecentlyLocal] = useState([]);

  const pickerRef = useRef(null);
  const scrollRef = useRef(null);

  const ScrollTo = (key) => {
    const el = document.getElementById(key);
    if (el) {
      scrollRef.current.scrollTop = el.offsetTop - 110;
    }
  };

  useEffect(() => {
    pickerRef.current.parentElement.style.position = 'relative';
    if (recently && !recentlyData) {
      const items = JSON.parse(localStorage.getItem('react-emoji-selectors'));
      if (items) {
        setRecentlyLocal(items);
      } else {
        setRecentlyLocal([]);
      }
    }
  }, []);

  const handleOutput = (data) => {
    output(data);

    if (recently && !recentlyData) {
      const newArr = [...recentlyLocal];
      if (!newArr.includes(data)) {
        newArr.splice(0, 0, data);
      } else {
        newArr.splice(newArr.indexOf(data), 1);
        newArr.splice(0, 0, data);
      }
      if (newArr.length > 14) {
        newArr.splice(newArr.length - 1, 1);
      }
      setRecentlyLocal(newArr);
      localStorage.setItem('react-emoji-selectors', JSON.stringify(newArr));
    }
  };

  const handleClearRecently = () => {
    if (clearRecently) {
      clearRecently();
    } else {
      setRecentlyLocal([]);
      localStorage.setItem('react-emoji-selectors', JSON.stringify([]));
    }
  };

  return (
    <div
      ref={pickerRef}
      className={styles.emojiPicker}
    >
      <div className={styles.fixedPanel}>
        <div className={styles.emojiFooter}>
          <div
            onClick={() => ScrollTo('Smileys & Emotion')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg version="1.1" x="0px" y="0px" width="17px" height="17px" viewBox="0 0 106.059 106.06">
              <path d={IconPath.smile} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('People & Body')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16 16" version="1.1">
              <path d={IconPath.hand} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Animals & Nature')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512.001 512.001" width="20px" height="20px">
              <path d={IconPath.dog} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Food & Drink')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32">
              <path d={IconPath.pizza[0]} />
              <path d={IconPath.pizza[1]} />
              <path d={IconPath.pizza[2]} />
              <path d={IconPath.pizza[3]} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Travel & Places')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
              <path d={IconPath.globe} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Activities')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="17px" height="17px" viewBox="0 0 390.044 390.043">
              <path d={IconPath.basketball} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Objects')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg width="17px" height="17px" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
              <path d={IconPath.light} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Symbols')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 20 20">
              <path d={IconPath.lang} />
            </svg>
          </div>
          <div
            onClick={() => ScrollTo('Flags')}
            onMouseEnter={(e) => { e.target.style.color = highlight; }}
            onMouseLeave={(e) => { e.target.style.color = 'rgb(0,0,0,0.4)'; }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16px" height="16px" x="0px" y="0px" viewBox="0 0 489 489">
              <path d={IconPath.flag} />
            </svg>
          </div>
        </div>
        <div className={styles.searchWrap}>
          <div className={styles.emojiSearch}>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search emoji"
            />
          </div>
        </div>
        <div className={styles.recentlyWrap}>
          {recently && recentlyData
            ? (
              <>
                {recentlyData.length > 0
                && (
                  <div id="emoji-recently" className={styles.emojiGroup}>
                    <div className={styles.emojiRecentlyTitle}>
                      <div>Recently</div>
                      <div onClick={handleClearRecently}>Clear</div>
                    </div>
                    <div className={styles.emojiGrid}>
                      {recentlyData.map((data) => (
                        <div
                          onClick={() => handleOutput(data)}
                          key={`emoji_recently_${data}`}
                          onMouseEnter={(e) => { e.target.style.backgroundColor = highlight; }}
                          onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {recentlyLocal.length > 0
                && (
                  <div id="emoji-recently" className={styles.emojiGroup}>
                    <div className={styles.emojiRecentlyTitle}>
                      <div>Recently</div>
                      <div onClick={handleClearRecently}>Clear</div>
                    </div>
                    <div className={styles.emojiGrid}>
                      {recentlyLocal.map((data) => (
                        <div
                          onClick={() => handleOutput(data)}
                          key={`emoji_recently_${data}`}
                          onMouseEnter={(e) => { e.target.style.backgroundColor = highlight; }}
                          onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
      </div>

      <div ref={scrollRef} className={styles.emojiContainer}>

        <div className={styles.emojiBody}>
          {search === ''
            ? (
              <>
                {Object.keys(emojiGroup).map((key) => (
                  <div id={key} key={key} className={styles.emojiGroup}>
                    <div>{key}</div>
                    <div className={styles.emojiGrid}>
                      {emojiGroup[key].map((data) => (
                        <div
                          onClick={() => handleOutput(data.emoji)}
                          key={`emoji_item_${data.emoji}`}
                          onMouseEnter={(e) => { e.target.style.backgroundColor = highlight; }}
                          onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                        >
                          {data.emoji}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.emojiGrid}>
                {emojiList.filter((key) => {
                  if (key.name.includes(search.toLowerCase())) {
                    return key;
                  }
                  return false;
                }).map((key) => (
                  <div
                    onClick={() => handleOutput(key.emoji)}
                    key={key.emoji}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = highlight; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                  >
                    {key.emoji}
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default EmojiPicker;
