import React, { useEffect } from 'react';
import HeadingFilters from 'components/heading-filters';
import StaffFilters from 'admin/pages/staff/filters';
import StaffList from 'admin/pages/staff/list';
import styles from 'admin/pages/staff/list/styles.module.scss';
import LoadingComponent from 'components/loadingComponent';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { requestStaff, setStaffLoading } from 'store/reducers/staff/actions';
import { getStaffLoading, getStaff } from 'store/reducers/staff/selectors';

const StaffPage = () => {
  const staff = useSelector(getStaff);
  const isLoading = useSelector(getStaffLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestStaff());

    return () => {
      dispatch(setStaffLoading(true));
    };
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  const TitleComponent = (
    <div className={styles.companyWrapper}>
      <div className={styles.company}>
        <h2 className={styles.companyName}><FormattedMessage id="staffList" /></h2>
      </div>
    </div>
  );

  return (
    <>
      <HeadingFilters
        title={TitleComponent}
        FiltersComponent={<StaffFilters usersCount={staff.length} />}
      />
      <div className="container">
        <StaffList users={staff} />
      </div>
    </>
  );
};

export default StaffPage;
