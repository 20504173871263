export default {
  loginWelcome: 'Welcome to',
  loginButton: 'Log in',
  loginContent: 'We are ordering north time curiously roof-pierced. Providence still would that mies, neglected effects? Good incantations very mysterious them, charles after? Strange which notes some, twilight mind could! Direction literature announced with, that realm less!',
  cancel: 'Cancel',
  add: 'Add',
  error404BackHome: 'Back to home',
  error404NotFound: 'Page not found',
  generateReport: 'Generate report',
  generateTrend: 'Generate trend',
  manageReports: 'Manage reports',
  manageCompanies: 'Manage companies',
  logOutAccount: 'Log out',
  reports: 'Reports',
  typeReportName: 'Type report name',
  typeTrendName: 'Type trend name',
  sortBy: 'Sort by',
  companiesList: 'List of companies',
  typeCompanyName: 'Type company name',
  selectAll: 'Select All',
  addCompany: 'Add company',
  editCompany: 'Edit company',
  enterCompanyName: 'Enter company name',
  trendCompanies: 'Shared companies',
  deleteSelected: 'Delete selected',
  addUser: 'Add user',
  editUser: 'Edit user',
  editStaff: 'Edit staff',
  typeUsersEmail: 'Type user’s email',
  companyUsers: 'Company’s users',
  users: 'users',
  chooseRole: 'Choose role',
  email: 'Email',
  admin: 'Admin',
  contributor: 'Contributor',
  'power-client': 'Poweruser',
  client: 'Client',
  addUserError: 'Please enter correct email or choose role',
  companyNameRequired: 'Company name is required',
  save: 'Save',
  viewUsers: 'View users',
  yes: 'Yes',
  deletion: 'Deletion',
  deleteConfirm: 'Are you sure you want to confirm the deletion action?',
  deleteCharacterConfirm: 'Are you sure you want to delete this influencer?',
  searchCompaniesOrUsers: 'Search companies or users',
  chooseCompaniesOrUsers: 'Choose companies or users',
  myReports: 'My reports',
  myTrends: 'My trends',
  backToAllReports: 'Back to all reports',
  back: 'Back',
  totalLikes: 'Total likes',
  totalReposts: 'Total reposts',
  totalComments: 'Total comments',
  totalViews: 'Total views',
  allTrends: 'All trends',
  sharedWith: 'Shared with',
  shareWith: 'Share with',
  shareTo: 'Shared to',
  viewFullReport: 'View full report',
  viewFullTrend: 'View Full Trend',
  trends: 'Trends',
  trend: 'Trend',
  previewMode: 'Preview mode',
  close: 'Close',
  newest: 'Newest',
  oldest: 'Oldest',
  sortAlphabetAsc: 'Alphabet A-Z',
  sortAlphabetDesc: 'Alphabet Z-A',
  compareSelected: 'Compare selected',
  reportGeneration: 'Report generation',
  reportName: 'Report Name',
  reportNamePlaceholder: 'e.g. Kids Toys Trend Report',
  description: 'Description',
  dontSendEmail: 'Don\'t send the email',
  shortReportDescription: 'Short description of report',
  enterCompanyOrUser: 'Enter company or user',
  addTrend: 'Add trend',
  saveDraft: 'Save as draft',
  generate: 'Generate',
  trendGeneration: 'Trend generation',
  editTrend: 'Edit trend',
  trendTitlePlaceholder: 'e.g. toys',
  trendDescription: 'Trend description',
  shortTrendDescription: 'Short description of trend',
  trendTypesKeys: 'Trend type keys',
  trendMainCharacter: 'Influencer',
  upload: 'Upload',
  addLink: 'Add link',
  link: 'Link',
  putLinkOfPost: 'Enter link to post',
  enterHeadlinePost: 'Enter headline of the post',
  headline: 'Headline',
  manageStaff: 'Manage staff',
  staff: 'Staff',
  filterByStatus: 'Filter by status',
  allStatuses: 'All',
  approved: 'Approved',
  pending: 'Pending',
  declined: 'Declined',
  draft: 'Draft',
  moreUsers: 'More users',
  lessUsers: 'Less users',
  manageUsers: 'Manage users',
  trendCategory: 'Trend category',
  trendCountry: 'Trend country',
  trendTags: 'Trend tags',
  userLimit: 'User limit',
  userLimitRequired: 'User limit is required',
  companies: 'Companies',
  glossary: 'Glossary/FAQ',
  categories: 'Categories',
  hashtags: 'Hashtags',
  typeKeys: 'Type keys',
  addCategory: 'Add category',
  searchCategory: 'Search categories',
  categoryNameRequired: 'Category name is required',
  editCategory: 'Edit category',
  enterCategoryName: 'Enter category name',
  addHashtag: 'Add hashtag',
  editHashtag: 'Edit hashtag',
  searchHashtags: 'Search hashtags',
  enterHashtag: 'Enter hashtag name',
  enterHashtags: 'Enter hashtags',
  hashtagNameRequired: 'Hashtag name is required',
  useParsedInfo: 'Use parsed info',
  addInfoManually: 'Add info manually',
  image: 'Image',
  wait: 'Please wait a moment',
  edit: 'Edit',
  savePdf: 'Save as PDF',
  editReport: 'Edit report',
  saveChanges: 'Save changes',
  addTypeKey: 'Add type key',
  editTypeKey: 'Edit type key',
  enterTypeKeyName: 'Enter type key name',
  typeKeyNameRequired: 'Type key name required',
  searchTypeKey: 'Search type key',
  approveReport: 'Approve and Publish',
  declineReport: 'Decline',
  enterTagsDescription: 'Type hashtag name (without “#”) or choose from autocomplete  then press “Enter”',
  typeHashtags: 'Type hashtag name (without “#”) or choose from autocomplete  then press “Enter”',
  emptyMetricLeave: 'Leave the field empty if you don’t have data for it',
  notificationListHeader: 'Notifications',
  notificationListClear: 'Clear all',
  notificationReportForReviewDescription: 'Report for review',
  notificationReportRejectedDescription: 'Report declined',
  notificationReportPublishedDescription: 'New report',
  notificationReportApprovedDescription: 'Report approved',
  forApproval: 'For approval',
  created: 'Created',
  trendDate: 'Trend date',
  trendDatePlaceholder: 'Date when trade become popular',
  updated: 'Updated',
  edited: 'Edited',
  kymIndex: 'KYM Index',
  viral: 'Viral',
  viralTooltip: 'Viral evaluates the popularity and engagement of a trend',
  relevant: 'Relevant',
  relevantTooltip: 'Relevant evaluates the topicals and how well it fits into the conversation of the internet',
  recent: 'Recent',
  recentTooltip: 'Recent evaluates the timeliness and age of a trend',
  spread: 'Spread',
  spreadTooltip: 'Spread evaluates the distribution of a trend across different communities and platforms',
  volume: 'Volume',
  volumeTooltip: 'Volume evaluates the count of posts related to the trend',
  onlyImagesAllowed: 'Only images allowed',
  imageMaxSize: 'Image maximum size is {size}mb',
  author: 'Author',
  staffList: 'List of Staff',
  no: 'No',
  noReports: 'No reports by your request',
  noTrends: 'No trends by your request',
  noPeople: 'No influencers by your request',
  noCollections: 'No collections by your request',
  noPeopleCollections: 'No influencers collections by your request',
  filters: 'Filters',
  filtersLikes: 'Likes',
  filtersShares: 'Reposts',
  filtersComments: 'Comments',
  filtersViews: 'Views',
  filtersCategories: 'Categories',
  filtersTypes: 'Type',
  filtersViral: 'Viral',
  filtersRelevant: 'Relevant',
  filtersRecent: 'Recent',
  filtersSpread: 'Spread',
  filtersVolume: 'Volume',
  filtersKym: 'KYM Index',
  filtersPlatforms: 'Platforms',
  filtersCountry: 'Country',
  filtersDateRange: 'Date Range',
  parsing: 'Parsing',
  parsingPostError: 'Unable to parse this link. Paste another link or add information manually',
  parsingPostSuccess: 'Link was parsed successfully',
  unableToParseLink: 'Unable to parse link',
  successParseLink: 'Link was parsed success',
  reset: 'Reset',
  enterDataManually: 'Please enter data manually',
  contactUs: 'Contact Us',
  privacyPolicy: 'Privacy Policy',
  subscribe: 'Subscribe',
  subscribeAndLearn: 'Subscribe & learn more',
  blog: 'Blog',
  sendMessage: 'Send message',
  reportEditSavingMessage: 'Updating...',
  reportEditInitialMessage: 'Loading...',
  searchCountry: 'Search countries',
  loading: 'Loading...',
  glossaryTitle: 'Glossary/FAQ',
  glossaryTitleEdit: 'Glossary/FAQ Edit',
  glossaryPageTitle: 'Link title',
  glossaryMainTitle: 'Title',
  glossaryDescription: 'Description',
  glossaryCancelTitle: 'Cancel edit',
  glossaryCancelDescription: 'Do you really want to cancel glossary edit?',
  trendComments: 'Comments',
  commentsSortDate: 'By date',
  commentsSortPopular: 'By popular',
  commentsSortMostLiked: 'By most liked',
  likes: 'likes',
  likesOne: 'like',
  reply: 'Reply',
  replyingTo: 'Replying to',
  collections: 'Collections',
  emptyCollectionList: 'EMPTY COLLECTION LIST',
  emptyPeopleCollectionList: 'EMPTY INFLUENCERS COLLECTION LIST',
  done: 'Done',
  deleteCommentTitle: 'Delete comment',
  deleteCommentText: 'Are you sure you want to delete comment?',
  delete: 'Delete',
  commentWasDeleted: 'Comment was deleted',
  deleteCollection: 'Delete collection',
  deleteCollectionText: 'This Collection will be deleted. Continue?',
  deletePeopleCollection: 'Delete collection',
  deletePeopleCollectionText: 'This Collection will be deleted. Continue?',
  addTrendToCollection: 'Add this trend to a collection',
  addCharacterToCollection: 'Add this influencer to a collection',
  createNewCollection: 'Create new collection',
  createCollection: 'Create collection',
  trendsTotal: 'trends',
  trendsTotalOne: 'trend',
  name: 'Name',
  descriptionInput: 'Description (optional)',
  share: 'Share',
  editCollection: 'Edit collection',
  update: 'Update',
  deleted: 'Deleted',
  deleteTrendCollection: 'Are you sure you want to delete the trend from the collection?',
  deleteTrendCollectionTitle: 'Delete Trend',
  addToCollection: 'Add to collection',
  searchCompany: 'Search company',
  readMore: 'Read more',
  showLess: 'Show less',
  createdBy: 'Created by',
  termsAndConditions: 'Terms and Conditions',
  filterByCompany: 'By company',
  all: 'All',
  searchCollection: 'Search collection',
  typeCollectionName: 'Type collection name',
  mentionCommentNotifText: 'mentioned you in a comment',
  wordCloud: 'Word cloud',
  allCompanies: 'All companies',
  customizeWordCloud: 'Customize your word cloud',
  savePng: 'Save PNG',
  excludedWords: 'Excluded Words',
  emptyWordCloud: 'Word list is empty',
  wordsAppearing: 'Words appearing',
  imgUploadError: 'Max upload size 5mb',
  imgNotSupported: 'File format not supported',
  people: 'Influencers',
  peopleCollections: 'Influencers Collections',
  addNew: 'Add new',
  typePeopleName: 'Type influencer name',
  addNewCharacter: 'Add new influencer',
  editCharacter: 'Edit influencer',
  enterName: 'Enter name',
  mainCharacter: 'Influencer',
  mainCharacterName: 'Influencer name',
  mainCharacters: 'Influencers',
  characterNameRequired: 'Influencer name is required',
  characterEmptyTags: 'This influencer has no tags yet',
  characterNoResults: 'No results found',
  characterNoPeople: 'No influencers by your request',
  popularLess: 'Less popular',
  popularMore: 'More popular',
  addedToCollection: 'Added to collections',
  inTrends: 'In trends',
  socialMedia: 'Social media',
  addUserDownloadExample: 'Download example',
  addUserUploadEmails: 'Upload the emails',
  bulkUploadUserErrorExt: 'Supports only .xls and .xlsx files',
  bulkUploadErrorContent: 'You upload are not valid file content',
  bulkUploadSuccess: 'File uploaded successfully. You uploaded {value} emails',
  addUserLimitError: 'Exceeded the limit of users in the company',
  peopleCount: 'people',
  userExamplePosts: 'User Examples',
  brandExamplePosts: 'Brand Examples',
  peopleTotal: 'people',
  peopleTotalOne: 'person',
  emptyCollection: 'This collection is empty',
  emptyTrends: 'Empty trends list',
};
